import {FC, ReactElement} from "react";
import {IconName, SpanSubtitle2, Svg, ThemeType} from "@linkeo.com/ui-lib-react";
import {useTheme} from "styled-components";

interface ServiceInfoProps {
    icon?: IconName;
    text: string;
    children?: ReactElement;
}
export const ServiceInfo: FC<ServiceInfoProps> = ({icon, text, children}) => {
    const theme = useTheme() as ThemeType;

    return <span style={{marginRight: '15px'}}>
        {icon && <Svg icon={icon} height={20} width={20} fill={theme.colors.grey40} style={{verticalAlign: 'middle', marginRight: '5px'}}/>}
        {children}
        <SpanSubtitle2>
            {text}
        </SpanSubtitle2>
    </span>
}