import {FC, useState} from "react";
import {PageWrapper} from "../../commons/components/page-wrapper";
import {Container} from "@linkeo.com/ui-lib-react";
import {ParamsCard} from "../../params/components/params-card";
import {useIntl} from "react-intl";
import {ParamsNotificationsManagerForm} from "../../params/components/notifications/params-notifications-manager-form";
import {ParamsNotificationsForm} from "../../params/components/notifications/params-notifications-form";
import {BackToTop} from "../../commons/components/back-to-top";

export const ParamsNotificationsPage: FC = () => {
    const intl = useIntl();
    const [isLoading] = useState<boolean>(false);

    return <PageWrapper isLoading={isLoading}>
        <Container size={'lg'}>
            <ParamsCard
                title={intl.formatMessage({
                    id: 'ParamsNotificationsPageManagerTitle',
                    defaultMessage: 'Notification de rendez-vous (au gérant)'
                })}
                subtitle={intl.formatMessage({
                    id: 'ParamsNotificationsPageManagerSubTitle',
                    defaultMessage: 'Paramétrez l\'envoi automatique d\'une notification sur le téléphone mobile principal lors de la création/modification/annulation d\'un rendez-vous.'
                })}>
                <ParamsNotificationsManagerForm/>
            </ParamsCard>
            <ParamsCard
                title={intl.formatMessage({
                    id: 'ParamsNotificationsPageEmployeeTitle',
                    defaultMessage: 'Rappel de rendez-vous (à l\'employé)'
                })}
                subtitle={intl.formatMessage({
                    id: 'ParamsNotificationsPageEmployeeSubTitle',
                    defaultMessage: 'Paramétrez l\'envoi automatique d\'un rappel de rendez-vous sur le téléphone mobile associé à chaque planning individuel.'
                })}>
                <ParamsNotificationsForm
                    emailLabel={intl.formatMessage({
                        id: 'ParamsNotificationsEmployeeFormEmailSwitchLabel',
                        defaultMessage: 'Recevez un rappel du rendez-vous par e-mail.'
                    })}
                    smsLabel={intl.formatMessage({
                        id: 'ParamsNotificationsEmployeeFormSmsSwitchLabel',
                        defaultMessage: 'Recevez un rappel du rendez-vous par SMS.'
                    })}
                />
            </ParamsCard>
            <ParamsCard
                title={intl.formatMessage({
                    id: 'ParamsNotificationsPageClientTitle',
                    defaultMessage: 'Rappel de rendez-vous (au client)'
                })}
                subtitle={intl.formatMessage({
                    id: 'ParamsNotificationsPageClientSubTitle',
                    defaultMessage: 'Paramétrez l\'envoi automatique d\'un rappel de rendez-vous à vos clients, pour vous assurer de leur présence.'
                })}>
                <ParamsNotificationsForm
                    emailLabel={intl.formatMessage({
                        id: 'ParamsNotificationsClientFormEmailSwitchLabel',
                        defaultMessage: 'Proposez aux clients un rappel du rendez-vous par e-mail.'
                    })}
                    smsLabel={intl.formatMessage({
                        id: 'ParamsNotificationsclientFormSmsSwitchLabel',
                        defaultMessage: 'Proposez aux clients un rappel du rendez-vous par SMS.'
                    })}
                />
            </ParamsCard>
            <BackToTop />
        </Container>
    </PageWrapper>
}