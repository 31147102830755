import {Dispatch, FC, SetStateAction, useState} from "react";
import {
    Col,
    ColorPicker, getTypo,
    Input,
    InputNumber, LabelWrapper,
    Row,
    SelectOption, SimpleSelect, Uploader,
    useToaster
} from "@linkeo.com/ui-lib-react";
import {CKEditor} from '@ckeditor/ckeditor5-react';
import Editor from "ckeditor5-custom-build";
import {useIntl} from "react-intl";
import styled from "styled-components";
import {ServiceFormProps} from "./service-edit-form";
import {Category, defaultColor} from "../services-types";
import {useLocale} from "../../providers/intl-provider";

const WysiwygWrapper = styled.div`
    ${getTypo('input')};

    .ck {
        height: 200px;
        border: 1px solid ${({theme}) => theme.colors.grey90};
        border-radius: 4px;
        box-sizing: border-box;
        background-color: ${({theme}) => theme.colors.grey95};
        color: ${({theme}) => theme.colors.grey20};
        transition: border-color ease-in-out 200ms, box-shadow ease-in-out 200ms;

        &:hover {
            border-color: ${({theme}) => theme.colors.grey60};
        }
    }

    .ck.ck-editor__editable.ck-focused:not(.ck-editor_nested-editable) {
        border: 1px solid ${({theme}) => theme.colors.grey60};
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    }
`

interface ServiceInfosFormProps {
    form: ServiceFormProps;
    setForm: Dispatch<SetStateAction<ServiceFormProps>>;
    categories: Category[] | null;
    codeBouton: string;
    token: string;
    apiUri: string;
}

export const ServiceInfosForm: FC<ServiceInfosFormProps> = ({
                                                                form,
                                                                setForm,
                                                                categories,
                                                                codeBouton,
                                                                token,
                                                                apiUri
                                                            }) => {
    const intl = useIntl();
    const toast = useToaster();
    const {locale} = useLocale();
    const selectOptions: { label: string; value: Category | undefined }[] | undefined = categories ? [{
        value: undefined,
        label: intl.formatMessage({id: 'ParamsServiceInfosFormNoCategoryLabel', defaultMessage: 'Pas de catégorie'})
    }, ...categories.map(el => {
        return {
            label: el.title,
            value: el
        }
    })] : undefined;
    const [selectedOptions, setSelectedOptions] = useState<SelectOption<Category | undefined>|undefined>(
        selectOptions?.find(el => el.value?.id === form.category?.id)
    );

    const onCategorySelect = (category: SelectOption<{
        id: string,
        title: string,
        description: string
    } | undefined> | undefined) => {
        setSelectedOptions(category);
        setForm({...form, category: category?.value})
    }

    return <div style={{margin: '28px 0'}}>
        <Row>
            <Col columns={[12, 12, 3]}>
                <div style={{marginTop: "36px"}}>
                    <Uploader
                        small
                        buttonLabel={intl.formatMessage({
                            id: 'buttonLabel',
                            description: 'parcourir l\'ordinateur'
                        })}
                        middleLabel={intl.formatMessage({
                            id: 'ParamsServiceInfosFormImageBlockupLoaderMessage',
                            defaultMessage: 'Déposer{br} une image ici ou'
                        }, {br: <br/>}) as string}
                        onError={() => toast(intl.formatMessage({
                            id: 'errorMessage',
                            defaultMessage: 'Une erreur est survenue'
                        }))}
                        previewStyle={{borderRadius: '3px'}}
                        width={'100%'} height={'200px'}
                        fileType={'image/*'}
                        apiBaseUri={apiUri}
                        codeBouton={codeBouton}
                        token={token}
                        value={form.file}
                        onChange={(file) => setForm({...form, file})}
                    />
                </div>
            </Col>
            <Col columns={[12, 12, 5]}>
                <Input type={'text'} required
                       value={form.title}
                       onChange={(title) => setForm({...form, title})}
                       label={intl.formatMessage({
                           id: 'ParamsServiceInfosFormTitleInputLabel',
                           defaultMessage: 'Titre de la prestation'
                       })}/>
                <LabelWrapper required label={intl.formatMessage({
                    id: 'ParamsServiceInfosFormDescriptionInputLabel',
                    defaultMessage: 'Description de la prestation'
                })}>
                    <WysiwygWrapper>
                        <CKEditor editor={Editor}
                                  data={form.description}
                                  config={{
                                      toolbar:  [
                                              'bold',
                                              'italic',
                                              'link',
                                              'underline',
                                              'fontColor',
                                              '|',
                                              'bulletedList',
                                              'numberedList',
                                              '|',
                                              'undo',
                                              'redo'
                                          ],
                                      language: locale.substring(0, 2),
                                  }}
                                  onChange={(_, editor) => setForm({...form, description: editor.getData()})}
                        />
                    </WysiwygWrapper>
                </LabelWrapper>
            </Col>
            <Col columns={[12, 12, 4]}>
                <InputNumber type={'number'}
                             value={form.price}
                             step={0.01}
                             required
                             min={0.0}
                             onChange={(price) => price !== null && setForm({...form, price})}
                             label={intl.formatMessage({
                                 id: 'ParamsServiceInfosFormPriceInputLabel',
                                 defaultMessage: 'Prix'
                             })}/>
                <InputNumber type={'number'}
                             required
                             value={form.duration}
                             min={1} max={1440}
                             onChange={(duration) => duration !== null && setForm({...form, duration})}
                             label={intl.formatMessage({
                                 id: 'ParamsServiceInfosFormDurationInputLabel',
                                 defaultMessage: 'Durée en minutes'
                             })}/>
                {selectOptions && <SimpleSelect<Category | undefined> options={selectOptions}
                                                                selected={selectedOptions}
                                                                onSelect={onCategorySelect}
                                                                label={intl.formatMessage({
                                                                    id: 'ParamsServiceInfosFormCategorySelectLabel',
                                                                    defaultMessage: 'Catégorie'
                                                                })}/>}
                <ColorPicker onChange={(color) => setForm({...form, color: color.length <= 0 ? defaultColor : color})}
                             value={form.color}
                             caption={intl.formatMessage({
                                 id: 'ParamsServiceInfosFormColorSelectLabel',
                                 defaultMessage: 'Couleur sur l\'agenda'
                             })}/>
            </Col>
        </Row>
    </div>
}