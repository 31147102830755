import {FC} from "react";
import {
    Container,
    DashboardCard, DashboardCardBody,
    DashboardCardSubtitle,
    DashboardSlider,
    Flex, getTypo, SpanH5,
    ThemeType
} from "@linkeo.com/ui-lib-react";
import {FormattedMessage, useIntl} from "react-intl";
import {ReactComponent as HelpHexaIcon} from '../icons/help_hexa.svg';
import {ReactComponent as LifeBuoyIcon} from '../icons/life_buoy.svg';
import styled, {useTheme} from "styled-components";
import {PrimaryLink} from "../commons/components/primary-link";
import {routeAgendas, routeDateAgendas, routeEvents, routeGuide, routeParams, routeParamsServices} from "../routes";
import {generatePath} from "react-router-dom";
import {EventCard} from "../events/components/event-card";
import {DashboardLinkCard} from "./dashboard-link-card";

const H2 = styled.h2`
    ${getTypo('body2')};
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    font-style: normal;
    color: ${({theme}) => theme.colors.dark};
`

export const Dashboard: FC = () => {
    const intl = useIntl();
    const {colors} = useTheme() as ThemeType;

    return <Container size={1100}>
        <Flex justifyContent={['center']} wraps={['wrap']}>
            <DashboardSlider
                slides={[
                    {
                        alt: 'calendar_icon',
                        title: intl.formatMessage({
                            id: 'HomePageDashboardSliderTitle1',
                            defaultMessage: 'Bienvenue sur l\'espace administrateur de votre application Planner!'
                        }),
                        body: intl.formatMessage({
                            id: 'HomePageDashboardSliderBody1',
                            defaultMessage: 'Vous pouvez suivre et administrer les demandes de rendez-vous de vos clients à partir d\'ici.'
                        }),
                        imageUri: 'img/welcome_01.svg'
                    },
                    {
                        alt: 'manager_icon',
                        title: intl.formatMessage({
                            id: 'HomePageDashboardSliderTitle2',
                            defaultMessage: 'Gérez vos demandes'
                        }),
                        body: intl.formatMessage({
                            id: 'HomePageDashboardSliderBody2',
                            defaultMessage: 'Les rendez-vous que vous acceptez seront enregistrés sur votre site. Les rendez-vous que vous refusez ne seront pas intégrés à votre planning et les créneaux resteront disponibles à la réservation sur votre site.'
                        }),
                        imageUri: 'img/welcome_02.svg'
                    },
                    {
                        alt: 'clock_icon',
                        title: intl.formatMessage({
                            id: 'HomePageDashboardSliderTitle3',
                            defaultMessage: 'Optimisez votre planning'
                        }),
                        body: intl.formatMessage({
                            id: 'HomePageDashboardSliderBody3',
                            defaultMessage: 'Vous pouvez visualiser le planning de votre société dans sa globalité et par type de prestations, pour une organisation optimale. {br}Vous pouvez également modifier vos paramètres à tout moment.'
                        }, {br: <br/>}) as string,
                        imageUri: 'img/welcome_03.svg'
                    }
                ]}
                subtitle={intl.formatMessage({
                    id: 'HomePageDashboardSliderSubtitle',
                    defaultMessage: 'À propos de Planner'
                })}/>
            <DashboardCard>
                <DashboardCardSubtitle>
                    <FormattedMessage id={'dashboardCardGuideSubtitle'} defaultMessage={'Prise en main'}/>
                </DashboardCardSubtitle>
                <H2>
                    <HelpHexaIcon style={{marginRight: '5px'}}
                                  width={35}
                                  height={40}
                                  fill={colors.primary}/>
                    <FormattedMessage
                        id={'dashboardCardGuideTitle'}
                        defaultMessage={'Guide utilisateur'}
                    />
                </H2>
                <DashboardCardBody style={{margin: '16px 0 18px'}}>
                    <FormattedMessage
                        id={'dashboardCardGuideCaption'}
                        defaultMessage={'Consultez nos guides vidéos pour une prise en main rapide de votre application.'}
                    />
                </DashboardCardBody>
                <PrimaryLink link={routeGuide}
                             icon={'right'}
                             text={intl.formatMessage({
                                 id: 'dashboardCardGuideLink',
                                 defaultMessage: 'Consultez nos guides vidéos'
                             })}/>
            </DashboardCard>
            <DashboardCard>
                <DashboardCardSubtitle>
                    <FormattedMessage id={'dashboardCardEventSubtitle'} defaultMessage={'Mon prochain rdv'}/>
                </DashboardCardSubtitle>
                <EventCard key={0}
                           index={0}
                           isUnique
                           date={'2024-02-11 09:30'}
                           color={'#E5CBA0'}
                           firstName={'Prenom'}
                           lastName={'Nom'}
                           email={'test@email.com'}
                           phone={'0123456789'}
                           link={generatePath(routeDateAgendas, {editDate: '2024-02-11'})}
                />
            </DashboardCard>
            <DashboardCard noPadding={true}>
                <Flex justifyContent={'center'} alignItems={'center'} style={{flex: '1 0 auto'}}>
                    <DashboardLinkCard
                        link={routeEvents}
                        title={intl.formatMessage({
                            id: 'HomePageDashboardLinkCardEventsTitle',
                            defaultMessage: 'Mes rdv'
                        })}
                        subtitle={intl.formatMessage({
                            id: 'HomePageDashboardLinkCardEventsSubTitle',
                            defaultMessage: 'Acceptez ou refusez les demandes de rdv de vos clients'
                        })}
                        icon={'clock'}
                        borderRight={`1px solid ${colors.grey90}`}
                        borderBottom={`1px solid ${colors.grey90}`}/>
                    <DashboardLinkCard
                        link={routeAgendas}
                        icon={'calendar'}
                        title={intl.formatMessage({
                            id: 'HomePageDashboardLinkCardCalendarTitle',
                            defaultMessage: 'Planning'
                        })}
                        subtitle={intl.formatMessage({
                            id: 'HomePageDashboardLinkCardCalendarSubTitle',
                            defaultMessage: 'Consultez et organisez votre planning général'
                        })}/>
                    <DashboardLinkCard
                        link={routeParams}
                        icon={'gear'}
                        title={intl.formatMessage({id: 'navSettings', defaultMessage: 'Paramètres'})}
                        subtitle={intl.formatMessage({
                            id: 'HomePageDashboardLinkCardParamsSubTitle',
                            defaultMessage: 'Administrez les préférences de votre module'
                        })}/>
                    <DashboardLinkCard
                        link={routeParamsServices}
                        icon={'version'}
                        title={intl.formatMessage({id: 'navSettingsService', defaultMessage: 'Prestations'})}
                        subtitle={intl.formatMessage({
                            id: 'HomePageDashboardLinkCardParamsServicesSubTitle',
                            defaultMessage: 'Gérez les prestations que vous proposez à vos clients'
                        })}
                        borderLeft={`1px solid ${colors.grey90}`}
                        borderTop={`1px solid ${colors.grey90}`}/>

                </Flex>
            </DashboardCard>
            <DashboardCard>
                <DashboardCardSubtitle>
                    <FormattedMessage id={'HompePageDashboardCardHelpTitle'} defaultMessage={'Assistance'}/>
                </DashboardCardSubtitle>
                <H2 style={{marginBottom: '24px'}}>
                    <LifeBuoyIcon style={{marginRight: '5px'}}
                                  width={32}
                                  height={32}
                                  fill={colors.danger}/>
                    <FormattedMessage id={'supportPhone'} defaultMessage={'09 72 67 01 67'}/>
                </H2>
                <SpanH5>
                    <FormattedMessage id={'HomePageDashboardCardHelpCaption'} defaultMessage={'Besoin d\'aide?'}/>
                </SpanH5>
                <DashboardCardBody style={{margin: '16px 0 18px'}}>
                    <FormattedMessage
                        id={'dashboardCardHelpCaption'}
                        defaultMessage={'N\'hésitez pas à contacter le service clients. Joignable du lundi au vendredi de 08h30 à 18h00'}
                    />
                </DashboardCardBody>
            </DashboardCard>
        </Flex>
    </Container>
}