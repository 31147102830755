import {FC, useState} from "react";
import {Col, Radio} from "@linkeo.com/ui-lib-react";
import {FormattedMessage, useIntl} from "react-intl";
import {RadioInput} from "../../commons/components/radio-input";

interface CustomListSpotsProps {
    number: number;
    name: string;
    disabled: boolean;
    value: number;
    onChangeValue: (value: number) => void;
}

export const CustomListSpots: FC<CustomListSpotsProps> = ({
                                                                          number,
                                                                          name,
                                                                          disabled,
                                                                          value,
                                                                          onChangeValue
                                                                      }) => {
    const intl = useIntl();
    const [customValue, setCustomValue] = useState<number>(value > number + 1 ? value : number + 1)

    return <>
        {Array.from({length: number}, (_, i) => {
            return <Col key={i}>
                <Radio name={name}
                       disabled={disabled}
                       onChange={() => onChangeValue(i + 1)}
                       value={value === (i + 1)}>
                    <FormattedMessage id={'spotsLabel'}
                                      defaultMessage={'{number, plural, one {1 place} other {# places}}'}
                                      values={{number: i + 1}}/>
                </Radio>
            </Col>
        })}
        <Col>
            <Radio name={name}
                   disabled={disabled}
                   onChange={() => onChangeValue(customValue)}
                   value={value >= number + 1}>
                <RadioInput inputValue={customValue}
                            onInputChange={(v) => {
                                      setCustomValue(v && v > number + 1 ? v : number + 1)
                                      onChangeValue(v && v > number + 1 ? v : number + 1)
                                  }}
                            disabled={disabled}
                            minValue={number + 1}
                            postText={intl.formatMessage({
                                      id: 'customSpotsLabel',
                                      defaultMessage: 'places'
                                  })}/>
            </Radio>
        </Col>
    </>
}