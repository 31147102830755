import React, {FC} from "react";
import {Col, Row, SpanSubtitle1} from "@linkeo.com/ui-lib-react";
import {FormattedMessage} from "react-intl";
import {TimeSelect} from "../../commons/components/time-select";
import {hoursList, minutesList} from "../../utils/date";

const from = 'from';
const to = 'to';
export type DateField = typeof from | typeof to;

export const hour = 'hour';
const min = 'min';
export type TimeField = typeof hour | typeof min;

interface FromToSelectProps {
    fromDate: Date;
    onTimeSelect: (dateField: DateField, timeField: TimeField, value: string) => void;
    toDate: Date;
    disabled: boolean;
    hasDuration?: boolean
}

export const FromToSelect: FC<FromToSelectProps> = ({
                                                        onTimeSelect,
                                                        fromDate,
                                                        toDate,
                                                        disabled,
                                                        hasDuration
                                                    }) => {


    return <Row style={{rowGap: 16, width: '100%'}}>
        <Col>
            <label>
                <SpanSubtitle1>
                    <FormattedMessage id={'appointmentDialogTimingFromLabel'} defaultMessage={'de : '}/>
                </SpanSubtitle1>
            </label>
            <TimeSelect value={fromDate.getHours().toString().padStart(2, '0') + 'h'}
                        disabled={disabled}
                        onSelect={(e) => onTimeSelect(from, hour, e.target.value)}
                        options={hoursList}/>
            <TimeSelect value={fromDate.getMinutes().toString()}
                        disabled={disabled}
                        onSelect={(e) => onTimeSelect(from, min, e.target.value)}
                        options={minutesList}/>
        </Col>
        <Col style={{paddingRight: 0}}>
            <label>
                <SpanSubtitle1>
                    <FormattedMessage id={'appointmentDialogTimingToLabel'} defaultMessage={'à : '}/>
                </SpanSubtitle1>
            </label>
            <TimeSelect value={toDate.getHours().toString().padStart(2, '0') + 'h'}
                        onSelect={(e) => onTimeSelect(to, hour, e.target.value)}
                        disabled={disabled || hasDuration}
                        options={hoursList}/>
            <TimeSelect value={toDate.getMinutes().toString()}
                        onSelect={(e) => onTimeSelect(to, min, e.target.value)}
                        disabled={disabled || hasDuration}
                        options={minutesList}/>
        </Col>
    </Row>
}