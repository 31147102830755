import React, {FC} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useTheme} from "styled-components";
import {Button, Col, SpanBody2, SpanSubtitle2, ThemeType} from "@linkeo.com/ui-lib-react";
import {statusPending} from "../customers-types";
import {Container} from "./list-card";

interface CustomerBookingCardProps {
    index: number;
    title: string;
    currency: string;
    price: number;
    date: string;
    onCancelAppointmentClick: () => void;
    status: string
}

export const CustomerBookingCard: FC<CustomerBookingCardProps> = ({
                                                                      index,
                                                                      title,
                                                                      currency,
                                                                      price,
                                                                      date,
                                                                      onCancelAppointmentClick,
                                                                      status
                                                                  }) => {
    const intl = useIntl();
    const theme = useTheme() as ThemeType;

    return <Container index={index} justifyContent={'space-between'}
                      alignItems={'center'}>
        <Col>
            <div>
                <SpanBody2>{title} -</SpanBody2>
                <SpanBody2> {intl.formatNumber(price, {style: 'currency', currency})}</SpanBody2>
            </div>
            <SpanSubtitle2>{intl.formatDate(date, {dateStyle: 'full', timeStyle: 'short'})}</SpanSubtitle2>
        </Col>
        <Col style={{display: 'flex', alignItems: 'center'}}>
            {status === statusPending
                ? <Button colorType={'secondary'} fontColor={theme.colors.light}
                          onClick={onCancelAppointmentClick}>
                    <FormattedMessage id={'cancelBookingButton'}
                                      defaultMessage={'Annuler le rendez-vous'}/>
                </Button>
                : <><Button colorType={'secondary'} fontColor={theme.colors.light}
                            onClick={onCancelAppointmentClick}>
                    <FormattedMessage id={'cancelButton'} defaultMessage={'Refuser'}/>
                </Button>
                    <Button colorType={'primary'} style={{marginLeft: "16px"}}>
                        <FormattedMessage id={'ApproveButton'} defaultMessage={'Accepter'}/>
                    </Button></>}
        </Col>
    </Container>
}