import { Col, Input, Row } from "@linkeo.com/ui-lib-react";
import { FC, useState } from "react";
import { useIntl } from "react-intl";

interface ParamsInfosFormProps {

}

export const ParamsInfosForm: FC<ParamsInfosFormProps> = () => {
    const intl = useIntl();
    const [infosForm, setInfosForm] = useState({
        phone: '',
        mobile: '',
        email: '',
        companyName: '',
        zipCode: '',
        address: '',
        city: '',
        website: ''
    })

    return <form>
        <Row justifyContent={'space-between'} style={{marginTop: '12px'}}>
            <Col columns={[11, 5.5]}>
                <Input
                    type={'tel'}
                    required
                    placeholder={intl.formatMessage({ id: 'ParamsInfosFormPhoneLabel', defaultMessage: 'N° de téléphone principal' })}
                    label={intl.formatMessage({ id: 'ParamsInfosFormPhonePlaceholder', defaultMessage: 'Numéro de téléphone principal' })}
                    value={infosForm.phone}
                    onChange={(phone) => setInfosForm({ ...infosForm, phone })} />
                <Input
                    type={'email'}
                    label={intl.formatMessage({ id: 'ParamsInfosFormEmailLabel', defaultMessage: 'Email :' })}
                    value={infosForm.email}
                    onChange={(email) => setInfosForm({ ...infosForm, email })} />
            </Col>
            <Col columns={[11, 5.5]}>
                <Input
                    type={'tel'}
                    placeholder={intl.formatMessage({ id: 'ParamsInfosFormMobilePlaceholder', defaultMessage: 'Numéro de téléphone mobile' })}
                    label={intl.formatMessage({ id: 'ParamsInfosFormMobileLabel', defaultMessage: 'N° de mobile pour notifications :' })}
                    value={infosForm.mobile}
                    onChange={(mobile) => setInfosForm({ ...infosForm, mobile })} />
            </Col>
        </Row>
        <Row justifyContent={'space-between'} style={{marginTop: '20px'}}>
            <Col columns={[11, 5.5]}>
                <Input
                    type={'text'}
                    label={intl.formatMessage({ id: 'ParamsInfosFormCompanyLabel', defaultMessage: 'Nom du commerce :' })}
                    value={infosForm.companyName}
                    onChange={(companyName) => setInfosForm({ ...infosForm, companyName })} />
                <Input
                    type={'text'}
                    label={intl.formatMessage({ id: 'ParamsInfosFormZipCodeLabel', defaultMessage: 'Code postal :' })}
                    value={infosForm.zipCode}
                    onChange={(zipCode) => setInfosForm({ ...infosForm, zipCode })} />
            </Col>
            <Col columns={[11, 5.5]}>
                <Input
                    type={'text'}
                    label={intl.formatMessage({ id: 'ParamsInfosFormAddressLabel', defaultMessage: 'Adresse du commerce :' })}
                    value={infosForm.address}
                    onChange={(address) => setInfosForm({ ...infosForm, address })} />
                    <Input
                    type={'text'}
                    label={intl.formatMessage({ id: 'ParamsInfosFormCityLabel', defaultMessage: 'Ville :' })}
                    value={infosForm.city}
                    onChange={(city) => setInfosForm({ ...infosForm, city })} />
            </Col>
        </Row>
        <Row justifyContent={'space-between'} style={{marginTop: '20px'}}>
            <Col columns={[11, 5.5]}>
                <Input
                    type={'text'}
                    label={intl.formatMessage({ id: 'ParamsInfosFormWebsiteLabel', defaultMessage: 'Site internet :' })}
                    value={infosForm.website}
                    onChange={(website) => setInfosForm({ ...infosForm, website })} />
            </Col>
        </Row>
    </form>
}