import axios, {AxiosRequestHeaders} from "axios";
import {ResultPagination} from "../commons/common-types";
import {Category, Service, ServicePayload} from "./services-types";

export class ServicesApi {
    public constructor(private apiBaseUri: string) {
    }

    async getCategoryList(codeBouton: string, token: string, limit: number, offset: number, signal?: AbortSignal): Promise<ResultPagination<Category>> {
        const headers: AxiosRequestHeaders = {Authorization: `Bearer ${token}`}
        const response = await axios.get(`${this.apiBaseUri}/${codeBouton}/categories?limit=${limit}&offset=${offset}`, {headers, signal})
        return response.data
    }

    async getService(codeBouton: string, token: string, id: string): Promise<Service> {
        const headers : AxiosRequestHeaders = {Authorization: `Bearer ${token}`}
        const response = await axios.get(`${this.apiBaseUri}/${codeBouton}/services/${id}`, {headers})
        return response.data
    }

    async createService(codeBouton: string, body: ServicePayload, token: string): Promise<Service> {
        const headers: AxiosRequestHeaders = {Authorization: `Bearer ${token}`}
        const response = await axios.post(`${this.apiBaseUri}/${codeBouton}/services`, body, {headers})
        return response.data
    }

    async updateService(codeBouton: string, token: string, serviceId: string, service: ServicePayload): Promise<Service> {
        const headers: AxiosRequestHeaders = {Authorization: `Bearer ${token}`}
        const response = await axios.put(`${this.apiBaseUri}/${codeBouton}/services/${serviceId}`, service, {headers})
        return response.data
    }
}