import { FC } from "react";
import { Card, Svg, SpanBody2, ThemeType, IconName } from "@linkeo.com/ui-lib-react";
import { useTheme } from "styled-components";

interface GiftcardTotalCardProps {
    icon: IconName;
    text: string
}

export const GiftcardTotalCard: FC<GiftcardTotalCardProps> = ({ icon, text }) => {
    const theme = useTheme() as ThemeType;

    return <Card style={{ display: 'flex', alignItems: 'center' }}>
        <Svg icon={icon} width={32} height={32} fill={theme.colors.primary} style={{ marginRight: '20px' }} />
        <SpanBody2>
            {text}
        </SpanBody2>
    </Card>
}