import {FC} from "react";
import {Dialog, SpanBody2, SpanSubtitle2, ThemeType} from "@linkeo.com/ui-lib-react";
import {DeleteDialogFooter} from "../../commons/components/delete-dialog-footer";
import {FormattedMessage, useIntl} from "react-intl";
import {useTheme} from "styled-components";

interface ServiceDeleteModalProps {
    onClose: () => void;
    onServiceDelete: () => void;
    show: boolean;
    selectedService: string
}

export const ServiceDeleteModal: FC<ServiceDeleteModalProps> = ({
                                                                                onClose,
                                                                                onServiceDelete,
                                                                                show,
                                                                                selectedService
                                                                            }) => {
    const intl = useIntl();
    const theme = useTheme() as ThemeType;

    return <Dialog onClose={onClose}
                   show={show}
                   backgroundColor={theme.colors.danger}
                   title={intl.formatMessage({
                       id: 'ParamsServiceDeleteModalTitle',
                       defaultMessage: 'Supprimer la prestation'
                   })}
                   size={'md'}
                   footer={<DeleteDialogFooter onConfirmDeleteClick={onServiceDelete} onCancelClick={onClose}/>}>
        <div style={{textAlign: 'center'}}>
            <div>
                <SpanBody2 style={{color: theme.colors.danger}}>
                    {selectedService}
                </SpanBody2>
            </div>
            <SpanSubtitle2>
                <FormattedMessage
                    id={'ParamsServiceDeleteModalCaption'}
                    defaultMessage={'Êtes-vous certain de vouloir supprimer cette prestation ?'}
                />
            </SpanSubtitle2>
        </div>
    </Dialog>
}