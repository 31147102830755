import React, {FC, FormEvent, useState} from "react";
import {
    Dialog,
    Flex,
    Radio,
    SpanSubtitle2,
    Svg,
    ThemeType,
    Col,
    Row,
    SpanSubtitle1,
    DatePicker
} from "@linkeo.com/ui-lib-react";
import {FormattedMessage, useIntl} from "react-intl";
import styled, {useTheme} from "styled-components";
import {ConfirmDialogFooter} from "../../commons/components/confirm-dialog-footer";
import {TimeSelect} from "../../commons/components/time-select";
import {hoursList, minutesList, setHoursDate} from "../../utils/date";
import {Locale} from "../../providers/intl-provider";

const RadioWrapper = styled(Col)<{ selected: boolean }>`
    word-break: break-word;

    > div {
        background: ${({theme}) => theme.colors.grey90};
        padding: 10px 0 5px 5px;
        border: ${({selected, theme}) => `2px solid ${selected ? theme.colors.primary : theme.colors.grey90}`};
        border-radius: 3px;
    }
`

interface TransferEventModalProps {
    onClose: () => void;
    show: boolean;
    firstName: string;
    lastName: string;
    agendas: string[];
    date: string;
    locale: Locale
}

export const TransferEventModal: FC<TransferEventModalProps> = ({
                                                                    onClose,
                                                                    show,
                                                                    lastName,
                                                                    firstName,
                                                                    agendas,
                                                                    date: formDate,
                                                                    locale
                                                                }) => {
    const intl = useIntl();
    const theme = useTheme() as ThemeType;
    const formId = 'eventTransferForm';
    const [selectedAgenda, setSelectedAgenda] = useState<string>();
    const [date, setDate] = useState<string>(formDate)

    const _onSubmit = (e: FormEvent) => {
        e.preventDefault();
        e.stopPropagation();
    }

    return <Dialog
        onClose={onClose}
        show={show}
        title={intl.formatMessage({id: 'TransferEventModalTitle', defaultMessage: 'Transfert'})}
        size={'md'}
        footer={<ConfirmDialogFooter onClose={onClose} formId={formId}/>}>
        <form id={formId} onSubmit={_onSubmit}
              style={{maxHeight: `calc(${window.innerHeight}px - 280px)`, overflowY: 'auto'}}>
            <SpanSubtitle2>
                <FormattedMessage
                    id={'TransferEventModalBody'}
                    defaultMessage={'Sélectionnez l`\'agenda sur lequel vous souhaitez transférer le client suivant:'}/>
            </SpanSubtitle2>
            <Flex style={{margin: '14px 0'}} alignItems={'center'}>
                <Svg icon={'person'} fill={theme.colors.grey40} height={24} width={24}
                     style={{
                         background: theme.colors.grey90,
                         borderRadius: '50%',
                         marginRight: '10px',
                         padding: '2px'
                     }}/>
                <SpanSubtitle2 style={{fontWeight: 700}}>
                    {firstName} {lastName}
                </SpanSubtitle2>
            </Flex>
            <Row alignItems={['center']} style={{maxWidth: '100%'}}>
                <Col>
                    <label>
                        <SpanSubtitle1>
                            <FormattedMessage id={'ScheduleFormSelectDayLabel'} defaultMessage={'Le: '}/>
                        </SpanSubtitle1>
                    </label>
                </Col>
                <Col style={{padding: 0}}>
                    <DatePicker locale={locale.substring(0, 2)}
                                value={new Date(date)}
                                onChange={(val) => {
                                    if (val) {
                                        setDate((prevState) =>
                                            setHoursDate(new Date(val).toString(), new Date(prevState).getHours(), new Date(prevState).getMinutes()))
                                    }
                                }}/>
                </Col>
            </Row>
            <label style={{paddingRight: '16px'}}>
                <SpanSubtitle1>
                    <FormattedMessage id={'appointmentDialogTimingToLabel'} defaultMessage={'à : '}/>
                </SpanSubtitle1>
            </label>
            <TimeSelect value={new Date(date).getHours().toString().padStart(2, '0') + 'h'}
                        onSelect={(e) => setDate(setHoursDate(date, parseInt(e.target.value), new Date(date).getMinutes()))}
                        options={hoursList}/>
            <TimeSelect value={new Date(date).getMinutes().toString()}
                        onSelect={(e) => setDate(setHoursDate(date, new Date(date).getHours(), parseInt(e.target.value)))}
                        options={minutesList}/>
            <Row style={{maxWidth: '100%'}}>
                {agendas.map((agenda, index) =>
                    <RadioWrapper key={index} columns={[12, 6]} onClick={() => setSelectedAgenda(agenda)}
                                  selected={selectedAgenda === agenda}>
                        <Radio name={'AgendaRadioInput'}
                               style={{background: theme.colors.grey90}}
                               onChange={() => setSelectedAgenda(agenda)}
                               value={selectedAgenda === agenda}>
                            {agenda}
                        </Radio>
                    </RadioWrapper>)}
            </Row>
        </form>
    </Dialog>
}