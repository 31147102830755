import {FC} from "react";
import {Flex, SpanBody2, SpanH1} from "@linkeo.com/ui-lib-react";
import {FormattedMessage} from "react-intl";

export const ServerError: FC = () => {

    return <Flex direction={'column'}
                 justifyContent={'center'}
                 alignItems={'center'}
                 style={{height: '100%', textAlign: 'center', padding: '100px 0', rowGap: 20}}>
        <SpanH1>500</SpanH1>
        <SpanBody2>
            <FormattedMessage
                id={'ErrorSubtitle'}
                defaultMessage={'Une erreur s\'est produite alors que{br}le serveur tentait de traiter la demande.'}
                values={{br: <br/>}}/>
        </SpanBody2>
    </Flex>
}