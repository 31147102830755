import {FC, useState} from "react";
import {PageWrapper} from "../commons/components/page-wrapper";
import {Card, Container, Row} from "@linkeo.com/ui-lib-react";
import {useIntl} from "react-intl";
import {EventCard} from "../events/components/event-card";
import {generatePath} from "react-router-dom";
import {routeAgendas, routeDateAgendas} from "../routes";
import {PrimaryLink} from "../commons/components/primary-link";
import {PendingEventCard} from "../events/components/pending-event-card";
import {BackToTop} from "../commons/components/back-to-top";
import {ContainerHeader} from "../commons/components/container-header";

interface Events {
    date: string;
    color: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string
}

const EVENTS_MOCK_UP = [{
    date: '2023-11-18 10:30:00',
    color: '#4f81bd',
    firstName: 'Test',
    lastName: 'Test',
    email: 'test@email.com',
    phone: '+33666666666'
}, {
    date: '2023-11-26 14:00:00',
    color: '#ffffff',
    firstName: 'Hello',
    lastName: 'Hello',
    email: 'hello@email.com',
    phone: '+33600000000'
}]
export const EventsPage: FC = () => {
    const intl = useIntl();
    const [isLoading] = useState<boolean>(false);
    const [eventsList] = useState<Events[]>(EVENTS_MOCK_UP);
    const [pendingEvents] = useState([''])

    return <PageWrapper isLoading={isLoading}>
        <Container size={'lg'}>
            {pendingEvents.length > 0 && <>
                <ContainerHeader
                    title={intl.formatMessage({
                        id: 'EventsPagePendingEventsTitle',
                        defaultMessage: 'Demandes de rendez-vous client'
                    })}
                    subtitle={intl.formatMessage({
                        id: 'EventsPagePendingEventsSubTitle',
                        defaultMessage: 'Acceptez ou refusez les demandes de rendez-vous suivantes.'
                    })}
                />
                <PendingEventCard
                    service={'Coupe coiffage'}
                    date={'2023-11-18 10:30:00'}
                    duration={30}
                    lastName={'Test'}
                    firstName={'Test'}
                    spot={1}
                    subscribed={true}
                />
                <PendingEventCard
                    service={'AQUAMIX'}
                    date={'2023-11-26 16:30:00'}
                    duration={60}
                    lastName={'Test'}
                    firstName={'Test'}
                    spot={4}
                    subscribed={false}
                    options={['Musique', 'Bonnet de bain']}
                />
                <PendingEventCard
                    service={'Rasage à l\'ancienne'}
                    date={'2023-12-04 10:00:00'}
                    duration={45}
                    lastName={'Hello'}
                    firstName={'Hello'}
                    spot={1}
                    subscribed={false}
                    options={['Massage']}
                />
            </>}
            <ContainerHeader
                title={intl.formatMessage({id: 'EventsPageTitle', defaultMessage: 'Mes prochains rendez-vous'})}
                subtitle={intl.formatMessage({
                    id: 'EventsPageSubTitle',
                    defaultMessage: 'Ici consultez d\'un coup d\'oeil vos prochains rendez-vous.'
                })}/>
            {eventsList.length > 0 && <Card style={{marginTop: '28px'}}>
                <Row alignItems={'center'} justifyContent={'flex-start'} style={{padding: '30px 0'}}>
                    {eventsList.slice(0, eventsList.length <= 4 ? eventsList.length : 4).map((event, index) =>
                        <EventCard key={index}
                                   index={index}
                                   date={event.date}
                                   color={event.color}
                                   firstName={event.firstName}
                                   lastName={event.lastName}
                                   email={event.email}
                                   phone={event.phone}
                                   link={generatePath(routeDateAgendas, {editDate: event.date.slice(0, 10)})}
                        />)}
                </Row>
            </Card>}
            <div style={{margin: '28px 0'}}>
                <PrimaryLink link={routeAgendas}
                             icon={'right'}
                             text={intl.formatMessage({
                                 id: 'EventsPageLinkLabel',
                                 defaultMessage: 'Consultez le planning général'
                             })}/>
            </div>
            <BackToTop/>
        </Container>
    </PageWrapper>
}