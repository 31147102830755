export const updateItemInArray = function <T>(current: T[], index: number, value: T): T[] {
    const arr = [...current];
    arr[index] = value;
    return arr;
};

export const reOrderList = function <T>(list: T[], from: number, to: number): T[] {
    const newList = [...list];
    const [item] = newList.splice(from, 1);
    newList.splice(to, 0, item);
    return newList
}

export const hasDuplicates = function<T>(array: T[]): boolean {
    const seen = new Set();
    for (const item of array) {
        const key = JSON.stringify(item);
        if (seen.has(key)){
            return true
        }
        seen.add(key)
    }
    return false
}