import React, {FC} from "react";
import {Button, SpanButton} from "@linkeo.com/ui-lib-react";
import {useIntl} from "react-intl";

interface ConfirmDialogFooterProps {
    onClose: () => void;
    formId: string
}

export const ConfirmDialogFooter: FC<ConfirmDialogFooterProps> = ({onClose, formId }) => {
    const intl = useIntl();

    return <>
        <Button colorType={'secondary'}
                onClick={onClose}><SpanButton>{intl.formatMessage({
            id: 'CancelButtonLabel',
            defaultMessage: 'Annuler'
        })}</SpanButton></Button>
        <Button type={'submit'} form={formId} colorType={'primary'}><SpanButton>{intl.formatMessage({
            id: 'ConfirmButtonLabel',
            defaultMessage: 'Valider'
        })}</SpanButton></Button>
    </>
}