import {FC, useState} from "react";
import {PageWrapper} from "../../commons/components/page-wrapper";
import {
    Card,
    Container,
    H2,
    Row,
    Svg,
    ThemeType
} from "@linkeo.com/ui-lib-react";
import {FormattedMessage} from "react-intl";
import {useTheme} from "styled-components";
import {useNavigate, useParams} from "react-router";
import {routeCustomers} from "../../routes";
import {CustomerTabContent} from "../../customers/components/customer-tab-content";

interface CustomerEditPageProps {

}

export const CustomerEditPage: FC<CustomerEditPageProps> = () => {
    const theme = useTheme() as ThemeType;
    const navigate = useNavigate();
    const {id} = useParams();
    const [loading] = useState<boolean>(false);

    return <PageWrapper isLoading={loading}>
        <Container size={'lg'}>
            <Card>
                <Row justifyContent={'space-between'} alignItems={'center'} style={{padding: '0 16px'}}>
                    <H2>
                        {id ? <FormattedMessage id={'CustomerEditPageEditTitle'} defaultMessage={'Fiche client'}/>
                            : <FormattedMessage id={'CustomerEditPageCreateTitle'} defaultMessage={'Ajouter un client'}/>}
                    </H2>
                    <Svg icon={'cross'} fill={theme.colors.secondary} width={28} height={28}
                         style={{cursor: 'pointer'}} onClick={() => navigate(routeCustomers)}/>
                </Row>
                <CustomerTabContent
                    id={id}
                />
            </Card>
        </Container>
    </PageWrapper>
}