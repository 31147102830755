import React, {FC, FormEvent, useState} from "react";
import {
    BaseButton, Button,
    Col, getTypo,
    InOut,
    Input,
    RadioGroup,
    RadioOption,
    Row, SpanBody1, SpanButton, SpanLink,
    Svg,
    Switch,
    ThemeType, useToaster
} from "@linkeo.com/ui-lib-react";
import {FormattedMessage, useIntl} from "react-intl";
import {ChoiceInputs} from "./choice-inputs";
import styled, {useTheme} from "styled-components";
import {useNavigate} from "react-router";
import {routeOptions} from "../../routes";
import {OptionInput} from "./option-input";
import {updateItemInArray} from "../../utils/misc";
import {DownPaymentForm} from "../../commons/components/down-payment-form";

const H3 = styled.h3`
    color: ${({theme}) => theme.colors.grey10};
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 21px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    margin-bottom: 32px;
`
const SectionTitle = styled.p`
    ${getTypo('subtitle1')};
    margin: 32px 0 12px;
`

const service = 'service';
const booking = 'booking';

interface Choice {
    id?: string;
    label: string;
    price: number
}

interface FormProps {
    name: string;
    type: typeof service | typeof booking;
    min: number;
    max: number | null;
    countable: boolean
    extraCharge: number;
    maxExtra: number;
    maxFree: number;
    choices: Choice[];
    downPayment?: number;
}

interface OptionFormProps {
    form: FormProps;
    onSubmit: (form: FormProps) => void;
    loading: boolean;
    paymentPercent: number;
}

export const OptionForm: FC<OptionFormProps> = ({form: formProp, onSubmit, loading, paymentPercent}) => {
    const intl = useIntl();
    const theme = useTheme() as ThemeType;
    const navigate = useNavigate();
    const toast = useToaster();
    const [form, setForm] = useState<FormProps>(formProp);
    const typeList: RadioOption<typeof service | typeof booking>[] = [
        {
            name: intl.formatMessage({
                id: 'OptionFormInfoRadioLabelService',
                defaultMessage: 'Option de prestation (l’option s’appliquera à une ou des prestations)'
            }),
            data: service,
            columns: [12]
        },
        {
            name: intl.formatMessage({
                id: 'OptionFormInfoRadioLabelBooking',
                defaultMessage: 'Option de réservation (l’option s’appliquera aux réservations)'
            }),
            data: booking,
            columns: [12]
        }
    ]
    const [getRadio, setRadio] = useState<RadioOption<typeof service | typeof booking>>(form.type === service ? typeList[0] : typeList[1])
    const [freeOption, setFreeOption] = useState<boolean>(!form.choices.find(e => e.price > 0))
    const [severalChoices, setSeveralChoices] = useState<boolean>(!!(form.max) && form.max > 1)
    const [getExtraCharge, setExtraCharge] = useState<boolean>(form.maxExtra > 0)

    const _onSubmit = (e: FormEvent) => {
        e.preventDefault();
        e.stopPropagation();
        if (form.min > form.choices.length) {
            toast(intl.formatMessage({
                id: 'optionFormMinChoiceError',
                defaultMessage: 'Vous devez sélectionner un nombre de choix minimum inférieur ou égal au nombre de choix possible'
            }))
        } else if (form.max && form.max < form.min) {
            toast(intl.formatMessage({
                id: 'optionFormMaxChoiceError',
                defaultMessage: 'Vous devez sélectionner un nombre de choix maximum supérieur ou égal au nombre de choix minimum'
            }))
        } else if (form.min >= 1 && (form.max === null || form.max === 0)) {
            toast(intl.formatMessage({
                id: 'optionFormMaxNullError',
                defaultMessage: 'Vous devez sélectionner un nombre de choix maximum supérieur à 0'
            }))
        } else if (!form.countable && form.max && form.max > form.choices.length) {
            toast(intl.formatMessage({
                id: 'optionFormChoicesLengthError',
                defaultMessage: 'Vous devez sélectionner un nombre de choix maximum inférieur ou égal au nombre de choix possibles'
            }))
        } else if (freeOption) {
            const newChoices = form.choices.map(choice => {
                return {id: choice.id, label: choice.label, price: 0}
            })
            let newForm = {...form, choices: newChoices}
            if (newForm.extraCharge > 0) {
                newForm = {...form, maxFree: form.maxFree > 0 ? form.maxFree : (form.max || 1), max: (form.maxFree > 0 ? form.maxFree : (form.max || 1)) + form.maxExtra, choices: newChoices}
                if (!newForm.max || newForm.max < 1) {
                    toast(intl.formatMessage({
                        id: 'optionFormMaxFreeOptionError',
                        defaultMessage: 'Le nombre de choix gratuits maximum doit être supérieur ou égal à 1'
                    }))
                } else if (newForm.maxExtra <= 0) {
                    toast(intl.formatMessage({
                        id: 'optionFormMaxExtraFreeOptionError',
                        defaultMessage: 'Le nombre de supplément maximum doit être supérieur à 0'
                    }))
                } else {
                    if (newForm.max && newForm.max <= newForm.min) {
                        toast(intl.formatMessage({
                            id: 'optionFormMaxChoiceError',
                            defaultMessage: 'Vous devez sélectionner un nombre de choix maximum supérieur ou égal au nombre de choix minimum'
                        }))
                    } else if (!newForm.countable && newForm.max && newForm.max > newForm.choices.length) {
                        toast(intl.formatMessage({
                            id: 'optionFormChoicesLengthError',
                            defaultMessage: 'Vous devez sélectionner un nombre de choix maximum inférieur ou égal au nombre de choix possibles'
                        }))
                    } else {
                        onSubmit(newForm)
                    }
                }
            } else {
                if (newForm.maxExtra > 0) {
                    toast(intl.formatMessage({
                        id: 'optionFormMaxExtraError',
                        defaultMessage: 'Vous devez indiquer un tarif unitaire du supplément supérieur à 0'
                    }))
                } else {
                    onSubmit(newForm)
                }
            }
        } else {
            onSubmit(form)
        }
    }

    const onRadioChange = (e: RadioOption<typeof service | typeof booking>) => {
        setRadio(e)
        setForm({
            ...form,
            type: e.data,
            downPayment: undefined
        })
    }

    const onChoiceRemove = (id: string | undefined) => {
        setForm({...form, choices: form.choices.filter((el) => el.id !== id)})
    }

    const onAddClick = () => {
        const newChoice = [...form.choices, {
            id: Math.floor((1 + Math.random()) * 0x1000000)
                .toString(16)
                .substring(1), label: '', price: 0
        }]
        setForm({...form, choices: newChoice})
    }

    const onMandatorySwitchChange = () => {
        if (form.min > 0) {
            setForm({...form, min: 0})
        } else {
            if (!severalChoices) {
                setForm({...form, min: 1, max: 1})
            } else {
                setForm({...form, min: 1})
            }
        }
    }

    const onSeveralChoiceChange = () => {
        setSeveralChoices(!severalChoices)
        if (severalChoices) {
            setForm({...form, max: 1, maxFree: 0, extraCharge: 0, maxExtra: 0, downPayment: undefined})
        }
    }

    const onFreeOptionChange = () => {
        setFreeOption(!freeOption)
        if (freeOption) {
            setForm({
                ...form,
                extraCharge: 0,
                maxExtra: 0,
                maxFree: 0
            })
        } else if (form.type === booking && !getExtraCharge) {
            setForm({...form, downPayment: undefined})
        }
    }

    const onExtraChargeChange = () => {
        setExtraCharge(!getExtraCharge)
        if (getExtraCharge) {
            setForm({...form, extraCharge: 0, maxExtra: 0, downPayment: undefined})
        }
    }

    const onMaxChange = (n: number | null) => {
        if (getExtraCharge) {
            setForm({...form, max: n, maxFree: n || 0})
        } else {
            setForm({...form, max: n})
        }
    }

    return <form onSubmit={e => _onSubmit(e)}>
        <fieldset style={{border: 0, padding: 0, marginTop: '32px'}}>
            <H3>{intl.formatMessage({
                id: 'OptionFormTitle1',
                defaultMessage: '1/3 - Informations'
            })}</H3>
            <Input
                style={{marginBottom: '32px'}}
                label={intl.formatMessage({id: 'OptionFormInfoInputLabel', defaultMessage: 'Nom de l’option'})}
                required
                value={form.name}
                onChange={(name) => setForm({...form, name})}
            />
            <RadioGroup
                groupName={intl.formatMessage({id: 'OptionFormInfoRadioGroupTitle', defaultMessage: 'Type d’option.'})}
                label={intl.formatMessage({id: 'OptionFormInfoRadioGroupTitle', defaultMessage: 'Type d’option.'})}
                value={getRadio}
                onChange={onRadioChange}
                options={typeList}
            />
        </fieldset>
        <fieldset style={{border: 0, padding: 0}}>
            <H3>{intl.formatMessage({
                id: 'OptionFormTitle2',
                defaultMessage: '2/3 - Paramètres de l’option'
            })}</H3>
            <Switch
                value={form.min > 0}
                onChange={onMandatorySwitchChange}
                onLabel={intl.formatMessage({
                    id: 'OptionFormSwitchMandartoryOption',
                    defaultMessage: 'Option obligatoire (votre client devra obligatoirement faire un choix pour cette option)'
                })}
            />
            <Switch
                value={freeOption}
                onChange={onFreeOptionChange}
                onLabel={intl.formatMessage({
                    id: 'OptionFormSwitchFreeOption',
                    defaultMessage: 'Option gratuite (cette option sera gratuite sauf supplément)'
                })}
            />
            <Switch
                value={severalChoices}
                onChange={onSeveralChoiceChange}
                onLabel={intl.formatMessage({
                    id: 'OptionFormSwitchSeveralOption',
                    defaultMessage: 'Choix multiple (votre client pourra faire plusieurs choix pour cette option)'
                })}
            />
            <InOut show={severalChoices}>
                <Row justifyContent={form.min > 0 ? 'space-between' : 'flex-start'}>
                    {form.min > 0 && <OptionInput
                        label={intl.formatMessage({
                            id: 'OptionFormMinInputLabel',
                            defaultMessage: 'Nombre de choix {br}minimum'
                        }, {br: <br/>})}
                        min={1}
                        value={form.min ? form.min : 1}
                        onChange={(n) => {
                            n === null ? setForm({...form, min: 1})
                                : setForm({...form, min: n})
                        }}
                    />}
                    <OptionInput
                        label={freeOption ? intl.formatMessage({
                                id: 'OptionFormMaxFreeInputLabel',
                                defaultMessage: 'Nombre de choix {br}gratuits maximum'
                            }, {br: <br/>})
                            : intl.formatMessage({
                                id: 'OptionFormMaxInputLabel',
                                defaultMessage: 'Nombre de choix {br}maximum'
                            }, {br: <br/>})}
                        min={1}
                        value={form.maxFree > 0 ? form.maxFree : (form.max || 1)}
                        onChange={onMaxChange}
                    />
                </Row>
                <InOut show={freeOption}>
                    <Switch
                        value={getExtraCharge}
                        onChange={onExtraChargeChange}
                        onLabel={intl.formatMessage({
                            id: 'OptionFormExtraChargeLabel',
                            defaultMessage: 'Supplément possible'
                        })}
                    />
                    <InOut show={getExtraCharge}>
                        <Row justifyContent={'space-between'}>
                            <OptionInput
                                label={intl.formatMessage({
                                    id: 'OptionFormExtraChargePriceLabel',
                                    defaultMessage: 'Tarif unitaire du{br}supplément'
                                }, {br: <br/>})}
                                min={0}
                                value={form.extraCharge || 0}
                                onChange={n => {
                                    n === null ? setForm({...form, extraCharge: 0})
                                        : setForm({...form, extraCharge: n})
                                }}
                            />
                            <OptionInput
                                label={intl.formatMessage({
                                    id: 'OptionFormExtraChargeTotalLabel',
                                    defaultMessage: 'Nombre de suppléments{br}maximum'
                                }, {br: <br/>})}
                                min={0}
                                value={form.maxExtra || 0}
                                onChange={n => {
                                    n === null ? setForm({...form, maxExtra: 0})
                                        : setForm({...form, maxExtra: n})
                                }}
                            />
                        </Row>
                    </InOut>
                </InOut>
                <Switch
                    value={!form.countable}
                    onChange={() => setForm({...form, countable: !form.countable})}
                    onLabel={intl.formatMessage({
                        id: 'OptionFormUnitChoiceLabel',
                        defaultMessage: 'Choix unitaire (votre client ne pourra pas sélectionner plus d’une unité pour chaque choix proposé)'
                    })}
                />
            </InOut>
            <InOut show={form.type === booking}>
                <SectionTitle>
                    <FormattedMessage id={'optionFormDownPaymentSectionTitle'} defaultMessage={'Acompte sur l’option'}/>
                </SectionTitle>
                <SpanBody1>
                    <FormattedMessage id={'optionFormDownPaymentSectionSubtitle'}
                                      defaultMessage={'Personnalisez l\'acompte pour cette option. {next}'}
                                      values={{
                                          next: <i>
                                              <FormattedMessage id={'ParamsServiceEditFormDepositSubTitle2'}
                                                                defaultMessage={'Par défaut, l\'acompte est de {paymentPercent}%.'}
                                                                values={{paymentPercent}}/>
                                          </i>
                                      }}
                    />
                </SpanBody1>
                <DownPaymentForm downPayment={form.downPayment}
                                 initialDownPaymentValue={formProp.downPayment}
                                 onChange={(downPayment) => setForm({
                                     ...form,
                                     downPayment
                                 })}
                                 disabledSwitch={freeOption && (!getExtraCharge || !severalChoices)}
                />
            </InOut>
        </fieldset>
        <fieldset style={{border: 0, padding: 0, marginTop: '16px'}}>
            <H3>{intl.formatMessage({
                id: 'OptionFormTitle3',
                defaultMessage: '3/3 - Choix possibles'
            })}</H3>
            {form.choices.map((choice, index) => {
                return <ChoiceInputs key={choice.id} {...choice}
                                     removable={form.choices.length !== 1}
                                     freeOption={freeOption}
                                     onChoiceRemove={() => onChoiceRemove(choice.id)}
                                     onPriceChange={(val) => setForm({
                                         ...form,
                                         choices: updateItemInArray(form.choices, index, {
                                             ...choice,
                                             price: parseInt(val)
                                         })
                                     })}
                                     onNameChange={(val) => setForm({
                                         ...form,
                                         choices: updateItemInArray(form.choices, index, {...choice, label: val})
                                     })}
                />
            })}
            <Row alignItems={'center'} style={{marginTop: '32px', rowGap: '28px'}}>
                <Col columns={[12, 12, 8.6]}>
                    <BaseButton style={{display: 'flex', alignItems: 'center'}} onClick={onAddClick}>
                        <Svg icon={"plus-circle"} fill={theme.colors.light} stroke={theme.colors.grey20} width={28}
                             height={28}/>
                        <SpanLink style={{
                            textDecoration: 'inherit',
                            marginLeft: '10px'
                        }}>{intl.formatMessage({
                            id: 'OptionFormAddInputButtonLabel',
                            defaultMessage: 'Ajouter un choix'
                        })}</SpanLink>
                    </BaseButton>
                </Col>
                <Col columns={[12, 12, 3.4]} style={{display: 'flex', justifyContent: 'space-between'}}>
                    <Button colorType={'secondary'}
                            onClick={() => navigate(routeOptions)}><SpanButton>{intl.formatMessage({
                        id: 'CancelButtonLabel',
                        defaultMessage: 'Annuler'
                    })}</SpanButton></Button>
                    <Button type={'submit'} colorType={'primary'} loading={loading}><SpanButton>{intl.formatMessage({
                        id: 'SaveButtonLabel',
                        defaultMessage: 'Enregistrer'
                    })}</SpanButton></Button>
                </Col>
            </Row>
        </fieldset>
    </form>
}