import {FC, useState} from "react";
import {Card, Col, SpanSubtitle1, Switch, ThemeType} from "@linkeo.com/ui-lib-react";
import {ReactComponent as ComputerIcon} from '../../../icons/computer.svg';
import {useTheme} from "styled-components";
import {useIntl} from "react-intl";

interface ParamsActivateBookingFormProps {

}

export const ParamsActivateBookingForm: FC<ParamsActivateBookingFormProps> = () => {
    const theme = useTheme() as ThemeType;
    const intl = useIntl();
    const [isActive, setIsActive] = useState<boolean>(true)

    return <Card style={{padding: 0, marginTop: '28px'}}>
        <form>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', flexWrap: 'wrap'}}>
                <Col columns={[12, 1]} style={{padding: '30px'}}>
                    <ComputerIcon fill={theme.colors.grey80} style={{marginTop: '16px'}}/>
                </Col>
                <Col columns={[12, 5.5]} style={{padding: '30px'}}>
                    <Switch
                        value={isActive}
                        onIcon={'check'}
                        offIcon={'cross'}
                        color={theme.colors.grey40}
                        onChange={setIsActive}
                        onLabel={intl.formatMessage({
                            id: 'ParamsActivateBookingSwitchLabel',
                            defaultMessage: 'Activer la prise de rendez-vous en ligne'
                        })}
                    />
                </Col>
                <Col columns={[12, 5.5]}
                     style={{backgroundColor: theme.colors.invertPrimary, padding: '43px 16px', flex: 1}}>
                    <SpanSubtitle1>
                        {isActive ? intl.formatMessage({
                                id: 'ParamsActivateBookingIsActiveSubtitle',
                                defaultMessage: 'Vos clients peuvent actuellement prendre rendez-vous depuis votre site internet'
                            })
                            : intl.formatMessage({
                                id: 'ParamsActivateBookingNotActiveSubtitle',
                                defaultMessage: 'La prise de rendez-vous depuis votre site internet est actuellement désactivée'
                            })}
                    </SpanSubtitle1>
                </Col>
            </div>
        </form>
    </Card>
}