import {ResultPagination} from "../commons/common-types";
import axios, {AxiosRequestHeaders} from "axios";
import {Agenda} from "./agendas-types";

export class AgendasApi {
    public constructor(private apiBaseUri: string) {
    }

    async getAgendaList(codeBouton: string, token: string, limit: number, offset: number, signal?: AbortSignal): Promise<ResultPagination<Agenda>> {
        const headers: AxiosRequestHeaders = {Authorization: `Bearer ${token}`}
        const response = await axios.get(`${this.apiBaseUri}/${codeBouton}/agendas?limit=${limit}&offset=${offset}`, {headers, signal})
        return response.data
    }
}