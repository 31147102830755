import {FC, useEffect, useState} from "react";
import styled, {useTheme} from "styled-components";
import {
    BaseButton,
    Col,
    getTypo,
    H4,
    Row,
    SpanSubtitle1,
    Svg,
    ThemeType
} from "@linkeo.com/ui-lib-react";
import {lighten} from "polished";
import {getLocaleDaysNames, getLocaleMonthsNames} from "../../../utils/date";
import {Locale} from "../../../providers/intl-provider";
import {useIntl} from "react-intl";

const CalendarContainer = styled.table`
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  border-collapse: collapse;
  border-radius: 3px;
  overflow: hidden;
`
const HeaderWrapper = styled.tr`
  background-color: ${({theme}) => lighten(0.1, theme.colors.primary)};
`
const NavButton = styled(BaseButton)`
  border-radius: 50%;
  border: 3px solid ${({theme}) => theme.colors.grey90};
  transition: all .3s ease;

  &:hover {
    background-color: ${({theme}) => theme.colors.primary};
    border: 3px solid ${({theme}) => theme.colors.primary};
  }
`
const WeekdayHeader = styled.tr`
  background-color: ${({theme}) => lighten(0.05, theme.colors.primary)};
  display: flex;
  justify-content: space-around;
`
const WeekdayCell = styled.th`
  padding: 18px 10px;
  word-break: break-all;
  ${getTypo('subtitle1')}
  text-transform: capitalize;
  color: ${({theme}) => theme.colors.grey90};
`
const DaysContainer = styled.tr`
  display: flex;
  flex-wrap: wrap;
  border: 0;
`
const DayCell = styled.td`
  background-color: ${({theme}) => theme.colors.light};
  width: calc(100% / 7);
  text-align: center;
  padding: 0;
  border-bottom: 1px solid ${({theme}) => theme.colors.grey90};
`
const DayButton = styled(BaseButton)`
  width: 100%;
  height: 68px;
  position: relative;

  &:hover {
    background-color: ${({theme}) => theme.colors.grey90};
  }

  &:focus {
    background-color: ${({theme}) => lighten(0.35, theme.colors.danger)};

    &:hover {
      background-color: ${({theme}) => lighten(0.36, theme.colors.danger)};
    }
  }
`
const Notification = styled(SpanSubtitle1)`
  position: absolute;
  top: 0;
  right: 0;
  font-size: 11px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  color: ${({theme}) => theme.colors.light};
  background-color: ${({theme}) => lighten(0.02, theme.colors.danger)};
`

type CalendarView = { month: number, year: number }

interface CalendarHeaderProps {
    view: CalendarView;
    onPrevClick: () => void;
    onNextClick: () => void;
    locale: Locale;
}

const CalendarHeader: FC<CalendarHeaderProps> = ({view, onPrevClick, onNextClick, locale}) => {
    const theme = useTheme() as ThemeType;
    const currentMonth = getLocaleMonthsNames(locale)[view.month];

    return <HeaderWrapper>
        <th colSpan={7} style={{padding: '16px'}}>
            <Row alignItems={'center'} justifyContent={'space-between'}>
                <Col>
                    <H4 style={{color: theme.colors.grey90, margin: 0, fontWeight: 100, textTransform: 'capitalize'}}>
                        {`${currentMonth} ${view.year}`}
                    </H4>
                </Col>
                <Col>
                    <NavButton onClick={onPrevClick}>
                        <Svg icon={'left'} fill={theme.colors.grey90} width={34} height={34}
                             style={{marginRight: '3px'}}/>
                    </NavButton>
                    <NavButton style={{marginLeft: '15px'}} onClick={onNextClick}>
                        <Svg icon={'right'} fill={theme.colors.grey90} width={34} height={34}
                             style={{marginLeft: '3px'}}/>
                    </NavButton>
                </Col>
            </Row>
        </th>
    </HeaderWrapper>
}

interface ParamsClosureCalendarProps {
    locale: Locale;
    onDateClick: (date: CalendarDate) => void;
}

export interface CalendarDate {
    date: Date;
    number?: number;
    notifications?: number;
    isClose?: boolean;
}

export const ParamsClosureCalendar: FC<ParamsClosureCalendarProps> = ({locale, onDateClick}) => {
    const theme = useTheme() as ThemeType;
    const intl = useIntl();
    const [view, setView] = useState<CalendarView>({
        month: new Date().getMonth(),
        year: new Date().getFullYear()
    })
    const [days, setDays] = useState<CalendarDate[]>([])

    const generateMonthDays = (year: number, month: number): CalendarDate[] => {
        const firstDayOfMonth = new Date(year, month, 1);
        const weekdayOfFirstDay = firstDayOfMonth.getDay()
        const firstMondayOfMonth = new Date(firstDayOfMonth);
        firstMondayOfMonth.setDate(1 - (weekdayOfFirstDay === 0 ? 6 : weekdayOfFirstDay - 1))
        return Array.from({length: 42}, (_, index) => {
            const date = new Date(firstMondayOfMonth);
            date.setDate(date.getDate() + index)
            return {date};
        });
    }

    useEffect(() => {
        setDays(generateMonthDays(view.year, view.month))
    }, [view]);

    const onPreviousMonthClick = () => {
        const month = view.month - 1;
        setView({
            month: month < 0 ? 11 : month,
            year: month < 0 ? (view.year - 1) : view.year
        })
    }

    const onNextMonthClick = () => {
        const month = view.month + 1;
        setView({
            month: month > 11 ? 0 : month,
            year: month > 11 ? (view.year + 1) : view.year
        })
    }

    return <CalendarContainer id={'calendar'}>
        <thead>
        <CalendarHeader view={view}
                        locale={locale}
                        onNextClick={onNextMonthClick}
                        onPrevClick={onPreviousMonthClick}/>
        <WeekdayHeader>
            {getLocaleDaysNames(locale).map(day => {
                return <WeekdayCell key={day}>{day.substring(0, 3)}.</WeekdayCell>
            })}
        </WeekdayHeader>
        </thead>
        <tbody>
        <DaysContainer>
            {days.map((d, index) => {
                return <DayCell key={index}>
                    <DayButton onClick={() => onDateClick(d)}>
                        {d.notifications && d.notifications > 0 && <Notification>{d.notifications}</Notification>}
                        <div>
                            <SpanSubtitle1 style={{
                                display: 'inline-block',
                                width: '28px',
                                height: '28px',
                                borderRadius: '50%',
                                margin: '20px 0 -4px',
                                border: new Date(d.date).setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0)
                                    ? `2px solid ${theme.colors.danger}` : 0,
                                color: d.isClose ? theme.colors.danger : d.date.getMonth() !== view.month || new Date(d.date).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)
                                    ? theme.colors.grey60 : theme.colors.dark,
                                opacity: d.isClose ? 0.5 : 1
                            }}>
                                {d.date.getDate()}
                            </SpanSubtitle1>
                        </div>
                        <SpanSubtitle1 style={{fontSize: '11px', opacity: d.isClose ? 0 : 1}}>
                            {d.number && d.number !== 0 ? intl.formatMessage({
                                id: 'calendarPeopleDesc',
                                defaultMessage: '{number} pers.'
                            }, {number: d.number}) : '--'}
                        </SpanSubtitle1>
                    </DayButton>
                </DayCell>
            })}
        </DaysContainer>
        </tbody>
    </CalendarContainer>
}