import {FC} from "react";
import {Col, SpanBody2, SquareButton, ThemeType} from "@linkeo.com/ui-lib-react";
import {useTheme} from "styled-components";
import {ImageLoader} from "../../commons/components/image-loader";
import {CardWrapper} from "../../params/components/card-wrapper";

interface ServiceThumbnailsCardProps {
    title: string;
    onDeleteClick: () => void;
    uri?: string;
    onEditClick: () => void;
}

export const ServiceThumbnailCard: FC<ServiceThumbnailsCardProps> = ({
                                                                                     title,
                                                                                     onDeleteClick,
                                                                                     uri,
                                                                                     onEditClick
                                                                                 }) => {
    const theme = useTheme() as ThemeType;

    return <Col columns={[3]} style={{marginBottom: '20px', minHeight: '340px'}}>
        <CardWrapper style={{minHeight: '100%'}}>
            <SpanBody2>
                {title}
            </SpanBody2>
            <div style={{margin: '20px 0'}}>
                <SquareButton icon={'edit'} fontColor={theme.colors.grey40} onClick={onEditClick}/>
                <SquareButton icon={'delete'} fontColor={theme.colors.grey40} onClick={onDeleteClick}
                              style={{marginLeft: '15px'}}/>
            </div>
            <div style={{width: '100%'}}>
                <ImageLoader uri={uri}/>
            </div>
        </CardWrapper>
    </Col>
}