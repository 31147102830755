import React, {FC} from "react";
import {Row, SpanCaption1} from "@linkeo.com/ui-lib-react";


export const EmptyListMessage: FC<{ message: string }> = ({message}) => {

    return <Row justifyContent={'center'}>
        <SpanCaption1>
            {message}
        </SpanCaption1>
    </Row>
}