import React, {FC} from "react";
import {Button, Col, SpanSubtitle1, ThemeType} from "@linkeo.com/ui-lib-react";
import {FormattedMessage, useIntl} from "react-intl";
import {used} from "../giftcards-types";
import styled, {useTheme} from "styled-components";

const DetailsRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid ${({theme}) => theme.colors.grey90};
  padding: 32px;
  background-color: ${({theme}) => theme.colors.invertPrimary};
  @media screen and (max-width: 600px) {
    padding: 10px;
  }
`
const Container = styled.div<{ show: boolean }>`
  position: relative;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
  max-height: ${({show}) => show ? '250px' : 0};
  @media screen and (max-width: 600px) {
    max-height: ${({show}) => show ? '500px' : 0};
  }
`

interface GiftcardSubInfoCardProps {
    show: boolean;
    giftedBy: string;
    email: string;
    phone: string;
    purchaseDate: string;
    service: string;
    price: number;
    currency: string;
    giftedTo: string;
    utilization?: string;
    expirationDate: string;
    status: string;
    onActivateGiftcardClick: () => void;
    onPrintClick: () => void;
    onEmailClick: () => void;
    onCashClick: () => void;
}

export const GiftcardSubInfoCard: FC<GiftcardSubInfoCardProps> = ({
                                                                      show,
                                                                      giftedBy,
                                                                      currency,
                                                                      giftedTo,
                                                                      expirationDate,
                                                                      purchaseDate,
                                                                      service,
                                                                      price,
                                                                      utilization,
                                                                      phone,
                                                                      email,
                                                                      status,
                                                                      onActivateGiftcardClick,
                                                                      onPrintClick,
                                                                      onEmailClick,
                                                                      onCashClick
                                                                  }) => {
    const intl = useIntl();
    const theme = useTheme() as ThemeType;

    return <Container show={show}>
        <DetailsRow>
            <Col columns={[12, 6]}>
                <SpanSubtitle1><FormattedMessage
                    id={'GiftcardListPageGiftedBySubtitle'}
                    defaultMessage={'Offert par : {br} {name}'}
                    values={{br: null, name: giftedBy}}
                /></SpanSubtitle1>
                <div><SpanSubtitle1> <FormattedMessage
                    id={'GiftcardListPageEmailDetails'}
                    defaultMessage={'Adresse Email : {email}'}
                    values={{email}}
                /></SpanSubtitle1></div>
                <SpanSubtitle1> <FormattedMessage
                    id={'GiftcardListPagePhoneDetails'}
                    defaultMessage={'Numéro de téléphone : {phone}'}
                    values={{phone}}
                /></SpanSubtitle1>
                <div>
                    <SpanSubtitle1>
                        <FormattedMessage
                            id={'GiftcardsListPagePurchaseDateDetails'}
                            defaultMessage={'Date d\'achat : {date}'}
                            values={{date: intl.formatDate(purchaseDate, {dateStyle: 'short'})}}
                        />
                    </SpanSubtitle1>
                </div>
            </Col>
            <Col columns={[12, 6]}>
                <SpanSubtitle1> <FormattedMessage
                    id={'GiftcardListPageServiceSubtitle'}
                    defaultMessage={'Prestation : {service}'}
                    values={{service}}
                /></SpanSubtitle1>
                <div><SpanSubtitle1> <FormattedMessage
                    id={'GiftcardListPagePriceDetails'}
                    defaultMessage={'Valeur du bon : {price}'}
                    values={{price: intl.formatNumber(price, {style: 'currency', currency})}}
                /></SpanSubtitle1></div>

                {status === used
                    ? <>
                        <SpanSubtitle1> <FormattedMessage
                            id={'GiftcardListPageExpirationDateDetails'}
                            defaultMessage={'Date d\'utilisation  : {date}'}
                            values={{date: intl.formatDate(expirationDate, {dateStyle: 'short'})}}
                        /></SpanSubtitle1>
                        <div><SpanSubtitle1> <FormattedMessage
                            id={'GiftcardListPageGiftedToDetails'}
                            defaultMessage={'Bénéficiaire : {giftedTo}'}
                            values={{giftedTo}}
                        /></SpanSubtitle1></div>
                        {utilization && <SpanSubtitle1> <FormattedMessage
                            id={'GiftcardListPageUsedDetailsSubtitle'}
                            defaultMessage={'Mode d\'utilisation : {value}'}
                            values={{value: utilization}}
                        /></SpanSubtitle1>}
                    </>
                    : new Date(expirationDate) < new Date()
                        ? <>
                            <div><SpanSubtitle1> <FormattedMessage
                                id={'GiftcardListPageExpirationDateDetailsSubtitle'}
                                defaultMessage={'Date d\'expiration : {date}'}
                                values={{date: intl.formatDate(expirationDate, {dateStyle: 'short'})}}
                            /></SpanSubtitle1></div>
                            <Button onClick={onActivateGiftcardClick} colorType={'secondary'}
                                    fontColor={theme.colors.light} style={{marginTop: '10px'}}>
                                <FormattedMessage id={'GiftcardListPageReactivateButtonTitle'}
                                                  defaultMessage={'Réactiver ce bon cadeau'}/>
                            </Button>
                        </>
                        : <>
                            <Button onClick={onPrintClick} colorType={'secondary'}
                                    fontColor={theme.colors.light} style={{marginTop: '10px'}}>
                                <FormattedMessage id={'GiftcardListPagePrintButtonTitle'} defaultMessage={'Imprimer'}/>
                            </Button>
                            <Button onClick={onEmailClick} colorType={'secondary'}
                                    fontColor={theme.colors.light} style={{margin: '10px 8px 0'}}>
                                <FormattedMessage id={'email'} defaultMessage={'Email'}/>
                            </Button>
                            <Button onClick={onCashClick} colorType={'primary'} style={{marginTop: '10px'}}>
                                <FormattedMessage id={'GiftcardListPageCashButtonTitle'}
                                                  defaultMessage={'Encaisser le bon cadeau'}/>
                            </Button>
                        </>}
            </Col>
        </DetailsRow>
    </Container>
}