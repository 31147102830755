import {FC} from "react";
import {Link} from "react-router-dom";
import {IconName, SpanLink, Svg, ThemeType} from "@linkeo.com/ui-lib-react";
import styled, {useTheme} from "styled-components";

const StyledSpan = styled(SpanLink)`
  color: ${({theme}) => theme.colors.primary};
  font-weight: bold;
  text-decoration: underline;
  font-size: 16px;

  &:hover {
    color: ${({theme}) => theme.colors.primary};
    text-decoration: inherit;
  }
`

interface PrimaryLinkProps {
    link: string;
    text: string;
    icon?: IconName
}

export const PrimaryLink: FC<PrimaryLinkProps> = ({link, text, icon}) => {
    const theme = useTheme() as ThemeType;

    return <Link to={link} style={{textDecoration: 'inherit', display: 'flex', alignItems: 'center'}}>
        <StyledSpan>{text}</StyledSpan>
        {icon && <Svg icon={icon} fill={theme.colors.primary} width={26} height={26}/>}
    </Link>
}