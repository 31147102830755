import {Agenda} from "./agendas-types";
import {createContext, FC, useCallback, useContext, useMemo, useState} from "react";
import {AgendasApi} from "./agendas-api";
import {appointmentsApi} from "../config/config";

export interface AgendasContextProps {
    agendasList: Agenda[] | null;
    fetchAgendasList: (signal?: AbortSignal) => Promise<void>
}

const AgendasContext = createContext<AgendasContextProps>({
    agendasList: null,
    fetchAgendasList: () => Promise.reject(new Error ('Provider not initialized'))
})

const fetchAllAgendas = async (codeBouton: string, token: string, api: AgendasApi, offset: number, signal?: AbortSignal): Promise<Agenda[]> => {
    const response = await api.getAgendaList(codeBouton, token, 20, offset, signal);
    if ((offset + response.count) < response.total) {
        return [...response.result, ...await fetchAllAgendas(codeBouton, token, api, offset + response.count, signal)]
    }
    return response.result
}

export const useAgendasStore = () => useContext(AgendasContext);

interface AgendasStoreProps {
    codeBouton: string;
    token: string
}

export const AgendasStore: FC<AgendasStoreProps> = ({children, codeBouton, token}) => {
    const api = useMemo<AgendasApi>(() => new AgendasApi(appointmentsApi), []);
    const [agendasList, setAgendasList] = useState<Agenda[] | null>(null);

    const fetchAgendasList = useCallback(async (signal?: AbortSignal) => {
        const result = await fetchAllAgendas(codeBouton, token, api, 0, signal);
        setAgendasList(result)
    }, [api, codeBouton, token])

    return <AgendasContext.Provider value={{
        agendasList,
        fetchAgendasList
    }}>
        {children}
    </AgendasContext.Provider>
}