import {FC, ReactNode} from "react";
import {Row, SpanCaption2, ThemeType} from "@linkeo.com/ui-lib-react";
import {useTheme} from "styled-components";

interface InputWrapperProps {
    children: ReactNode;
    text: string;
    dangerText?: string;
}

export const InputWrapper: FC<InputWrapperProps> = ({children, text, dangerText}) => {
    const theme = useTheme() as ThemeType;

    return <>
        <Row style={{margin: '20px 0'}}>
            {children}
        </Row>
        <p style={{paddingBottom: '28px'}}>
            <SpanCaption2 style={{color: theme.colors.grey30}}>
                {text}
            </SpanCaption2>
            <br />
            {dangerText && <SpanCaption2 style={{color: theme.colors.danger, display: 'inline-block', marginTop: '12px'}}>
                {dangerText}
            </SpanCaption2>}
        </p>
    </>
}