import {FC, useState} from "react";
import {Card, Col, SpanSubtitle1, Switch, ThemeType} from "@linkeo.com/ui-lib-react";
import {useIntl} from "react-intl";
import {useTheme} from "styled-components";

interface ParamsBookingValidationFormProps {

}

export const ParamsBookingValidationForm: FC<ParamsBookingValidationFormProps> = () => {
    const intl = useIntl();
    const theme = useTheme() as ThemeType;
    const [active, setActive] = useState<boolean>(false)

    return <form>
        <Card style={{ padding: 0, marginTop: '28px'}}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', flexWrap: 'wrap' }}>
                <Col columns={[12, 5.5]} style={{ padding: '16px' }}>
                    <Switch
                        value={active}
                        onIcon={'check'}
                        offIcon={'cross'}
                        color={theme.colors.grey40}
                        onChange={setActive}
                        onLabel={intl.formatMessage({
                            id: 'ParamsBookingValidationSwitchLabel',
                            defaultMessage: 'Valider automatiquement les demandes'
                        })}
                    />
                </Col>
                <Col columns={[12, 6.5]} style={{ backgroundColor: theme.colors.invertPrimary, padding: '28px 16px'}}>
                    <SpanSubtitle1>
                        {active ? intl.formatMessage({ id: 'ParamsBookingValidationIsActiveSubtitle', defaultMessage: 'La validation automatique est activée. Les rendez-vous pris par vos clients seront automatiquement confirmés et ajoutés à votre planning.' })
                            : intl.formatMessage({ id: 'ParamsBookingValidationNotActiveSubtitle', defaultMessage: 'La validation automatique est désactivée. Pour chaque rendez-vous, vous devrez décider si vous souhaitez l\'accepter ou le refuser. Soyez réactif, pensez à consulter régulièrement les nouvelles demandes.' })}
                    </SpanSubtitle1>
                </Col>
            </div>
        </Card>
    </form>
}