import {FC, useState} from "react";
import {Card, Col, Row} from "@linkeo.com/ui-lib-react";
import {ParamsClosuresReadonlyCard} from "./params-closures-readonly-card";
import {ParamsClosureCard} from "./params-closure-card";
import {Locale} from "../../../providers/intl-provider";
import {CalendarDate, ParamsClosureCalendar} from "./params-closure-calendar";

interface ParamsClosuresCalendarFormProps {
    locale: Locale;
}

export const ParamsClosuresCalendarForm: FC<ParamsClosuresCalendarFormProps> = ({locale}) => {
    const [readOnly, setReadOnly] = useState<boolean>(true)
    const [isClosed] = useState<boolean>(false);
    const [selectedDate, setSelectedDate] = useState<string>(new Date().toString())

    const onDateClick = (date: CalendarDate) => {
        setSelectedDate(date.date.toString())
        setReadOnly(false);
    }

    const onClosureDateSave = (startDate: Date, endDate: Date) => {
        console.log(startDate, endDate)
        setReadOnly(true)
    }

    return <Row style={{marginTop: '32px'}} justifyContent={'center'}>
        <Col columns={[12, 12, 6]}>
            <ParamsClosureCalendar locale={locale} onDateClick={onDateClick}/>
        </Col>
        <Col columns={[12, 12, 6]}>
            <Card style={{minHeight: '300px'}}>
                {readOnly ? <ParamsClosuresReadonlyCard/>
                    : <ParamsClosureCard date={selectedDate}
                                         locale={locale}
                                         isClosed={isClosed}
                                         onClosureDelete={() => setReadOnly(true)}
                                         onClose={() => setReadOnly(true)}
                                         onClosureDateSave={onClosureDateSave}
                    />}
            </Card>
        </Col>
    </Row>
}