import {FC, FormEvent, useEffect, useState} from "react";
import {Card, Checkbox, Col, Row} from "@linkeo.com/ui-lib-react";
import {ParamsFormHeader} from "../../params/components/params-form-header";
import {useIntl} from "react-intl";
import {AgendasInfoForm} from "./agendas-info-form";
import {ParamsCard} from "../../params/components/params-card";
import {Opening, OpeningKey, AgendasAvailabilitiesForm} from "./agendas-availabilities-form";
import {FormFooter} from "../../commons/components/form-footer";
import {updateItemInArray} from "../../utils/misc";
import {getLocaleDaysNames} from "../../utils/date";

export interface AgendaFormProps {
    id?: string;
    name: string;
    notifName: string;
    email: string;
    tel: string;
    description: string;
    services: string[];
    openings: Opening[]
}

interface AgendasEditFormProps {
    onCloseClick: () => void;
    form: AgendaFormProps;
    locale: string
}

export const AgendasEditForm: FC<AgendasEditFormProps> = ({onCloseClick, form: formProps, locale}) => {
    const intl = useIntl();
    const [form, setForm] = useState<AgendaFormProps>(formProps)

    useEffect(() => {
        setForm(formProps)
    }, [formProps])

    const onCheckboxSelect = (value: string) => {
        const find = form.services.find(el => el === value)
        if (!find) {
            setForm({...form, services: [...form.services, value]})
        } else {
            setForm({...form, services: form.services.filter(el => el !== value)})
        }
    }

    const onTimingUpdate = (value: string, index: number, i: number, key: OpeningKey) => {
        const updatedForm: AgendaFormProps = {...form};
        const updatedOpening: Opening = {...updatedForm.openings[index]};
        const updatedHours = [...updatedOpening.hours];
        updatedHours[i][key] = value;
        updatedOpening.hours = updatedHours;
        updatedForm.openings[index] = updatedOpening;
        setForm({...updatedForm})
    }

    const onIsolatedDaySwitchChange = (index: number) => {
        if (form.openings[index].day.length === 1) {
            setForm({
                ...form,
                openings: updateItemInArray(form.openings, index, {...form.openings[index], day: [0, 4]})
            })
        } else {
            setForm({...form, openings: updateItemInArray(form.openings, index, {...form.openings[index], day: [0]})})
        }
    }

    const onContinuousDaySwitchChange = (index: number) => {
        if (form.openings[index].hours.length === 1) {
            setForm({
                ...form,
                openings: updateItemInArray(form.openings, index, {
                    ...form.openings[index],
                    hours: [{from: '09:00', to: '12:00'}, {from: '14:00', to: '18:00'}]
                })
            })
        } else {
            setForm({
                ...form,
                openings: updateItemInArray(form.openings, index, {
                    ...form.openings[index],
                    hours: [{from: '09:00', to: '18:00'}]
                })
            })
        }
    }

    const _onSubmit = (e: FormEvent) => {
        e.preventDefault();
        e.stopPropagation();
        console.log(form)
    }

    return <Card style={{marginBottom: '28px'}}>
        <form onSubmit={_onSubmit}>
            <ParamsFormHeader
                title={form.id ? intl.formatMessage({
                    id: 'ParamsAgendaEditFormTitle',
                    defaultMessage: 'Modifier un planning'
                }) : intl.formatMessage({id: 'ParamsAgendaEditFormAddTitle', defaultMessage: 'Ajoutez une planning'})}
                subtitle={intl.formatMessage({
                    id: 'ParamsAgendaEditFormEditSubtitle',
                    defaultMessage: 'Renseignez ici les informations concernant le planning.'
                })}
                onCloseClick={onCloseClick}
            />
            <AgendasInfoForm
                form={form}
                setForm={setForm}
            />
            <ParamsCard inCard
                        title={intl.formatMessage({
                            id: 'ParamsAgendasAvailabilitiesFormTitle',
                            defaultMessage: 'Disponibilités du planning'
                        })}
                        subtitle={intl.formatMessage({
                            id: 'ParamsAgendasAvailabilitiesFormSubTitle',
                            defaultMessage: 'Renseignez ici les horaires sur lesquelles le planning accepte les rendez-vous.'
                        })}>
                <AgendasAvailabilitiesForm
                    openings={form.openings}
                    onIsolatedDaySwitchChange={onIsolatedDaySwitchChange}
                    onContinuousDaySwitchChange={onContinuousDaySwitchChange}
                    onAddOpeningButtonClick={() => setForm({
                        ...form,
                        openings: [...form.openings, {day: [0, 4], hours: [{from: '09:00:00', to: '18:00:00'}]}]
                    })}
                    onSelectDayChange={(value, index) => setForm({
                        ...form,
                        openings: updateItemInArray(form.openings, index, {
                            ...form.openings[index],
                            day: [getLocaleDaysNames(locale).indexOf(value)]
                        })
                    })}
                    onStartDayChange={(value, index) => setForm({
                        ...form,
                        openings: updateItemInArray(form.openings, index, {
                            ...form.openings[index],
                            day: updateItemInArray(form.openings[index].day, 0, getLocaleDaysNames(locale).indexOf(value))
                        })
                    })}
                    onEndDayChange={(value, index) => setForm({
                        ...form,
                        openings: updateItemInArray(form.openings, index, {
                            ...form.openings[index],
                            day: updateItemInArray(form.openings[index].day, 1, getLocaleDaysNames(locale).indexOf(value))
                        })
                    })}
                    onSelectedTimingChange={(value, index, i, key) => onTimingUpdate(value, index, i, key)}
                    onDeleteClick={(index) => {
                        if (form.openings.length > 1) {
                            setForm({...form, openings: form.openings.filter((_, i) => i !== index)})
                        }
                    }}
                    onTimeIntervalAddClick={(index) => {
                        setForm({
                            ...form,
                            openings: updateItemInArray(form.openings, index, {
                                ...form.openings[index],
                                hours: [...form.openings[index].hours, {from: '12:00', to: '18:00'}]
                            })
                        })
                    }}
                    onTimeIntervalDeleteClick={(index, i) => {
                        setForm({
                            ...form,
                            openings: updateItemInArray(form.openings, index, {
                                ...form.openings[index],
                                hours: form.openings[index].hours.filter((_, j) => i !== j)
                            })
                        })
                    }}
                />
            </ParamsCard>
            <ParamsCard inCard
                        title={intl.formatMessage({
                            id: 'ParamsAgendasAvailabilityServiceFormTitle',
                            defaultMessage: 'Prestations associées au planning'
                        })}
                        subtitle={intl.formatMessage({
                            id: 'ParamsAgendasAvailabilityServiceFormSubTitle',
                            defaultMessage: 'Cochez dans la liste ci-dessous les prestations qui sont ouvertes à la réservation sur ce planning.'
                        })}>
                <div style={{margin: '28px 0'}}>
                    <Row>
                        {['AQUAGYM', 'Beard trimmer', 'Conseil digital', 'Coupe coiffage', 'Espagnol', 'Rasage à l’ancienne', 'Shampoing coupe coiffage']
                            .map((item, index) => {
                                return <Col key={index}>
                                    <Checkbox onChange={() => onCheckboxSelect(item)}
                                              value={!!form.services.find(el => el === item)}>
                                        {item}
                                    </Checkbox>
                                </Col>
                            })}
                    </Row>
                </div>
            </ParamsCard>
            <FormFooter onCancelClick={onCloseClick}
                        saveIcon={'check'}
                        submitButtonText={intl.formatMessage({
                            id: 'ParamsAgendaEditSubmitFormButtonLabel',
                            defaultMessage: 'Enregistrer le planning'
                        })}
            />
        </form>
    </Card>
}