import React, {FC, useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router";
import {PageWrapper} from "../../commons/components/page-wrapper";
import {ContainerHeader} from "../../commons/components/container-header";
import {FormattedMessage, useIntl} from "react-intl";
import {BaseButton, Button, Container, InOut, Row, SpanButton, Svg, ThemeType} from "@linkeo.com/ui-lib-react";
import styled, {useTheme} from "styled-components";
import {routeParamsServiceCreate, routeParamsServiceEdit} from "../../routes";
import {EmptyListMessage} from "../../params/components/empty-list-message";
import {ServiceCard} from "../../services/components/service-card";
import {ServiceDeleteModal} from "../../services/components/service-delete-modal";
import {ServiceThumbnailCard} from "../../services/components/service-thumbnail-card";
import {BackToTop} from "../../commons/components/back-to-top";
import {CategoryCard} from "../../services/components/category-card";
import {CategoryDeleteModal} from "../../services/components/category-delete-modal";
import {reOrderList} from "../../utils/misc";
import {ServiceCategoryForm} from '../../services/components/service-category-form';
import {generatePath} from "react-router-dom";

const LayoutSvg = styled(Svg)`
    &:hover {
        fill: ${({theme}) => theme.colors.grey20}
    }
`

const CATEGORY_LIST_MOCKUP = [{
    id: 'edcrfvTGByhnUJik',
    title: 'Cheveux',
    description: ''
}, {
    id: 'zsxEDcrfvBGTyhNJu',
    title: 'Cours collectifs',
    description: 'Groupe'
}, {
    id: 'ujnikOLpmYHkjhgf',
    title: 'Formation',
    description: ''
}]
const SERVICES_LIST_MOCKUP = [{
    id: 'aqwzsxEDCrfvTGByhnUJikOlpm',
    title: 'AQUAGYM',
    category: 'Cours collectifs',
    agendas: ['Eugénie', 'Laurence', "Jonathan", "Alison"],
    description: '<p>Musique rythmée, professeur toujours de bonne humeur, ambiance conviviale, <strong>les cours d’aquagym sont faits pour vous !</strong></p>',
    duration: 45,
    giftable: true,
    peopleMax: 8,
    price: 40,
    waitingList: 1
}, {
    id: '01964cecfa49f789bd6702c02cf6e531b2913f73',
    title: 'Coupe coiffage',
    category: 'Cheveux',
    waitingList: 0,
    price: 55,
    peopleMax: 4,
    giftable: true,
    duration: 60,
    description: '',
    agendas: ['Eugénie', 'Stéphanie', 'Luc']
}, {
    id: 'pmoikujYHNtgbRFvedcXCCVbvn',
    title: 'Coloration',
    category: 'Cheveux',
    agendas: ['Eugénie', 'Laurence'],
    peopleMax: 2,
    description: '',
    duration: 120,
    giftable: false,
    price: 75,
    waitingList: 1
}]

interface ServicesListPageProps {

}

export const ServicesListPage: FC<ServicesListPageProps> = () => {
    const intl = useIntl();
    const theme = useTheme() as ThemeType;
    const navigate = useNavigate();
    const [loading] = useState<boolean>(false);
    const [listLayout, setListLayout] = useState<boolean>(true);
    const [smallScreen, setSmallScreen] = useState(false);
    const [openServiceDeleteModal, setOpenServiceDeleteModal] = useState<boolean>(false);
    const [openCategoryDeleteModal, setOpenCategoryDeleteModal] = useState<boolean>(false);
    const [openEditCategory, setOpenEditCategory] = useState<boolean>(false);
    const ref = useRef<HTMLDivElement | null>(null)
    const [servicesList] = useState(SERVICES_LIST_MOCKUP);
    const [categoryList, setCategoryList] = useState(CATEGORY_LIST_MOCKUP);
    const defaultCategoryForm = {
        title: '',
        description: '',
        services: []
    }
    const [categoryForm, setCategoryForm] = useState(defaultCategoryForm);
    const dragOverItem = useRef<number | null>(null);
    const dragItem = useRef<number | null>(null);

    useEffect(() => {
        const handleResize = () => {
            const size = window.innerWidth;
            if (size < 960) {
                setSmallScreen(true)
            } else {
                setSmallScreen(false)
            }
        }
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    const onDeleteClick = () => {
        setOpenServiceDeleteModal(true)
    }

    const onDragStart = (index: number) => {
        dragItem.current = index;
    }

    const onDragOver = (e: React.DragEvent) => {
        e.preventDefault();
    }

    const onDragEnter = (index: number) => {
        dragOverItem.current = index;
    }

    const onDrop = () => {
        if (dragItem.current !== null && dragOverItem.current !== null) {
            setCategoryList(reOrderList(categoryList, dragItem.current, dragOverItem.current))
        }
        dragOverItem.current = null;
        dragItem.current = null;
    }

    return <PageWrapper isLoading={loading}>
        <Container size={'lg'}>
            <ContainerHeader title={intl.formatMessage({
                id: 'ParamsServicesPageContainerHeaderTitle',
                defaultMessage: 'Prestations proposées'
            })}
                             subtitle={intl.formatMessage({
                                 id: 'ParamsServicesPageContainerHeaderSubTitle',
                                 defaultMessage: 'Ajoutez et éditez les prestations que vous proposez à {br}vos clients sur votre site.'
                             }, {br: <br/>}) as string}
                             buttonLabel={intl.formatMessage({
                                 id: 'ParamsServicesPageContainerHeaderAddButtonLabel',
                                 defaultMessage: '+ Ajoutez une prestation'
                             })}
                             to={routeParamsServiceCreate}>
                <>
                    {!smallScreen && <>
                        <BaseButton style={{marginRight: '8px'}} onClick={() => setListLayout(true)}>
                            <LayoutSvg icon={'burger-menu'} width={30} height={30}
                                       fill={listLayout ? theme.colors.grey20 : theme.colors.grey60}/>
                        </BaseButton>
                        <BaseButton style={{marginRight: '28px'}} onClick={() => setListLayout(false)}>
                            <LayoutSvg icon={'handle'} width={30} height={30}
                                       fill={listLayout ? theme.colors.grey60 : theme.colors.grey20}/>
                        </BaseButton>
                    </>}
                    <Button onClick={() => ref.current?.scrollIntoView({behavior: 'smooth'})}
                            style={{margin: smallScreen ? '10px 18px' : 'initial'}}>
                        <SpanButton style={{color: theme.colors.grey20, fontWeight: '600'}}>
                            <FormattedMessage
                                id={'ParamsServicesPageContainerHeaderCategoriesButton'}
                                defaultMessage={'Gérer les catégories'}
                            />
                        </SpanButton>
                    </Button>
                </>
            </ContainerHeader>
            {servicesList.length > 0
                ? !listLayout && !smallScreen
                    ? <Row wraps={"wrap"}>
                        {servicesList.map(service => {
                            return <ServiceThumbnailCard key={service.id}
                                                         title={service.title}
                                                         onEditClick={() => navigate(generatePath(routeParamsServiceEdit, {id: service.id}))}
                                                         onDeleteClick={onDeleteClick}/>
                        })}
                    </Row>
                    : servicesList.map(service => {
                        return <ServiceCard key={service.id}
                                            {...service}
                                            currency={'EUR'}
                                            onEditClick={() => navigate(generatePath(routeParamsServiceEdit, {id: service.id}))}
                                            onDeleteClick={onDeleteClick}/>
                    })
                : <EmptyListMessage message={intl.formatMessage({
                    id: 'ParamsServicesPageEmptyListMessage',
                    defaultMessage: 'Vous ne proposez pas encore de prestations sur votre site.'
                })}/>}
            <div ref={ref}>
                <ContainerHeader
                    title={intl.formatMessage({
                        id: 'ParamsServicePageCategoryHeaderTitle',
                        defaultMessage: 'Catégories de prestations'
                    })}
                    subtitle={intl.formatMessage({
                        id: 'ParamsServicePageCategoryHeaderSubTitle',
                        defaultMessage: 'Ajoutez, éditez ou supprimez des catégories pour classer vos prestations.'
                    })}
                    buttonLabel={intl.formatMessage({
                        id: 'ParamsServicePageCategoryHeaderButtonLabel',
                        defaultMessage: '+ Ajouter une catégorie'
                    })}
                    onClick={() => {
                        setCategoryForm(defaultCategoryForm)
                        setOpenEditCategory(true)
                    }}
                />
                <InOut show={openEditCategory}>
                    <ServiceCategoryForm
                        form={categoryForm}
                        onCancelClick={() => setOpenEditCategory(false)}
                        services={['AQUAGYM', 'Coupe coiffage', 'Espagnol']}
                    />
                </InOut>
                {categoryList.length > 0
                    ? categoryList.map((category, index) => {
                        return <CategoryCard
                            key={category.id}
                            description={category.description}
                            title={category.title}
                            services={['AQUAMIX', 'Coupe coiffage']}
                            onDeleteClick={() => setOpenCategoryDeleteModal(true)}
                            onDragOver={(e) => onDragOver(e)}
                            onDragStart={() => onDragStart(index)}
                            onDrop={onDrop}
                            onDragEnter={() => onDragEnter(index)}
                            form={categoryForm}
                            onEditClick={() => {
                                setOpenEditCategory(false);
                                setCategoryForm({...category, services: []})
                            }}
                        />
                    })
                    : <EmptyListMessage message={intl.formatMessage({
                        id: 'ParamsServicePageEmptyCategoryListMessage',
                        defaultMessage: 'Aucune catégorie créée'
                    })}/>}
            </div>
            <CategoryDeleteModal
                show={openCategoryDeleteModal}
                onClose={() => setOpenCategoryDeleteModal(false)}
                onDeleteClick={() => {
                }}
            />
            <ServiceDeleteModal show={openServiceDeleteModal}
                                onServiceDelete={() => {
                                      }}
                                selectedService={'AQUAGYM'}
                                onClose={() => setOpenServiceDeleteModal(false)}/>
            <BackToTop/>
        </Container>
    </PageWrapper>
}