import {FC, useEffect, useState} from "react";
import {BaseButton, Svg, ThemeType} from "@linkeo.com/ui-lib-react";
import styled, {useTheme} from "styled-components";

const Container = styled(BaseButton)<{show: boolean}>`
  position: fixed;
  bottom: 0;
  right: 0;
  height: 40px;
  width: 40px;
  margin: 40px;
  background-color: ${({theme}) => theme.colors.grey20};
  border-radius: 4px;
  display: ${({show}) => show ? 'flex' : 'none'};
  justify-content: center;
  align-items: center;
`

export const BackToTop: FC = () => {
    const theme = useTheme() as ThemeType;
    const [show, setShow] = useState<boolean>(false)

    useEffect(() => {
        const onScroll = () => {
            if (window.scrollY > 100) {
                setShow(true)
            } else {
                setShow(false)
            }
        }
        window.addEventListener('scroll', onScroll)
        return () => window.removeEventListener('scroll', onScroll)
    }, [])

    return <Container show={show} onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>
        <Svg icon={'up'} fill={theme.colors.light} width={34} height={34}/>
    </Container>
}