import {ChangeEvent, Dispatch, FC, SetStateAction, useRef, useState} from "react";
import {BaseInput, Button, Col, Dropdown, Row, SpanSubtitle1, Svg, ThemeType} from "@linkeo.com/ui-lib-react";
import {FormattedMessage, useIntl} from "react-intl";
import styled, {useTheme} from "styled-components";

const membership = ['Aucun', 'Carte 10', 'Abonnement annuel', 'Club']

const SelectRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 7px 16px;
    background-color: ${({theme}) => theme.colors.grey95};
    cursor: pointer;
    border-radius: 3px;
`
const List = styled.div`
    background-color: ${({theme}) => theme.colors.grey90};
    padding: 12px;
    border-top: 1px solid ${({theme}) => theme.colors.grey80};

    &:hover {
        background-color: ${({theme}) => theme.colors.grey95};
    }
`

interface CustomersNavProps {
    onSelectClick: () => void;
    isSelected: boolean;
    setSearchValue: Dispatch<SetStateAction<string>>;
    searchValue: string
}

export const CustomersNav: FC<CustomersNavProps> = ({onSelectClick, isSelected, setSearchValue, searchValue}) => {
    const theme = useTheme() as ThemeType;
    const intl = useIntl();
    const [show, setShow] = useState<boolean>(false);
    const wrapperRef = useRef(null);

    const onSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value)
    }

    return <div style={{backgroundColor: theme.colors.grey90, padding: '8px'}}>
        <Row justifyContent={'center'} alignItems={'center'} style={{rowGap: '15px'}}>
            <Col>
                <SelectRow
                    ref={wrapperRef}
                    onMouseEnter={() => setShow(true)}
                    onClick={() => setShow(true)}
                    onMouseLeave={() => setShow(false)}>
                    <SpanSubtitle1 style={{color: theme.colors.grey60}}>
                        <FormattedMessage id={"CustomersNavMembershipTitle"} defaultMessage={'Abonnements:'}/>
                    </SpanSubtitle1>
                    <Svg icon={"down"} width={20} height={20} style={{marginLeft: "10px"}} fill={theme.colors.grey60}/>
                    <Dropdown show={show} onClose={() => setShow(false)} wrapperRef={wrapperRef}>
                        {membership.map((el, index) => <List key={index}>
                            <SpanSubtitle1 style={{color: theme.colors.grey60}}>{el}</SpanSubtitle1>
                        </List>)}
                    </Dropdown>
                </SelectRow>
            </Col>
            <Col>
                <BaseInput
                    placeholder={intl.formatMessage({
                        id: 'CustomersNavSearchInputPlaceholder',
                        defaultMessage: 'Rechercher'
                    })}
                    onChange={onSearchChange}
                    value={searchValue}
                />
            </Col>
            <Col>
                <Button colorType="outline" onClick={onSelectClick} style={{marginRight: '16px'}}>
                    {isSelected
                        ? <FormattedMessage id={'CustomersNavSelectButtonTitleUnselect'}
                                            defaultMessage={'Tout désélectionner'}/>
                        : <FormattedMessage id={'CustomersNavSelectButtonTitleSelect'}
                                            defaultMessage={'Tout sélectionner'}/>}
                </Button>
                <Button colorType="primary" style={{marginLeft: '16px'}}>
                    <FormattedMessage id={'CustomersNavExportButtonTitle'} defaultMessage={'Exporter'}/>
                </Button>
            </Col>
        </Row>
    </div>
}