import React, {FC, FormEvent, useEffect, useState} from "react";
import {Button, Col, DatePicker, Row, SpanButton, SpanSubtitle1, SquareButton} from "@linkeo.com/ui-lib-react";
import {useIntl} from "react-intl";
import {Locale} from "../../../providers/intl-provider";
import {getPreviousDate} from "../../../utils/date";

interface ParamsClosureCardProps {
    date: string;
    onClosureDelete: () => void;
    isClosed: boolean;
    locale: Locale;
    onClose: () => void;
    onClosureDateSave: (startDate: Date, endDate: Date) => void;
}

export const ParamsClosureCard: FC<ParamsClosureCardProps> = ({
                                                                  date,
                                                                  onClosureDelete,
                                                                  isClosed,
                                                                  locale,
                                                                  onClose,
                                                                  onClosureDateSave
                                                              }) => {
    const intl = useIntl();
    const selectedDate = intl.formatDate(date, {dateStyle: 'full'})
    const [startDate, setStartDate] = useState<Date>(new Date(date));
    const [endDate, setEndDate] = useState<Date>(new Date(date))

    useEffect(() => {
        setStartDate(new Date(date));
        setEndDate(new Date(date))
    }, [date])

    const _onSubmit = (e: FormEvent) => {
        e.preventDefault();
        e.stopPropagation();
        onClosureDateSave(startDate, endDate)
    }

    return <form onSubmit={_onSubmit}>
        <Row alignItems={'center'} justifyContent={'space-between'} style={{marginBottom: '20px'}}>
            <Col>
                <SpanSubtitle1 style={{textTransform: 'capitalize'}}>
                    {selectedDate}
                </SpanSubtitle1>
            </Col>
            {isClosed && <Col>
                <SquareButton icon={'delete'} onClick={onClosureDelete}/>
            </Col>}
        </Row>

        <Row style={{marginBottom: '48px'}}>
            <Col columns={[12, 6]}>
                <DatePicker
                    locale={locale.substring(0, 2)}
                    label={intl.formatMessage({
                        id: 'ParamsClosurePageStartDateLabel',
                        defaultMessage: 'Date de début:'
                    })}
                    disabledDays={{min: getPreviousDate()}}
                    onChange={(v) => setStartDate(v ? v : new Date(date))}
                    value={startDate}/>
            </Col>
            <Col columns={[12, 6]}>
                <DatePicker
                    locale={locale.substring(0, 2)}
                    disabledDays={{min: startDate}}
                    label={intl.formatMessage({id: 'ParamsClosurePageEndDateLabel', defaultMessage: 'Date de fin:'})}
                    onChange={(v) => setEndDate(v ? v : new Date(date))}
                    value={endDate}/>
            </Col>
        </Row>

        <Button type={'submit'} colorType={'primary'} style={{margin: '5px 20px 5px 0'}}>
            <SpanButton>{intl.formatMessage({
                id: 'saveUpdateButtonTitle',
                defaultMessage: 'Enregistrer les modifications'
            })}</SpanButton>
        </Button>
        <Button colorType={'secondary'} onClick={onClose}>
            <SpanButton>{intl.formatMessage({
                id: 'CancelButtonLabel',
                defaultMessage: 'Annuler'
            })}
            </SpanButton>
        </Button>
    </form>
}