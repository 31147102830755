import {FC} from "react";
import {BaseButton, SpanButton, ThemeType} from "@linkeo.com/ui-lib-react";
import {FormattedMessage} from "react-intl";
import {useTheme} from "styled-components";
import {ScheduleRow} from "./schedule-row";
import {getLocaleDaysNames} from "../../utils/date";

export type OpeningKey = 'from' | 'to';

interface Hours {
    from: string;
    to: string
}

export interface Opening {
    day: number[];
    hours: Hours[]
}

interface AgendasAvailabilitiesFormProps {
    openings: Opening[];
    onAddOpeningButtonClick: () => void;
    onIsolatedDaySwitchChange: (index: number) => void;
    onContinuousDaySwitchChange: (index: number) => void;
    onSelectDayChange: (value: string, index: number) => void;
    onStartDayChange: (value: string, index: number) => void;
    onEndDayChange: (value: string, index: number) => void;
    onSelectedTimingChange: (value: string, index: number, i: number, key: OpeningKey) => void;
    onDeleteClick: (index: number) => void;
    onTimeIntervalAddClick: (index: number) => void;
    onTimeIntervalDeleteClick: (i: number, index: number) => void;
}

export const AgendasAvailabilitiesForm: FC<AgendasAvailabilitiesFormProps> = ({
                                                                                              openings,
                                                                                              onAddOpeningButtonClick,
                                                                                              onSelectDayChange,
                                                                                              onEndDayChange,
                                                                                              onStartDayChange,
                                                                                              onIsolatedDaySwitchChange,
                                                                                              onContinuousDaySwitchChange,
                                                                                              onSelectedTimingChange,
                                                                                              onDeleteClick,
                                                                                              onTimeIntervalAddClick,
                                                                                              onTimeIntervalDeleteClick
                                                                                          }) => {
    const theme = useTheme() as ThemeType;
    const daysList = getLocaleDaysNames('fr-FR');
    const hoursList = Array.from({length: 24}, (_, i) => i.toString().padStart(2, '0') + 'h');
    const minutesList = Array.from({length: 12}, (_, i) => (i * 5).toString().padStart(2, '0'));

    return <div style={{marginBottom: '38px'}}>
        {openings.map((opening, index) => {
            return <ScheduleRow index={index} key={index}
                                daysList={daysList}
                                hoursList={hoursList}
                                minutesList={minutesList}
                                opening={opening}
                                onIsolatedDaySwitchChange={() => onIsolatedDaySwitchChange(index)}
                                onContinuousDaySwitchChange={() => onContinuousDaySwitchChange(index)}
                                onSelectDayChange={onSelectDayChange}
                                onStartDayChange={onStartDayChange}
                                onEndDayChange={onEndDayChange}
                                onSelectedTimingChange={onSelectedTimingChange}
                                onScheduleRowDeleteClick={() => onDeleteClick(index)}
                                onTimeIntervalAddClick={() => onTimeIntervalAddClick(index)}
                                onTimeIntervalDeleteClick={(i) => onTimeIntervalDeleteClick(index, i)}
            />
        })}
        <BaseButton style={{margin: "16px 0", display: 'flex'}} onClick={onAddOpeningButtonClick}>
            <SpanButton style={{color: theme.colors.grey60, fontSize: '42px', marginRight: '5px'}}>+</SpanButton>
            <SpanButton
                style={{color: theme.colors.primary, textTransform: 'uppercase', fontSize: '14px', fontWeight: 600}}>
                <FormattedMessage
                    id={'ParamsAgendaAvailabilityAddButton'}
                    defaultMessage={'Ajouter des disponibilités'}
                />
            </SpanButton>
        </BaseButton>
    </div>
}