import React, {FC} from "react";
import styled from "styled-components";
import {getTypo} from "@linkeo.com/ui-lib-react";

const Select = styled.select<{disabled: boolean}>`
    padding: 8px;
    outline: none;
    background-color: ${({theme}) => theme.input.backgroundColor};
    ${getTypo('input')};
    color: ${({theme, disabled}) => disabled ? theme.colors.grey80 : theme.colors.dark};
    text-transform: capitalize;
    border: 1px solid ${({theme}) => theme.input.borderColor};
    border-radius: ${({theme}) => theme.input.borderRadius};
`

interface TimeSelectProps {
    value: string;
    onSelect: (e: React.ChangeEvent<HTMLSelectElement>) => void;
    options: string[]
    style?: any;
    id?: string;
    disabled?: boolean;
}

export const TimeSelect: FC<TimeSelectProps> = ({value, onSelect, options, style = {}, id = '', disabled = false}) => {

    return <Select value={value} style={style} id={id} disabled={disabled}
                   onChange={onSelect}>
        {options.map((option) => (
            <option key={option} value={option}>
                {option}
            </option>
        ))}
    </Select>
}