import React, {FC} from "react";
import {
    Button,
    Dialog,
    Row,
    SpanBody2,
    SpanButton,
    Svg,
    ThemeType
} from "@linkeo.com/ui-lib-react";
import {useTheme} from "styled-components";
import {useIntl} from "react-intl";

interface GiftcardSuccessModalProps {
    onClose: () => void;
    openModal: boolean;
    text: string | JSX.Element | (string | JSX.Element)[];
    title: string;
    onClick?: () => void;
    textButton?: string
}

export const GiftcardSuccessModal: FC<GiftcardSuccessModalProps> = ({
                                                                        onClose,
                                                                        openModal,
                                                                        text,
                                                                        title,
                                                                        onClick,
                                                                        textButton
                                                                    }) => {
    const theme = useTheme() as ThemeType;
    const intl = useIntl();

    return <Dialog onClose={onClose} show={openModal}
                   title={title}
                   size={'sm'}
                   footer={<Button colorType={'primary'} onClick={onClose}>
                       <SpanButton>{intl.formatMessage({
                           id: 'OkButtonLabel',
                           defaultMessage: 'Ok'
                       })}</SpanButton>
                   </Button>}>
        <Row style={{textAlign: 'center', justifyContent: 'center'}}>
            <Svg icon={'check-circle'} fill={theme.colors.success} width={26} height={26}
                 style={{marginBottom: '20px'}}/>
            <SpanBody2>
                {text}
            </SpanBody2>
            {onClick && textButton && <Button style={{marginTop: '28px'}}>
                {textButton}
            </Button>}
        </Row>
    </Dialog>
}