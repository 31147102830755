import {FC, useEffect, useState} from "react";
import {
    Col, 
    Row,
    SpanBody2,
    SpanSubtitle1, SpanSubtitle2,
    SquareButton,
    Svg,
    ThemeType
} from "@linkeo.com/ui-lib-react";
import {FormattedMessage, useIntl} from "react-intl";
import styled, {useTheme} from "styled-components";
import {ReactComponent as WaitingListIcon} from "../../icons/waiting-list.svg";
import {ReactComponent as PriceIcon} from "../../icons/price.svg";
import {ServiceInfo} from "./service-info";
import {CardWrapper} from "../../params/components/card-wrapper";
import {ImageLoader} from "../../commons/components/image-loader";
import { Tag } from "../../commons/components/tag";

const HeaderRow = styled(Row)`
    padding: 18px 0;
    
    @media screen and (max-width: 992px) {
        row-gap: 25px;
        
        .service_card_header_item:nth-child(2) {
            order: 3;
        }
    }
    
`

interface ServiceCardProps {
    title: string;
    category: string;
    peopleMax: number;
    giftable: boolean;
    waitingList: number;
    uri?: string;
    duration: number;
    price: number;
    currency: string;
    onDeleteClick: () => void;
    description: string;
    agendas: string[];
    onEditClick: () => void;
}

export const ServiceCard: FC<ServiceCardProps> = ({
                                                                  title,
                                                                  category,
                                                                  peopleMax,
                                                                  giftable,
                                                                  waitingList,
                                                                  duration,
                                                                  currency,
                                                                  price,
                                                                  uri,
                                                                  onDeleteClick,
                                                                  description: descriptionProps,
                                                                  agendas,
                                                                  onEditClick
                                                              }) => {
    const theme = useTheme() as ThemeType;
    const intl = useIntl();
    const [description, setDescription] = useState<string>('');

    useEffect(() => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(descriptionProps, 'text/html');
        const textContent = doc.body.textContent;
        if (textContent !== null) {
            setDescription(textContent)
        }
    }, [descriptionProps])

    return <CardWrapper style={{marginBottom: '30px'}}>
        <Row justifyContent={'space-between'}>
            <Col>
                <SpanSubtitle1 style={{color: theme.colors.grey60}}>
                    <FormattedMessage id={'ParamsServiceCardTitle'} defaultMessage={'Prestation :'}/>
                </SpanSubtitle1>
            </Col>
        </Row>
        <HeaderRow>
            <Col className={'service_card_header_item'} columns={[5, 5, 5, 2.5]}>
                <SpanBody2>
                    {title}
                </SpanBody2>
            </Col>
            <Col className={'service_card_header_item'} columns={[12, 12, 12, 7.5]}>
                <ServiceInfo text={category}/>
                <ServiceInfo text={intl.formatMessage({
                    id: 'ParamsServiceCardMaxPeopleInfo',
                    defaultMessage: '{max} pers'
                }, {max: peopleMax})} icon={'group'}/>
                {waitingList > 0 && <ServiceInfo text={intl.formatMessage({
                    id: 'ParamsServiceCarWaitingListInfo',
                    defaultMessage: '{number, plural, one {1 place} other {# places}}'
                }, {number: waitingList})}>
                    <WaitingListIcon height={20} width={20} fill={theme.colors.grey40}
                                     style={{verticalAlign: 'middle', marginRight: '5px'}}/>
                </ServiceInfo>}
                <ServiceInfo
                    text={duration.toString()} icon={'clock'}/>
                <ServiceInfo text={intl.formatNumber(price, {
                    style: 'currency',
                    currency,
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0,
                    currencyDisplay: 'name'
                })}>
                    <PriceIcon height={20} width={20} fill={theme.colors.grey40}
                               style={{verticalAlign: 'middle', marginRight: '5px'}}/>
                </ServiceInfo>
                {giftable && <Svg icon={'gift'} height={20} width={20} fill={theme.colors.grey40}
                                  style={{verticalAlign: 'middle', marginRight: '5px'}}/>}
            </Col>
            <Col className={'service_card_header_item'} columns={[6, 6, 6, 2]} style={{textAlign: 'end'}}>
                <SquareButton icon={'edit'} fontColor={theme.colors.grey40} style={{margin: '0 15px'}}
                              onClick={onEditClick}/>
                <SquareButton icon={'delete'} fontColor={theme.colors.grey40} onClick={onDeleteClick}/>
            </Col>
        </HeaderRow>
        <Row>
            <Col columns={[12, 12, 2.5]}>
                <div style={{width: '100%', height: '118px'}}>
                    <ImageLoader uri={uri}/>
                </div>
            </Col>
            <Col columns={[12, 12, 9.5]}>
                <SpanSubtitle1 style={{color: theme.colors.grey60}}>
                    <FormattedMessage
                        id={'ParamsServiceCardDescriptionTitle'}
                        defaultMessage={'Description de la prestation :'}
                    />
                </SpanSubtitle1>
                <div style={{margin: '8px 0 28px'}}>
                    <SpanSubtitle2>
                        {description}
                    </SpanSubtitle2>
                </div>
                <SpanSubtitle1 style={{color: theme.colors.grey60}}>
                    <FormattedMessage id={'ParamsServiceCardAgendaTitle'} defaultMessage={'Plannings associés'}/>
                </SpanSubtitle1>
                <div style={{margin: '8px 0'}}>
                    {agendas.map((name, index) => {
                        return <Tag key={index}>
                            {name}
                        </Tag>
                    })}
                </div>
            </Col>
        </Row>
    </CardWrapper>
}