import {FC, useState} from "react";
import {Card, Col, getTypo, Row, SpanSubtitle1, Switch, ThemeType} from "@linkeo.com/ui-lib-react";
import {FormattedMessage, useIntl} from "react-intl";
import styled, {useTheme} from "styled-components";

const Li = styled.li`
  ${getTypo('subtitle1')}
  color: ${({theme}) => theme.colors.grey60}
`

interface ParamsNotificationsManagerFormProps {

}

export const ParamsNotificationsManagerForm: FC<ParamsNotificationsManagerFormProps> = () => {
    const intl = useIntl();
    const theme = useTheme() as ThemeType;
    const [active, setActive] = useState<boolean>(true)

    return <Card style={{marginTop: '28px'}}>
        <form>
            <Row>
                <Col columns={[12, 12, 6]}>
                    <Switch
                        onIcon={'check'}
                        offIcon={'cross'}
                        color={theme.colors.grey40}
                        onLabel={intl.formatMessage({
                            id: 'ParamsNotificationsManagerFormSwitchLabel',
                            defaultMessage: 'Recevez les SMS de notifications'
                        })}
                        value={active}
                        onChange={(v) => setActive(v)}
                    />
                </Col>
                <Col columns={[12, 12, 6]}>
                    <SpanSubtitle1>
                        <FormattedMessage
                            id={'ParamsNotificationsPageManagerListTitle'}
                            defaultMessage={'Les notifications seront reçues :'}
                        />
                    </SpanSubtitle1>
                    <ul>
                        <Li>
                            <FormattedMessage
                                id={'ParamsNotificationsPageManagerListItem1'}
                                defaultMessage={'À la création/demande d\'un nouveau rendez-vous'}
                            />
                        </Li>
                        <Li>
                            <FormattedMessage
                                id={'ParamsNotificationsPageManagerListItem2'}
                                defaultMessage={'À l\'annulation d\'un rendez-vous'}
                            />
                        </Li>
                        <Li>
                            <FormattedMessage
                                id={'ParamsNotificationsPageManagerListItem3'}
                                defaultMessage={'À la modification d\'un rendez-vous'}
                            />
                        </Li>
                    </ul>
                </Col>
            </Row>
        </form>
    </Card>
}