import React, {FC, FormEvent, useState} from "react";
import {Dialog, Input, Row, SpanBody2} from "@linkeo.com/ui-lib-react";
import {FormattedMessage, useIntl} from "react-intl";
import {ConfirmDialogFooter} from "../../commons/components/confirm-dialog-footer";

interface GiftcardCashModalProps {
    onClose: () => void;
    openCashModal: boolean;
    code: string;
    price: number;
    currency: string;
    service: string;
}

export const GiftcardCashModal: FC<GiftcardCashModalProps> = ({
                                                                  onClose,
                                                                  openCashModal,
                                                                  code,
                                                                  price,
                                                                  currency,
                                                                  service
                                                              }) => {
    const intl = useIntl();
    const [inputValue, setInputValue] = useState<string>('')
    const formId = 'giftcardSendingForm';

    const _onSubmit = (e: FormEvent) => {
        e.preventDefault();
        e.stopPropagation();
        console.log(inputValue)
    }

    return <Dialog onClose={onClose} show={openCashModal} title={intl.formatMessage({
        id: 'GiftcardListPageCashButtonTitle',
        defaultMessage: 'Encaisser le bon cadeau'
    })} size={'sm'} footer={<ConfirmDialogFooter onClose={onClose} formId={formId}/>}>
        <Row style={{textAlign: 'center', marginBottom: '38px'}}>
            <SpanBody2>
                <FormattedMessage
                    id={'GiftcardCashModalSubtitle'}
                    defaultMessage={'Vous êtes sur le point de valider l\'utilisation du bon cadeau n°{code} d\'une valeur de {price} pour la prestation: {service}.'}
                    values={{
                        code,
                        price: intl.formatNumber(price, {style: "currency", currency}),
                        service
                    }}
                />
            </SpanBody2>
        </Row>
        <form onSubmit={_onSubmit} id={formId}>
            <Input required
                   value={inputValue}
                   onChange={setInputValue}
                   label={intl.formatMessage({
                       id: 'GiftcardCashModalInputLabel',
                       defaultMessage: 'Nom du bénéficiaire du bon cadeau'
                   })}
                   caption={intl.formatMessage({
                       id: 'GiftcardCashModalInputCaption',
                       defaultMessage: 'Renseignez ici le nom de la personne qui utilise le bon cadeau.'
                   })}
            />
        </form>
    </Dialog>
}