import {FC} from "react";
import {Flex, SpanBody2, SpanH1} from "@linkeo.com/ui-lib-react";
import {FormattedMessage} from "react-intl";

export const Error404: FC = () => {

    return <Flex direction={'column'}
              justifyContent={'center'}
              alignItems={'center'}
              style={{height: '100%', textAlign: 'center', padding: '100px 0', rowGap: 20}}>
            <SpanH1>404</SpanH1>
            <SpanBody2>
                <FormattedMessage
                    id={'404Subtitle'}
                    defaultMessage={'La page que vous recherchez{br}semble introuvable'}
                    values={{br: <br/>}}/>
            </SpanBody2>
        </Flex>
}