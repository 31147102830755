import {Option, OptionPayload} from "./options-types";
import {Locale} from "../providers/intl-provider";
import axios, {AxiosRequestHeaders} from "axios";
import {ResultPagination} from "../commons/common-types";

export class OptionsApi {
    public constructor(private apiBaseUri: string) {
    }

    async getOptionsList(codeBouton: string, token: string, limit: number, offset: number, type?: string, serviceId?: string, signal?: AbortSignal): Promise<ResultPagination<Option>> {
        const headers: AxiosRequestHeaders = {Authorization: `Bearer ${token}`}
        const response = await axios.get(`${this.apiBaseUri}/${codeBouton}/options?limit=${limit}&offset=${offset}${type ? `&type=${type}` : ''}${serviceId ? `&serviceId=${serviceId}` : ''}`, {headers, signal})
        return response.data
    }

    async createOption(codeBouton: string, body: OptionPayload, token: string, locale: Locale): Promise<Option> {
        const headers: AxiosRequestHeaders = {Authorization: `Bearer ${token}`, 'Accept-Language': locale || 'fr-FR'};
        const response = await axios.post(`${this.apiBaseUri}/${codeBouton}/options`, body, {headers})
        return response.data
    }

    async deleteOption(codeBouton: string, optionId: string, token: string): Promise<Option> {
        const headers: AxiosRequestHeaders = {Authorization: `Bearer ${token}`}
        const response = await axios.delete(`${this.apiBaseUri}/${codeBouton}/options/${optionId}`, {headers})
        return response.data
    }

    async getOption(codeBouton: string, token: string, optionId: string): Promise<Option> {
        const headers : AxiosRequestHeaders = {Authorization: `Bearer ${token}`}
        const response = await axios.get(`${this.apiBaseUri}/${codeBouton}/options/${optionId}`, {headers})
        return response.data
    }

    async updateOption(codeBouton: string, token: string, optionId: string, option: OptionPayload): Promise<Option> {
        const headers: AxiosRequestHeaders = {Authorization: `Bearer ${token}`}
        const response = await axios.put(`${this.apiBaseUri}/${codeBouton}/options/${optionId}`, option, {headers})
        return response.data
    }
}