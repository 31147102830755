import React, {FC, useState} from "react";
import {Card, ThemeType} from "@linkeo.com/ui-lib-react";
import {useIntl} from "react-intl";
import {useTheme} from "styled-components";
import {used} from "../giftcards-types";
import {GiftcardValidityModal} from "./giftcard-validity-modal";
import {GiftcardInfoCard} from "./giftcard-info-card";
import {GiftcardSubInfoCard} from "./giftcard-sub-info-card";
import {GiftcardSuccessModal} from "./giftcard-success-modal";
import {GiftcardCashModal} from "./giftcard-cash-modal";

interface GiftcardCardProps {
    code: string;
    giftedBy: string;
    status: string;
    expirationDate: string;
    email: string;
    phone: string;
    purchaseDate: string;
    service: string;
    price: number;
    currency: string;
    giftedTo: string;
    utilization?: string
}

export const GiftcardCard: FC<GiftcardCardProps> = ({
                                                        code,
                                                        giftedBy,
                                                        status,
                                                        expirationDate,
                                                        email,
                                                        phone,
                                                        purchaseDate,
                                                        service,
                                                        price,
                                                        giftedTo,
                                                        currency,
                                                        utilization
                                                    }) => {
    const theme = useTheme() as ThemeType;
    const intl = useIntl();
    const [show, setShow] = useState<boolean>(false);
    const [openActivateGiftcard, setOpenActivateGiftcard] = useState<boolean>(false);
    const [openEmailModal, setOpenEmailModal] = useState<boolean>(false)
    const [openCashModal, setOpenCashModal] = useState<boolean>(false)

    return <Card style={{marginBottom: '16px', padding: 0}}>
        <GiftcardInfoCard
            code={code}
            giftedBy={giftedBy}
            color={status === used ? theme.colors.primary :
                new Date(expirationDate) < new Date() ? theme.colors.grey70 : theme.colors.success}
            icon={status === used ? 'cross-circle' :
                new Date(expirationDate) < new Date() ? "pending-circle" : "check-circle"}
            statusText={status === used ? intl.formatMessage({
                id: 'GiftcardStatusUsed',
                defaultMessage: 'Bon cadeau déjà utilisé'
            }) : new Date(expirationDate) < new Date()
                ? intl.formatMessage({
                    id: 'GiftcardStatusExpired',
                    defaultMessage: 'Bon cadeau expiré'
                }) : intl.formatMessage({
                    id: 'GiftcardStatusOK',
                    defaultMessage: 'Bon cadeau valable'
                })}
            dateSubtitle={status === used || new Date(expirationDate) < new Date()
                ? intl.formatMessage({
                    id: 'GiftcardsExpirationDate',
                    defaultMessage: 'le {date}'
                }, {date: intl.formatDate(expirationDate, {dateStyle: 'short'})})
                : intl.formatMessage({
                    id: 'GiftcardsValidityDate',
                    defaultMessage: 'jusqu\'au {date}'
                }, {date: intl.formatDate(expirationDate, {dateStyle: 'short'})})}
            onIconClick={() => setShow(!show)}
            showIcon={show ? 'up' : 'down'}
        />
        <GiftcardSubInfoCard
            onActivateGiftcardClick={() => setOpenActivateGiftcard(true)}
            onPrintClick={() => {
            }}
            onEmailClick={() => setOpenEmailModal(true)}
            onCashClick={() => setOpenCashModal(true)}
            giftedBy={giftedBy}
            price={price}
            expirationDate={expirationDate}
            service={service}
            currency={currency}
            utilization={utilization}
            status={status}
            giftedTo={giftedTo}
            purchaseDate={purchaseDate}
            phone={phone}
            email={email}
            show={show}
        />
        <GiftcardValidityModal
            onClose={() => setOpenActivateGiftcard(false)}
            openActivateGiftcard={openActivateGiftcard}
            currency={currency}
            service={service}
            code={code}
            price={price}
            expirationDate={expirationDate}
        />
        <GiftcardSuccessModal
            onClose={() => setOpenEmailModal(false)}
            openModal={openEmailModal}
            title={intl.formatMessage({id: 'GiftcardEmailModalTitle', defaultMessage: 'Email envoyé !'})}
            text={intl.formatMessage({
                id: 'GiftcardEmailModalSubtitle',
                defaultMessage: 'Le bon cadeau n°{code} d\'une valeur de {price} pour la prestation: {service}{br} a été envoyé par email à {br}{email}. ' +
                    '{br}Il expirera le {date}.'
            }, {
                code,
                price: intl.formatNumber(price, {style: "currency", currency}),
                service,
                email,
                date: intl.formatDate(expirationDate, {dateStyle: 'short'}),
                br: <br />
            })}
        />
        <GiftcardCashModal
            onClose={() => setOpenCashModal(false)}
            openCashModal={openCashModal}
            price={price}
            code={code}
            service={service}
            currency={currency}
        />
    </Card>
}