export const hoursList = Array.from({length: 24}, (_, i) => i.toString().padStart(2, '0') + 'h');
export const minutesList = Array.from({length: 2}, (_, i) => (i * 30).toString().padStart(2, '0'));

export const getPreviousDate = (date?: string, interval?: number): Date => {
    const yesterday = date ? new Date(date) : new Date();
    yesterday.setDate(yesterday.getDate() - (interval ? interval : 1))
    return yesterday
}

export const getNextDate = (date?: string, interval?: number): Date => {
    const next = date ? new Date(date) : new Date();
    next.setDate(next.getDate() + (interval ? interval : 1))
    return next
}

export const getLocaleMonthsNames = (locale?: string): string[] => {
    return Array.from({length: 12}, (_, i) => {
        const date = new Date();
        date.setMonth(i);
        return date.toLocaleDateString(locale, {month: 'long'})
    })
}

export const getLocaleDaysNames = (locale?: string): string[] => {
    return Array.from({length: 7}, (_, i) => {
        const date = new Date();
        const offset = (i + 1) > 6 ? 0 : (i + 1) < 0 ? 6 : (i + 1)
        date.setDate(date.getDate() - date.getDay() + offset);
        return date.toLocaleDateString(locale, {weekday: 'long'})
    })
}

export const getISOWeek = (date: Date): number => {
    let d = new Date(date.valueOf());
    const day = (d.getDay() + 6) % 7;
    d.setDate(d.getDate() - day + 3);
    const firstThursday = d.valueOf();
    d.setMonth(0, 1);
    if (d.getDay() !== 4) {
        d.setMonth(0, 1 + ((4 - d.getDay()) + 7) % 7)
    }
    return 1 + Math.ceil((firstThursday - d.valueOf()) / 604800000)
}

export const getDayArray = (interval: number, locale?: string, fromHour?: number, fromMin?: number): string[] => {
    const date = new Date(1970, 0, 1);
    if (fromHour || fromMin) {
        date.setTime(date.getTime() + ((fromHour||0) * 3600 * 1000) + ((fromMin||0) * 60 * 1000))
    }
    const array = [];
    while (date.getDate() === 1) {
        array.push(date.toLocaleTimeString(locale));
        date.setMinutes(date.getMinutes() + interval)
    }
    return array
}

export const setHoursDate = (date: string, hours: number, min?: number, sec?: number, ms?: number): string => {
    const formatedDate = new Date(date);
    formatedDate.setHours(hours, min ? min : 0 , sec ? sec : 0, ms ? ms : 0);
    return formatedDate.toString()
}

export const isSameWeek = (checkDate: string, currentDate: string) : boolean => {
    const date = new Date(currentDate);
    date.setHours(0,0,0,0)
    const endOfWeek = new Date(date);
    endOfWeek.setDate(endOfWeek.getDate() + 6)
    endOfWeek.setHours(0,0,0,0);
    const dateToCheck = new Date(checkDate);
    return dateToCheck >= date && dateToCheck <= endOfWeek
}

export const getDuration = (startDate: string, endDate: string): number => {
    const date1 = new Date(startDate);
    const date2 = new Date(endDate);
    const diff = date2.getTime() - date1.getTime();
    return Math.round(diff / 60000)
}

export const getLocalDuration = (min: number): string => {
    const hours = Math.floor(min / 60);
    const minutes = min % 60;
    return `${hours > 0 ? hours+'h' : ''}${minutes > 0 ? minutes+'min' : ''}`
}