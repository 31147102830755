import React, {FC, useState} from "react";
import {Col, InOut, Input, Row, SquareButton, ThemeType} from "@linkeo.com/ui-lib-react";
import {useIntl} from "react-intl";
import {useTheme} from "styled-components";

interface ChoicesInputsProps {
    label: string;
    price?: number;
    removable: boolean;
    onChoiceRemove: () => void;
    onNameChange: (name: string) => void;
    onPriceChange: (price: string) => void;
    freeOption: boolean
}

export const ChoiceInputs: FC<ChoicesInputsProps> = ({
                                                        label,
                                                        price,
                                                        removable,
                                                        onChoiceRemove,
                                                        onPriceChange,
                                                        onNameChange,
                                                        freeOption
                                                    }) => {
    const intl = useIntl();
    const theme = useTheme() as ThemeType;
    const [show, setShow] = useState<boolean>(true)

    const onButtonClick = () => {
        if (!removable) {
            return
        }
        setShow(false);
        onChoiceRemove()
    }

    return <InOut show={show} startTriggering>
        <Row alignItems={['flex-end']} justifyContent={['flex-start']}>
            <Col columns={[freeOption ? 11 : 5.5]}>
                <Input
                    label={intl.formatMessage({
                        id: 'OptionFormSeveralOptionsLabelName',
                        defaultMessage: 'Nom du choix'
                    })}
                    required
                    placeholder={label}
                    value={label}
                    onChange={(name) => onNameChange(name)}
                />
            </Col>

            {!freeOption && <Col columns={[4, 4, 5.5]}>
                <Input
                    label={intl.formatMessage({id: 'OptionFormSeveralOptionsLabelPrice', defaultMessage: 'Tarif'})}
                    required
                    value={price ? price.toString() : ''}
                    placeholder={price ? price.toString() : ''}
                    onChange={(price) => onPriceChange(price)}
                />
            </Col>}

            <Col columns={[1]}>
                <SquareButton onClick={onButtonClick} style={{marginBottom: '15px'}} icon={'delete'}
                              fontColor={theme.colors.grey60}/>
            </Col>
        </Row>
    </InOut>
}