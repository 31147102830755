import {FC} from "react";
import {Dialog, Row, SpanSubtitle2} from "@linkeo.com/ui-lib-react";
import {FormattedMessage, useIntl} from "react-intl";
import {DeleteDialogFooter} from "../../../commons/components/delete-dialog-footer";

interface ParamsDiscountDeleteModalProps {
    show: boolean;
    onClose: () => void;
    onDeleteClick: () => void;
}

export const ParamsDiscountDeleteModal: FC<ParamsDiscountDeleteModalProps> = ({show, onClose, onDeleteClick}) => {
    const intl = useIntl();

    return <Dialog onClose={onClose}
                   show={show}
                   title={intl.formatMessage({
                       id: 'ParamsDiscountDeleteModalTitle',
                       defaultMessage: 'Supprimer la promo'
                   })}
                   size={'md'}
                   footer={<DeleteDialogFooter onCancelClick={onClose}
                                               onConfirmDeleteClick={onDeleteClick}/>}>
        <Row justifyContent={'center'}>
            <SpanSubtitle2>
                <FormattedMessage
                    id={'ParamsDiscountDeleteModalContent'}
                    defaultMessage={'Êtes-vous sûr de vouloir supprimer cette promo ?'}
                />
            </SpanSubtitle2>
        </Row>
    </Dialog>
}