import {FC} from "react";
import {BaseInputNumber} from "@linkeo.com/ui-lib-react";

interface RadioInputProps {
    inputValue: number | null;
    onInputChange: (value: number | null) => void;
    minValue: number;
    postText: string;
    step?: number;
    preText?: string
    maxValue?: number;
    disabled?: boolean;
}

export const RadioInput: FC<RadioInputProps> = ({
                                                    inputValue,
                                                    onInputChange,
                                                    minValue,
                                                    postText,
                                                    step,
                                                    preText,
                                                    maxValue,
                                                    disabled
                                                }) => {

    return <>
        {preText && preText}
        <BaseInputNumber min={minValue} max={maxValue}
                         disabled={disabled}
                         step={step}
                         value={inputValue}
                         style={{width: '60px', margin: '0 5px'}}
                         onChange={onInputChange}/>
        {postText}
    </>
}