import React, {FC, useState} from "react";
import {CardLoading} from "./card-loading";
import {EmptyListText} from "./empty-list-text";
import {FormattedMessage, useIntl} from "react-intl";
import {
    InfiniteScroll,
    SpanBody2,
    Dialog
} from "@linkeo.com/ui-lib-react";
import {DialogFooter} from "../../commons/components/dialog-footer";
import {CustomerBookingCard} from "./customer-booking-card";
import {statusPending, statusValid} from "../customers-types";

interface CustomerBookingListProps {
    currency: string;
    loading: boolean;
    isLoading: boolean;
    lock: boolean;
    onAppointmentsLoading: (offset: number) => void;
    appointmentsLength: number
}

export const CustomerBookingList: FC<CustomerBookingListProps> = ({
                                                                      loading,
                                                                      currency,
                                                                      onAppointmentsLoading,
                                                                      isLoading,
                                                                      lock,
                                                                      appointmentsLength
                                                                  }) => {
    const intl = useIntl();
    const [openModal, setOpenModal] = useState<boolean>(false)

    return loading ? <CardLoading/>
        : appointmentsLength > 0 ?
            <>
                <InfiniteScroll
                    isLoading={isLoading}
                    lock={lock}
                    moreResultLabel={intl.formatMessage({
                        id: 'DefaultMoreResultLabel',
                        defaultMessage: 'Charger plus'
                    })}
                    onNext={() => onAppointmentsLoading(appointmentsLength)}>
                    <CustomerBookingCard index={0} title={'Coupe coiffage'} currency={currency} price={32}
                                         date={new Date().toString()}
                                         onCancelAppointmentClick={() => setOpenModal(true)} status={statusPending}/>
                    <CustomerBookingCard index={1} title={'Cours d\'espagnol'} currency={currency} price={40}
                                         date={new Date().toString()}
                                         onCancelAppointmentClick={() => setOpenModal(true)} status={statusValid}/>
                </InfiniteScroll>
                <Dialog onClose={() => setOpenModal(false)}
                        show={openModal}
                        title={intl.formatMessage({
                            id: 'CancelBookingModalTitle',
                            defaultMessage: 'Annuler la réservation'
                        })} size={'sm'}
                        footer={<DialogFooter onCancelClick={() => setOpenModal(false)} onApproveClick={() => {
                        }}/>}>
                    <SpanBody2 style={{textAlign: 'center'}}>
                        <FormattedMessage id={'CancelBookingModalContent'}
                                          defaultMessage={'Êtes vous sûr de vouloir annuler cette réservation ? {br}Votre client sera prévenu par Email.'}
                                          values={{br: <br/>}}/>
                    </SpanBody2>
                </Dialog>
            </>
            : <EmptyListText text={intl.formatMessage({
                id: 'CustomersAppointmentsBookingListEmpty',
                defaultMessage: 'Il n\'y a pas de réservations à venir pour ce client.'
            })}/>
}