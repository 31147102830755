import {FC} from "react";
import {ServicesStore} from "../../services/services-store";
import {Route, Routes} from "react-router";
import {routeParamsServiceCreate, routeParamsServiceEdit, routeParamsServices} from "../../routes";
import {ServicesListPage} from "./services-list-page";
import {ServicesEditPage} from "./services-edit-page";
import {useAuthStore} from "../../providers/sso/auth-provider";
import {useConfigurationStore} from "../../params/configuration-store";

const getLocalPath = (fullPath: string):string => {
    const re = new RegExp(`^${routeParamsServices}`);
    return fullPath.replace(re, '');
}

export const ServicesIndexPage: FC = () => {
    const {token, codeBouton} = useAuthStore();
    const {config: {appointment: {paymentActive}}} = useConfigurationStore();

    return <ServicesStore codeBouton={codeBouton} token={token} paymentActive={paymentActive}>
        <Routes>
            <Route path={getLocalPath(routeParamsServices)} element={<ServicesListPage />} />
            <Route path={getLocalPath(routeParamsServiceCreate)} element={<ServicesEditPage codeBouton={codeBouton} token={token}/>}/>
            <Route path={getLocalPath(routeParamsServiceEdit)} element={<ServicesEditPage codeBouton={codeBouton} token={token}/>}/>
        </Routes>
    </ServicesStore>
}