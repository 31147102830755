import {Dispatch, FC, SetStateAction, useRef, useState} from "react";
import {BaseButton, Calendar, Dropdown, SpanSubtitle1, Svg, ThemeType} from "@linkeo.com/ui-lib-react";
import {FormattedDate, FormattedMessage, useIntl} from "react-intl";
import {getISOWeek} from "../../utils/date";
import styled, {useTheme} from "styled-components";

const DatePicker = styled(Svg)`
    &:hover {
        fill: ${({theme}) => theme.colors.grey20};
        cursor: pointer;
    }
`

interface NavCalendarSelectProps {
    date: string;
    setDate: Dispatch<SetStateAction<string>>;
    small: boolean;
    showWeek: boolean;
}

export const NavCalendarSelect: FC<NavCalendarSelectProps> = ({date, setDate, small, showWeek}) => {
    const theme = useTheme() as ThemeType;
    const intl = useIntl();
    const [openCalendar, setOpenCalendar] = useState<boolean>(false);
    const refDatePicker = useRef<HTMLDivElement>(null);
    const endOfWeekDay = new Date(date);
    endOfWeekDay.setDate(endOfWeekDay.getDate() + 6);

    return <div ref={refDatePicker} style={{
        display: 'flex',
        alignItems: 'center',
        borderRight: `1px solid ${theme.colors.grey80}`,
        margin: '-14px 0',
        padding: small ? '14px 20px 14px 0' : '14px 20px'
    }}>
        {!small && <><SpanSubtitle1 style={{textTransform: 'capitalize', fontWeight: 700}}>
            {showWeek
                ? `${intl.formatDate(date, {day: '2-digit'})} - ${intl.formatDate(endOfWeekDay, {day: '2-digit', month: 'short', year: 'numeric'})}`
                : <FormattedDate value={date} dateStyle={'full'}/>}
        </SpanSubtitle1>
            <SpanSubtitle1 style={{fontWeight: 700, color: theme.colors.grey40, margin: '0 20px 0 10px'}}>
                <FormattedMessage id={'AgendasPageWeekNumber'} defaultMessage={'(Semaine {number})'}
                                  values={{number: getISOWeek(new Date(date))}}/>
            </SpanSubtitle1></>
        }
        <BaseButton onClick={() => setOpenCalendar(true)}>
            <DatePicker icon={'calendar'} fill={theme.colors.grey60} width={24} height={24}
                        style={{marginBottom: '4px'}}/>
        </BaseButton>
        <Dropdown
            show={openCalendar}
            onClose={() => setOpenCalendar(false)}
            wrapperRef={refDatePicker}>
            <div>
                <BaseButton style={{position: 'absolute', right: 0, zIndex: 1}}
                            onClick={() => setOpenCalendar(false)}>
                    <Svg icon={'cross'} height={28} width={28}/>
                </BaseButton>
                <Calendar onChange={(val) => {
                    setDate(val.toString())
                    setOpenCalendar(false)
                }}/>
            </div>
        </Dropdown>
    </div>
}