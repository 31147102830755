import {Dispatch, FC, SetStateAction} from "react";
import { Col, Input, Row, Textarea } from "@linkeo.com/ui-lib-react";
import { useIntl } from "react-intl";
import {AgendaFormProps} from "./agendas-edit-form";

interface AgendasInfoFormProps {
    form: AgendaFormProps;
    setForm: Dispatch<SetStateAction<AgendaFormProps>>
}

export const AgendasInfoForm: FC<AgendasInfoFormProps> = ({ form, setForm }) => {
    const intl = useIntl();

    return <div style={{margin: '28px 0'}}>
        <Row>
            <Col columns={[12, 12, 6]}>
                <Input type={"text"} required
                    placeholder={intl.formatMessage({ id: 'requiredNamePlaceholder', defaultMessage: 'Nom *' })}
                    value={form.name}
                    onChange={(name) => setForm({ ...form, name })} />
                <Input type={'text'}
                    placeholder={intl.formatMessage({ id: 'notifNamePlaceholder', defaultMessage: 'Nom à utiliser dans les mails de notifications' })}
                    value={form.notifName}
                    onChange={(notifName) => setForm({ ...form, notifName })} />
                <Input type={'email'} required
                 placeholder={intl.formatMessage({id: 'requiredEmailPlaceholder', defaultMessage: 'Email associé *'})}
                 value={form.email}
                 onChange={(email) => setForm({...form, email})}
                />
                <Input type={'tel'} required
                placeholder={intl.formatMessage({id: 'requiredPhonePlaceholder', defaultMessage: 'Téléphone *'})}
                value={form.tel}
                onChange={(tel) => setForm({...form, tel})}
                />
            </Col>
            <Col columns={[12, 12, 6]}>
                <Textarea placeholder={intl.formatMessage({id: 'descriptionPlaceholder', defaultMessage: 'Description'})}
                 value={form.description}
                 style={{minHeight: '110px'}}
                 onChange={(description) => setForm({...form, description})}
                />
            </Col>
        </Row>
    </div>
}