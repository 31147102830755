import {FC, useState} from "react";
import {ParamsCard} from "../params-card";
import {useIntl} from "react-intl";
import {Switch, Textarea, ThemeType} from "@linkeo.com/ui-lib-react";
import {useTheme} from "styled-components";

interface ParamsBookingMessageFormProps {

}

export const ParamsBookingMessageForm: FC<ParamsBookingMessageFormProps> = () => {
    const intl = useIntl();
    const theme = useTheme() as ThemeType;
    const [message, setMessage] = useState<string>('');
    const [isMandatory, setIsMandatory] = useState<boolean>(false);

    return <ParamsCard inCard={true}
                       title={intl.formatMessage({
                           id: 'ParamsBookingMessageTitle',
                           defaultMessage: 'Messages à vos clients'
                       })}
                       subtitle={intl.formatMessage({
                           id: 'ParamsBookingMessageSubTitle',
                           defaultMessage: 'Demandez des informations spécifiques à vos clients. Ce message sera affiché avant la validation d\'une réservation.'
                       })}>
        <Textarea value={message} onChange={(v) => setMessage(v)} />
        <Switch
            value={isMandatory}
            small
            onIcon={'check'}
            offIcon={'cross'}
            color={theme.colors.grey40}
            onChange={setIsMandatory}
            onLabel={intl.formatMessage({
                id: 'ParamsBookingMessageMandatorySwitchLabel',
                defaultMessage: 'Informations obligatoire'
            })}
        />
    </ParamsCard>
}