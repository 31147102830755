import {FC, ReactNode} from "react";
import {Row, SpanCaption1} from "@linkeo.com/ui-lib-react";
import {ParamsRadioItem} from "../params-radio-item";

interface ParamsBookingInputGroupProps {
    label?: string;
    caption: string;
    children: ReactNode;
    type: string;
}

export const ParamsBookingInputGroup: FC<ParamsBookingInputGroupProps> = ({label, caption, children, type}) => {
    return <ParamsRadioItem
        inCard={true}
        label={label}>
        <Row style={{marginLeft: type === 'radio' ? '18px' : '38px'}}>
            {children}
        </Row>
        <SpanCaption1>
            {caption}
        </SpanCaption1>
    </ParamsRadioItem>
}