import React, {FC, useState} from "react";
import {
    BaseButton,
    Button,
    Card,
    Col,
    Row, SpanBody2, SpanCaption1,
    SpanLink,
    SpanSubtitle1,
    SpanSubtitle2,
    Svg,
    ThemeType
} from "@linkeo.com/ui-lib-react";
import {useTheme} from "styled-components";
import {FormattedDate, FormattedMessage, FormattedTime} from "react-intl";
import {RefuseEventDialog} from "./refuse-event-dialog";

interface PendingEventCardProps {
    service: string;
    duration: number;
    date: string;
    firstName: string;
    lastName: string;
    subscribed: boolean;
    spot: number;
    options?: string[];
}

export const PendingEventCard: FC<PendingEventCardProps> = ({
                                                                service,
                                                                date,
                                                                spot,
                                                                duration,
                                                                lastName,
                                                                firstName,
                                                                subscribed,
                                                                options
                                                            }) => {
    const theme = useTheme() as ThemeType;
    const [showOptions, setShowOptions] = useState<boolean>(false);
    const [confirmed, setConfirmed] = useState<boolean | undefined>();
    const [showRefuseModal, setShowRefuseModal] = useState<boolean>(false)

    return <Card style={{margin: '0 0 28px'}}>
        <Row alignItems={'flex-start'} justifyContent={'space-between'} style={{rowGap: '10px'}}>
            <Col columns={[12, 12, 5.75, 5.75]} style={{marginTop: '18px'}}>
                <div style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
                    <Svg icon={'clock'} fill={theme.colors.grey60} width={20} height={20}/>
                    <SpanSubtitle2 style={{margin: '0 10px', fontWeight: 600}}>{service}</SpanSubtitle2>
                    <SpanSubtitle2
                        style={{
                            padding: '0 10px',
                            borderLeft: `1px solid ${theme.colors.grey80}`,
                            fontSize: '14px'
                        }}>
                        <FormattedTime value={date} hour={'numeric'} minute={'numeric'}/> - <FormattedTime
                        value={new Date(new Date(date).getTime() + duration * 60000)} hour={'numeric'}
                        minute={'numeric'}/>
                    </SpanSubtitle2>
                    <SpanSubtitle2 style={{
                        padding: '0 10px',
                        borderLeft: `1px solid ${theme.colors.grey80}`,
                        fontSize: '14px',
                        textTransform: 'capitalize'
                    }}>
                        <FormattedDate value={date} day={'numeric'} month={'short'} year={'numeric'}/>
                    </SpanSubtitle2>
                </div>
            </Col>
            <Col columns={[12, 12, 2.75, 2.75]}>
                <div style={{display: 'flex', flexWrap: 'wrap'}}>
                    <Svg icon={spot > 1 ? 'group' : 'person'} style={{marginTop: '18px'}} fill={theme.colors.grey60}
                         width={20} height={20}/>
                    <div style={{display: 'flex', flexDirection: 'column', margin: '0 10px'}}>
                        {subscribed && <SpanSubtitle1 style={{color: theme.colors.primary, fontWeight: 600}}>
                            <FormattedMessage id={'Subscribed'} defaultMessage={'Abonné'}/>
                        </SpanSubtitle1>}
                        <SpanSubtitle1 style={{color: theme.colors.grey40, fontWeight: 600}}>
                            {firstName} {lastName}
                        </SpanSubtitle1>
                        <SpanSubtitle1 style={{color: theme.colors.grey40, fontWeight: 600}}>
                            <FormattedMessage id={'EventsPagePendingCardSpotsLabel'}
                                              defaultMessage={'{number} personne(s)'} values={{number: spot}}/>
                        </SpanSubtitle1>
                        {options && options.length > 0 && <>
                            <><BaseButton style={{display: "flex", alignItems: "center"}}
                                          onClick={() => setShowOptions(!showOptions)}>
                                <Svg icon={showOptions ? "down" : "right"} fill={theme.colors.primary} width={15}
                                     height={15}/>
                                <SpanLink style={{color: theme.colors.primary, fontWeight: 600, fontSize: '14px'}}>
                                    <FormattedMessage id={'EventsPagePendingCardsOptionsListDropDownLabel'}
                                                      defaultMessage={'Voir les options'}/>
                                </SpanLink>
                            </BaseButton>
                                {showOptions && <ul style={{all: 'inherit'}}>
                                    {options.map((option, index) => <li key={index} style={{all: 'inherit', margin: 0}}>
                                        <SpanCaption1>{option}</SpanCaption1></li>)}
                                </ul>}
                            </>
                        </>}
                    </div>
                </div>
            </Col>
            <Col columns={[12, 12, 3.5, 3.5]} style={{textAlign: 'center', marginTop: '8px'}}>
                {confirmed !== undefined
                    ? <div style={{display: 'flex', alignItems: 'center'}}>
                        <Svg icon={confirmed ? 'check-circle' : 'cross-circle'}
                             fill={confirmed ? theme.colors.primary : theme.colors.danger}
                             width={32} height={32}/>
                        <SpanBody2
                            style={{color: confirmed ? theme.colors.primary : theme.colors.danger, marginLeft: '8px'}}>
                            {confirmed
                                ? <FormattedMessage id={'EventsPageConfirmedEventText'}
                                                    defaultMessage={'Demande acceptée'}/>
                                : <FormattedMessage id={'EventsPageRefusedEventText'}
                                                    defaultMessage={'Demande refusée'}/>}
                        </SpanBody2>
                    </div>
                    : <div style={{display: 'flex', justifyContent: 'space-evenly', flexWrap: 'wrap', gap: '5px'}}>
                        <Button colorType={'primary'} onClick={() => setConfirmed(true)}>
                            <FormattedMessage id={'ApproveButton'} defaultMessage={'Accepter'}/>
                        </Button>
                        <Button colorType={'secondary'} fontColor={theme.colors.light}
                                onClick={() => setShowRefuseModal(true)}>
                            <FormattedMessage id={'cancelButton'} defaultMessage={'Refuser'}/>
                        </Button>
                    </div>}
            </Col>
        </Row>
        <RefuseEventDialog onCloseModal={() => setShowRefuseModal(false)}
                           onConfirm={() => {
                               setConfirmed(false)
                               setShowRefuseModal(false)
                           }}
                           showRefuseModal={showRefuseModal}/>
    </Card>
}