import React, {FC} from "react";
import {Card, Col, Row, SpanSubtitle2, Switch, ThemeType} from "@linkeo.com/ui-lib-react";
import {useTheme} from "styled-components";
import {FormattedMessage, useIntl} from "react-intl";
import {Link} from "react-router-dom";
import {routeOptions, routeParamsServices} from "../../../routes";
import {DownPaymentRadioGroup} from "../../../commons/components/down-payment-form";
import { ROOT_PATH } from "../../../config/config";

interface ParamsActivatePaymentFormProps {
    active: boolean;
    onActiveChange: (v: boolean) => void;
    paymentPercent: number;
    onPaymentPercentChange: (v: number) => void;
}

export const ParamsActivatePaymentForm: FC<ParamsActivatePaymentFormProps> = ({
                                                                                  active,
                                                                                  onActiveChange,
                                                                                  onPaymentPercentChange,
                                                                                  paymentPercent
                                                                              }) => {
    const theme = useTheme() as ThemeType;
    const intl = useIntl();

    const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        const basePath = ROOT_PATH.length > 1 ? ROOT_PATH : '';
        window.location.pathname = basePath + routeParamsServices;
    }

    return <Card style={{marginTop: '28px'}}>
        <form>
            <Row alignItems={'center'} justifyContent={'flex-start'}>
                <Col columns={[12, 12, 5]}>
                    <Switch
                        value={active}
                        onIcon={'check'}
                        offIcon={'cross'}
                        color={theme.colors.grey40}
                        onChange={onActiveChange}
                        onLabel={intl.formatMessage({
                            id: 'ParamsActivatePaymentFormSwitchLabel',
                            defaultMessage: 'Activer le paiement à la réservation'
                        })}
                    />
                </Col>
                <Col columns={[12, 12, 7]}>
                    <SpanSubtitle2>
                        <FormattedMessage
                            id={'ParamsActivatePaymentSwitchCaption'}
                            defaultMessage={'Vos clients devront payer leur prestation dès leur réservation pour valider leur demande. {br}Les demandes seront automatiquement validées.'}
                            values={{br: <br/>}}
                        />
                    </SpanSubtitle2>
                </Col>
            </Row>
            <Row style={{padding: '24px 0'}}>
                <Col columns={[12, 12, 6]}>
                    <SpanSubtitle2>
                        <FormattedMessage
                            id={'ParamsPaymentDownPaymentCation2'}
                            defaultMessage={'Veuillez saisir le pourcentage d’acompte par défaut que vous voulez appliquer à toutes vos prestations/options.'}
                        />
                    </SpanSubtitle2>
                </Col>
                <Col columns={[12, 12, 6]}>
                    <SpanSubtitle2>
                        <FormattedMessage
                            id={'ParamsPaymentDownPaymentCation1'}
                            defaultMessage={'Pour personnaliser le pourcentage de l\'acompte par prestation/option, allez dans {link1} ou dans {link2}'}
                            values={{
                                link1: <Link to={routeParamsServices} onClick={handleClick}>
                                    <FormattedMessage id={'navSettingsService'} defaultMessage={'Prestations'}/>
                                </Link>,
                                link2: <Link to={routeOptions}>
                                    <FormattedMessage id={'navSettingsOptions'} defaultMessage={'Options'}/>
                                </Link>
                            }}
                        />
                    </SpanSubtitle2>
                </Col>
            </Row>
            <Row>
                <DownPaymentRadioGroup downPayment={paymentPercent}
                                       initialDownPaymentValue={paymentPercent}
                                       onChange={(v) => onPaymentPercentChange(v ? v : 0)}
                                       includeZero
                                       disabled={!active}/>
            </Row>
        </form>
    </Card>
}