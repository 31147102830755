import {FC} from "react";
import {Col, Row, ThemeType} from "@linkeo.com/ui-lib-react";
import styled, {keyframes, useTheme} from "styled-components";

const Anim = keyframes`
  from{
    background-position-x: 0;
  }
  to{
    background-position-x: 100%;
  }
`;
const Load = styled.div`
  position: relative;
  width: 100%;
  height: 18px;
  &:after{
  background: ${({ theme }) =>
    `linear-gradient(90deg, ${theme.colors.grey95} 30%, ${theme.colors.grey90} 40%, ${theme.colors.grey80} 50%, ${theme.colors.grey90} 60%, ${theme.colors.grey95} 70%) repeat`
};
  background-size: 400%;
  background-position-x: 0;
  background-position-y: center;
  animation: ${Anim} ease-in-out infinite 1500ms reverse;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
    border-radius: 4px;
  }
`;

export const CardLoading: FC = () => {
    const theme = useTheme() as ThemeType;

    return <>
        <Row justifyContent={'space-between'} alignItems={'center'} style={{padding: '30px'}}>
            <Col columns={[12, 12, 6]}>
                <Load />
                <Load style={{margin: '8px 0'}}/>
            </Col>
            <Col columns={[12, 12, 4]}>
                <Load />
            </Col>
        </Row>
        <Row justifyContent={'space-between'} alignItems={'center'} style={{padding: '26px', borderTop: `1px solid ${theme.colors.grey90}`}}>
            <Col columns={[12, 12, 6]}>
                <Load />
                <Load style={{margin: '8px 0'}}/>
            </Col>
            <Col columns={[12, 12, 4]}>
                <Load />
            </Col>
        </Row>
    </>
}