import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';

(window as any).PLNR_React_BO = (element: HTMLElement | string, lang: string | undefined) => {
    let el: HTMLElement | null = element instanceof HTMLElement ? element : document.getElementById(element);
    if (el === null) {
        throw new Error("Invalid element provided for bootstrap, pass an HtmlElement or an id");
    }
    ReactDOM.render(
        <React.StrictMode>
            <App lang={lang}></App>
        </React.StrictMode>,
        el,
    );
    return ()=>ReactDOM.unmountComponentAtNode(el as HTMLElement);
}
