import {FC, useEffect, useState} from "react";
import {Col, Radio, Row, SelectOption, SpanSubtitle1, ThemeType} from "@linkeo.com/ui-lib-react";
import {FormattedMessage, useIntl} from "react-intl";
import {useTheme} from "styled-components";
import {ParamsRadioCard} from "../params-radio-card";
import {ParamsRadioItem} from "../params-radio-item";
import {ParamsRadioSelect} from "../params-radio-select";
import {RadioInput} from "../../../commons/components/radio-input";

interface ParamsBookingDelayFormProps {

}

export const ParamsBookingDelayForm: FC<ParamsBookingDelayFormProps> = () => {
    const intl = useIntl();
    const theme = useTheme() as ThemeType;
    const [getBookingNotification, setGetBookingNotification,] = useState<boolean>(false)
    const [bookingNotificationNumberValue, setBookingNotificationNumberValue] = useState<number>(1)
    const shortDelaySelectOptions = [
        {label: intl.formatMessage({id: 'hours', defaultMessage: 'heure(s)'}), value: 'hours'},
        {label: intl.formatMessage({id: 'days', defaultMessage: 'jour(s)'}), value: 'days'}]
    const longDelaySelectOptions = [
        {label: intl.formatMessage({id: 'hours', defaultMessage: 'heure(s)'}), value: 'hours'},
        {label: intl.formatMessage({id: 'days', defaultMessage: 'jour(s)'}), value: 'days'},
        {label: intl.formatMessage({id: 'months', defaultMessage: 'mois'}), value: 'months'}]
    const [bookingNotificationSelectValue, setBookingNotificationSelectValue] = useState<SelectOption<string>>(shortDelaySelectOptions[0])
    const [delayError, setDelayError] = useState<boolean>(false)
    const [bookingNow, setBookingNow,] = useState<boolean>(true)
    const [clientBookingSelectValue, setClientBookingSelectValue] = useState<SelectOption<string>>(longDelaySelectOptions[0])
    const [clientBookingNumberValue, setClientBookingNumberValue] = useState<number>(1)
    const [isCancellable, setIsCancellable] = useState<boolean>(true)
    const [cancelBookingSelectValue, setCancelBookingSelectValue] = useState<SelectOption<string>>(shortDelaySelectOptions[0])
    const [cancelBookingValue, setCancelBookingValue] = useState<number>(1)
    const [isLimited, setIsLimited] = useState<boolean>(false)
    const [bookingLimitValue, setBookingLimitValue] = useState<number>(1)

    useEffect(() => {
        if (getBookingNotification && !bookingNow && bookingNotificationNumberValue >= clientBookingNumberValue) {
            setDelayError(true)
        } else {
            setDelayError(false)
        }
    }, [bookingNotificationNumberValue, bookingNow, clientBookingNumberValue, getBookingNotification])

    return <form>
        <Row style={{marginTop: '28px'}}>
            <Col columns={[12, 12, 12, 6]}>
                <ParamsRadioCard title={intl.formatMessage({
                    id: 'ParamsBookingDelayRadioTitle',
                    defaultMessage: 'Prise de rendez-vous'
                })}>
                    <ParamsRadioItem label={intl.formatMessage({
                        id: 'ParamsBookingDelayRadioLabelBooking',
                        defaultMessage: 'Pour pouvoir traiter une demande, vous devez être prévenu :'
                    })}>
                        <Radio name={'ParamsBookingDelayRadioBookingOption'}
                               onChange={() => setGetBookingNotification(!getBookingNotification)}
                               value={!getBookingNotification}>
                            <FormattedMessage
                                id={'ParamsBookingDelayRadioBookingOption1'}
                                defaultMessage={'Pas besoin d\'être prévenu à l\'avance'}
                            />
                        </Radio>
                        <Radio name={'ParamsBookingDelayRadioBookingOption'}
                               onChange={() => setGetBookingNotification(!getBookingNotification)}
                               value={getBookingNotification}>
                            <ParamsRadioSelect
                                postText={intl.formatMessage({
                                    id: 'ParamsBookingDelayInAdvanceLabel',
                                    defaultMessage: 'à l\'avance'
                                })}
                                hasError={delayError}
                                selectOptions={shortDelaySelectOptions}
                                selectValue={bookingNotificationSelectValue}
                                onSelect={(v) => setBookingNotificationSelectValue(v)}
                                numberValue={bookingNotificationNumberValue}
                                minValue={1}
                                onNumberChange={(v) => setBookingNotificationNumberValue(v ? v : 1)}
                            />
                        </Radio>
                    </ParamsRadioItem>
                    <ParamsRadioItem label={intl.formatMessage({
                        id: 'ParamsBookingDelayRadioLabelBookingDelay',
                        defaultMessage: 'Un client peut prendre rendez-vous à l\'avance :'
                    })}>
                        <Radio name={'ParamsBookingDelayOption'}
                               onChange={() => setBookingNow(!bookingNow)}
                               value={bookingNow}>
                            <FormattedMessage
                                id={'ParamsBookingDelayRadioLabelDelayOption1'}
                                defaultMessage={'Dès qu\'il le souhaite'}
                            />
                        </Radio>
                        <Radio name={'ParamsBookingDelayOption'}
                               onChange={() => setBookingNow(!bookingNow)}
                               value={!bookingNow}>
                            <ParamsRadioSelect
                                postText={intl.formatMessage({
                                    id: 'ParamsBookingDelayInAdvanceLabel',
                                    defaultMessage: 'à l\'avance'
                                })}
                                hasError={delayError}
                                selectOptions={longDelaySelectOptions}
                                selectValue={clientBookingSelectValue}
                                onSelect={(v) => setClientBookingSelectValue(v)}
                                numberValue={clientBookingNumberValue}
                                minValue={1}
                                onNumberChange={(v) => setClientBookingNumberValue(v ? v : 1)}
                            />
                        </Radio>
                    </ParamsRadioItem>
                    {delayError && <SpanSubtitle1 style={{color: theme.colors.danger}}>
                        <FormattedMessage
                            id={'ParamsBookingDelayErrorMessage'}
                            defaultMessage={'Verifiez que le temps de notification soit inferieur au temps de prise rdv en avance.'}
                        />
                    </SpanSubtitle1>}
                </ParamsRadioCard>
            </Col>
            <Col columns={[12, 12, 12, 6]}>
                <ParamsRadioCard title={intl.formatMessage({
                    id: 'ParamsBookingCancelTitle',
                    defaultMessage: 'Annulation & modification'
                })}>
                    <ParamsRadioItem label={intl.formatMessage({
                        id: 'ParamsBookingCancelRadioLabel',
                        defaultMessage: 'Un client peut annuler ou modifier son rendez-vous :'
                    })}>
                        <Radio name={'ParamsBookingCancelRadioLabelOption'}
                               onChange={() => setIsCancellable(!isCancellable)}
                               value={isCancellable}>
                            <FormattedMessage
                                id={'ParamsBookingCancelRadioLabelOption1'}
                                defaultMessage={'Jusqu\'au dernier moment'}
                            />
                        </Radio>
                        <Radio name={'ParamsBookingCancelRadioLabelOption'}
                               onChange={() => setIsCancellable(!isCancellable)}
                               value={!isCancellable}>
                            <ParamsRadioSelect
                                postText={intl.formatMessage({
                                    id: 'ParamsBookingDelayInAdvanceLabel',
                                    defaultMessage: 'à l\'avance'
                                })}
                                preText={intl.formatMessage({id: 'until', defaultMessage: 'Jusqu\'à'})}
                                hasError={false}
                                selectOptions={shortDelaySelectOptions}
                                selectValue={cancelBookingSelectValue}
                                onSelect={(v) => setCancelBookingSelectValue(v)}
                                numberValue={cancelBookingValue}
                                minValue={1}
                                onNumberChange={(v) => setCancelBookingValue(v ? v : 1)}
                            />
                        </Radio>
                    </ParamsRadioItem>
                </ParamsRadioCard>
            </Col>
        </Row>
        <Row style={{marginTop: '28px'}}>
            <Col columns={[12]}>
                <ParamsRadioCard title={intl.formatMessage({
                    id: 'ParamsBookingNumberLimitedTitle',
                    defaultMessage: 'Limite du nombre de rendez-vous'
                })}>
                    <Row alignItems={'flex-start'} style={{marginTop: '10px'}}>
                        <Col style={{marginTop: '20px'}}>
                            <SpanSubtitle1>
                                <FormattedMessage
                                    id={'ParamsBookingNumberLimitedLabel'}
                                    defaultMessage={'Au maximum, un client peut prendre :'}
                                />
                            </SpanSubtitle1>
                        </Col>
                        <Col>
                            <Radio name={'ParamsBookingNumberLimitedOption'}
                                   onChange={() => setIsLimited(!isLimited)}
                                   style={{marginTop: '15px'}}
                                   value={isLimited}>
                                <RadioInput inputValue={bookingLimitValue}
                                            minValue={1}
                                            onInputChange={(v) => setBookingLimitValue(v ? v : 1)}
                                            postText={intl.formatMessage({
                                                      id: 'ParamsBookingDelayBookingLimitedLabel',
                                                      defaultMessage: 'rendez-vous consécutifs'
                                                  })}
                                />
                            </Radio>
                        </Col>
                        <Col>
                            <Radio name={'ParamsBookingNumberLimitedOption'}
                                   onChange={() => setIsLimited(!isLimited)}
                                   value={!isLimited}>
                                <FormattedMessage
                                    id={'ParamsBookingDelayBookingNotLimitedLabel'}
                                    defaultMessage={'Pas de limite de rendez-vous'}
                                />
                            </Radio>
                        </Col>
                    </Row>
                </ParamsRadioCard>
            </Col>
        </Row>
    </form>
}