import { FC } from "react";
import { Row, SpanSubtitle1 } from "@linkeo.com/ui-lib-react";

export const EmptyListText: FC<{text: string}> = ({text}) => {

    return <Row justifyContent={'center'} style={{padding: '60px 0 30px'}}>
            <SpanSubtitle1>
                {text}
            </SpanSubtitle1>
        </Row>
}