import {FC, useState} from "react";
import {useIntl} from "react-intl";
import {Container} from "@linkeo.com/ui-lib-react";
import {PageWrapper} from "../../commons/components/page-wrapper";
import {ParamsCard} from "../../params/components/params-card";
import {ParamsActivateBookingForm} from "../../params/components/booking/params-activate-booking-form";
import {ParamsBookingButtonForm} from "../../params/components/booking/params-booking-button-form";
import {ParamsInfosForm} from "../../params/components/general/params-infos-form";
import {BackToTop} from "../../commons/components/back-to-top";
import {ParamsCurrencyForm} from "../../params/components/general/params-currency-form";
import {ParamsTimezoneForm} from "../../params/components/general/params-timezone-form";
import {getTimeZoneOffset, timezoneList} from "../../utils/timezone";

interface ParamsPageProps {

}

export const ParamsPage: FC<ParamsPageProps> = () => {
    const intl = useIntl();
    const [loading] = useState<boolean>(false)

    return <PageWrapper isLoading={loading}>
        <Container size={'lg'}>
            <ParamsCard
                title={intl.formatMessage({
                    id: 'ParamsPageActivateBookingTitle',
                    defaultMessage: 'Activation de la prise de rendez-vous'
                })}
                subtitle={intl.formatMessage({
                    id: 'ParamsPageActivateBookingSubTitle',
                    defaultMessage: 'Ici, activez ou désactivez la possibilité pour vos clients de prendre rendez-vous depuis votre site internet.'
                })}>
                <ParamsActivateBookingForm/>
            </ParamsCard>
            <ParamsCard
                title={intl.formatMessage({
                    id: 'ParamsPageBookingButtonOptionTitle',
                    defaultMessage: "Bouton de prise de rendez-vous"
                })}
                subtitle={intl.formatMessage({
                    id: 'ParamsPageBookingButtonOptionSubTitle',
                    defaultMessage: 'Choisissez ici le texte qui sera affiché sur le bouton permettant de réserver une prestation.'
                })}
            >
                <ParamsBookingButtonForm/>
            </ParamsCard>
            <ParamsCard
                title={intl.formatMessage({id: 'ParamsPageTimezoneTitle', defaultMessage: 'Fuseau horaire'})}
                subtitle={intl.formatMessage({
                    id: 'ParamsPageTimezoneSubTitle',
                    defaultMessage: 'Sélectionnez ici le fuseau horaire de votre planning.'
                })}>
                <ParamsTimezoneForm
                    timezoneOptions={timezoneList.map((timezone: string) => {
                        return {
                            value: timezone,
                            label: timezone + ` (UTC${getTimeZoneOffset(timezone)})`
                        }
                    })}
                />
            </ParamsCard>
            <ParamsCard
                title={intl.formatMessage({id: 'ParamsPageCurrencyTitle', defaultMessage: 'Devise'})}
                subtitle={intl.formatMessage({
                    id: 'ParamsPageCurrencySubTitle',
                    defaultMessage: 'Choisissez ici la devise que vous souhaitez utiliser.'
                })}>
                <ParamsCurrencyForm
                    currencyOptions={[
                        {label: "euros", value: 'EUR'},
                        {label: 'dollars (USD)', value: 'USD'},
                        {label: "dollars (CAD)", value: 'CAD"'}
                    ]}
                />
            </ParamsCard>
            <ParamsCard
                title={intl.formatMessage({id: 'ParamsPageInfosTitle', defaultMessage: 'Coordonnées du gérant'})}
                subtitle={intl.formatMessage({
                    id: 'ParamsPageInfosSubTitle',
                    defaultMessage: 'Renseignez ici le numéro de téléphone principal auquel vos clients pourront vous joindre si vous désactivez la prise de RDV en ligne ou si ils souhaitent annuler ou modifier leur rendez-vous directement par téléphone.'
                })}>
                <ParamsInfosForm/>
            </ParamsCard>
            <BackToTop/>
        </Container>
    </PageWrapper>
}