import {FC} from "react";
import {ReactComponent as DragIcon} from "../../icons/drag-cursor.svg";
import styled from "styled-components";
import {darken} from "polished";

const DragWrapper = styled.button`
  cursor: grab;
  height: 32px;
  width: 32px;
  border: 0;
  padding: 4px 0 0 4px;
  margin: auto;
  border-radius: 4px;
  background-color: ${({theme}) => theme.colors.grey90};
  
  &:hover{
    background-color: ${({theme}) => darken(0.05, theme.colors.grey90)};
  }
`

export const DragButton: FC = () => {

    return <DragWrapper>
        <DragIcon width={26} height={26}/>
    </DragWrapper>
}