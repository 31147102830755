import {FC} from "react";
import {Card, Col, Row, SpanSubtitle2, Switch, ThemeType} from "@linkeo.com/ui-lib-react";
import {useTheme} from "styled-components";
import {FormattedMessage, useIntl} from "react-intl";

interface PaymentGiftcardFormProps {
    active: boolean;
    onActiveChange: (v: boolean) => void;
}

export const PaymentGiftcardForm: FC<PaymentGiftcardFormProps> = ({active, onActiveChange}) => {
    const theme = useTheme() as ThemeType;
    const intl = useIntl();

    return <Card style={{marginTop: '28px'}}>
        <form>
            <Row alignItems={'center'}>
                <Col columns={[12, 12, 6]}>
                    <Switch
                        value={active}
                        onIcon={'check'}
                        offIcon={'cross'}
                        color={theme.colors.grey40}
                        onChange={onActiveChange}
                        onLabel={intl.formatMessage({
                            id: 'ParamsPaymentGiftcardFormSwitchLabel',
                            defaultMessage: 'Activer l\'achat des bons cadeaux'
                        })}
                    />
                </Col>
                <Col columns={[12, 12, 6]}>
                    <SpanSubtitle2>
                        <FormattedMessage
                         id={'ParamsPaymentGiftcardFormSwitchCaption'}
                         defaultMessage={'Vos clients pourront maintenant acheter des bons cadeau sur votre site.'}
                        />
                    </SpanSubtitle2>
                </Col>
            </Row>
        </form>
    </Card>
}