import React, {FC, useMemo, useState} from "react";
import {Button, Dialog, Row, SelectOption, SimpleSelect, SpanBody2, SpanButton} from "@linkeo.com/ui-lib-react";
import {FormattedMessage, useIntl} from "react-intl";

interface GiftcardValidityModalProps {
    code: string;
    service: string;
    price: number;
    currency: string;
    expirationDate: string;
    openActivateGiftcard: boolean;
    onClose: () => void;
}

export const GiftcardValidityModal: FC<GiftcardValidityModalProps> = ({
                                                                          code,
                                                                          service,
                                                                          price,
                                                                          currency,
                                                                          expirationDate,
                                                                          openActivateGiftcard,
                                                                          onClose
                                                                      }) => {
    const intl = useIntl();
    const validityOptions: SelectOption<number>[] = useMemo(() => [
        {
            value: 182.5,
            label: intl.formatMessage({id: 'validityOption6Month', defaultMessage: '6 mois'})
        },
        {
            value: 365,
            label: intl.formatMessage({id: 'validityOption1Year', defaultMessage: '1 an'})
        }
    ], [intl])
    const [giftcardValidity, setGiftcardValidity] = useState<SelectOption<number>>(validityOptions[0])

    return <Dialog onClose={onClose} show={openActivateGiftcard} size={'sm'}
                   title={intl.formatMessage({
                       id: 'GiftcardListPageReactivateButtonTitle',
                       defaultMessage: 'Réactiver ce bon cadeau'
                   })}
                   footer={<>
                       <Button colorType={'secondary'}
                               onClick={onClose}><SpanButton>{intl.formatMessage({
                           id: 'CancelButtonLabel',
                           defaultMessage: 'Annuler'
                       })}</SpanButton></Button>
                       <Button colorType={'primary'}><SpanButton>{intl.formatMessage({
                           id: 'ConfirmButtonLabel',
                           defaultMessage: 'Valider'
                       })}</SpanButton></Button>
                   </>}>
        <Row style={{textAlign: 'center'}}>
            <SpanBody2>
                <FormattedMessage
                    id={'GiftcardActivateModalSubtitle1'}
                    defaultMessage={'Vous êtes sur le point de prolonger le bon cadeau n°{code} d\'une valeur de {price} {br}pour la prestation: {service}. ' +
                        '{br}Il a expiré le {date}.'}
                    values={{
                        code,
                        service,
                        price: intl.formatNumber(price, {style: 'currency', currency}),
                        date: intl.formatDate(expirationDate, {dateStyle: "short"}),
                        br: <br/>
                    }}
                />
            </SpanBody2>
            <SpanBody2 style={{padding: '32px'}}>
                <FormattedMessage id={'GiftcardActivateModalSubtitle2'}
                                  defaultMessage={'De combien de temps voulez-vous prolonger l\'utilisation de ce bon cadeau ?'}/>
            </SpanBody2>
        </Row>
        <SimpleSelect options={validityOptions} selected={giftcardValidity} onSelect={setGiftcardValidity} />
    </Dialog>
}