import {createIntl, createIntlCache, IntlShape, RawIntlProvider} from 'react-intl';
import {createContext, FC, useContext} from 'react';

const cache = createIntlCache();
export const Locales = ['fr-FR', 'fr-CA', 'en-US', 'en-AU', 'en-CA'] as const;
export type Locale = typeof Locales[number];

export function isLocale(str: any): str is Locale {
    return Locales.indexOf(str) !== -1;
}

interface IntlProviderProps {
    locale: Locale,
    messages: Record<string, string>
}

interface IntlContext extends IntlProviderProps {
    intl: IntlShape;
}

const LocalContext = createContext<IntlContext>(
    {
        messages: {},
        locale: 'fr-FR',
        intl: createIntl({
            locale: 'fr-FR',
            messages: {},
        }, cache),
    },
);

export const useLocale = () => useContext(LocalContext);
export const IntlProvider: FC<IntlProviderProps> = ({children, locale, messages}) => {
    const intl = createIntl({
        locale,
        messages,
    }, cache);

    return <LocalContext.Provider value={{messages, locale, intl}}>
        <RawIntlProvider value={intl}>
            {children}
        </RawIntlProvider>
    </LocalContext.Provider>;
};