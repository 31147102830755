import {FC} from "react";
import {useTheme} from "styled-components";
import {Button, SpanButton, ThemeType} from "@linkeo.com/ui-lib-react";
import {FormattedMessage} from "react-intl";

interface DeleteDialogFooterProps {
    onCancelClick: () => void;
    onConfirmDeleteClick: () => void;
}

export const DeleteDialogFooter: FC<DeleteDialogFooterProps> = ({ onConfirmDeleteClick, onCancelClick }) => {
    const theme = useTheme() as ThemeType;

    return <>
        <Button colorType={'secondary'} onClick={onCancelClick}>
            <SpanButton>
                <FormattedMessage
                    id={'CancelButtonLabel'}
                    defaultMessage={'Annuler'}
                />
            </SpanButton>
        </Button>
        <Button backgroundColor={theme.colors.danger} borderColor={theme.colors.danger} onClick={onConfirmDeleteClick}>
            <SpanButton>
                <FormattedMessage
                    id={'RemoveButtonLabel'}
                    defaultMessage={'Supprimer'}
                />
            </SpanButton>
        </Button>
    </>
}