import {FC, useState} from "react";
import {Col, Radio, Row, SpanCaption1} from "@linkeo.com/ui-lib-react";
import {ParamsRadioCard} from "../params-radio-card";
import {FormattedMessage, useIntl} from "react-intl";
import {ParamsRadioItem} from "../params-radio-item";
import {RadioInput} from "../../../commons/components/radio-input";

interface ParamsBookingTimingFormProps {

}

export const ParamsBookingTimingForm: FC<ParamsBookingTimingFormProps> = () => {
    const intl = useIntl();
    const [bookingTime, setBookingTime] = useState<number | string>(30);
    const [customBookingTime, setCustomBookingTime] = useState<number>(10);
    const [hasDelay, setHasDelay] = useState<boolean>(false);
    const [delay, setDelay] = useState<number>(5)

    return <form>
        <Row style={{marginTop: '28px'}}>
            <Col columns={[12, 12, 12, 6]}>
                <ParamsRadioCard title={intl.formatMessage({
                    id: 'ParamsBookingTimingFormTitle1',
                    defaultMessage: 'Créneaux ouverts'
                })}>
                    <ParamsRadioItem label={intl.formatMessage({
                        id: 'ParamsBookingTimingFormLabel1',
                        defaultMessage: 'Sur votre site internet les rendez-vous sont proposés toutes les :'
                    })}>
                        <Row>
                            <Col>
                                <Radio name={'ParamsBookingTimingForm'}
                                       onChange={() => setBookingTime(15)}
                                       value={bookingTime === 15}>
                                    <FormattedMessage id={'TimingMins'}
                                                      defaultMessage={'{number, plural, =0 {0 minute} one {1 minute} other {# minutes}}'}
                                                      values={{number: 15}}/>
                                </Radio>
                                <Radio name={'ParamsBookingTimingForm'}
                                       onChange={() => setBookingTime(60)}
                                       value={bookingTime === 60}>
                                    <FormattedMessage id={'TimingHours'}
                                                      defaultMessage={'{number, plural, =0 {0 heure} one {1 heure} other {# heures}}'}
                                                      values={{number: 1}}/>
                                </Radio>
                            </Col>
                            <Col>
                                <Radio name={'ParamsBookingTimingForm'}
                                       onChange={() => setBookingTime(30)}
                                       value={bookingTime === 30}>
                                    <FormattedMessage id={'TimingMins'}
                                                      defaultMessage={'{number, plural, =0 {0 minute} one {1 minute} other {# minutes}}'}
                                                      values={{number: 30}}/>
                                </Radio>
                                <Radio name={'ParamsBookingTimingForm'}
                                       onChange={() => setBookingTime(customBookingTime.toString())}
                                       value={typeof bookingTime === 'string'}>
                                    <RadioInput inputValue={customBookingTime}
                                                onInputChange={(v) => setCustomBookingTime(v ? v : 5)}
                                                minValue={5}
                                                step={5}
                                                postText={intl.formatMessage({
                                                          id: 'minutes',
                                                          defaultMessage: 'minute(s)'
                                                      })}/>
                                </Radio>
                            </Col>
                        </Row>
                    </ParamsRadioItem>
                    <SpanCaption1>
                        <FormattedMessage
                            id={'ParamsBookingTimingFormCaption1'}
                            defaultMessage={'Par exemple si vous choisissez toute les 15 minutes et que votre journée commence a 9h, vos clients pourront prendre RDV à 9h00, 9h15, 9h30 etc...'}
                        />
                    </SpanCaption1>
                </ParamsRadioCard>
            </Col>
            <Col columns={[12, 12, 12, 6]}>
                <ParamsRadioCard title={intl.formatMessage({
                    id: 'ParamsBookingTimingFormTitle2',
                    defaultMessage: 'Délais de battement'
                })}>
                    <ParamsRadioItem label={intl.formatMessage({
                        id: 'ParamsBookingTimingFormLabel2',
                        defaultMessage: 'Si vous le souhaitez, vous pouvez appliquer un délai entre les rendez-vous.'
                    })}>
                        <Radio name={'ParamsBookingTimingDelayForm'}
                               onChange={() => setHasDelay(!hasDelay)}
                               value={!hasDelay}>
                            <FormattedMessage
                                id={'ParamsBookingTimingDelayRadioLabel'}
                                defaultMessage={'Pas de délai'}
                            />
                        </Radio>
                        <Radio name={'ParamsBookingTimingDelayForm'} onChange={() => setHasDelay(!hasDelay)}
                               value={hasDelay}>
                            <RadioInput inputValue={delay}
                                        onInputChange={(v) => setDelay(v ? v : 5)}
                                        minValue={5}
                                        step={5}
                                        preText={intl.formatMessage({id: 'ParamsBookingTimingRadioOptionText', defaultMessage: 'Appliquer un délai de'})}
                                        postText={intl.formatMessage({id: 'minutes', defaultMessage: 'minute(s)'})}/>
                        </Radio>
                    </ParamsRadioItem>
                    <SpanCaption1>
                        <FormattedMessage
                            id={'ParamsBookingTimingFormCaption2'}
                            defaultMessage={'Par exemple si votre rendez-vous s\'est terminé à 9h30 et que vous appliquez un délai de 15min. Aucune réservation ne sera possible avant 9h45.'}
                        />
                    </SpanCaption1>
                </ParamsRadioCard>
            </Col>
        </Row>
    </form>
}