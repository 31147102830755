import {File} from '../commons/common-types';

export const defaultColor = '#ffffff';

export interface Option {
    id: string;
    name: string;
}

export interface Category {
    id: string;
    title: string;
    description: string
}

export interface Agenda {
    id: string,
    name: string
}

export interface Service extends ServicePayload {
    id: string;
}

export interface ServicePayload {
    title: string;
    description: string;
    duration: number;
    price: number;
    spots: number;
    waitingListSpots: number;
    file?: File,
    category?: Category;
    agendas: Agenda[];
    color: string;
    bookable: boolean;
    options: Option[],
    giftable: boolean;
    maxSpotsPerBookEntry: number;
    downPayment?: number;
}