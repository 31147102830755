import React, {FC, FormEvent, useState} from "react";
import {Col, getTypo, Input, LabelWrapper, Row, Shapes} from "@linkeo.com/ui-lib-react";
import {useIntl} from "react-intl";
import styled from "styled-components";
import {useNavigate} from "react-router";
import {routeCustomers} from "../../routes";
import {FormFooter} from "../../commons/components/form-footer";

const TextInput = styled.textarea`
  background-color: ${({theme}) => theme.input.backgroundColor};
  color: ${({theme}) => theme.input.color};
  border: ${({theme}) => theme.input.borderColor} 1px solid;
  outline: none;
  width: 100%;
  border-radius: 2px;
  padding: 7px;
  height: 164px;
  max-width: 210%;
  min-width: 150px;
  min-height: 38px;
  transition: background ease-in-out 200ms, border-color ease-in-out 200ms, color ease-in-out 200ms,
  box-shadow ease-in-out 200ms;
  ${getTypo('input')};
  color: ${({theme}) => theme.input.color};

  &:focus {
    background-color: ${({theme}) => theme.colors.grey95};
    border: ${({theme}) => theme.colors.grey60} 1px solid;
    ${Shapes.shadow1};
  }

  &:hover {
    border: ${({theme}) => theme.colors.grey70} 1px solid;
  }
`

interface FormProps {
    name: string;
    lastname: string;
    email: string;
    phone: string;
    details?: string
}

interface CustomerInfoFormProps {

}

export const CustomerInfoForm: FC<CustomerInfoFormProps> = () => {
    const intl = useIntl();
    const navigate = useNavigate();
    const [form, setForm] = useState<FormProps>({
        name: '',
        lastname: '',
        email: '',
        phone: ''
    })

    const _onSubmit = (e: FormEvent) => {
        e.preventDefault();
        e.stopPropagation();
    }

    return <form onSubmit={e => _onSubmit(e)}>
        <Row gapColumn={20}>
            <Col columns={[12, 6, 6]}>
                <Input label={intl.formatMessage({
                    id: 'CustomerEditPageNameInputLabel',
                    defaultMessage: 'Prénom'
                })}
                       required={true}
                       type={'text'}
                       value={form.name}
                       onChange={(name) => setForm({...form, name})}
                />
            </Col>
            <Col columns={[12, 6, 6]}>
                <Input label={intl.formatMessage({
                    id: 'CustomerEditPageLastNameInputLabel',
                    defaultMessage: 'Nom'
                })}
                       required={true}
                       type={'text'}
                       value={form.lastname}
                       onChange={(lastname) => setForm({...form, lastname})}
                />
            </Col>
        </Row>
        <Row gapColumn={20}>
            <Col columns={[12, 6, 6]}>
                <Input label={intl.formatMessage({
                    id: 'CustomerEditPageEmailInputLabel',
                    defaultMessage: 'Adresse e-mail'
                })}
                       required={true}
                       type={'email'}
                       value={form.email}
                       onChange={(email) => setForm({...form, email})}
                />
            </Col>
            <Col columns={[12, 6, 6]}>
                <Input label={intl.formatMessage({
                    id: 'CustomerEditPagePhoneInputLabel',
                    defaultMessage: 'Téléphone'
                })}
                       type={'tel'}
                       required={true}
                       value={form.phone}
                       onChange={(phone) => setForm({...form, phone})}
                />
            </Col>
        </Row>
        <Row gapColumn={20}>
            <Col columns={[12, 6, 6]}>
                <LabelWrapper label={intl.formatMessage({
                    id: 'CustomerEditPageMoreInfoInputLabel',
                    defaultMessage: 'Informations complémentaires'
                })}>
                    <TextInput value={form.details} onChange={(el) => setForm({...form, details: el.target.value})}/>
                </LabelWrapper>
            </Col>
        </Row>
        <FormFooter
            onCancelClick={() => navigate(routeCustomers)}
            submitButtonText={intl.formatMessage({id: 'SaveButtonLabel', defaultMessage: 'Enregistrer'})} />
    </form>
}