import {FC, FormEvent, useEffect, useState} from "react";
import {
    Card, Checkbox,
    Col, H2,
    Radio,
    Row,
    SelectOption,
    SimpleSelect, SpanBody1, SpanCaption2,
    Switch,
    ThemeType,
    useToaster
} from "@linkeo.com/ui-lib-react";
import {ParamsFormHeader} from "../../params/components/params-form-header";
import {FormattedMessage, useIntl} from "react-intl";
import {ServiceInfosForm} from "./service-infos-form";
import {FormFooter} from "../../commons/components/form-footer";
import {ParamsCard} from "../../params/components/params-card";
import {useTheme} from "styled-components";
import {InputWrapper} from "./input-wrapper";
import {CustomListSpots} from "./custom-list-spots";
import {Agenda, Category, Option} from "../services-types";
import {Link} from "react-router-dom";
import {routeParamsAgendas} from "../../routes";
import {DownPaymentForm} from "../../commons/components/down-payment-form";
import {File} from "../../commons/common-types"

export interface ServiceFormProps {
    id?: string;
    title: string;
    description: string;
    duration: number;
    price: number;
    spots: number;
    waitingListSpots: number;
    file?: File,
    category?: Category;
    agendas: Agenda[];
    color: string;
    bookable: boolean;
    options: Option[],
    giftable: boolean;
    maxSpotsPerBookEntry: number;
    downPayment?: number;
}

interface ServiceEditFormProps {
    form: ServiceFormProps;
    onCloseClick: () => void;
    categories: Category[] | null;
    agendas: Agenda[] | null;
    paymentActive: boolean;
    giftcardActive: boolean;
    options: Option[];
    loading: boolean;
    onSubmit: (form: ServiceFormProps) => void;
    codeBouton: string;
    token: string;
    apiUri: string;
    paymentPercent: number
}

export const ServiceEditForm: FC<ServiceEditFormProps> = ({
                                                              form: formProps,
                                                              onCloseClick,
                                                              categories,
                                                              agendas,
                                                              paymentActive,
                                                              giftcardActive,
                                                              options,
                                                              onSubmit,
                                                              loading,
                                                              codeBouton,
                                                              token,
                                                              apiUri,
                                                              paymentPercent
                                                          }) => {
    const intl = useIntl();
    const toast = useToaster();
    const theme = useTheme() as ThemeType;
    const [form, setForm] = useState<ServiceFormProps>(formProps);
    const defaultMinMultiSpotOption: SelectOption<number> = {label: '2', value: 2};
    const [multiSpotsOption, setMultiSpotsOption] = useState<SelectOption<number>[]>(Array.from({length: form.spots - 1}, (_, i) => {
        return {label: (i + 2).toString(), value: i + 2}
    }))
    const [selectedMultiSpots, setSelectedMultiSpots] = useState<SelectOption<number>>(
        form.maxSpotsPerBookEntry > 1
            ? multiSpotsOption.find(el => el.value === form.maxSpotsPerBookEntry) || defaultMinMultiSpotOption
            : defaultMinMultiSpotOption)
    const [allowOptions, setAllowOptions] = useState<boolean>(form.options.length > 0);

    useEffect(() => {
        setForm(formProps)
    }, [formProps]);

    useEffect(() => {
        if (form.spots > 1) {
            setMultiSpotsOption(Array.from({length: form.spots - 1}, (_, i) => {
                return {label: (i + 2).toString(), value: i + 2}
            }))
            if (form.spots < selectedMultiSpots.value) {
                setSelectedMultiSpots({label: form.spots.toString(), value: form.spots})
            }
        } else {
            setForm((prevState) => {
                return {...prevState, multiSpots: 1}
            })
        }
    }, [form.spots, selectedMultiSpots.value])

    const onCheckboxSelect = function <T extends { id: string }>(value: T, key: keyof ServiceFormProps) {
        const find = (form[key] as T[]).find(el => el.id === value.id);
        if (!find) {
            setForm({...form, [key]: [...form[key] as T[], value]})
        } else {
            setForm({...form, [key]: (form[key] as T[]).filter(el => el.id !== value.id)})
        }
    }

    const _onSubmit = (e: FormEvent) => {
        if (!allowOptions) {
            setForm({...form, options: []})
        }
        e.preventDefault();
        e.stopPropagation();
        if (!form.description.trim()) {
            window.scrollTo({top: 0, behavior: 'smooth'});
            toast(intl.formatMessage({
                id: 'serviceEditFormDescRequiredErrorMessage',
                defaultMessage: 'La description est requise.'
            }))
        } else {
            onSubmit(form)
        }
    }

    return <Card style={{marginBottom: '28px'}}>
        <form onSubmit={_onSubmit}>
            <ParamsFormHeader
                title={form.id ? intl.formatMessage({
                    id: 'ParamsServiceEditFormHeaderTitle',
                    defaultMessage: 'Editez une prestation'
                }) : intl.formatMessage({
                    id: 'ParamsServiceCreateFormHeaderTitle',
                    defaultMessage: 'Ajoutez une prestation'
                })}
                subtitle={intl.formatMessage({
                    id: 'ParamsServiceEditFormHeaderSubTitle',
                    defaultMessage: 'Renseignez ici les informations concernant la prestation.'
                })}
                onCloseClick={onCloseClick}/>
            <ServiceInfosForm
                form={form}
                setForm={setForm}
                categories={categories}
                codeBouton={codeBouton}
                token={token}
                apiUri={apiUri}
            />
            <ParamsCard inCard
                        title={intl.formatMessage({
                            id: 'ParamsServiceEditFormAvailabilityTitle',
                            defaultMessage: 'Disponibilité de la prestation'
                        })}
                        subtitle={intl.formatMessage({
                            id: 'ParamsServiceEditFormAvailabilitySubTitle',
                            defaultMessage: 'Activez la réservation en ligne et/ou les bons cadeau pour cette prestation.'
                        })}>
                <InputWrapper text={intl.formatMessage({
                    id: 'ParamsServiceEditFormAvailabilityInfos',
                    defaultMessage: 'Une prestation disponible à la réservation sera proposée sur votre site internet. ' +
                        '{br}Une prestation disponible sous forme de bon cadeau pourra être achetée sur votre site internet pour être réservée ultérieurement. ' +
                        'Les bons cadeaux seront valables pour une période d\' 1 an à partir de leur date d’achat.'
                }, {br: <br/>}) as string}
                              dangerText={!giftcardActive ? intl.formatMessage({
                                      id: 'ParamsServiceEditFormAvailabilityWarningInfos',
                                      defaultMessage: 'Les bons cadeaux ' +
                                          'ne pourront être active que lorsque le paiement sur votre site internet sera actif. {br}Veuillez contacter notre service' +
                                          ' client à l\'adresse suivante pour configurer votre paiement: service@linkeo.com'
                                  }, {br: <br/>}) as string
                                  : undefined}>
                    <Col columns={[12, 12, 5]}>
                        <Switch onIcon={'check'} offIcon={'cross'} value={form.bookable}
                                small color={theme.colors.grey40}
                                onChange={() => setForm({...form, bookable: !form.bookable})}
                                onLabel={intl.formatMessage({
                                    id: 'ParamsServiceEditFormActiveLabel',
                                    defaultMessage: 'Prestation disponible à la réservation'
                                })}/>
                    </Col>
                    <Col columns={[12, 12, 7]}>
                        <Switch onIcon={'check'} offIcon={'cross'} value={form.giftable}
                                small color={theme.colors.grey40}
                                disabled={!giftcardActive}
                                onChange={() => setForm({...form, giftable: !form.giftable})}
                                onLabel={intl.formatMessage({
                                    id: 'ParamsServiceEditFormGiftableLabel',
                                    defaultMessage: 'Prestation disponible sous forme de bon cadeau'
                                })}
                        />
                    </Col>
                </InputWrapper>
            </ParamsCard>
            <ParamsCard inCard
                        title={intl.formatMessage({
                            id: 'ParamsServiceEditFormSpotsTitle',
                            defaultMessage: 'Places disponibles'
                        })}
                        subtitle={intl.formatMessage({
                            id: 'ParamsServiceEditFormSpotsSubtitle',
                            defaultMessage: 'Renseignez ici le nombre de clients qui peuvent réserver simultanement cette prestation.'
                        })}>
                <Row style={{margin: '20px 0'}} wraps={'wrap'}>
                    <CustomListSpots
                        number={5}
                        disabled={false}
                        name={'ParamsServiceEditFormSpotsNumber'}
                        value={form.spots}
                        onChangeValue={(spots) => setForm({...form, spots})}/>
                </Row>
            </ParamsCard>
            <ParamsCard inCard
                        title={intl.formatMessage({
                            id: 'ParamsServiceEditFormWaitingListTitle',
                            defaultMessage: 'Liste d\'attente'
                        })}
                        subtitle={intl.formatMessage({
                            id: 'ParamsServiceEditFormWaitingListSubTitle',
                            defaultMessage: 'Activez la liste d\'attente et renseignez le nombre maximum de places disponibles sur cette liste d\'attente.'
                        })}>
                <InputWrapper
                    text={intl.formatMessage({
                        id: 'ParamsServiceFormWaitingListCaption1',
                        defaultMessage: 'Lorsque la liste d’attente d\'une prestation est activée, vos clients peuvent s’inscrire sur un créneau déjà occupé. ' +
                            'Si un créneau se libère, il est automatiquement attribué au 1er client sur la liste d’attente.'
                    })}
                    dangerText={paymentActive ? intl.formatMessage({
                        id: 'ParamsServiceFormWaitingListWarningCaption',
                        defaultMessage: 'La liste d\'attente ne sera pas proposée si le paiement à la réservation est activé.'
                    }) : undefined}>
                    <Col>
                        <Switch onIcon={'check'} offIcon={'cross'} value={form.waitingListSpots > 0}
                                small color={theme.colors.grey40}
                                disabled={paymentActive}
                                onChange={(v) => setForm({...form, waitingListSpots: v ? 1 : 0})}
                                onLabel={intl.formatMessage({
                                    id: 'ParamsServiceEditFormWaitingListSwitchLabel',
                                    defaultMessage: 'Activer la liste d\'attente'
                                })}
                        />
                    </Col>
                    <CustomListSpots
                        number={3}
                        name={'ParamsServiceEditFormWaitingListSpots'}
                        disabled={form.waitingListSpots < 1 || paymentActive}
                        value={form.waitingListSpots}
                        onChangeValue={(waitingListSpots) => setForm({...form, waitingListSpots})}/>
                </InputWrapper>
            </ParamsCard>
            {form.spots > 1 && <ParamsCard inCard
                                           title={intl.formatMessage({
                                               id: 'ParamsServiceEditFormMultiSpotsTitle',
                                               defaultMessage: 'Réservation multiplaces'
                                           })}
                                           subtitle={intl.formatMessage({
                                               id: 'ParamsServiceEditFormMultiSpotsSubTitle',
                                               description: 'Autoriser un client seul à reserver plusieurs places en une seule fois.'
                                           })}>
                <InputWrapper text={intl.formatMessage({
                    id: 'ParamsServiceEditFormMultiSpotsCaption',
                    defaultMessage: 'Lorsque la réservation multiplaces est activée, vous autorisez un client seul à effectuer une réservation pour plusieurs personnes à la fois.'
                })}>
                    <Col>
                        <Switch onIcon={'check'} offIcon={'cross'} value={form.maxSpotsPerBookEntry > 1}
                                small color={theme.colors.grey40}
                                onChange={(v) => setForm({...form, maxSpotsPerBookEntry: v ? 2 : 1})}
                                onLabel={intl.formatMessage({
                                    id: 'ParamsServiceEditFormMultiSpotsSwitchLabel',
                                    defaultMessage: 'Activer la réservation multiplaces'
                                })}
                        />
                    </Col>
                    <Col>
                        <Radio name={'ParamsServiceEditFormMultiSpotsNumber'}
                               disabled={form.maxSpotsPerBookEntry === 1}
                               onChange={() => {
                               }}
                               value={form.maxSpotsPerBookEntry > 1}>
                            <Row alignItems={'center'}>
                                <Col columns={[12, 4]} style={{padding: '0 4px 0 16px'}}>
                                    <SimpleSelect options={multiSpotsOption} selected={selectedMultiSpots} small
                                                  disabled={form.maxSpotsPerBookEntry === 1}
                                                  onSelect={(v) => {
                                                      setSelectedMultiSpots(v)
                                                      setForm({...form, maxSpotsPerBookEntry: v.value})
                                                  }}/>
                                </Col>
                                <Col columns={[12, 2]} style={{padding: '4px'}}>
                                    <FormattedMessage id={'customSpotsLabel'} defaultMessage={'places'}/>
                                </Col>
                            </Row>
                        </Radio>
                    </Col>
                </InputWrapper>
            </ParamsCard>}
            {options.length > 0 && <><H2>
                <FormattedMessage id={'Options'} defaultMessage={'Options'}/>
            </H2>
                <Row style={{margin: '20px 0'}}>
                    <Col>
                        <Switch onIcon={'check'} offIcon={'cross'} value={allowOptions}
                                small color={theme.colors.grey40}
                                onChange={(v) => {
                                    setAllowOptions(v);
                                    setForm({...form, options: []})
                                }}
                                onLabel={intl.formatMessage({
                                    id: 'ParamsServiceEditFormOptionsSwitchLabel',
                                    defaultMessage: 'Activer les options'
                                })}
                        />
                    </Col>
                </Row>
                <SpanBody1>
                    <FormattedMessage
                        id={'ParamsServiceEditFormOptionsCaption'}
                        defaultMessage={'Cochez dans la liste ci-dessous les options sur lesquelles cette prestation est disponible.'}
                    />
                </SpanBody1>
                <Row style={{margin: '12px 0 28px'}}>
                    {options.map((option, index) => {
                        return <Col key={index}>
                            <Checkbox disabled={!allowOptions}
                                      onChange={() => onCheckboxSelect(option, 'options')}
                                      value={!!(form.options.find(el => el.id === option.id))}>
                                {option.name}
                            </Checkbox>
                        </Col>
                    })}
                </Row></>}
            <ParamsCard inCard
                        title={intl.formatMessage({
                            id: 'ParamsServiceEditFormAgendasTitle',
                            defaultMessage: 'Attribution de la prestation'
                        })}
                        subtitle={intl.formatMessage({
                            id: 'ParamsServiceEditFormAgendasSubTitle',
                            defaultMessage: 'Cochez dans la liste ci-dessous les plannings sur lesquelles cette prestation est disponible.'
                        })}>
                <Row style={{margin: '12px 0 28px'}}>
                    {agendas && agendas.length > 0
                        ? agendas.map((agenda, index) => {
                            return <Col key={index}>
                                <Checkbox onChange={() => onCheckboxSelect(agenda, 'agendas')}
                                          value={!!form.agendas.find(el => el.id === agenda.id)}>
                                    {agenda.name}
                                </Checkbox>
                            </Col>
                        })
                        : <p>
                            <SpanCaption2 style={{color: theme.colors.danger}}>
                                <FormattedMessage id={'ParamsServiceEditFormAgendasErrorCaption'}
                                                  defaultMessage={'Aucun planning disponible.'}/>
                            </SpanCaption2>
                            <br/>
                            <SpanCaption2
                                style={{color: theme.colors.danger, display: 'inline-block', marginTop: '12px'}}>
                                <FormattedMessage
                                    id={'ParamsServiceEditFormAgendasErrorDangerCaption'}
                                    defaultMessage={'Vous devez créer au moins un planning individuel pour l\'associer à vos prestations. {br}Pour créer un planning, allez sur {link}.'}
                                    values={{
                                        link: <Link to={routeParamsAgendas}>
                                            <FormattedMessage id={'navSettingsPlanning'}
                                                              defaultMessage={'Plannings individuels'}/>
                                        </Link>, br: <br/>
                                    }}
                                />
                            </SpanCaption2>
                        </p>
                    }
                </Row>
            </ParamsCard>
            <ParamsCard inCard
                        title={intl.formatMessage({
                            id: 'ParamsServiceEditFormDepositTitle',
                            defaultMessage: 'Acompte personnalisé'
                        })}
                        subtitle={intl.formatMessage({
                            id: 'ParamsServiceEditFormDepositSubTitle',
                            defaultMessage: 'Personnalisez l\'acompte pour cette prestation. {value}'
                        }, {
                            value: <i>
                                <FormattedMessage id={'ParamsServiceEditFormDepositSubTitle2'}
                                                  defaultMessage={'Par défaut, l\'acompte est de {paymentPercent}%'}
                                                  values={{paymentPercent}}/>
                            </i>
                        }) as string}>
                <DownPaymentForm downPayment={form.downPayment}
                                 onChange={(downPayment) => setForm({...form, downPayment})}
                                 initialDownPaymentValue={formProps.downPayment}/>
            </ParamsCard>
            <FormFooter onCancelClick={onCloseClick}
                        saveIcon={'check'}
                        loading={loading}
                        disabled={!agendas || agendas.length === 0}
                        submitButtonText={intl.formatMessage({
                            id: 'ParamsServiceEditFormSubmitButtonText',
                            defaultMessage: 'Enregistrer la prestation'
                        })}/>
        </form>
    </Card>
}