import {FC, useEffect, useRef, useState} from "react";
import {
    Button,
    Col,
    Dialog, getTypo, InOut, Keyframes,
    Row, SpanBody1,
    SpanBody2,
    SpanCaption1,
    SpanSubtitle2, Svg, ThemeType
} from "@linkeo.com/ui-lib-react";
import {FormattedDate, FormattedMessage} from "react-intl";
import styled, {useTheme} from "styled-components";

const Calendar = styled.div`
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`
const CalendarHeader = styled.span`
    height: 6px;
    width: 65px;
    display: block;
    background: linear-gradient(#0db3b2, #0daaa8);
    border-top-right-radius: 2px;
    border-top-left-radius: 2px;
`
const CalendarBody = styled.span`
    text-align: center;
    display: block;
    width: 65px;
    padding: 5px 0 10px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    background: white;
`
const Number = styled.p`
    ${getTypo('body2')}
    text-align: center;
    font-weight: 100;
    font-size: 32px;
    line-height: 22px;
    color: ${({theme}) => theme.colors.grey40};
`
const Separation = styled.div`
    border-bottom: 1px solid ${({theme}) => theme.colors.grey90};
    margin: 20px -48px;
`
const Box = styled.span`
    ${getTypo('subtitle2')};
    color: ${({theme}) => theme.colors.primary};
    margin-left: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 5px;
`;

export interface Customer {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    done: boolean;
    subscribed: boolean;
    spots: number;
    adminNotes?: string;
}

interface AppointmentDialogProps {
    onClose: () => void;
    show: boolean;
    date: Date;
    from: string;
    to: string;
    service: string;
    max: number;
    spot: number;
    id: string;
    onEditClick: () => void;
    onDeleteClick: () => void;
    onTransferClick: () => void;
    onAddClick: () => void;
    customers: Customer[]
}

export const AppointmentDialog: FC<AppointmentDialogProps> = ({
                                                                  onClose,
                                                                  show,
                                                                  date,
                                                                  service,
                                                                  max,
                                                                  spot,
                                                                  id,
                                                                  from,
                                                                  to,
                                                                  customers,
                                                                  onAddClick,
                                                                  onDeleteClick,
                                                                  onEditClick,
                                                                  onTransferClick
                                                              }) => {
    const theme = useTheme() as ThemeType;
    const [getSlide, setSlide] = useState<number>(0);
    const ref = useRef<HTMLDivElement | null>(null);
    const [maxHeight, setMaxHeight] = useState<number|undefined>(ref.current?.clientHeight);

    useEffect(() => {
        const resizeElement = () => {
            if (ref.current !== null) {
                const rect = ref.current?.getBoundingClientRect();
                setMaxHeight(rect.height)
            }
        }
        resizeElement();
        window.addEventListener('resize', resizeElement);
        return () => {
            window.addEventListener('resize', resizeElement)
        }
    }, [getSlide])

    return <Dialog
        onClose={onClose}
        show={show}
        title={''}
        size={'md'}>
        <Row justifyContent={'center'} alignItems={'center'} wrap={'wrap'} style={{rowGap: '10px'}}>
            <Col>
                <Calendar>
                    <CalendarHeader/>
                    <CalendarBody>
                        <SpanSubtitle2 style={{
                            color: theme.colors.grey60,
                            textTransform: 'uppercase',
                            textAlign: 'center',
                            fontSize: '13px'
                        }}>
                            <FormattedDate value={date} month={'short'}/>
                        </SpanSubtitle2>
                        <Number>
                            <FormattedDate value={date} day={'numeric'}/>
                        </Number>
                    </CalendarBody>
                </Calendar>
            </Col>
            <Col style={{maxWidth: '100%', wordWrap: 'break-word'}}>
                <SpanBody2>
                    {service}
                </SpanBody2>
                <div>
                    <SpanBody2>{from} — {to}</SpanBody2>
                </div>
                {max > 1 && <SpanSubtitle2 style={{color: theme.colors.grey60}}>
                    <FormattedMessage
                        id={'AppointmentDialogMaxSpotCaption'}
                        defaultMessage={'{spot}/{max} personne(s)'}
                        values={{spot, max}}
                    />
                </SpanSubtitle2>}
                <div>
                    <SpanCaption1 style={{fontStyle: 'inherit', color: theme.colors.grey20}}>
                        id: {id}
                    </SpanCaption1>
                </div>
            </Col>
        </Row>
        <Separation/>
        <Row wrap={'nowrap'} justifyContent={'space-between'}>
            <Col style={{padding: 0}}>
                <Button style={{height: '100%', width: '18px'}}
                        onClick={() => setSlide((prevState) => {
                            if (prevState === 0) {
                                return customers.length - 1
                            } else {
                                return prevState - 1
                            }
                        })}
                        backgroundColor={theme.colors.light}
                        borderColor={theme.colors.light}>
                    <Svg icon={'left'} height={24} width={24} style={{marginLeft: '-12px'}}/>
                </Button>
            </Col>
            <div style={{maxWidth: '80%', overflow: 'hidden', maxHeight}}>
                {customers.map((el, index) => <InOut key={index} show={getSlide === index}
                                                     enter={{keyframes: Keyframes.slideInFromLeft}}
                                                     exit={{keyframes: Keyframes.slideOutToRight}}>
                    <div ref={ref} style={{display: 'flex', flexDirection: 'column', alignItems: 'center', rowGap: '6px'}}>
                        <SpanBody2>{el.firstName} {el.lastName}</SpanBody2>
                        <Col style={{marginBottom: '8px'}}>
                            {el.done &&
                                <Svg icon={'check-circle'} width={17} height={17} style={{verticalAlign: 'top'}}/>}
                            {el.subscribed && <Box>
                                <FormattedMessage id={"Subscribed"} defaultMessage={"Abonné"}/>
                            </Box>}
                            <Box>
                                <FormattedMessage
                                    id={'EventsPagePendingCardSpotsLabel'}
                                    defaultMessage={'{number} personne(s)'}
                                    values={{number: el.spots}}
                                />
                            </Box>
                        </Col>
                        <SpanBody1>{el.email}</SpanBody1>
                        <SpanBody1>{el.phone}</SpanBody1>
                        {el.adminNotes && <Col style={{textAlign: 'center'}}>
                            <SpanSubtitle2 style={{color: theme.colors.primary}}>
                                <FormattedMessage id={'appointmentDialogAdminNotes'}
                                                  defaultMessage={'Votre commentaire: '}/>
                            </SpanSubtitle2>
                            <SpanSubtitle2>
                                {el.adminNotes}
                            </SpanSubtitle2>
                        </Col>}
                        <Col style={{textAlign: 'center'}}>
                            <Button small style={{margin: '5px'}} onClick={onEditClick}>
                                <FormattedMessage id={'buttonLabelEdit'} defaultMessage={'Modifier'}/>
                            </Button>
                            <Button small style={{margin: '5px'}} onClick={onTransferClick}>
                                <FormattedMessage id={'buttonLabelTransfer'} defaultMessage={'Transférer'}/>
                            </Button>
                            <Button small style={{margin: '5px'}} onClick={onDeleteClick}
                                    backgroundColor={theme.colors.danger}
                                    fontColor={theme.colors.light}
                                    borderColor={theme.colors.danger}>
                                <FormattedMessage id={'RemoveButtonLabel'} defaultMessage={'Supprimer'}/>
                            </Button>
                        </Col>
                    </div>
                </InOut>)}
            </div>
            <Col style={{padding: 0}}>
                <Button style={{height: '100%', width: '18px'}}
                        onClick={() => setSlide((prevState) => {
                            if (prevState === (customers.length - 1)) {
                                return 0
                            } else {
                                return prevState + 1
                            }
                        })}
                        backgroundColor={theme.colors.light}
                        borderColor={theme.colors.light}>
                    <Svg icon={'right'} height={24} width={24} style={{marginLeft: '-12px'}}/>
                </Button>
            </Col>
        </Row>
        {max > 1 && spot < max && <Button style={{display: 'block', margin: '15px auto 5px'}}
                                          onClick={onAddClick}
                                          icon={'person-plus'}
                                          backgroundColor={'#0daaa8'} borderColor={'#0daaa8'}
                                          fontColor={theme.colors.light}>
            <FormattedMessage id={'AppointmentDialogAddPeopleButtonLabel'} defaultMessage={'Ajouter 1 pers.'}/>
        </Button>}
    </Dialog>
}