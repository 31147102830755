import React, {FC, useMemo, useRef, useState} from "react";
import {PageWrapper} from "../../commons/components/page-wrapper";
import {Container, InOut} from "@linkeo.com/ui-lib-react";
import {ContainerHeader} from "../../commons/components/container-header";
import {useIntl} from "react-intl";
import {AgendasCard} from "../../agendas/components/agendas-card";
import {SubscriptionModal} from "../../agendas/components/subscription-modal";
import {AgendasDeleteModal} from "../../agendas/components/agendas-delete-modal";
import {AgendaFormProps, AgendasEditForm} from "../../agendas/components/agendas-edit-form";
import {BackToTop} from "../../commons/components/back-to-top";
import {reOrderList} from "../../utils/misc";
import {EmptyListMessage} from "../../params/components/empty-list-message";

interface ParamsAgendasPageProps {

}

export const ParamsAgendasPage: FC<ParamsAgendasPageProps> = () => {
    const intl = useIntl();
    const [agendasList, setAgendasList] = useState(
        [{id: '0', name: 'Jonathan', description: 'Gérant'},
            {id: '1', name: 'Marie', description: 'Assistante'},
            {id: '2', name: 'Jean-Christophe', description: 'Serveur'},
            {id: '3', name: 'Christelle', description: ''}
        ])
    const [selectedAgenda] = useState('')
    const [loading] = useState<boolean>(false);
    const [openSubscriptionModal, setOpenSubscriptionModal] = useState<boolean>(false);
    const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
    const [openEditAgenda, setOpenEditAgenda] = useState<boolean>(false);
    const defaultAgendaForm: AgendaFormProps = useMemo(() => {
        return {
            name: '',
            notifName: '',
            email: '',
            tel: '',
            description: '',
            services: [],
            openings: [
                {day: [0, 4], hours: [{from: '09:00:00', to: '18:00:00'}]}
            ]
        }
    }, [])
    const [agendaForm, setAgendaForm] = useState<AgendaFormProps>(defaultAgendaForm);
    const dragOverItem = useRef<number | null>(null);
    const dragItem = useRef<number | null>(null)

    const onDragStart = (index: number) => {
        dragItem.current = index;
    }

    const onDragOver = (e: React.DragEvent) => {
        e.preventDefault();
    }

    const onDragEnter = (index: number) => {
        dragOverItem.current = index;
    }

    const onDrop = () => {
        if (dragItem.current !== null && dragOverItem.current !== null) {
            setAgendasList(reOrderList(agendasList, dragItem.current, dragOverItem.current))
        }
        dragOverItem.current = null;
        dragItem.current = null;
    }

    return <PageWrapper isLoading={loading}>
        <Container size={'lg'}>
            <ContainerHeader
                title={intl.formatMessage({
                    id: 'ParamsAgendasPageHeaderTitle',
                    defaultMessage: 'Plannings individuels'
                })}
                subtitle={intl.formatMessage({
                    id: 'ParamsAgendasPageHeaderSubTitle',
                    defaultMessage: 'Consultez, modifiez et créez de nouveaux plannings individuels. {br}Chaque planning individuel doit être associé à une ou plusieurs prestations.'
                }, {br: <br/>}) as string}
                buttonLabel={intl.formatMessage({
                    id: 'ParamsAgendasPageHeaderButtonLabel',
                    defaultMessage: '+ Ajouter un planning'
                })}
                onClick={() => {
                    setAgendaForm(defaultAgendaForm);
                    setOpenEditAgenda(true)
                }}/>
            <InOut show={openEditAgenda}>
                <AgendasEditForm
                    locale={'fr-FR'}
                    onCloseClick={() => {
                        setOpenEditAgenda(false);
                        setAgendaForm(defaultAgendaForm);
                    }}
                    form={agendaForm}
                />
            </InOut>
            {agendasList.length > 0
                ? agendasList.map((agenda, index) => {
                    return <AgendasCard key={agenda.id}
                                        description={agenda.description}
                                        name={agenda.name}
                                        disabled={!!(agendaForm.id && agendaForm.id === agenda.id)}
                                        onDragOver={(e) => onDragOver(e)}
                                        onDragStart={() => onDragStart(index)}
                                        onDrop={onDrop}
                                        onDragEnter={() => onDragEnter(index)}
                                        onEditClick={() => {
                                                  setAgendaForm({...agendaForm, id: agenda.id});
                                                  setOpenEditAgenda(true);
                                                  window.scrollTo({top: 0, behavior: 'smooth'})
                                              }}
                                        onSubscriptionClick={() => setOpenSubscriptionModal(true)}
                                        onAgendaDeleteClick={() => setOpenDeleteModal(true)}
                    />
                })
                : <EmptyListMessage message={intl.formatMessage({
                    id: 'ParamsAgendasPageEmptyListCaption',
                    defaultMessage: 'Vous n\'avez pas encore d\'agenda. Commencez par en créer un depuis le bouton "Ajouter un agenda".'
                })}/>}
            <SubscriptionModal openModal={openSubscriptionModal}
                               onCloseModal={() => setOpenSubscriptionModal(false)}
                               link={selectedAgenda}
            />
            <AgendasDeleteModal openModal={openDeleteModal}
                                onAgendaDelete={() => {
                                      }}
                                onCloseModal={() => setOpenDeleteModal(false)}/>
            <BackToTop/>
        </Container>
    </PageWrapper>
}