import React, {FC} from "react";
import {Button, Col, IconName, Row, SpanButton, ThemeType} from "@linkeo.com/ui-lib-react";
import {useIntl} from "react-intl";
import {useTheme} from "styled-components";

interface FormFooterProps {
    onCancelClick: () => void;
    submitButtonText: string;
    loading?: boolean
    saveIcon?: IconName;
    disabled?: boolean
}

export const FormFooter: FC<FormFooterProps> = ({onCancelClick, submitButtonText, loading, saveIcon, disabled}) => {
    const intl = useIntl();
    const theme = useTheme() as ThemeType;

    return <Row justifyContent={'flex-end'} alignItems={'center'}>
        <Col>
            <Button colorType={'secondary'}
                    style={{margin: '0 20px 10px 0', color: theme.colors.light}}
                    onClick={onCancelClick}>
                <SpanButton>{intl.formatMessage({
                    id: 'CancelButtonLabel',
                    defaultMessage: 'Annuler'
                })}
                </SpanButton>
            </Button>
            <Button loading={loading} type={'submit'} colorType={'primary'} icon={saveIcon} disabled={disabled}>
                <SpanButton>{submitButtonText}</SpanButton>
            </Button>
        </Col>
    </Row>
}