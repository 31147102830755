import {Dispatch, FC, SetStateAction, useEffect, useMemo, useState} from "react";
import {
    BaseButton,
    Col, SelectOption,
    SpanSubtitle1,
    ThemeType
} from "@linkeo.com/ui-lib-react";
import {useTheme} from "styled-components";
import {FormattedMessage, useIntl} from "react-intl";
import {NavSearchInput} from "../../commons/components/nav-search-input";
import {Nav} from "../../commons/components/nav";
import {NavFilter} from "../../commons/components/nav-filter";
import {NavCalendarSelect} from "./nav-calendar-select";

interface AgendasNavProps {
    date: string;
    setDate: Dispatch<SetStateAction<string>>;
    searchValue: string;
    setSearchValue: Dispatch<SetStateAction<string>>;
    agendas: string[];
    selectedAgenda: string | undefined;
    setSelectedAgenda: Dispatch<SetStateAction<string | undefined>>
}

export const AgendasNav: FC<AgendasNavProps> = ({
                                                    date,
                                                    setDate,
                                                    setSearchValue,
                                                    searchValue,
                                                    agendas,
                                                    selectedAgenda,
                                                    setSelectedAgenda
                                                }) => {
    const theme = useTheme() as ThemeType;
    const intl = useIntl();
    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
    const agendasOptions: SelectOption<string>[] = useMemo(() => {
            return [{
                value: 'all',
                label: intl.formatMessage({
                    id: 'AgendasNavFilterItemAll',
                    defaultMessage: 'Planning : Tous ({number, plural, =0 {0 agenda} one {1 agenda} other {# agendas}})'
                }, {number: agendas.length}),
                uid: 'uidAll'
            }, ...agendas.map((agenda, index) => {
                return {
                    value: agenda,
                    label: intl.formatMessage({
                        id: 'AgendasNavFilterItem',
                        defaultMessage: 'Planning : {agenda}'
                    }, {agenda}),
                    uid: 'uid' + agenda + index + 1
                }
            })]
        }, [agendas, intl]
    )

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        }
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    return <Nav alignItems={'center'}>
        {windowWidth > 1200 && <Col
            style={{borderRight: `1px solid ${theme.colors.grey80}`, margin: '-18px 0', padding: '18px 20px 18px 0'}}>
            <BaseButton onClick={() => setDate(new Date().toString())}>
                <SpanSubtitle1 style={{fontWeight: 700, color: theme.colors.grey40}}>
                    <FormattedMessage id={'AgendasPageNavCurrentDayButtonLabel'} defaultMessage={'Aujourd\'hui'}/>
                </SpanSubtitle1>
            </BaseButton>
        </Col>}
        <NavCalendarSelect date={date} setDate={setDate} small={windowWidth <= 998} showWeek={!!selectedAgenda}/>
        <NavFilter label={intl.formatMessage({id: 'AgendasNavFilterTitle', defaultMessage: 'Planning:'})}
                   options={agendasOptions}
                   selected={selectedAgenda
                       ? intl.formatMessage({
                           id: 'AgendasNavFilterContent',
                           defaultMessage: 'de {agenda}'
                       }, {agenda: selectedAgenda})
                       : intl.formatMessage({id: 'AgendasNavFilterContentAll', defaultMessage: 'de Tous'})}
                   onFilterClick={(value) => value === 'all' ? setSelectedAgenda(undefined) : setSelectedAgenda(value)}/>
        {windowWidth > 600 && <NavSearchInput searchValue={searchValue}
                         placeholder={intl.formatMessage({
                             id: 'AgendasNavSearchInputPlaceholder',
                             defaultMessage: 'Recherche'
                         })}
                         setSearchValue={setSearchValue}/>}
    </Nav>
}