import {FC, useState} from "react";
import {PageWrapper, PageWrapperSubHeader} from "../commons/components/page-wrapper";
import {AgendasNav} from "../agendas/components/agendas-nav";
import {AgendasTable} from "../agendas/components/agendas-table";
import {getNextDate, getPreviousDate, isSameWeek, setHoursDate} from "../utils/date";
import {AppointmentFormDialog} from "../agendas/components/appointment-form-dialog";
import {useParams} from "react-router";
import {Service} from "../agendas/agendas-types";
import {AppointmentDialog, Customer} from "../agendas/components/appointment-dialog";
import {DeleteEventModal} from "../agendas/components/delete-event-modal";
import {TransferEventModal} from "../agendas/components/transfer-event-modal";

interface Event {
    available: boolean,
    notes: string,
    allDay: boolean,
    from: string,
    to: string,
    isDone: boolean,
    reminderSms: boolean,
    reminderEmail: boolean,
    service: Service | undefined,
    spots: number,
    lastName: string,
    firstName: string,
    email: string,
    phone: string,
    agenda: string
}

export const DateAgendasPage: FC = () => {
    const {editDate} = useParams();
    const [date, setDate] = useState<string>(editDate ? new Date(editDate).toString() : new Date().toString());
    const [searchValue, setSearchValue] = useState<string>('')
    const [filteredAgenda, setFilteredAgenda] = useState<string | undefined>();
    const [openAppointmentFormModal, setOpenAppointmentFormModal] = useState<boolean>(false);
    const [agendaServicesList, setAgendaServicesList] = useState<{ label: string, value: Service }[]>([]);
    const [events, setEvents] = useState<Event[]>([]);
    const [selectedDate, setSelectedDate] = useState<string | undefined>();
    const [selectedAgenda, setSelectedAgenda] = useState<string>('');
    const [openAppointmentModal, setOpenAppointmentModal] = useState<boolean>(false);
    const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
    const [openTransferModal, setOpenTransferModal] = useState<boolean>(false);
    const [selectedCustomerEvent, setSelectedCustomerEvent] = useState<Customer>();

    const onCloseModal = () => {
        setOpenAppointmentFormModal(false);
        setSelectedDate(undefined);
        setSelectedAgenda('');
        setAgendaServicesList([]);
    }

    return <PageWrapper isLoading={false}>
        <PageWrapperSubHeader>
            <AgendasNav date={date} setDate={setDate}
                        searchValue={searchValue} setSearchValue={setSearchValue}
                        selectedAgenda={filteredAgenda}
                        setSelectedAgenda={(value) => {
                            setFilteredAgenda(value);
                            setDate(new Date().toString())
                        }}
                        agendas={['Amandine', 'Jonathan', 'Laurence']}/>
        </PageWrapperSubHeader>
        <AgendasTable
            agendas={filteredAgenda ? undefined : ['Amandine', 'Jonathan', 'Laurence']}
            date={date}
            locale={'fr-FR'}
            onPreviousDateClick={() => setDate(getPreviousDate(date, filteredAgenda ? 7 : 1).toString())}
            onNextDateClick={() => setDate(getNextDate(date, filteredAgenda ? 7 : 1).toString())}
            onAgendaClick={(hour, selectedWeekDate, agenda) => {
                agenda && setSelectedAgenda(agenda);
                selectedWeekDate && setSelectedDate(setHoursDate(selectedWeekDate.toString(), parseInt(hour.substring(0, 3)), parseInt(hour.substring(3))));
                setDate(setHoursDate(date, parseInt(hour.substring(0, 3)), parseInt(hour.substring(3))))
                setAgendaServicesList([
                    {
                        label: 'AQUAMIX',
                        value: {id: 'fakeId1', title: 'AQUAMIX', spots: 4, duration: 30, color: '#f7f7f7'}
                    },
                    {
                        label: 'AQUAGYM',
                        value: {id: 'fakeId2', title: 'AQUAGYM', spots: 8, duration: 45, color: '#c35a15'}
                    },
                    {
                        label: 'Gymnastique',
                        value: {id: 'fakeId3', title: 'Gymnastique', spots: 5, duration: 60, color: '#0072ae'}
                    }
                ]);
                setOpenAppointmentFormModal(true)
            }}
            onEventClick={() => {
                setOpenAppointmentModal(true)
            }}
            events={filteredAgenda
                ? events.filter(el => el.agenda === filteredAgenda && isSameWeek(el.from, date))
                : events.filter(el => new Date(el.from).toDateString() === new Date(date).toDateString())}
        />
        <AppointmentFormDialog
            onClose={onCloseModal}
            onSubmit={(newEvent) => {
                setEvents([...events, {...newEvent, agenda: filteredAgenda ? filteredAgenda : selectedAgenda}]);
                onCloseModal();
            }}
            servicesList={agendaServicesList}
            date={selectedDate ? selectedDate : date}
            selectedCustomerEvent={selectedCustomerEvent}
            show={openAppointmentFormModal}/>
        <AppointmentDialog
            date={new Date(date)}
            id={'9a4ffe15963586200a9ca0d9f0f55d088c296139'}
            from={'10h30'}
            to={'11h30'}
            service={'Coupe coiffage'}
            max={3}
            spot={2}
            customers={[{
                firstName: 'Prénom',
                lastName: 'Nom',
                email: 'test@email.com',
                phone: '0123456789',
                done: true,
                subscribed: true,
                spots: 1,
                adminNotes: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
            }, {
                firstName: 'Hello',
                lastName: 'World',
                email: 'hello@world.com',
                phone: '0147852369',
                done: true,
                subscribed: false,
                spots: 1
            }]}
            show={openAppointmentModal}
            onAddClick={() => {
                setOpenAppointmentModal(false);
                setOpenAppointmentFormModal(true)
            }}
            onDeleteClick={() => {
                setOpenAppointmentModal(false);
                setOpenDeleteModal(true)
            }}
            onEditClick={() => {
                setOpenAppointmentModal(false);
                setSelectedCustomerEvent({
                    firstName: 'Hello',
                    lastName: 'World',
                    email: 'hello@world.com',
                    phone: '0147852369',
                    done: true,
                    subscribed: false,
                    spots: 1
                })
                setOpenAppointmentFormModal(true)
            }}
            onTransferClick={() => {
                setOpenAppointmentModal(false)
                setOpenTransferModal(true)
            }}
            onClose={() => setOpenAppointmentModal(false)}/>
        <DeleteEventModal
            show={openDeleteModal}
            firstName={'Prenom'}
            lastName={'Nom'}
            spot={2}
            onDeleteClick={() => {
            }}
            onClose={() => setOpenDeleteModal(false)}/>
        <TransferEventModal
            show={openTransferModal}
            locale={'fr-FR'}
            date={setHoursDate(date, 16, 0, 0, 0)}
            agendas={['Eugénie', 'Laurence', 'Jonathan', 'Michel']}
            firstName={'Prenom'}
            lastName={'Nom'}
            onClose={() => setOpenTransferModal(false)}/>
    </PageWrapper>
}