import {FC} from "react";
import styled, {useTheme} from "styled-components";
import {BaseButton, getTypo, IconName, Svg, ThemeType} from "@linkeo.com/ui-lib-react";
import {getDayArray, getDuration, getLocalDuration, getNextDate} from "../../utils/date";
import {Locale} from "../../providers/intl-provider";
import {Service} from "../agendas-types";
import {FormattedDate, useIntl} from "react-intl";
import {getContrastYIQ} from "../../utils/color";

interface Event {
    available: boolean,
    notes: string,
    allDay: boolean,
    from: string,
    to: string,
    isDone: boolean,
    reminderSms: boolean,
    reminderEmail: boolean,
    service: Service | undefined,
    spots: number,
    lastName: string,
    firstName: string,
    email: string,
    phone: string,
    agenda: string
}

const ButtonWrapper = styled.th`
    position: sticky;
    top: 0;
    width: 4%;
    border-right: 1px solid ${({theme}) => theme.colors.grey90};

    &:hover {
        background-color: ${({theme}) => theme.colors.grey90};

        > button > svg {
            fill: ${({theme}) => theme.colors.dark}
        }
    }
`

interface TableButtonProps {
    icon: IconName;
    onClick: () => void;
}

const TableButton: FC<TableButtonProps> = ({icon, onClick}) => {
    const theme = useTheme() as ThemeType;

    return <ButtonWrapper>
        <BaseButton onClick={onClick}>
            <Svg icon={icon} fill={theme.colors.grey60} width={22} height={22}/>
        </BaseButton>
    </ButtonWrapper>
}

const TableWrapper = styled.div`
    width: auto;
    margin: 0 -48px;
    z-index: 1;
    padding: 0 0 25px 0;

    @media screen and (max-width: 760px) {
        margin: 0 -16px;
    }
`
const Table = styled.table`
    width: 100%;
    background-color: ${({theme}) => theme.colors.grey95};
    border-spacing: 0;
    border-collapse: collapse;

    td {
        padding: 0;
    }
`
const THeadCell = styled.th<{ color?: string }>`
    ${getTypo('subtitle1')}
    text-transform: uppercase;
    font-weight: 600;
    color: ${({color, theme}) => color ? color : theme.colors.dark};
    border-right: 1px solid ${({theme}) => theme.colors.grey90};
    padding: 15px 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 1em;
    position: sticky;
    top: 0;
`
const HourTr = styled.td`
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    font-weight: 600;
    color: ${({theme}) => theme.colors.grey60};
    border-bottom: 1px solid ${({theme}) => theme.colors.grey90};
    text-align: center;
`
const HourTd = styled.td<{ disabled: boolean }>`
    position: relative;
    height: 45px;
    padding: 0;
    border-bottom: 1px solid ${({theme}) => theme.colors.grey90};
    background-color: ${({theme, disabled}) => disabled ? theme.colors.grey80 : theme.colors.grey95};
    cursor: ${({disabled}) => disabled ? 'auto' : 'pointer'};

    &:hover {
        background-color: ${({theme, disabled}) => disabled ? theme.colors.grey80 : theme.colors.grey90};
    }
`
const EventDiv = styled.div<{ color?: string, duration: number }>`
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 5px;
    z-index: 2;
    height: ${({duration}) => duration * 100 / 30}%;
    background-color: ${({color, theme}) => color ?? theme.colors.light};
    color: ${({
                  color,
                  theme
              }) => color ? getContrastYIQ(color, theme.colors.light, theme.colors.dark) : theme.colors.dark};
    font-family: "Open Sans", sans-serif;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 13px;
    font-weight: 600;

    &:hover {
        background-color: ${({theme}) => theme.colors.grey90};
        color: ${({theme}) => theme.colors.dark};
        box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.35);
    }
`

interface AgendasTableProps {
    agendas?: string[];
    locale: Locale;
    onPreviousDateClick: () => void;
    onNextDateClick: () => void;
    onAgendaClick: (hour: string, date?: Date, agenda?: string) => void;
    events: Event[];
    date: string;
    onEventClick: () => void
}

export const AgendasTable: FC<AgendasTableProps> = ({
                                                        agendas,
                                                        locale,
                                                        onNextDateClick,
                                                        onPreviousDateClick,
                                                        onAgendaClick,
                                                        date: currentDate,
                                                        events,
                                                        onEventClick
                                                    }) => {
    const theme = useTheme() as ThemeType;
    const intl = useIntl();

    return <TableWrapper>
        <Table>
            <thead style={{borderBottom: `1px solid ${theme.colors.grey90}`}}>
            <tr>
                <TableButton icon={'left'} onClick={onPreviousDateClick}/>
                {agendas
                    ? agendas.map((agenda, index) => {
                        return <THeadCell key={index} color={theme.colors.grey60}>{agenda}</THeadCell>
                    })
                    : Array.from({length: 7}, (_, index) => {
                        const date = new Date(currentDate);
                        date.setDate(date.getDate() + index);
                        return <THeadCell key={index}>
                            <FormattedDate value={date} weekday={'long'} day={'2-digit'}/>
                        </THeadCell>
                    })
                }
                <TableButton icon={'right'} onClick={onNextDateClick}/>
            </tr>
            </thead>
            <tbody>
            {getDayArray(30, locale, 8).map((hour, index) => <tr key={index}>
                <HourTr>{hour.substring(0, 5).replace(':', 'h')}</HourTr>
                {agendas
                    ? agendas.map((agenda, i) => {
                        const event = events.filter(el => (el.agenda === agenda) && (new Date(el.from).toTimeString().substring(0, 8) === hour));
                        return <HourTd key={i}
                                       disabled={index === 10 || hour.substring(0, 2) === '08' || i === 0}
                                       onClick={() => index === 10 || hour.substring(0, 2) === '08' || i === 0 || event.length > 0 ? {} : onAgendaClick(hour, undefined, agenda)}>
                            {event.length > 0 && <EventDiv color={event[0].service?.color}
                                                           onClick={onEventClick}
                                                           duration={event[0].service?.duration || getDuration(event[0].from, event[0].to)}>
                                {event.length === 1
                                    ? event[0].firstName + ' ' + event[0].lastName
                                    : intl.formatMessage({
                                        id: 'AppointmentDialogMaxSpotCaption',
                                        defaultMessage: '{spot}/{max} personne(s)'
                                    }, {
                                        spot: event.reduce((acc, cur) => acc + cur.spots, 0),
                                        max: event[0].service?.spots
                                    })}
                                <br/>
                                {getLocalDuration(event[0].service?.duration || getDuration(event[0].from, event[0].to))}
                            </EventDiv>}
                        </HourTd>
                    })
                    : Array.from({length: 7}, (_, i) => {
                        return <HourTd key={i} disabled={index === 8 || hour.substring(0, 2) === '08' || i === 2}
                                       onClick={() => index === 10 || hour.substring(0, 2) === '08' || i === 2
                                           ? {}
                                           : onAgendaClick(hour, i === 0 ? undefined : getNextDate(currentDate, i))}>
                        </HourTd>
                    })}
                <HourTr>{hour.substring(0, 5).replace(':', 'h')}</HourTr>
            </tr>)}
            </tbody>
        </Table>
    </TableWrapper>
}