// @ts-ignore
export const timezoneList = Intl.supportedValuesOf('timeZone')

export const getTimeZoneOffset = (timezone: string) => {
    let utcDate = new Date(new Date().toLocaleString('en-US', {timeZone: 'UTC'}))
    let timezoneDate = new Date(new Date().toLocaleString('en-US', {timeZone: timezone}))
    const timezoneOffset = (utcDate.getTime() - timezoneDate.getTime()) / 60000;
    const offsetHours = Math.floor(Math.abs(timezoneOffset) / 60).toString().padStart(2, '0');
    const offsetMins = Math.floor(Math.abs(timezoneOffset) % 60).toString().padStart(2, '0');
    return `${timezoneOffset <= 0 ? '+' : '-'}${offsetHours}:${offsetMins}`
}