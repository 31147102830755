import {Dispatch, FC, SetStateAction, useMemo} from "react";
import {SelectOption} from "@linkeo.com/ui-lib-react";
import {useIntl} from "react-intl";
import {NavSearchInput} from "../../commons/components/nav-search-input";
import {NavFilter} from "../../commons/components/nav-filter";
import {Nav} from "../../commons/components/nav";

interface GiftcardNavProps {
    setSearchValue: Dispatch<SetStateAction<string>>;
    searchValue: string;
    onFilterClick: (value: string) => void;
}

export const GiftcardNav: FC<GiftcardNavProps> = ({setSearchValue, searchValue, onFilterClick}) => {
    const intl = useIntl();
    const statusOptions: SelectOption<string>[] = useMemo(() => [
        {
            value: 'all',
            label: intl.formatMessage({id: 'GiftcardNavStatusFilterAll', defaultMessage: 'Tous'})
        },
        {
            value: 'paid',
            label: intl.formatMessage({id: 'GiftcardNavStatusFilterValid', defaultMessage: 'Valables'})
        },
        {
            value: 'expired',
            label: intl.formatMessage({id: 'GiftcardNavStatusFilterExpired', defaultMessage: 'Expirés'})
        },
        {
            value: 'used',
            label: intl.formatMessage({id: 'GiftcardNavStatusFilterUsed', defaultMessage: 'Déjà utilisés'})
        }
    ], [intl])

    return <Nav alignItems={'center'}>
        <NavFilter label={intl.formatMessage({id: 'GiftcardNavStatusFilterTitle', defaultMessage: 'Statut: '})}
                   options={statusOptions} onFilterClick={onFilterClick}/>
        <NavSearchInput searchValue={searchValue}
                        setSearchValue={setSearchValue}
                        placeholder={intl.formatMessage({
                            id: 'GiftcardNavSearchInputPlaceholder',
                            defaultMessage: 'N°, Nom, Email, Tél, Prestation'
                        })}/>
    </Nav>
}