import {FC, useState} from "react";
import {Container} from "@linkeo.com/ui-lib-react";
import {useIntl} from "react-intl";
import {PageWrapper} from "../../commons/components/page-wrapper";
import {ParamsCard} from "../../params/components/params-card";
import {ParamsClosuresCalendarForm} from "../../params/components/closures/params-closures-calendar-form";

export const ParamsClosuresPage: FC = () => {
    const intl = useIntl();
    const [isLoading] = useState<boolean>(false)

    return <PageWrapper isLoading={isLoading}>
        <Container size={'lg'}>
            <ParamsCard
                title={intl.formatMessage({id: 'ParamsClosuresPageTitle', defaultMessage: 'Fermetures exceptionnelles'})}
                subtitle={intl.formatMessage({id: 'ParamsClosuresPageSubTitle', defaultMessage: 'Renseignez ci-dessous les dates de fermeture exceptionnelle.'})}>
                <ParamsClosuresCalendarForm locale={'fr-FR'}/>
            </ParamsCard>
        </Container>
    </PageWrapper>
}