import React, {FC, useEffect, useState} from "react";
import {Container, useToaster} from "@linkeo.com/ui-lib-react";
import {useParams} from "react-router";
import {AxiosError} from "axios";
import {PageWrapper} from "../../commons/components/page-wrapper";
import {ServiceEditForm,} from "../../services/components/service-edit-form";
import {routeParamsServices} from "../../routes";
import {useAgendasStore} from "../../agendas/agendas-store";
import {useConfigurationStore} from "../../params/configuration-store";
import {useOptionsStore} from "../../options/options-store";
import {useServicesStore} from "../../services/services-store";
import {ServicePayload} from "../../services/services-types";
import {useIntl} from "react-intl";
import { uploaderApi, ROOT_PATH } from '../../config/config';
import {Error404} from "../../commons/components/404-error";

const serviceType = 'service';

export const ServicesEditPage: FC<{ codeBouton: string, token: string }> = ({codeBouton, token}) => {
    const {id} = useParams();
    const intl = useIntl();
    const toast = useToaster();
    const {fetchAgendasList, agendasList} = useAgendasStore();
    const {fetchAllOptions, optionsList} = useOptionsStore();
    const {
        fetchCategoryList,
        categoryList,
        fetchService,
        resetService,
        service,
        editService,
        error
    } = useServicesStore();
    const {config, fetchData} = useConfigurationStore();
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true)

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        const signal = controller.signal;
        setLoading(true);
        Promise.allSettled([
            id ? fetchService(id) : Promise.resolve(null),
            fetchData(signal),
            fetchCategoryList(signal),
            fetchAgendasList(signal),
            fetchAllOptions(serviceType, signal)
        ]).catch(e => {
            if (isMounted) {
                console.error(e);
                controller.abort()
            }
        }).finally(() => isMounted && setLoading(false))
        return () => {
            isMounted = false;
            controller.abort();
            resetService()
        }
    }, [fetchAgendasList, fetchAllOptions, fetchData, fetchCategoryList, id, fetchService, resetService])

    const onServiceSave = async (service: ServicePayload) => {
        setButtonLoading(true)
        try {
            await editService(service, id);
            resetService();
            const basePath = ROOT_PATH.length > 1 ? ROOT_PATH : '';
            window.location.pathname = basePath + routeParamsServices
        } catch (e) {
            console.error(e);
            if ((e as AxiosError).response?.status === 400) {
                toast(intl.formatMessage({
                    id: 'serviceEditPagePayloadError',
                    defaultMessage: 'Vous devez sélectionner au moins un planning à associer à cette prestation.'
                }))
            } else {
                toast(intl.formatMessage({id: 'errorMessage', defaultMessage: 'Une erreur est survenue'}))
            }
        } finally {
            setButtonLoading(false)
        }
    }

    return <PageWrapper isLoading={loading}>
        {!error ? <Container size={'lg'}>
            <ServiceEditForm
                onCloseClick={() => {
                    resetService();
                    const basePath = ROOT_PATH.length > 1 ? ROOT_PATH : '';
                    window.location.pathname = basePath + routeParamsServices;
                }}
                agendas={agendasList}
                form={service}
                options={optionsList}
                loading={buttonLoading}
                giftcardActive={config.giftcard.active}
                paymentActive={config.appointment.paymentActive}
                categories={categoryList}
                onSubmit={(form) => onServiceSave({...form, category: form.category})}
                codeBouton={codeBouton}
                token={token}
                apiUri={uploaderApi}
                paymentPercent={config.paymentPercent}
            />
        </Container> : <Error404/>}
    </PageWrapper>
}