import React, {FC, FormEvent, useEffect, useMemo, useState} from "react";
import {
    Button,
    Col,
    Dialog,
    Radio,
    Row,
    SelectOption,
    SpanButton,
    SpanSubtitle1,
    Switch,
    ThemeType
} from "@linkeo.com/ui-lib-react";
import {FormattedDate, FormattedMessage, useIntl} from "react-intl";
import {useTheme} from "styled-components";
import {setHoursDate} from "../../utils/date";
import {UnavailableAppointmentForm} from "./unavailable-appointment-form";
import {AvailableAppointmentForm} from "./available-appointment-form";
import {Service} from "../agendas-types";
import {DateField, hour, TimeField} from "./from-to-select";

interface Customer {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    done: boolean;
    subscribed: boolean;
    spots: number;
    adminNotes?: string;
}

interface FormProps {
    available: boolean,
    notes: string,
    allDay: boolean,
    from: string,
    to: string,
    isDone: boolean,
    reminderSms: boolean,
    reminderEmail: boolean,
    service: Service | undefined,
    spots: number,
    lastName: string,
    firstName: string,
    email: string,
    phone: string,
    agenda: string
}

interface AppointmentFormDialogProps {
    onClose: () => void;
    show: boolean;
    date: string;
    onSubmit: (form: FormProps) => void;
    servicesList: SelectOption<Service>[];
    selectedCustomerEvent?: Customer
}

export const AppointmentFormDialog: FC<AppointmentFormDialogProps> = ({
                                                                          onClose,
                                                                          show,
                                                                          date,
                                                                          onSubmit,
                                                                          servicesList,
                                                                          selectedCustomerEvent
                                                                      }) => {
    const intl = useIntl();
    const theme = useTheme() as ThemeType;
    const defaultAppointment = useMemo(() => {
        const to = new Date(date)
        servicesList.length > 0
            ? to.setMinutes(new Date(date).getMinutes() + servicesList[0].value.duration)
            : to.setMinutes(new Date(date).getMinutes() + 30);
        return {
            available: true,
            notes: '',
            allDay: false,
            from: date,
            to: to.toString(),
            isDone: false,
            reminderSms: false,
            reminderEmail: false,
            service: servicesList.length > 0 ? servicesList[0].value : undefined,
            spots: 1,
            lastName: '',
            firstName: '',
            email: '',
            phone: '',
            agenda: ''
        }
    }, [date, servicesList])
    const [appointmentForm, setAppointmentForm] = useState(defaultAppointment);
    const spotsList: SelectOption<number>[] = useMemo(() => {
        return Array.from(
            {length: servicesList.find(el => el.value.title === appointmentForm.service?.title)?.value.spots || 1},
            (_, i) => {
                return {
                    label: intl.formatMessage({
                        id: 'appointmentDialogServiceSpotsLabel',
                        defaultMessage: '{value} personne(s)'
                    }, {value: i + 1}),
                    value: i + 1
                }
            })
    }, [appointmentForm.service, intl, servicesList])

    useEffect(() => {
        setAppointmentForm((prevState) => {
            const to = new Date(date)
            servicesList.length > 0
                ? to.setMinutes(new Date(date).getMinutes() + servicesList[0].value.duration)
                : to.setMinutes(new Date(date).getMinutes() + 30);
            return {
                ...prevState,
                from: date,
                to: to.toString(),
                service: servicesList.length > 0 ? servicesList[0].value : undefined
            }
        })
        return () => setAppointmentForm(defaultAppointment)
    }, [date, defaultAppointment, servicesList])

    const _onSubmit = (e: FormEvent) => {
        onSubmit(appointmentForm)
        e.preventDefault();
        e.stopPropagation();
    }

    const onTimeSelect = (dateField: DateField, timeField: TimeField, value: string) => {
        if (timeField === hour) {
            setAppointmentForm({
                ...appointmentForm,
                [dateField]: setHoursDate(date, parseInt(value), new Date(appointmentForm[dateField]).getMinutes())
            })
        } else {
            setAppointmentForm({
                ...appointmentForm,
                [dateField]: setHoursDate(date, new Date(appointmentForm[dateField]).getHours(), parseInt(value))
            })
        }
    }

    const onServiceSelect = (service: Service) => {
        const newDate = new Date(date);
        const duration = servicesList.find(el => el.value.title === service.title)?.value.duration
        newDate.setMinutes(new Date(date).getMinutes() + (duration ? duration : 30))
        setAppointmentForm({
            ...appointmentForm,
            service,
            spots: 1,
            to: newDate.toString()
        })
    }

    return <Dialog onClose={onClose} show={show} size={'md'}
                   title={intl.formatMessage(({
                       id: 'appointmentDialogTitle',
                       defaultMessage: 'Configuration du rendez-vous'
                   }))}
                   footer={<>
                       <Button colorType={'secondary'} onClick={onClose}>
                           <SpanButton>
                               <FormattedMessage
                                   id={'CancelButtonLabel'}
                                   defaultMessage={'Annuler'}
                               />
                           </SpanButton>
                       </Button>
                       <Button type={'submit'}
                               form={'appointmentForm'}
                               backgroundColor={theme.colors.grey40}
                               borderColor={theme.colors.grey40}>
                           <SpanButton>
                               <FormattedMessage
                                   id={'OkButtonLabel'}
                                   defaultMessage={'Ok'}
                               />
                           </SpanButton>
                       </Button>
                   </>}>
        <form onSubmit={_onSubmit} id={'appointmentForm'}
              style={{maxHeight: `calc(${window.innerHeight}px - 280px)`, overflowY: 'auto'}}>
            <SpanSubtitle1 style={{textTransform: 'capitalize', fontWeight: 700}}>
                {selectedCustomerEvent
                    ? selectedCustomerEvent.firstName + ' ' + selectedCustomerEvent.lastName
                    : <FormattedDate value={date} dateStyle={'full'}/>}
            </SpanSubtitle1>
            <Row style={{maxWidth: '100%'}}>
                <Col>
                    <Radio name={'AppointmentDialogRadioOption'}
                           disabled={!!selectedCustomerEvent}
                           onChange={() => setAppointmentForm({...defaultAppointment, available: true})}
                           value={appointmentForm.available}>
                        <FormattedMessage id={'AppointmentDialogRadioOptionAvailable'} defaultMessage={'Disponible'}/>
                    </Radio>
                </Col>
                <Col>
                    <Radio name={'AppointmentDialogRadioOption'}
                           disabled={!!selectedCustomerEvent}
                           onChange={() => setAppointmentForm({...defaultAppointment, available: false})}
                           value={!appointmentForm.available}>
                        <FormattedMessage id={'AppointmentDialogRadioOptionNotAvailable'}
                                          defaultMessage={'Indisponible'}/>
                    </Radio>
                </Col>
            </Row>
            {appointmentForm.available
                ? <AvailableAppointmentForm isDone={appointmentForm.isDone}
                                            disabled={appointmentForm.allDay}
                                            fromDate={new Date(appointmentForm.from)}
                                            onTimeSelect={onTimeSelect}
                                            toDate={new Date(appointmentForm.to)}
                                            notes={appointmentForm.notes}
                                            reminderSms={appointmentForm.reminderSms}
                                            reminderEmail={appointmentForm.reminderEmail}
                                            onCheckboxChange={(name, value) => setAppointmentForm({
                                                ...appointmentForm,
                                                [name]: value
                                            })}
                                            servicesList={servicesList}
                                            selectedService={servicesList.find(el => el.value.title === appointmentForm.service?.title)}
                                            onServiceSelect={onServiceSelect}
                                            spotsList={spotsList}
                                            spots={appointmentForm.spots}
                                            onSpotsChange={(spots) => setAppointmentForm({...appointmentForm, spots})}
                                            onInputChange={(name, value) => setAppointmentForm({
                                                ...appointmentForm,
                                                [name]: value
                                            })}
                                            lastName={appointmentForm.lastName}
                                            firstName={appointmentForm.firstName}
                                            email={appointmentForm.email}
                                            phone={appointmentForm.phone}
                />
                : <UnavailableAppointmentForm notes={appointmentForm.notes}
                                              onNotesChange={(notes) => setAppointmentForm({...appointmentForm, notes})}
                                              disabled={appointmentForm.allDay}
                                              fromDate={new Date(appointmentForm.from)}
                                              toDate={new Date(appointmentForm.to)}
                                              onTimeSelect={onTimeSelect}
                />}
            <Switch value={appointmentForm.allDay}
                    disabled={!!selectedCustomerEvent}
                    onChange={() => setAppointmentForm({...appointmentForm, allDay: !appointmentForm.allDay})}
                    onLabel={intl.formatMessage({
                        id: 'AppointmentDialogAllDaySwitchLabel',
                        defaultMessage: 'Toute la journée'
                    })}
                    onIcon={'check'}
                    offIcon={'cross'}
                    color={theme.colors.grey40}
            />
        </form>
    </Dialog>
}