import {ChangeEvent, Dispatch, FC, SetStateAction} from "react";
import {BaseInput, Col, ThemeType} from "@linkeo.com/ui-lib-react";
import {useTheme} from "styled-components";

interface NavSearchInputProps {
    placeholder: string;
    searchValue: string;
    setSearchValue: Dispatch<SetStateAction<string>>
}

export const NavSearchInput: FC<NavSearchInputProps> = ({placeholder, setSearchValue, searchValue}) => {
    const theme = useTheme() as ThemeType;

    const onSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value)
    }

    return <Col style={{
        borderLeft: `1px solid ${theme.colors.grey80}`,
        margin: '-10px 0 -10px auto',
        padding: '10px 20px'
    }}>
        <BaseInput
            placeholder={placeholder}
            onChange={onSearchChange}
            value={searchValue}
            style={{backgroundColor: theme.colors.grey90, maxWidth: '300px', minWidth: '90px'}}
        />
    </Col>
}