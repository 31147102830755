import {Dispatch, FC, SetStateAction, useState} from "react";
import {Input, SelectOption, SimpleSelect} from "@linkeo.com/ui-lib-react";
import {useIntl} from "react-intl";

const defaultServices = [
    {
        id: 'az0eGGnbv456PM132I',
        title: 'Coupe coiffage',
        price: 30
    },
    {
        id: 'hgYH123POmnj58TGS',
        title: 'Rasage à l\'ancienne',
        price: 24
    },
    {
        id: 'jhgYY123Pmgt85GTQZ',
        title: 'Cours d\'espagnol',
        price: 45
    }
]

interface Service {
    id: string;
    title: string;
    price: number
}

export interface FormProps {
    code: string;
    service?: Service;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    expirationDate: string;
    purchaseDate: string
}

interface GiftcardFormProps {
    form: FormProps;
    setForm: Dispatch<SetStateAction<FormProps>>;
}

export const GiftcardForm: FC<GiftcardFormProps> = ({form, setForm}) => {
    const intl = useIntl();
    const selectOptions: SelectOption<Service>[]= defaultServices.map(el => {
        return {
            label: el.title,
            value: el
        }
    })
    const [selectedOption, setSelectedOption] = useState<SelectOption<typeof defaultServices[0]>>()

    const onServiceSelect = (service: SelectOption<Service>) => {
        setSelectedOption(service);
        setForm({...form, service: service.value})
    }

    return <>
        <SimpleSelect
            options={selectOptions}
            selected={selectedOption}
            onSelect={onServiceSelect}
            required
            label={intl.formatMessage({id: 'GiftcardFormServiceInputLabel', defaultMessage: 'Préstation à offrir'})}
        />
        <Input
            style={{width: '100%'}}
            type={'text'}
            required
            value={form.firstName}
            onChange={(firstName) => setForm({...form, firstName})}
            label={intl.formatMessage({id: 'GiftcardFormFirstnameInputLabel', defaultMessage: 'Prénom du client'})}/>
        <Input
            style={{width: '100%'}}
            type={'text'}
            required
            value={form.lastName}
            onChange={(lastName) => setForm({...form, lastName})}
            label={intl.formatMessage({id: 'GiftcardFormLastNameInputLabel', defaultMessage: 'Nom du client'})}/>
        <Input
            style={{width: '100%'}}
            type={'email'}
            required
            value={form.email}
            onChange={(email) => setForm({...form, email})}
            label={intl.formatMessage({id: 'GiftcardFormEmailInputLabel', defaultMessage: 'Adresse Email du client'})}/>
        <Input
            style={{width: '100%'}}
            type={'tel'}
            required
            value={form.phone}
            onChange={(phone) => setForm({...form, phone})}
            label={intl.formatMessage({id: 'GiftcardFormPhoneInputLabel', defaultMessage: 'Numéro de téléphone du client'})}/>
    </>
}