import React, {createContext, FC, useCallback, useContext, useMemo, useState} from "react";
import {useIntl} from "react-intl";
import {OptionsApi} from "./options-api";
import {servicesApi} from "../config/config";
import {Locale} from "../providers/intl-provider";
import {Option, OptionPayload, service} from "./options-types";

interface OptionsContextProps {
    option: OptionPayload;

    editOption(edit: OptionPayload, optionId: string | undefined): Promise<void>;

    resetOption(): void;

    optionsList: Option[];

    fetchOptionsList(type?: string): Promise<void>;

    loadMoreOptions(type?: string): Promise<boolean>;

    fetchAllOptions(type?: string, signal?: AbortSignal): Promise<void>;

    deleteOption(optionId: string): Promise<void>;

    fetchOption(optionId: string): Promise<void>;

    error: boolean;
}

const OptionsContext = createContext<OptionsContextProps>({
    option: {
        name: '',
        type: service,
        min: 0,
        countable: false,
        max: 1,
        maxExtra: 0,
        extraCharge: 0,
        maxFree: 0,
        choices: [],
        downPayment: 0
    },
    editOption: () => Promise.reject(new Error('Provider not initialized')),
    resetOption: () => {
    },
    optionsList: [],
    fetchOptionsList: () => Promise.reject(new Error('Provider not initialized')),
    fetchAllOptions: () => Promise.reject(new Error('Provider not initialized')),
    loadMoreOptions: () => Promise.reject(new Error('Provider not initialized')),
    deleteOption: () => Promise.reject(new Error('Provider not initialized')),
    fetchOption: () => Promise.reject(new Error('Provider not initialized')),
    error: false
})

export const useOptionsStore = () => useContext<OptionsContextProps>(OptionsContext)

interface OptionsStoreProps {
    token: string;
    codeBouton: string;
    locale: Locale;
}

export const OptionsStore: FC<OptionsStoreProps> = ({
                                                        children,
                                                        token,
                                                        locale,
                                                        codeBouton
                                                    }) => {
    const intl = useIntl();
    const api = useMemo<OptionsApi>(() => new OptionsApi(servicesApi), [])
    const defaultOption = useMemo<OptionPayload>(() => {
        return {
            name: '',
            type: service,
            min: 0,
            countable: false,
            max: 1,
            maxExtra: 0,
            extraCharge: 0,
            maxFree: 0,
            choices: [{
                id: '1',
                label: intl.formatMessage({id: 'OptionFormSeveralOptionsPlaceHolderName1', defaultMessage: 'Option 1'}),
                price: 11
            }, {
                id: '2',
                label: intl.formatMessage({id: 'OptionFormSeveralOptionsPlaceHolderName2', defaultMessage: 'Option 2'}),
                price: 7
            }],
            downPayment: 0
        }
    }, [intl]);

    const [option, setOption] = useState<OptionPayload>(defaultOption)
    const [optionsList, setOptionsList] = useState<Option[]>([]);
    const [error, setError] = useState<boolean>(false);

    const fetchOptionsList = useCallback(async (type?: string) => {
        const response = await api.getOptionsList(codeBouton, token, 10, 0, type)
        setOptionsList(response.result)
    }, [setOptionsList, api, codeBouton, token])

    const loadMoreOptions = async (type?: string) => {
        const response = await api.getOptionsList(codeBouton, token, 10, optionsList.length, type)
        setOptionsList([...optionsList, ...response.result])
        return (optionsList.length + response.count) >= response.total;
    }

    const fetchAllOptions = useCallback(async (type?: string, signal?: AbortSignal) => {
        let total;
        const limit = 20;
        let options: Option[] = [];
        do {
            const response = await api.getOptionsList(codeBouton, token, limit, options.length, type, undefined, signal);
            if (total === undefined){
                total = response.total
            }
            options = [...options, ...response.result]

        } while (options.length < total)
        setOptionsList(options)
    }, [api, token, codeBouton])

    const deleteOption = async (optionId: string) => {
        await api.deleteOption(codeBouton, optionId, token)
    }

    const fetchOption = useCallback(async (optionId: string) => {
        try {
            const response = await api.getOption(codeBouton, token, optionId)
            setOption(response)
        } catch (e) {
            console.error(e);
            setError(true)
        }
    }, [setOption, api, codeBouton, token])

    const editOption = async (edit: OptionPayload, optionId?: string) => {
        let response: Option
        if (optionId) {
            response = await api.updateOption(codeBouton, token, optionId, {...edit})
        } else {
            response = await api.createOption(codeBouton, {...edit}, token, locale)
        }
        setOption(response)
    }

    const resetOption = useCallback(() => {
        setOption(defaultOption);
        setError(false)
    }, [setOption, defaultOption])

    return <OptionsContext.Provider value={{
        option,
        editOption,
        resetOption,
        optionsList,
        fetchOptionsList,
        fetchAllOptions,
        loadMoreOptions,
        deleteOption,
        fetchOption,
        error
    }}>
        {children}
    </OptionsContext.Provider>
}