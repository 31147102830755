import {FC, useRef, useState} from "react";
import {
    BaseButton,
    Col, Dropdown,
    getTypo,
    Row, SpanButton, SpanSubtitle1, SquareButton, Switch,
    ThemeType,
} from "@linkeo.com/ui-lib-react";
import styled, {useTheme} from "styled-components";
import {FormattedMessage} from "react-intl";
import {Opening, OpeningKey} from "./agendas-availabilities-form";


const Select = styled.select`
    padding: 8px;
    outline: none;
    background-color: ${({theme}) => theme.input.backgroundColor};
    ${getTypo('input')};
    color: ${({theme}) => theme.colors.dark};
    text-transform: capitalize;
    border: 1px solid ${({theme}) => theme.input.borderColor};
    border-radius: ${({theme}) => theme.input.borderRadius};
`
const Label = styled.label`
    padding: 9px 3px;
    background-color: ${({theme}) => theme.input.backgroundColor};
    border: 1px solid ${({theme}) => theme.input.borderColor};
    ${getTypo('input')};
    color: ${({theme}) => theme.colors.grey60};
    border-radius: ${({theme}) => theme.input.borderRadius};
`
const OptionButton = styled(SquareButton)`
    &:hover {
        background-color: ${({theme}) => theme.colors.grey20} !important;
    }
`
const Container = styled.div`
    width: 195px;
    background-color: ${({theme}) => theme.colors.grey20};
    padding: 1px 14px 8px;
    color: ${({theme}) => theme.colors.grey60};
    border-bottom: 1px solid ${({theme}) => theme.colors.grey60};
`
const SpanDeleteButton = styled(SpanSubtitle1)`
    color: ${({theme}) => theme.colors.grey60};

    &:hover {
        color: ${({theme}) => theme.colors.light}
    }
`
const SwitchLabel = styled(SpanSubtitle1)`
    color: ${({theme}) => theme.colors.grey60};
    cursor: pointer;

    &:hover {
        color: ${({theme}) => theme.colors.light}
    }
`
const SmallRow = styled(Row)`
    margin: 0 0 20px;
    @media screen and (max-width: 770px) {
        margin: 20px 0 20px 20px
    }
`

interface ScheduleRowProps {
    index: number;
    daysList: string[];
    hoursList: string[];
    minutesList: string[];
    opening: Opening;
    onIsolatedDaySwitchChange: () => void;
    onContinuousDaySwitchChange: () => void;
    onSelectDayChange: (value: string, index: number) => void;
    onStartDayChange: (value: string, index: number) => void;
    onEndDayChange: (value: string, index: number) => void;
    onSelectedTimingChange: (value: string, index: number, i: number, key: OpeningKey) => void;
    onScheduleRowDeleteClick: () => void;
    onTimeIntervalAddClick: () => void;
    onTimeIntervalDeleteClick: (i: number) => void;
}

export const ScheduleRow: FC<ScheduleRowProps> = ({
                                                      index,
                                                      minutesList,
                                                      hoursList,
                                                      daysList,
                                                      opening,
                                                      onIsolatedDaySwitchChange,
                                                      onContinuousDaySwitchChange,
                                                      onSelectDayChange,
                                                      onEndDayChange,
                                                      onStartDayChange,
                                                      onSelectedTimingChange,
                                                      onScheduleRowDeleteClick,
                                                      onTimeIntervalAddClick,
                                                      onTimeIntervalDeleteClick
                                                  }) => {
    const theme = useTheme() as ThemeType;
    const [showOptions, setShowOptions] = useState<boolean>(false);
    const ref = useRef<null | HTMLDivElement>(null);

    return <div>
        <Row style={{padding: '30px 0'}}>
            {opening.day.length === 1 ? <Col columns={[12, 12, 4.75]}><Label htmlFor={`selectedDay-${index}`}>
                    <FormattedMessage id={'ScheduleFormSelectDayLabel'} defaultMessage={'Le: '}/>
                </Label>
                    <Select id={`selectedDay-${index}`} value={daysList[opening.day[0]]} style={{width: '85%'}}
                            onChange={(e) => onSelectDayChange(e.target.value, index)}>
                        {daysList.map((day) => (
                            <option key={day} value={day}>
                                {day}
                            </option>
                        ))}
                    </Select>
                </Col>
                : <Col columns={[12, 12, 4.75]}> <Label htmlFor={`selectedStartDay-${index}`}>
                    <FormattedMessage id={'ScheduleFormSelectStartDayLabel'} defaultMessage={'Du: '}/>
                </Label>
                    <Select id={`selectedStartDay-${index}`} value={daysList[opening.day[0]]}
                            onChange={(e) => onStartDayChange(e.target.value, index)}>
                        {daysList.map((day) => (
                            <option key={day} value={day}>
                                {day}
                            </option>
                        ))}
                    </Select>
                    <Label htmlFor={`selectedEndDay-${index}`} style={{marginLeft: '15px'}}>
                        <FormattedMessage id={'ScheduleFormSelectEndDayLabel'} defaultMessage={'Au: '}/>
                    </Label>
                    <Select id={`selectedEndDay-${index}`} value={daysList[opening.day[1]]}
                            onChange={(e) => onEndDayChange(e.target.value, index)}>
                        {daysList.map((day) => (
                            <option key={day} value={day}>
                                {day}
                            </option>
                        ))}
                    </Select>
                </Col>}
            <Col columns={[12, 12, 6.25]} style={{padding: 0}}>
                {opening.hours.map((time, i) => {
                    return <SmallRow alignItems={'center'} key={i}>
                        <Col columns={[12, 12, 12, 2]}>
                            <SpanSubtitle1 style={{color: theme.colors.grey60}}>
                                {opening.hours.length === 1
                                    ? <FormattedMessage id={'ScheduleFormTimeLabel'} defaultMessage={'Horaire: '}/>
                                    : i !== 0
                                        ?
                                        <FormattedMessage id={'ScheduleFormMultipleTimeLabel'} defaultMessage={'Et: '}/>
                                        : null
                                }
                            </SpanSubtitle1>
                        </Col>
                        <Col columns={[10]} style={{padding: 0}}>
                            <Label htmlFor={`selectedStartHours-${i}`}>
                                <FormattedMessage id={'ScheduleFormSelectStartHoursLabel'} defaultMessage={'De: '}/>
                            </Label>
                            <Select id={`selectedStartHours-${i}`}
                                    value={time.from.slice(0, 2) + 'h'}
                                    onChange={(e) => {
                                        onSelectedTimingChange((e.target.value.slice(0, 2) + ':' + time.from.slice(3, 5)), index, i, 'from')
                                    }}>
                                {hoursList.map((hour) => (
                                    <option key={hour} value={hour}>
                                        {hour}
                                    </option>
                                ))}
                            </Select>
                            <Select aria-label={`selectedStartMinutes-${i}`}
                                    value={time.from.slice(3, 5)}
                                    onChange={(e) => {
                                        onSelectedTimingChange((time.from.slice(0, 2) + ':' + e.target.value), index, i, 'from')
                                    }}>
                                {minutesList.map((minute) => (
                                    <option key={minute} value={minute}>
                                        {minute}
                                    </option>
                                ))}
                            </Select>
                            <Label htmlFor={`selectedEndHours-${i}`} style={{marginLeft: '15px'}}>
                                <FormattedMessage id={'ScheduleFormSelectEndHoursLabel'} defaultMessage={'À: '}/>
                            </Label>
                            <Select id={`selectedEndHours-${i}`}
                                    value={time.to.slice(0, 2) + 'h'}
                                    onChange={(e) => {
                                        onSelectedTimingChange((e.target.value.slice(0, 2) + ':' + time.to.slice(3, 5)), index, i, 'to')
                                    }}>
                                {hoursList.map((hour) => (
                                    <option key={hour} value={hour}>
                                        {hour}
                                    </option>
                                ))}
                            </Select>
                            <Select aria-label={`selectedEndMinutes-${i}`}
                                    value={opening.hours[0].to.slice(3, 5)}
                                    onChange={(e) => {
                                        onSelectedTimingChange((time.to.slice(0, 2) + ':' + e.target.value), index, i, 'to')
                                    }}>
                                {minutesList.map((minute) => (
                                    <option key={minute} value={minute}>
                                        {minute}
                                    </option>
                                ))}
                            </Select>
                            {i !== 0 && <SquareButton small icon={'delete'} fontColor={theme.colors.grey60}
                                                      style={{marginLeft: '15px'}}
                                                      onClick={() => onTimeIntervalDeleteClick(i)}
                            />}
                        </Col>
                    </SmallRow>
                })}
                {opening.hours.length > 1 && <Row>
                    <Col columns={[12, 12, 12, 1.5]}></Col>
                    <Col>
                        <BaseButton style={{display: 'flex'}} onClick={onTimeIntervalAddClick}>
                            <SpanButton
                                style={{
                                    color: theme.colors.grey60,
                                    fontSize: '42px',
                                    marginRight: '5px'
                                }}>+</SpanButton>
                            <SpanButton
                                style={{color: theme.colors.grey60, fontSize: '14px', fontWeight: 600}}>
                                <FormattedMessage
                                    id={'ParamsAgendaIntervalAddButton'}
                                    defaultMessage={'Ajouter une période'}
                                />
                            </SpanButton>
                        </BaseButton>
                    </Col>
                </Row>}
            </Col>
            <Col columns={[12, 12, 1]} style={{textAlign: 'right'}}>
                <div ref={ref}>
                    <OptionButton icon={'gear'} fontColor={theme.colors.light}
                                  backgroundColor={showOptions ? theme.colors.grey20 : theme.colors.grey60}
                                  onClick={() => setShowOptions(!showOptions)}/>
                    <Dropdown right show={showOptions} wrapperRef={ref} onClose={() => {
                    }}>
                        <Container style={{borderTopLeftRadius: '3px', borderTopRightRadius: '3px'}}>
                            <Row alignItems={'center'}>
                                <Col columns={[3]}>
                                    <Switch value={opening.hours.length === 1} onChange={onContinuousDaySwitchChange}/>
                                </Col>
                                <Col columns={[9]} style={{padding: '0 14px', textAlign: 'left'}}>
                                    <SwitchLabel onClick={onContinuousDaySwitchChange}>
                                        <FormattedMessage id={'ScheduleFormContinuousDaySwitchLabel'}
                                                          defaultMessage={'journée continue'}/>
                                    </SwitchLabel>
                                </Col>
                            </Row>
                            <Row alignItems={'center'}>
                                <Col columns={[3]}>
                                    <Switch value={opening.day.length === 1}
                                            onChange={onIsolatedDaySwitchChange}/>
                                </Col>
                                <Col columns={[9]} style={{padding: '0 14px', textAlign: 'left'}}>
                                    <SwitchLabel onClick={onIsolatedDaySwitchChange}>
                                        <FormattedMessage id={'ScheduleFormIsolatedDaySwitchLabel'}
                                                          defaultMessage={'jour isolé'}/>
                                    </SwitchLabel>
                                </Col>
                            </Row>
                        </Container>
                        <Container style={{borderBottomLeftRadius: '3px', borderBottomRightRadius: '3px'}}>
                            <BaseButton style={{padding: '10px 0'}} onClick={() => {
                                onScheduleRowDeleteClick();
                                setShowOptions(false)
                            }}>
                                <SpanDeleteButton>
                                    <FormattedMessage id={'ScheduleFormDeleteScheduleRowLabel'}
                                                      defaultMessage={'Supprimer ces horaires'}/>
                                </SpanDeleteButton>
                            </BaseButton>
                        </Container>
                    </Dropdown>
                </div>
            </Col>
        </Row>
        <div style={{borderBottom: `1px solid ${theme.colors.grey90}`}}></div>
    </div>
}