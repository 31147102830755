import { FC, useState } from "react";
import { Dialog, Flex, SpanBody2, SpanSubtitle2, Switch, ThemeType } from "@linkeo.com/ui-lib-react";
import { FormattedMessage, useIntl } from "react-intl";
import { useTheme } from "styled-components";
import { DeleteDialogFooter } from "../../commons/components/delete-dialog-footer";

interface DeleteEventModalProps {
    onClose: () => void;
    show: boolean;
    firstName: string;
    lastName: string;
    onDeleteClick: () => void;
    spot: number
}

export const DeleteEventModal: FC<DeleteEventModalProps> = ({ onClose, show, firstName, lastName, onDeleteClick, spot }) => {
    const intl = useIntl();
    const theme = useTheme() as ThemeType;
    const [removeAll, setRemoveAll] = useState<boolean>(false);

    return <Dialog
        onClose={onClose}
        show={show}
        title={intl.formatMessage({ id: 'RemoveButtonLabel', defaultMessage: "Supprimer" })}
        backgroundColor={theme.colors.danger}
        size={'sm'}
        footer={<DeleteDialogFooter onCancelClick={onClose} onConfirmDeleteClick={onDeleteClick} />}>
        <Flex justifyContent={'center'}>
            <SpanSubtitle2 style={{ textAlign: 'center', opacity: removeAll ? "0.3" : 'initial' }}>
                <FormattedMessage id={'DeleteEventModalLabelBody'} defaultMessage={'Êtes-vous certain de vouloir supprimer la participation du client suivant :'} />
            </SpanSubtitle2>
            <div style={{ margin: '10px 0', opacity: removeAll ? "0.3" : 'initial' }}>
                <SpanBody2>
                    {firstName} {lastName}
                </SpanBody2>
            </div>
            {spot > 1 && <Switch
                value={removeAll}
                onChange={setRemoveAll}
                onLabel={intl.formatMessage({
                    id: 'DeleteEventModalSwitchLabel',
                    defaultMessage: 'Supprimer la participation de l\'ensemble des clients'
                })} />}
        </Flex>
    </Dialog>
}