import axios, {AxiosRequestHeaders} from "axios";
import {Configuration, PaymentConfig} from './configuration-types';

export class ConfigurationApi {
    public constructor(private apiBaseUri: string) {
    }

    async getConfiguration(codeBouton: string, signal?: AbortSignal): Promise<Configuration> {
        const response = await axios.get(`${this.apiBaseUri}/${codeBouton}/configuration`, {signal});
        return response.data;
    }

    async updateConfiguration(codeBouton: string, token: string, config: Configuration): Promise<Configuration> {
        const headers: AxiosRequestHeaders = {Authorization: `Bearer ${token}`};
        const response = await axios.put(`${this.apiBaseUri}/${codeBouton}/configuration`, config, {headers})
        return response.data
    }

    async getPayment(codeBouton: string, token: string): Promise<PaymentConfig> {
        const headers: AxiosRequestHeaders = {Authorization: `Bearer ${token}`};
        const response = await axios.get(`${this.apiBaseUri}/${codeBouton}/configuration/payment`, {headers})
        return response.data
    }

    async updatePaymentConfig(codeBouton: string, token: string, paymentConfig: PaymentConfig): Promise<PaymentConfig> {
        const headers: AxiosRequestHeaders = {Authorization: `Bearer ${token}`};
        const response = await axios.put(`${this.apiBaseUri}/${codeBouton}/configuration/payment`, paymentConfig, {headers});
        return response.data
    }
}