import {FC, useState} from "react";
import {Col, Row, Select, SelectOption, SpanCaption1} from "@linkeo.com/ui-lib-react";
import {FormattedMessage, useIntl} from "react-intl";

interface ParamsTimezoneFormProps {
    timezoneOptions: SelectOption<string>[]
}

export const ParamsTimezoneForm: FC<ParamsTimezoneFormProps> = ({timezoneOptions}) => {
    const intl = useIntl();
    const [timezoneSelected, setTimezoneSelected] = useState(timezoneOptions[0])

    return <form>
        <Row alignItems={'center'} style={{marginTop: '10px'}}>
            <Col columns={[12, 12, 6]}>
                <Select
                    selected={timezoneSelected}
                    onSelect={setTimezoneSelected}
                    options={timezoneOptions}
                />
            </Col>
            <Col columns={[12, 12, 6]}>
                <SpanCaption1>
                    <FormattedMessage
                        id={'ParamsTimezoneFormCaption'}
                        defaultMessage={'Le fuseau horaire actuel est : {timezone}. {br}Sur ce fuseau horaire, il est actuellement : {time}'}
                        values={{
                            timezone: timezoneSelected.label,
                            br: <br/>,
                            time: intl.formatDate(new Date(), {timeStyle: 'short', timeZone: timezoneSelected.value})
                        }}
                    />
                </SpanCaption1>
            </Col>
        </Row>
    </form>
}