import {FC, ReactNode} from "react";
import {H2, SpanBody1} from "@linkeo.com/ui-lib-react";

interface ParamsCardProps {
    children: ReactNode;
    title: string;
    subtitle: string;
    inCard?: boolean
}

export const ParamsCard: FC<ParamsCardProps> = ({children, title, subtitle, inCard}) => {

    return <div style={{marginBottom: inCard ? '0' : '68px'}}>
        <H2>
            {title}
        </H2>
        <p style={{margin: 0}}>
            <SpanBody1>
                {subtitle}
            </SpanBody1>
        </p>
        {children}
    </div>
}