import React, {FC} from "react";
import {Checkbox, Col, Input, Row, SelectOption, SimpleSelect, Textarea} from "@linkeo.com/ui-lib-react";
import {DateField, FromToSelect, TimeField} from "./from-to-select";
import {FormattedMessage, useIntl} from "react-intl";
import {Service} from "../agendas-types";

interface AvailableAppointmentFormProps {
    isDone: boolean;
    fromDate: Date;
    onCheckboxChange: (name: string, value: boolean) => void;
    onTimeSelect: (dateField: DateField, timeField: TimeField, value: string) => void;
    toDate: Date;
    disabled: boolean;
    notes: string;
    reminderSms: boolean;
    reminderEmail: boolean;
    servicesList: SelectOption<Service>[];
    selectedService?: SelectOption<Service>;
    onServiceSelect: (service: Service) => void;
    spotsList: SelectOption<number>[];
    spots: number;
    onSpotsChange: (spot: number) => void;
    lastName: string;
    firstName: string;
    email: string;
    phone: string;
    onInputChange: (name: string, value: string) => void;
}

export const AvailableAppointmentForm: FC<AvailableAppointmentFormProps> = ({
                                                                                fromDate,
                                                                                onTimeSelect,
                                                                                toDate,
                                                                                disabled,
                                                                                isDone,
                                                                                notes,
                                                                                reminderSms,
                                                                                reminderEmail,
                                                                                servicesList,
                                                                                selectedService,
                                                                                onServiceSelect,
                                                                                spots,
                                                                                spotsList,
                                                                                onSpotsChange,
                                                                                lastName,
                                                                                onInputChange,
                                                                                onCheckboxChange,
                                                                                firstName,
                                                                                phone,
                                                                                email
                                                                            }) => {
    const intl = useIntl();

    return <>
        <Row alignItems={'center'} style={{maxWidth: '100%'}}>
            <Col columns={[12, 12, 12, 4.5]}>
                <Checkbox onChange={(val) => onCheckboxChange('isDone', val)} value={isDone}>
                    <FormattedMessage id={'availableAppointmentFormDoneCheckboxLabel'}
                                      defaultMessage={'Rendez-vous terminé'}/>
                </Checkbox>
            </Col>
            <Col columns={[12, 12, 12, 7.5]}>
                <FromToSelect
                    fromDate={fromDate}
                    toDate={toDate}
                    onTimeSelect={onTimeSelect}
                    hasDuration={true}
                    disabled={disabled}/>
            </Col>
        </Row>
        <Row alignItems={'center'} style={{maxWidth: '100%'}}>
            <Col columns={[12, 12, 12, 6]}>
                <SimpleSelect options={servicesList} selected={selectedService}
                              disabled={servicesList.length <= 1}
                              onSelect={(service) => onServiceSelect(service.value)}/>
            </Col>
            <Col columns={[12, 12, 12, 6]}>
                <SimpleSelect options={spotsList}
                              disabled={spotsList.length === 1}
                              selected={spotsList.find(el => el.value === spots)}
                              onSelect={(spot) => onSpotsChange(spot.value)}
                />
            </Col>
            <Col columns={[12, 6]}>
                <Input value={firstName} onChange={(value) => onInputChange('firstName', value)}
                       type={'text'} required
                       placeholder={intl.formatMessage({
                           id: 'CustomerEditPageNameInputLabel',
                           defaultMessage: 'Prénom'
                       })}/>
            </Col>
            <Col columns={[12, 6]}>
                <Input value={lastName} onChange={(value) => onInputChange('lastName', value)}
                       type={'text'} required
                       placeholder={intl.formatMessage({
                           id: 'CustomerEditPageLastNameInputLabel',
                           defaultMessage: 'Nom'
                       })}/>
            </Col>
            <Col columns={[12, 6]}>
                <Input value={email} onChange={(value) => onInputChange('email', value)}
                       type={'email'} required
                       placeholder={intl.formatMessage({
                           id: 'CustomerEditPageEmailInputLabel',
                           defaultMessage: 'Adresse e-mail'
                       })}/>
            </Col>
            <Col columns={[12, 6]}>
                <Input value={phone} onChange={(value) => onInputChange('phone', value)}
                       type={'tel'} required
                       placeholder={intl.formatMessage({
                           id: 'availableAppointmentFormMobilePhonePlaceholder',
                           defaultMessage: 'Téléphone mobile'
                       })}/>
            </Col>
            <Col columns={[12]}>
                <Textarea value={notes} onChange={(value) => onInputChange('notes', value)}
                          placeholder={intl.formatMessage({
                              id: 'availableAppointmentFormNotesPlaceholder',
                              defaultMessage: 'Commentaires sur le rendez-vous'
                          })}/>
            </Col>
        </Row>
        <Row style={{maxWidth: '100%'}}>
            <Col columns={[12, 12, 6, 6]}>
                <Checkbox onChange={(val) => onCheckboxChange('reminderEmail', val)} value={reminderEmail}>
                    <FormattedMessage id={'availableAppointmentFormReminderEmailLabel'}
                                      defaultMessage={'Envoyer/Recevoir un e-mail de rappel'}/>
                </Checkbox>
            </Col>
            <Col columns={[12, 12, 6, 6]}>
                <Checkbox onChange={(val) => onCheckboxChange('reminderSms', val)} value={reminderSms}>
                    <FormattedMessage id={'availableAppointmentFormReminderSmsLabel'}
                                      defaultMessage={'Envoyer/Recevoir un SMS de rappel'}/>
                </Checkbox>
            </Col>
        </Row>
    </>
}