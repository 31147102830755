import {FC} from "react";
import {InfiniteScroll, ThemeType} from "@linkeo.com/ui-lib-react";
import {useIntl} from "react-intl";
import {useTheme} from "styled-components";
import {EmptyListText} from "./empty-list-text";
import {CardLoading} from "./card-loading";
import {ListCard} from "./list-card";

interface CustomerAppointmentsTimelineListProps {
    currency: string;
    isLoading: boolean;
    onAppointmentsListLoading: (offset: number) => void;
    loading: boolean;
    lock: boolean;
    appointmentsLength: number;
}

export const CustomerAppointmentsTimelineList: FC<CustomerAppointmentsTimelineListProps> = ({
                                                                                                currency,
                                                                                                isLoading,
                                                                                                onAppointmentsListLoading,
                                                                                                loading,
                                                                                                lock,
                                                                                                appointmentsLength
                                                                                            }) => {
    const intl = useIntl();
    const theme = useTheme() as ThemeType;

    return isLoading ? <CardLoading/>
        : appointmentsLength > 0 ?
            <div role={"list"}>
                <InfiniteScroll isLoading={loading} lock={lock}
                                moreResultLabel={intl.formatMessage({
                                    id: 'DefaultMoreResultLabel',
                                    defaultMessage: 'Charger plus'
                                })}
                                onNext={() => onAppointmentsListLoading(appointmentsLength)}>
                    <ListCard index={0} title={"Coupe coiffage"}
                              details={intl.formatDate(new Date(), {dateStyle: 'full', timeStyle: 'short'})} icon={"check-circle"}
                              status={intl.formatMessage({
                                  id: 'PastAppointment',
                                  defaultMessage: 'Rendez-vous terminé'
                              })}
                              value={35} currency={currency}
                              color={theme.colors.success}/>
                    <ListCard index={1} title={"AQUABIKE"} details={intl.formatDate(new Date(), {dateStyle: 'full', timeStyle: 'short'})}
                              icon={"cross-circle"}
                              status={intl.formatMessage({
                                  id: 'cancelledAppointment',
                                  defaultMessage: 'Rendez-vous annulé'
                              })}
                              value={45} currency={currency}
                              color={theme.colors.danger}/>
                </InfiniteScroll>
            </div>
            : <EmptyListText text={intl.formatMessage({
                id: 'CustomersAppointmentsTimelineListEmpty',
                defaultMessage: 'Aucune réservation n\'a jamais été effectuée par ce client.'
            })}/>
}