import { getTypo } from "@linkeo.com/ui-lib-react";
import styled from "styled-components";

export const Tag = styled.span<{ small?: boolean }>`
display: inline-block;
background-color: ${({theme}) => theme.colors.grey90};
padding: ${({small}) => small ? '5px' : '8px'};
border-radius: 3px;
${getTypo('subtitle1')}
color: ${({theme}) => theme.colors.grey40};
margin: 0 10px 12px 0;

&:hover {
  background-color: ${({theme}) => theme.colors.grey95};
}
`