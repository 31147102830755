import {FC, useState} from "react";
import {Col, getTypo, IconName, Row, SelectOption, SimpleSelect, Svg, ThemeType} from "@linkeo.com/ui-lib-react";
import styled, {useTheme} from "styled-components";
import {FormattedMessage} from "react-intl";

const SelectRow = styled(Row)`
  ${getTypo('input')};
`
const SelectContainer = styled(Col)`
  padding: 0 4px;

  > div > div {
    margin: 0;
  }
`

interface ParamsNotificationsSelectRowProps {
    icon: IconName;
    text: string
}

export const ParamsNotificationsSelectRow: FC<ParamsNotificationsSelectRowProps> = ({icon, text}) => {
    const theme = useTheme() as ThemeType;
    const options: SelectOption<number>[] = [
        {label: '1', value: 1},
        {label: '3', value: 3},
        {label: '6', value: 6},
        {label: '12', value: 12},
        {label: '24', value: 24},
        {label: '48', value: 48}
    ]
    const [selectedOption, setSelectedOption] = useState<SelectOption<number>>(options[0])

    return <SelectRow alignItems={'center'}>
        <Col style={{padding: 0}}>
            <Svg icon={icon} fill={theme.colors.grey60} width={22} height={22} style={{marginRight: '12px'}}/>
        </Col>
        <Col style={{paddingLeft: 0}}>
            {text}
        </Col>
        <SelectContainer columns={[11, 11, 2]}>
            <SimpleSelect
                options={options}
                selected={selectedOption}
                onSelect={(v) => setSelectedOption(v)}
            />
        </SelectContainer>
        <Col style={{paddingRight: 0}}>
            <FormattedMessage
                id={'ParamsNotificationsSelectPostText'}
                defaultMessage={'heures avant le RDV.'}
            />
        </Col>
    </SelectRow>
}