import {FC, useState} from "react";
import {Card, Container} from "@linkeo.com/ui-lib-react";
import {useIntl} from "react-intl";
import {PageWrapper} from "../../commons/components/page-wrapper";
import {ParamsCard} from "../../params/components/params-card";
import {ParamsBookingValidationForm} from "../../params/components/booking/params-booking-validation-form";
import {BackToTop} from "../../commons/components/back-to-top";
import {ParamsBookingDelayForm} from "../../params/components/booking/params-booking-delay-form";
import {ParamsBookingTimingForm} from "../../params/components/booking/params-booking-timing-form";
import {ParamsBookingAgendaForm} from "../../params/components/booking/params-booking-agenda-form";
import {ParamsBookingMessageForm} from "../../params/components/booking/params-booking-message-form";
import {ParamsBookingAuthentificationForm} from "../../params/components/booking/params-booking-authentification-form";

interface ParamsBookingPageProps {

}

export const ParamsBookingPage: FC<ParamsBookingPageProps> = () => {
    const intl = useIntl();
    const [isLoading] = useState<boolean>(false)

    return <PageWrapper isLoading={isLoading}>
        <Container size={'lg'}>
            <ParamsCard
                title={intl.formatMessage({
                    id: 'ParamsBookingPageValidationTitle',
                    defaultMessage: 'Validation automatique'
                })}
                subtitle={intl.formatMessage({
                    id: 'ParamsBookingPageValidationSubTitle',
                    defaultMessage: 'Indiquez ici si vous souhaitez accepter automatiquement les demandes de rendez-vous.'
                })}>
                <ParamsBookingValidationForm/>
            </ParamsCard>
            <ParamsCard
                title={intl.formatMessage({id: 'ParamsBookingPageDelayTitle', defaultMessage: 'Délais et limites'})}
                subtitle={intl.formatMessage({
                    id: 'ParamsBookingPageDelaySubTitle',
                    defaultMessage: 'Indiquez ici tous les délais qui seront nécessaires au bon déroulement de vos rendez-vous et à votre organisation.'
                })}>
                <ParamsBookingDelayForm />
            </ParamsCard>
            <ParamsCard
                title={intl.formatMessage({id: 'ParamsBookingPageTimingTitle', defaultMessage: 'Rythme des rendez-vous'})}
                subtitle={intl.formatMessage({id: 'ParamsBookingPageTimingSubTitle', defaultMessage: 'Configurez ici les créneaux ouverts à la réservation sur votre site internet et gérez les délais entre vos rendez-vous.'})}>
                <ParamsBookingTimingForm />
            </ParamsCard>
            <Card>
                <ParamsBookingAgendaForm />
            </Card>
            <Card style={{margin: '68px 0'}}>
                <ParamsBookingMessageForm />
            </Card>
            <Card>
                <ParamsBookingAuthentificationForm />
            </Card>
            <BackToTop/>
        </Container>
    </PageWrapper>
}