import {FC, useEffect, useState} from "react";
import {PageWrapper} from "../../commons/components/page-wrapper";
import {Container, useToaster} from "@linkeo.com/ui-lib-react";
import {ParamsCard} from "../../params/components/params-card";
import {useIntl} from "react-intl";
import {ParamsActivatePaymentForm} from "../../params/components/payment/params-activate-payment-form";
import {PaymentGiftcardForm} from "../../params/components/payment/payment-giftcard-form";
import {PaymentStripeForm} from "../../params/components/payment/payment-stripe-form";
import {useConfigurationStore} from "../../params/configuration-store";
import {ServerError} from "../../commons/components/server-error";
import {Configuration, Mode, PaymentConfig} from "../../params/configuration-types";

export const ParamsPaymentPage: FC = () => {
    const intl = useIntl();
    const toast = useToaster();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const {
        config,
        fetchData,
        updateConfig,
        fetchPaymentData,
        paymentConfig,
        updatePaymentConfig
    } = useConfigurationStore();
    const [error, setError] = useState<boolean>(false);

    useEffect(() => {
        setIsLoading(true)
        Promise.all([fetchData(), fetchPaymentData()])
            .catch(e => {
                console.error(e)
                setError(true)
            })
            .finally(() => setIsLoading(false))
    }, [fetchData, fetchPaymentData])

    const onError = (e: any) => {
        console.error(e);
        if (e.response?.status === (400 || 404)) {
            toast(intl.formatMessage({id: 'paramsPaymentPageErrorMessage', defaultMessage: 'La valeur renseignée n\'est pas valide'}))
        } else {
            setError(true);
        }
    }

    const onConfigUpdate = async (config: Configuration) => {
        try {
            await updateConfig(config)
        } catch (e) {
            onError(e)
        }
    }

    const onPaymentConfigUpdate = async (config: PaymentConfig) => {
        try {
           await updatePaymentConfig(config)
        } catch (e) {
            onError(e)
        }
    }

    return <PageWrapper isLoading={isLoading}>
        {!error ? <Container size={'lg'}>
            <ParamsCard
                title={intl.formatMessage({id: 'ParamsPaymentPageTitle', defaultMessage: 'Paiement'})}
                subtitle={intl.formatMessage({
                    id: 'ParamsPaymentPageSubTitle',
                    defaultMessage: 'Configurez ici vos préférences concernant le paiement'
                })}>
                <ParamsActivatePaymentForm active={config.appointment.paymentActive}
                                           paymentPercent={config.paymentPercent / 100}
                                           onActiveChange={(paymentActive) => onConfigUpdate({
                                               ...config,
                                               appointment: {...config.appointment, paymentActive}
                                           })}
                                           onPaymentPercentChange={(v) => onConfigUpdate({
                                               ...config,
                                               paymentPercent: v * 100
                                           })}/>
                <PaymentGiftcardForm active={config.giftcard.active} onActiveChange={(active) => onConfigUpdate({
                    ...config,
                    giftcard: {...config.giftcard, active}
                })}/>
                <PaymentStripeForm {...paymentConfig}
                                   onStripeKeyUpdate={onPaymentConfigUpdate}
                                   onPaymentModeChange={(mode: Mode) => onPaymentConfigUpdate({
                                       ...paymentConfig,
                                       mode
                                   })}/>
            </ParamsCard>
        </Container> : <ServerError/>}
    </PageWrapper>
}