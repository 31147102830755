import {FC, useEffect, useState} from "react";
import {Card, Checkbox, Col, Row, SpanSubtitle1, SquareButton, ThemeType} from "@linkeo.com/ui-lib-react";
import {Link} from "react-router-dom";
import styled, {useTheme} from "styled-components";

const Article = styled(Card)`
    margin-bottom: 16px;
    
    @media screen and (max-width: 572px){
        padding: 8px 16px;
    }
`
interface CustomerCardProps {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    link: string;
    isSelected: boolean;
}

export const CustomerCard: FC<CustomerCardProps> = ({firstName, lastName, email, phone, link, isSelected}) => {
    const theme = useTheme() as ThemeType;
    const [checked, setChecked] = useState<boolean>(false);

    useEffect(() => {
        setChecked(isSelected)
    }, [isSelected])

    return <Article>
        <Row alignItems={'center'}>
            <Col columns={[0.5]}>
                <Checkbox onChange={setChecked} value={checked}/>
            </Col>
            <Col columns={[11, 6, 4]}>
                <SpanSubtitle1>{firstName} {lastName}</SpanSubtitle1>
            </Col>
            <Col columns={[12, 6, 4.5]}>
                <SpanSubtitle1>{email}</SpanSubtitle1>
            </Col>
            <Col columns={[12, 4, 2]}>
                <SpanSubtitle1>{phone}</SpanSubtitle1>
            </Col>
            <Col columns={[12, 1]}>
                <Link to={link}><SquareButton backgroundColor={theme.colors.secondary}
                                              icon={'edit'}
                                              fontColor={theme.colors.light}>
                </SquareButton></Link>
            </Col>
        </Row>
    </Article>
}