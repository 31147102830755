import { FC, useEffect, useState } from "react";
import { Card, Col, Row, SpanBody1, SpanBody2, SquareButton, Switch, ThemeType } from "@linkeo.com/ui-lib-react";
import styled, { useTheme } from "styled-components";
import { FormattedMessage, useIntl } from "react-intl";
import { getLocaleDaysNames } from "../../../utils/date";
import { Tag } from "../../../commons/components/tag";

const PromoCard = styled(Card) <{ disabled: boolean }>`
  opacity: ${({ disabled }) => disabled ? 0.4 : 1};
`;

const percent = 'percent';
const monetary = 'monetary';

type ValueType = typeof percent | typeof monetary

interface Opening {
    day: number;
    from: string;
    to: string
}

interface ParamsDiscountCardProps {
    active: boolean;
    endDate: string;
    startDate: string;
    openings?: Opening[];
    services: string[];
    value: number;
    type: ValueType;
    currency: string;
    onEditClick: () => void;
    onDeleteClick: () => void;
}

export const ParamsDiscountCard: FC<ParamsDiscountCardProps> = ({
    type,
    endDate,
    startDate,
    openings,
    services,
    value,
    active: activeProps,
    currency,
    onEditClick,
    onDeleteClick
}) => {
    const theme = useTheme() as ThemeType;
    const intl = useIntl();
    const [active, setActive] = useState<boolean>(activeProps);

    useEffect(() => {
        setActive(activeProps)
    }, [activeProps])

    return <PromoCard style={{ marginBottom: '28px' }} disabled={!active}>
        <Row alignItems={'flex-start'}>
            <Col columns={[12, 12, 6]}>
                <SpanBody1 style={{ color: theme.colors.success, fontWeight: 600 }}>
                    -{intl.formatNumber((type === percent ? value / 100 : value), {
                        style: type === percent ? 'percent' : 'currency',
                        currency,
                        maximumFractionDigits: 0
                    })}
                </SpanBody1>
                <div>
                    <SpanBody2>
                        <FormattedMessage
                            id={'ParamsDiscountsPageIntervalDate'}
                            defaultMessage={'Du {startDate} au {endDate}'}
                            values={{
                                startDate: intl.formatDate(startDate, { dateStyle: "short" }),
                                endDate: intl.formatDate(endDate, { dateStyle: "short" })
                            }}
                        />
                    </SpanBody2>
                </div>
                {openings && <SpanBody1>
                    <FormattedMessage
                        id={'ParamsDiscountsPageOpeningsDate'}
                        defaultMessage={'{day} de {from} à {to} {more}'}
                        values={{
                            day: getLocaleDaysNames()[openings[0].day].replace(/^./, str => str.toUpperCase()),
                            from: openings[0].from,
                            to: openings[0].to,
                            more: openings.length > 1 ? ` ... + ${openings.length - 1}` : null
                        }}
                    />
                </SpanBody1>}
                <div style={{ margin: '8px 0' }}>
                    {services.slice(0,3).map((service, index) => {
                        return <Tag small key={index}>
                            {service}
                        </Tag>

                    })}
                    {services.length > 3 && (
                        <Tag small>
                            +{services.length - 3}
                        </Tag>
                    )}
                </div>
            </Col>
            <Col columns={[6, 6, 4]}>
                <Switch
                    onIcon={'check'}
                    offIcon={'cross'}
                    color={theme.colors.grey40}
                    onLabel={intl.formatMessage({ id: 'active', defaultMessage: 'Actif' })}
                    value={active}
                    onChange={() => setActive(!active)}
                />
            </Col>
            <Col columns={[6, 6, 2.5, 2]} style={{ marginTop: '16px' }}>
                <SquareButton icon={'edit'} fontColor={theme.colors.grey40} style={{ margin: '0 15px' }}
                    onClick={onEditClick} />
                <SquareButton
                    icon={'delete'}
                    fontColor={theme.colors.grey40}
                    onClick={onDeleteClick} />
            </Col>
        </Row>
    </PromoCard>
}