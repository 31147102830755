import {FC, useState} from "react";
import {Container, InfiniteScroll} from "@linkeo.com/ui-lib-react";
import {useIntl} from "react-intl";
import {PageWrapper, PageWrapperSubHeader} from "../../commons/components/page-wrapper";
import {ContainerHeader} from "../../commons/components/container-header";
import {routeGiftcardCreate} from '../../routes';
import {GiftcardCard} from "../../giftcards/components/giftcard-card";
import {GiftcardNav} from "../../giftcards/components/giftcard-nav";
import {GiftcardListDetails} from "../../giftcards/components/giftcard-list-details";
import {BackToTop} from "../../commons/components/back-to-top";

interface GiftcardsListPageProps {

}

export const GiftcardsListPage: FC<GiftcardsListPageProps> = () => {
    const intl = useIntl();
    const [isLoading] = useState<boolean>(false);
    const [loading] = useState<boolean>(false);
    const [lock] = useState<boolean>(false);
    const [giftcardsList] = useState([''])
    const [searchValue, setSearchValue] = useState<string>('')

    return <PageWrapper isLoading={isLoading}>
        <PageWrapperSubHeader>
            <GiftcardNav setSearchValue={setSearchValue} searchValue={searchValue} onFilterClick={() => {}}/>
        </PageWrapperSubHeader>
        <Container size={'lg'}>
            <ContainerHeader
                title={intl.formatMessage({id: 'GiftcardsListPageTitle', defaultMessage: 'Tous les bons cadeaux'})}
                buttonLabel={intl.formatMessage({
                    id: 'GiftcardsListPageButtonLabel',
                    defaultMessage: '+ Créer un nouveau bon cadeau'
                })}
                to={routeGiftcardCreate}
            />
            <GiftcardListDetails
                total={3}
                paid={1}
                used={1}
                expired={1}
            />
            {giftcardsList.length > 0 && <InfiniteScroll isLoading={loading} lock={lock}
                                                         moreResultLabel={intl.formatMessage({
                                                             id: 'GiftcardListPageMoreResultLable',
                                                             defaultMessage: '+ Charger plus de bons cadeaux'
                                                         })}
                                                         onNext={() => {
                                                         }}>
                <GiftcardCard
                    code={'650AE6CC988EE53'} giftedBy={'Mmm Mmm'} status={'used'}
                    expirationDate={'2024-03-20T23:59:59+01:00'} email={'mmm@gmail.com'} phone={'0600000000'}
                    purchaseDate={'2023-09-20T14:09:20+02:00'}
                    service={'AQUAMIX'} price={35} currency={'EUR'} giftedTo={'Mmm Mmm'}
                    utilization={'En boutique'}/>
                <GiftcardCard
                    code={'650AE6CC988DD53'} giftedBy={'Monsieur Hello'} status={'paid'}
                    expirationDate={'2024-09-01T12:00:59+01:00'} email={'hello@gmail.com'}
                    phone={'0666666666'} purchaseDate={'2023-09-02T14:09:20+02:00'}
                    service={'Cours d\'espagnol'} price={55} currency={'EUR'} giftedTo={'Monsieur Hello'}/>
                <GiftcardCard
                    code={'650AE5MO988DD54'} giftedBy={'Mmm mmm'} status={'paid'}
                    expirationDate={'2023-07-01T12:00:59+01:00'} email={'contact@email.com'}
                    phone={'0000000000'} purchaseDate={'2022-06-02T14:09:20+02:00'}
                    service={'Coupe coiffage'} price={30} currency={'EUR'} giftedTo={'Mmm mmm'}/>
            </InfiniteScroll>}
            <BackToTop />
        </Container>
    </PageWrapper>
}