import {FC} from "react";
import {SpanSubtitle1} from "@linkeo.com/ui-lib-react";
import {FormattedMessage} from "react-intl";


export const ParamsClosuresReadonlyCard: FC = () => {

    return <>
        <div style={{margin: '10px 0 20px'}}>
            <SpanSubtitle1 style={{fontWeight: 'bold'}}>
                <FormattedMessage
                    id={'ParamsClosuresCalendarFormReadonlyTitle'}
                    defaultMessage={'Vous pouvez gérer vos fermetures à partir de ce calendrier.'}
                />
            </SpanSubtitle1>
        </div>
        <SpanSubtitle1>
            <FormattedMessage
                id={'ParamsClosuresCalendarFormReadonlySubTitle'}
                defaultMessage={'Vous pouvez par exemple renseigner vos fermetures pour congés en laissant la prise de rendez-vous en ligne activée pour les autres dates.'}
            />
        </SpanSubtitle1>
    </>
}