import { FC } from "react";
import { PageWrapper } from "../../../commons/components/page-wrapper";
import { Container } from "@linkeo.com/ui-lib-react";
import { PreviousPageLink } from "../../../commons/components/previous-page-link";
import { ParamsCard } from "../../../params/components/params-card";
import { useIntl } from "react-intl";
import { ParamsDiscountForm } from "../../../params/components/discounts/params-discount-form";
import { matchPath, useLocation, useNavigate, useParams } from "react-router";
import { routeParamsDiscounts, routeParamsGiftcardsDiscounts, routeParamsGiftcardsDiscountsCreate } from "../../../routes";
import {BackToTop} from "../../../commons/components/back-to-top";

export const ParamsDiscountEditPage: FC = () => {
    const intl = useIntl();
    const { id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const matchGiftcardsPath = matchPath(routeParamsGiftcardsDiscountsCreate + '/*', location.pathname)

    return <PageWrapper>
        <Container size={'lg'}>
            <PreviousPageLink
                link={matchGiftcardsPath ? routeParamsGiftcardsDiscounts : routeParamsDiscounts}
            />
            <ParamsCard
                title={matchGiftcardsPath
                    ? intl.formatMessage({
                        id: 'ParamsGiftcardsDiscountEditPageEditTitle',
                        defaultMessage: 'Éditer une promotion'
                    })
                    : id ? intl.formatMessage({
                        id: 'ParamsDiscountEditPageEditTitle',
                        defaultMessage: 'Éditer un créneaux promo'
                    })
                        : intl.formatMessage({
                            id: 'ParamsDiscountEditPageCreateTitle',
                            defaultMessage: 'Ajouter un créneaux promo'
                        })}
                subtitle={matchGiftcardsPath
                    ? intl.formatMessage({
                        id: 'ParamsGiftcardsDiscountEditPageCreateSubTitle',
                        defaultMessage: 'Éditez ici les promotions pour vos bon cadeaux.'
                    })
                    : intl.formatMessage({
                        id: 'ParamsDiscountEditPageCreateSubTitle',
                        defaultMessage: 'Ajouter ici des créneaux horaires à prix réduit.'
                    })}>
                <ParamsDiscountForm
                    form={{
                        active: true,
                        startDate: new Date().toString(),
                        endDate: new Date().toString(),
                        openings: matchGiftcardsPath ? undefined : [{
                            day: 0,
                            from: '00:00',
                            to: '00:00'
                        }],
                        services: [],
                        value: null,
                        type: 'percent'
                    }}
                    services={['AQUAMIX', "Coupe coiffage", "Taille barbe avec lame", 'Formation']}
                    onCancelClick={() => navigate(matchGiftcardsPath ? routeParamsGiftcardsDiscounts : routeParamsDiscounts)}
                    servicesSectionTitle={matchGiftcardsPath
                        ? intl.formatMessage({
                            id: 'ParamsGiftcardsDiscountFormServiceListTitle',
                            defaultMessage: 'Bons cadeaux impactées par la promotion:*'
                        })
                        : intl.formatMessage({
                            id: 'ParamsDiscountFormServiceListTitle',
                            defaultMessage: 'Prestations impactées par la réduction:*'
                        })}
                />
            </ParamsCard>
            <BackToTop />
        </Container>
    </PageWrapper>
}