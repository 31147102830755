import React, {FC} from "react";
import {SpanButton} from "@linkeo.com/ui-lib-react";
import styled from "styled-components";
import {Link} from "react-router-dom";
import { lighten } from "polished";

const StyledLinkElement = styled(Link)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background: ${({theme}) => theme.colors.primary};
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.05), 0px 1px 5px rgba(0, 0, 0, 0.04), 0px 2px 2px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  text-decoration: inherit;
  &:hover, &:focus {
    background: ${({theme}) => lighten(0.08, theme.colors.primary)};
  }
`

interface StyledLinkProps {
    to: string;
    buttonLabel: string
}

export const StyledLink:FC<StyledLinkProps> = ({to, buttonLabel}) => {

    return <StyledLinkElement
        to={to}>
        <SpanButton>{buttonLabel}</SpanButton>
    </StyledLinkElement>
}