import { FC, useState } from "react";
import { PageWrapper } from "../../../commons/components/page-wrapper";
import { Container } from "@linkeo.com/ui-lib-react";
import { EmptyList } from "../../../commons/components/empty-list";
import { useIntl } from "react-intl";
import { routeParamsDiscountsCreate, routeParamsDiscountsEdit, routeParamsGiftcardsDiscounts, routeParamsGiftcardsDiscountsCreate, routeParamsGiftcardsDiscountsEdit } from '../../../routes';
import { ContainerHeader } from "../../../commons/components/container-header";
import { BackToTop } from "../../../commons/components/back-to-top";
import { ParamsDiscountCard } from "../../../params/components/discounts/params-discount-card";
import { ParamsDiscountDeleteModal } from "../../../params/components/discounts/params-discount-delete-modal";
import { matchPath, useLocation, useNavigate } from "react-router";
import { generatePath } from "react-router-dom";

export const ParamsDiscountsListPage: FC = () => {
    const intl = useIntl();
    const navigate = useNavigate();
    const location = useLocation();
    const matchGiftcardsPath = matchPath(routeParamsGiftcardsDiscounts, location.pathname)
    const [promoList] = useState(['']);
    const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);

    return <PageWrapper>
        <Container size={'lg'}>
            {promoList.length > 0
                ? <>
                    <ContainerHeader
                        title={matchGiftcardsPath
                            ? intl.formatMessage({ id: 'ParamsGiftcardsDiscountsPageTitle', defaultMessage: 'Promotions Bons Cadeaux' })
                            : intl.formatMessage({ id: 'ParamsDiscountsPageTitle', defaultMessage: 'Créneaux promo' })}
                        subtitle={matchGiftcardsPath
                            ? intl.formatMessage({
                                id: 'ParamsGiftcardsDiscountsPageSubTitle',
                                defaultMessage: 'Configurez ici des promotions pour vos bons cadeaux.'
                            })
                            : intl.formatMessage({
                                id: 'ParamsDiscountsPageSubTitle',
                                defaultMessage: 'Proposez ici des créneaux horaires à prix réduit.'
                            })}
                        buttonLabel={intl.formatMessage({
                            id: 'ParamsDiscountsPageAddButtonLabel',
                            defaultMessage: '+ Ajouter une promotion'
                        })}
                        to={matchGiftcardsPath ? routeParamsGiftcardsDiscountsCreate : routeParamsDiscountsCreate}
                    />
                    <ParamsDiscountCard
                        type={'percent'}
                        value={20}
                        services={['AQUAMIX', 'Coupe coiffage']}
                        active={true}
                        startDate={'2024-03-14'}
                        endDate={'2024-03-28'}
                        openings={matchGiftcardsPath ? undefined : [{ day: 0, from: '09:00', to: '18:00' }]}
                        currency={'EUR'}
                        onDeleteClick={() => setOpenDeleteModal(true)}
                        onEditClick={() => {
                            navigate(generatePath(matchGiftcardsPath
                                ? routeParamsGiftcardsDiscountsEdit
                                : routeParamsDiscountsEdit,
                                { id: 'fakeId' }))
                        }}
                    />
                    <ParamsDiscountDeleteModal
                        show={openDeleteModal}
                        onClose={() => setOpenDeleteModal(false)}
                        onDeleteClick={() => { }}
                    />
                    <BackToTop />
                </>
                : <EmptyList
                    buttonLabel={intl.formatMessage({
                        id: 'ParamsDiscountsPageAddButtonLabel',
                        defaultMessage: '+ Ajouter une promotion'
                    })}
                    to={matchGiftcardsPath ? routeParamsGiftcardsDiscountsCreate : routeParamsDiscountsCreate}
                    title={matchGiftcardsPath
                        ? intl.formatMessage({ id: 'ParamsGiftcardsDiscountsPageTitle', defaultMessage: 'Promotions Bons Cadeaux' })
                        : intl.formatMessage({ id: 'ParamsDiscountsPageTitle', defaultMessage: 'Créneaux promo' })}
                    subtitle={matchGiftcardsPath
                        ? intl.formatMessage({
                            id: 'ParamsGiftcardsDiscountsPageEmptyListMessage',
                            defaultMessage: 'Vous pouvez configurer des promotions pour proposer des réductions sur certains bons cadeaux.'
                        })
                        : intl.formatMessage({
                            id: 'ParamsDiscountsPageEmptyListMessage',
                            defaultMessage: 'Vous pouvez utiliser les créneaux promo pour proposer des réductions sur certaines prestations ' +
                                'aux horaires auxquels vous avez du mal à remplir votre agenda.'
                        })}
                />}
        </Container>
    </PageWrapper>
}