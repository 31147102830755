import {FC} from "react";
import {Col, getTypo, Row, Select, SelectOption} from "@linkeo.com/ui-lib-react";
import styled from "styled-components";

const Label = styled.label`
  ${getTypo('input')};
`

interface TimeSelectProps {
    label: string;
    hoursOptions: SelectOption<string>[];
    selectedHour: SelectOption<string> | undefined;
    onHourSelect: (val: SelectOption<string>) => void;
    minOptions: SelectOption<string>[];
    selectedMin: SelectOption<string> | undefined;
    onMinSelect: (val: SelectOption<string>) => void;
    hasError: boolean
}

export const TimeSelect: FC<TimeSelectProps> = ({
                                                    label,
                                                    hoursOptions,
                                                    onHourSelect,
                                                    onMinSelect,
                                                    selectedHour,
                                                    selectedMin,
                                                    minOptions,
                                                    hasError
                                                }) => {

    return <Row alignItems={'center'}>
        <Col>
            <Label>{label}</Label>
        </Col>
        <Col columns={[4, 4, 4]} style={{padding: 0}}>
            <Select
                options={hoursOptions}
                selected={selectedHour}
                onSelect={onHourSelect}
                hasError={hasError}
            />
        </Col>
        <Col columns={[4, 4, 4]} style={{padding: 0}}>
            <Select
                options={minOptions}
                selected={selectedMin}
                onSelect={onMinSelect}
                hasError={hasError}
            />
        </Col>
    </Row>
}