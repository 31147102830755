import React, {FC} from "react";
import {
    Button,
    Dialog,
    SpanBody2,
    SpanButton,
    SpanSubtitle2,
    ThemeType
} from "@linkeo.com/ui-lib-react";
import {FormattedMessage, useIntl} from "react-intl";
import {useTheme} from "styled-components";

interface RefuseEventDialogProps {
    onCloseModal: () => void;
    showRefuseModal: boolean;
    onConfirm: () => void
}

export const RefuseEventDialog: FC<RefuseEventDialogProps> = ({onCloseModal, showRefuseModal, onConfirm}) => {
    const theme = useTheme() as ThemeType;
    const intl = useIntl();

    return <Dialog onClose={onCloseModal}
                   show={showRefuseModal}
                   title={intl.formatMessage({id: 'EventsPageRefuseModalTitle', defaultMessage: 'Demande de refus'})}
                   size={'md'}
                   footer={<>
                       <Button colorType={'secondary'} onClick={onCloseModal}>
                           <SpanButton>
                               <FormattedMessage
                                   id={'CancelButtonLabel'}
                                   defaultMessage={'Annuler'}
                               />
                           </SpanButton>
                       </Button>
                       <Button backgroundColor={theme.colors.grey40} borderColor={theme.colors.grey40}
                               onClick={onConfirm}>
                           <SpanButton>
                               <FormattedMessage
                                   id={'OkButtonLabel'}
                                   defaultMessage={'Ok'}
                               />
                           </SpanButton>
                       </Button>
                   </>}>
        <SpanBody2>
            <FormattedMessage id={'EventsPageRefuseModalContentTitle'} defaultMessage={'Message de refus'}/>
        </SpanBody2>
        <div>
            <SpanSubtitle2>
                <FormattedMessage id={'EventsPageRefuseModalContent'}
                                  defaultMessage={'Un message automatique va être envoyé à votre client pour l\'informer de votre refus.'}/>
            </SpanSubtitle2>
        </div>
    </Dialog>
}