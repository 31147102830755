import { FC, useState } from "react";
import { Dialog, Radio, ThemeType } from "@linkeo.com/ui-lib-react";
import { DeleteDialogFooter } from "../../commons/components/delete-dialog-footer";
import { FormattedMessage, useIntl } from "react-intl";
import { useTheme } from "styled-components";

interface CategoryDeleteModalProps {
    onClose: () => void;
    show: boolean;
    onDeleteClick: (value: boolean) => void;
}

export const CategoryDeleteModal: FC<CategoryDeleteModalProps> = ({ onClose, show, onDeleteClick }) => {
    const intl = useIntl();
    const theme = useTheme() as ThemeType;
    const [safeDelete, setSafeDelete] = useState<boolean>(false)

    return <Dialog
        onClose={onClose}
        show={show}
        size={'md'}
        title={intl.formatMessage({ id: 'ParamsCategoryDeleteModalTitle', defaultMessage: 'Supprimer la catégorie' })}
        backgroundColor={theme.colors.danger}
        footer={<DeleteDialogFooter
            onCancelClick={onClose}
            onConfirmDeleteClick={() => onDeleteClick(safeDelete)} />}>
        <Radio
            name={"ParamsCategoryDeleteModalRadioInput"}
            onChange={() => setSafeDelete(!safeDelete)}
            value={safeDelete}>
            <FormattedMessage
                id={'ParamsCategoryDeleteModalSafeDeleteRadioLabel'}
                defaultMessage={'Uniquement cette catégorie. {br}Les prestations contenues dans cette catégorie resteront en place.'}
                values={{ br: <br /> }}
            />
        </Radio>
        <Radio
            name={"ParamsCategoryDeleteModalRadioInput"}
            onChange={() => setSafeDelete(!safeDelete)}
            value={!safeDelete}>
            <FormattedMessage
                id={'ParamsCategoryDeleteModalUnSafeDeleteRadioLabel'}
                defaultMessage={'La catégorie et les prestations qu\'elle contient. {br}La catégorie et toutes les prestations qu\'elle contient seront supprimées définitivement.'}
                values={{ br: <br /> }}
            />
        </Radio>
    </Dialog>
}