import {FC} from "react";
import {
    Col,
    IconName,
    SpanBody2,
    SpanSubtitle2,
    Svg,
    ThemeType
} from "@linkeo.com/ui-lib-react";
import {Link} from "react-router-dom";
import styled, {useTheme} from "styled-components";

const CardWrapper = styled(Col)<{
    borderTop?: string,
    borderRight?: string,
    borderBottom?: string,
    borderLeft?: string
}>`
    text-align: center;
    padding: 10px;
    flex: 1 0 auto;
    border-top: ${({borderTop}) => borderTop};
    border-right: ${({borderRight}) => borderRight};
    border-bottom: ${({borderBottom}) => borderBottom};
    border-left: ${({borderLeft}) => borderLeft};
    transition: background-color .2s ease;

    &:hover {
        background-color: ${({theme}) => theme.colors.grey90};
    }
`
const LinkWrapper = styled(Link)`
    text-decoration: inherit;
    display: flex;
    flex-wrap: nowrap;
    flex: 1 0 50%;
    flex-direction: column;
    min-height: 155px;
    min-width: 155px;
`

interface DashboardLinkCardProps {
    borderTop?: string;
    borderRight?: string;
    borderBottom?: string;
    borderLeft?: string;
    link: string;
    title: string;
    icon: IconName;
    subtitle: string
}

export const DashboardLinkCard: FC<DashboardLinkCardProps> = ({
                                                                  borderTop,
                                                                  borderRight,
                                                                  borderBottom,
                                                                  borderLeft,
                                                                  link,
                                                                  title,
                                                                  icon,
                                                                  subtitle
                                                              }) => {
    const theme = useTheme() as ThemeType;

    return <LinkWrapper to={link}>
        <CardWrapper borderTop={borderTop}
                     borderRight={borderRight}
                     borderBottom={borderBottom}
                     borderLeft={borderLeft}>
            <Svg icon={icon} fill={theme.colors.primary} height={20} width={20} style={{marginTop: '8px'}}/>
            <div style={{margin: '5px 0'}}>
                <SpanBody2>
                    {title}
                </SpanBody2>
            </div>
            <SpanSubtitle2 style={{fontSize: '12px', lineHeight: '14px'}}>
                {subtitle}
            </SpanSubtitle2>
        </CardWrapper>
    </LinkWrapper>
}