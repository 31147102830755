import {FC, useState} from "react";
import {Card, Col, Radio, Row, SpanSubtitle1} from "@linkeo.com/ui-lib-react";
import {FormattedMessage} from "react-intl";

interface ParamsBookingButtonFormProps {

}

export const ParamsBookingButtonForm: FC<ParamsBookingButtonFormProps> = () => {
    const [radioValue, setRadioValue] = useState<string>('DEMANDER UN RDV')

    return <Card style={{marginTop: '28px'}}>
        <form>
            <Row alignItems={'center'} justifyContent={'space-between'}>
                <Col columns={[12, 2.5]}>
                    <SpanSubtitle1>
                        <FormattedMessage id={'ParamsBookingButtonFormLabel'} defaultMessage={'Intitulé du bouton :'}/>
                    </SpanSubtitle1>
                </Col>
                <Col columns={[12, 3.5]}>
                    <Radio name={"DEMANDER UN RDV"} onChange={() => setRadioValue('DEMANDER UN RDV')}
                           value={radioValue === 'DEMANDER UN RDV'}>
                        <FormattedMessage id='ParamsBookingButtonOption1' defaultMessage={'DEMANDER UN RDV'}/>
                    </Radio>
                </Col>
                <Col columns={[12, 4]}>
                    <Radio name={"RÉSERVER"} onChange={() => setRadioValue('RÉSERVER')}
                           value={radioValue === 'RÉSERVER'}>
                        <FormattedMessage id='ParamsBookingButtonOption2' defaultMessage={'RÉSERVER'}/>
                    </Radio>
                </Col>
            </Row>
        </form>
    </Card>
}