import { FC, FormEvent, useEffect, useState } from "react";
import { Card, Checkbox, Col, Input, Row, SpanBody2, SpanSubtitle1, SquareButton, Textarea, ThemeType } from "@linkeo.com/ui-lib-react";
import { DragButton } from "../../commons/components/drag-button";
import { FormattedMessage, useIntl } from "react-intl";
import { useTheme } from "styled-components";
import { Tag } from "../../commons/components/tag";
import { FormFooter } from "../../commons/components/form-footer";

interface CategoryCardProps {
    title: string;
    description: string;
    services: string[];
    onDeleteClick: () => void;
    onDragOver: (e: React.DragEvent) => void;
    onDrop: () => void;
    onDragStart: () => void;
    onDragEnter: () => void;
    form: { title: string, description: string, services: string[] };
    onEditClick: () => void;
}

export const CategoryCard: FC<CategoryCardProps> = ({
    title,
    description,
    services,
    onDeleteClick,
    onDragEnter,
    onDragOver,
    onDragStart,
    onDrop,
    form: formProps,
    onEditClick
}) => {
    const intl = useIntl();
    const theme = useTheme() as ThemeType;
    const [readOnly, setReadOnly] = useState<boolean>(true);
    const [form, setForm] = useState(formProps);

    useEffect(() => {
        if (formProps.description !== description || formProps.title !== title) {
            setReadOnly(true)
        }
        setForm(formProps)
    }, [formProps, description, title])

    const _onSubmit = (e: FormEvent) => {
        e.preventDefault();
        e.stopPropagation();
    }

    const onCheckboxSelect = (value: string) => {
        const find = form.services.find(el => el === value)
        if (!find) {
            setForm({...form, services: [...form.services, value]})
        } else {
            setForm({...form, services: form.services.filter(el => el !== value)})
        }
    }

    return <Card style={{ marginBottom: '26px' }} draggable
        onDragEnter={onDragEnter}
        onDragOver={(e) => onDragOver(e)}
        onDrop={onDrop}
        onDragStart={onDragStart}>
        <form onSubmit={_onSubmit}>
            <Row>
                <Col columns={[12, 12, 1]}>
                    <DragButton />
                </Col>
                <Col columns={[12, 12, 3]}>
                    <div>
                        <SpanSubtitle1 style={{ color: theme.colors.grey60 }}>
                            <FormattedMessage
                                id={'ParamsCategoryCardTitle'}
                                defaultMessage={'Titre de la catégorie'}
                            />
                        </SpanSubtitle1>
                    </div>
                    {readOnly
                        ? <SpanBody2>{title}</SpanBody2>
                        : <Input value={form.title} onChange={(title) => setForm({ ...form, title })} />}
                </Col>
                <Col columns={[12, 12, 6]}>
                    <div>
                        <SpanSubtitle1 style={{ color: theme.colors.grey60 }}>
                            <FormattedMessage
                                id={'ParamsAgendasCardAgendaDescriptionTitle'}
                                defaultMessage={'Description :'}
                            />
                        </SpanSubtitle1>
                    </div>
                    {readOnly
                        ? <SpanSubtitle1 style={{ lineHeight: '28px' }}>
                            {description}
                        </SpanSubtitle1>
                        : <Textarea
                            value={form.description}
                            onChange={(description) => setForm({ ...form, description })} />}
                </Col>
                <Col columns={[12, 12, 2]}>
                    <SquareButton icon={'edit'} fontColor={theme.colors.grey40} style={{ margin: '0 15px' }}
                        onClick={() => {
                            setReadOnly(false);
                            onEditClick()
                        }} />
                    <SquareButton
                        icon={'delete'}
                        fontColor={theme.colors.grey40}
                        onClick={onDeleteClick} />
                </Col>
            </Row>
            <Row style={{ marginTop: '28px' }}>
                <Col>
                    <div>
                        <SpanSubtitle1 style={{ color: theme.colors.grey60 }}>
                            <FormattedMessage
                                id={'ParamsCategoryCardServiceSubtitle'}
                                defaultMessage={'Prestations associées :'}
                            />
                        </SpanSubtitle1>
                    </div>
                    {readOnly 
                    ? <div style={{ margin: '8px 0' }}>
                        {services.map((service, index) => {
                            return <Tag key={index}>
                                {service}
                            </Tag>
                        })}
                    </div>
                    : <Row style={{marginBottom: '28px'}}>
                    {services.map((service, index) => {
                        return <Col key={index}>
                            <Checkbox value={!!form.services.find(el => el === service)}
                                onChange={() => onCheckboxSelect(service)}>
                                {service}
                            </Checkbox>
                        </Col>
                    })}
                </Row>}
                </Col>
            </Row>
            {!readOnly && <FormFooter
                onCancelClick={() => setReadOnly(true)}
                submitButtonText={intl.formatMessage({ id: 'ConfirmButtonLabel', defaultMessage: 'Valider' })} />}
        </form>
    </Card>
}