import {FC, useState} from "react";
import {Card, Col, Row, Switch, ThemeType} from "@linkeo.com/ui-lib-react";
import {useIntl} from "react-intl";
import {useTheme} from "styled-components";
import {ParamsNotificationsSelectRow} from "./params-notifications-select-row";

interface ParamsNotificationsFormProps {
    emailLabel: string;
    smsLabel: string;
}

export const ParamsNotificationsForm: FC<ParamsNotificationsFormProps> = ({emailLabel, smsLabel}) => {
    const intl = useIntl();
    const theme = useTheme() as ThemeType;
    const [emailActive, setEmailActive] = useState<boolean>(true)
    const [smsActive, setSmsActive] = useState<boolean>(true)

    return <Card style={{marginTop: '28px'}}>
        <form>
            <Row>
                <Col columns={[12, 12, 6]}>
                    <Switch
                        onIcon={'check'}
                        offIcon={'cross'}
                        color={theme.colors.grey40}
                        onLabel={emailLabel}
                        value={emailActive}
                        onChange={(v) => setEmailActive(v)}
                    />
                    <ParamsNotificationsSelectRow
                        icon={'mail'}
                        text={intl.formatMessage({
                            id: 'ParamsNotificationsSelectEmailPreText',
                            defaultMessage: 'Envoyer l\'e-mail'
                        })}
                    />
                </Col>
                <Col columns={[12, 12, 6]}>
                    <Switch
                        onIcon={'check'}
                        offIcon={'cross'}
                        color={theme.colors.grey40}
                        onLabel={smsLabel}
                        value={smsActive}
                        onChange={(v) => setSmsActive(v)}
                    />
                    <ParamsNotificationsSelectRow
                        icon={'message'}
                        text={intl.formatMessage({
                            id: 'ParamsNotificationsSelectSmsPreText',
                            defaultMessage: 'Envoyer le SMS'
                        })}
                    />
                </Col>
            </Row>
        </form>
    </Card>
}