import {FC} from "react";
import {Col, SpanBody2, SpanSmallButton, SpanSubtitle1, ThemeType} from "@linkeo.com/ui-lib-react";
import styled, {useTheme} from "styled-components";
import {FormattedDate, FormattedTime} from "react-intl";
import {getContrastYIQ} from "../../utils/color";
import {Link} from "react-router-dom";

const DateTag = styled.div<{ color: string }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${({color}) => color};
    width: 110px;
    height: 110px;
    border-radius: 50%;
    margin: 0 auto 15px;
`

interface EventCardProps {
    index: number;
    date: string;
    color: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    link: string;
    isUnique?: boolean
}

export const EventCard: FC<EventCardProps> = ({
                                                  index,
                                                  date,
                                                  color,
                                                  firstName,
                                                  lastName,
                                                  link,
                                                  phone,
                                                  email,
                                                  isUnique
                                              }) => {
    const theme = useTheme() as ThemeType;

    return <Col columns={isUnique ? 12 : [12, 6, 3]} style={{borderLeft: index !== 0 ? `1px solid ${theme.colors.grey90}` : "none"}}>
        <Link to={link} style={{textDecoration: 'inherit'}}>
            <DateTag color={color}>
                <SpanBody2
                    style={{fontSize: '20px', color: getContrastYIQ(color, theme.colors.light, theme.colors.dark)}}>
                    <FormattedTime value={date} timeStyle={'short'}/>
                </SpanBody2>
                <div>
                    <SpanSmallButton style={{
                        textTransform: 'capitalize',
                        fontWeight: 600,
                        color: getContrastYIQ(color, theme.colors.light, theme.colors.dark)
                    }}>
                        <FormattedDate value={date} day={'numeric'} month={'short'}/>
                    </SpanSmallButton>
                </div>
            </DateTag>
            <div style={{textAlign: 'center'}}>
                <SpanBody2>{firstName} {lastName}</SpanBody2>
                <div style={{marginTop: '10px'}}>
                    <SpanSubtitle1 style={{color: theme.colors.grey40}}>{email}</SpanSubtitle1>
                </div>
                <SpanSubtitle1 style={{color: theme.colors.grey40}}>{phone}</SpanSubtitle1>
            </div>
        </Link>
    </Col>
}