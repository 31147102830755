import {FC, FormEvent, useState} from "react";
import {PageWrapper} from "../../commons/components/page-wrapper";
import {
    Card,
    Col,
    Container,
    H2,
    Row,
    SpanCaption2,
    SpanSubtitle2,
    ThemeType
} from "@linkeo.com/ui-lib-react";
import {FormattedMessage, useIntl} from "react-intl";
import {useTheme} from "styled-components";
import {FormProps, GiftcardForm} from "../../giftcards/components/giftcard-form";
import {FormFooter} from "../../commons/components/form-footer";
import {routeGiftcards} from "../../routes";
import {useNavigate} from "react-router";
import {GiftcardPreview} from "../../giftcards/components/giftcard-preview";
import {GiftcardSuccessModal} from "../../giftcards/components/giftcard-success-modal";

interface GiftcardCreatePageProps {

}

export const GiftcardCreatePage: FC<GiftcardCreatePageProps> = () => {
    const theme = useTheme() as ThemeType;
    const intl = useIntl();
    const navigate = useNavigate();
    const [isLoading] = useState<boolean>(false);
    const [loading] = useState<boolean>(false);
    const [openSuccessModal, setOpenSuccessModal] = useState<boolean>(false);
    const [hasError] = useState<boolean>(false)
    const [form, setForm] = useState<FormProps>({
        code: '65140A57B56A076',
        service: undefined,
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        purchaseDate: new Date().toString(),
        expirationDate: new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toString()
    })

    const _onSubmit = (e: FormEvent) => {
        e.preventDefault();
        e.stopPropagation()
        setOpenSuccessModal(true)
    }

    return <PageWrapper isLoading={isLoading}>
        <Container size={'lg'}>
            <Card>
                <H2>
                    <FormattedMessage id={'GiftcardCreatePageTitle'} defaultMessage={'Créer un bon cadeau'}/>
                </H2>
                <SpanSubtitle2 style={{color: theme.colors.grey70}}>
                    <FormattedMessage id={'GiftcardCreatePageSubTitle'}
                                      defaultMessage={'Paramétrez ici votre nouveau bon cadeau'}/>
                </SpanSubtitle2>
                <form onSubmit={_onSubmit}>
                    <Row style={{marginTop: "28px", marginBottom: "18px"}}>
                        <Col columns={[12, 12, 6]}>
                            <GiftcardForm form={form} setForm={setForm}/>
                        </Col>
                        <Col columns={[12, 12, 6]}>
                            <GiftcardPreview
                                currency={'EUR'}
                                code={form.code}
                                serviceName={form.service?.title}
                                serviceValue={form.service?.price}
                                purchaseDate={form.purchaseDate}
                                expirationDate={form.expirationDate}
                                lastName={form.lastName}
                                firstName={form.firstName}
                                email={form.email}
                                phone={form.phone}
                            />
                        </Col>
                    </Row>
                    <Row style={{padding: '18px 18px 32px'}}>
                        <SpanCaption2>
                            <FormattedMessage
                                id={'GiftcardCreatePageCaptioon'}
                                defaultMessage={'Le bon cadeau sera automatiquement envoyé par email au client à l\'enregistrement. Vous pouvez également imprimer le bon cadeau pour le remettre en main propre à votre client.'}/>
                        </SpanCaption2>
                        {hasError && <SpanSubtitle2 style={{paddingTop: '15px', color: theme.colors.danger}}>
                            <FormattedMessage
                                id={'GiftcardCreateErrorMessage'}
                                defaultMessage={'Une erreur s\'est produite, veuillez réessayer. Si le problème persiste, veuillez contacter le service client.'}/>
                        </SpanSubtitle2>}
                    </Row>
                    <FormFooter
                        onCancelClick={() => navigate(routeGiftcards)}
                        loading={loading}
                        submitButtonText={intl.formatMessage({
                            id: 'GiftcardCreatePageSubmitButtonText',
                            defaultMessage: 'Enregistrer le bon cadeau'
                        })}/>
                </form>
                <GiftcardSuccessModal
                    onClose={() => setOpenSuccessModal(false)}
                    openModal={openSuccessModal}
                    title={intl.formatMessage({
                        id: 'GiftcardCreatePageSuccessModalTitle',
                        defaultMessage: 'Le bon cadeau a été créé avec succès !'
                    })}
                    onClick={() => {
                    }}
                    textButton={intl.formatMessage({
                        id: 'GiftcardCreatePageSuccessModalButtonText',
                        defaultMessage: 'Imprimer le bon cadeau'
                    })}
                    text={intl.formatMessage({
                        id: 'GiftcardCreatePageSuccessModalText',
                        defaultMessage: 'Le bon cadeau n°{code} d\'une valeur de {value} pour la prestation: {title} a été créé avec succès et envoyé par email à {br}{email}. {br}Il expirera le {expirationDate}'
                    }, {
                        code: form.code,
                        value: intl.formatNumber(form.service?.price || 0, {style: 'currency', currency: 'EUR'}),
                        title: form.service?.title,
                        email: form.email,
                        expirationDate: intl.formatDate(form.expirationDate, {dateStyle: 'short'}),
                        br: <br/>
                    })}
                />
            </Card>
        </Container>
    </PageWrapper>
}