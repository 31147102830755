import React, {createContext, FC, useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {SsoApi} from "./sso-api";

interface SSOData {
    refresh_token?: string | null;
    token: string;
    expires: number;
    codeBouton: string;
}

const defaultSSOData: SSOData = {
    token: '',
    refresh_token: '',
    expires: 0,
    codeBouton: ''
}

const AuthContext = createContext<SSOData>(defaultSSOData)
export const useAuthStore = () => useContext(AuthContext);

interface AuthProviderProps {
    onTokenChange: (token: string) => void;
    onCodeBoutonChange: (codeBouton: string) => void;
    ssoUri: string;
    clientSecret: string;
    clientId: string;
}

const STORAGE_KEY = 'sso_token';
const getStoredData = (): SSOData | null => {
    const storedToken = sessionStorage.getItem(STORAGE_KEY);
    if (!storedToken) {
        return null;
    }
    return JSON.parse(storedToken);
};
const getQueryParams = (): { token: string | null, refresh_token: string | null } => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    const refresh_token = urlParams.get('refresh_token');
    return {
        token: token,
        refresh_token: refresh_token,
    };
};
const isExpired = (data: SSOData): boolean => {
    return (data.expires * 1000 - 10000) < Date.now();
}

export const AuthProvider: FC<AuthProviderProps> = ({
                                                        children,
                                                        onTokenChange,
                                                        onCodeBoutonChange,
                                                        clientSecret,
                                                        ssoUri,
                                                        clientId
                                                    }) => {
    const [authData, setData] = useState<SSOData | null>(null)
    const interval = useRef<number>()
    const ssoApi = useMemo(() => new SsoApi(ssoUri), [ssoUri])
    const queryParams = getQueryParams()

    const refresh = useCallback(async (refreshToken: string, codeBouton: string): Promise<SSOData> => {
        const data = await ssoApi.refresh(clientId, clientSecret, refreshToken);
        return {
            token: data.access_token,
            expires: data.expires_in + (Date.now() / 1000),
            refresh_token: data.refresh_token,
            codeBouton
        };
    }, [clientSecret, clientId, ssoApi]);

    const updateToken = useCallback( (SSOData: SSOData) => {
        onTokenChange(SSOData.token);
        onCodeBoutonChange(SSOData.codeBouton)
        sessionStorage.setItem(STORAGE_KEY, JSON.stringify(SSOData));
        if (interval.current) {
            clearTimeout(interval.current)
        }
        interval.current = window.setTimeout(() => {
            refresh(SSOData.refresh_token || '', SSOData.codeBouton).then((tokenData) => {
                updateToken(tokenData);
            });
        }, (SSOData.expires * 1000 - 10000) - Date.now());
        setData(SSOData);
    }, [onCodeBoutonChange, onTokenChange, refresh])

    useEffect(() => {
        const storedData = getStoredData()
        if (!storedData && (!queryParams.refresh_token || !queryParams.token)) {
            return window.location.replace(ssoUri)
        } else if (queryParams.refresh_token && queryParams.token) {
            ssoApi.getResource(queryParams.token).then((res) => {
                updateToken({
                    token: res.access_token,
                    expires: res.expires,
                    codeBouton: res.user_id,
                    refresh_token: queryParams.refresh_token,
                })
            }).catch(() => {
                return window.location.replace(ssoUri)
            })
        } else if (storedData) {
            if (isExpired(storedData) && storedData.refresh_token) {
                refresh(storedData.refresh_token, storedData.codeBouton).then(token => updateToken(token)).catch(() => {
                    return window.location.replace(ssoUri)
                })
            } else {
                updateToken(storedData)
            }
        }
        return;
    }, [ssoUri, queryParams.refresh_token, queryParams.token, ssoApi, refresh, updateToken])

    return <AuthContext.Provider value={authData || defaultSSOData}>
        {authData ? children : null}
    </AuthContext.Provider>
}