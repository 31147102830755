import {FC, useState} from "react";
import {Col, Row, Select, SelectOption} from "@linkeo.com/ui-lib-react";

interface ParamsCurrencyFormProps {
    currencyOptions: SelectOption<string>[]
}

export const ParamsCurrencyForm: FC<ParamsCurrencyFormProps> = ({currencyOptions}) => {
    const [selectedCurrency, setSelectedCurrency] = useState(currencyOptions[0])

    return <form>
        <Row style={{marginTop: '10px'}}>
            <Col columns={[12, 12, 6]}>
                <Select
                    options={currencyOptions}
                    onSelect={setSelectedCurrency}
                    selected={selectedCurrency}
                />
            </Col>
        </Row>
    </form>
}