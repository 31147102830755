import {FC, useEffect, useState} from "react";
import { PageWrapper } from "../../commons/components/page-wrapper";
import {Card, Col, Container, Row, getTypo, useToaster, Uploader} from "@linkeo.com/ui-lib-react";
import { ParamsCard } from "../../params/components/params-card";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";
import {useAuthStore} from "../../providers/sso/auth-provider";
import {uploaderApi} from "../../config/config";
import {useConfigurationStore} from "../../params/configuration-store";
import {Configuration} from "../../params/configuration-types";
import {File} from "../../commons/common-types";

const Label = styled.label`
    ${getTypo('input')};
    display: flex;
    margin: 0 0 20px;
`

export const ParamsPersonalizationPage: FC = () => {
    const intl = useIntl();
    const toast = useToaster();
    const {token, codeBouton} = useAuthStore();
    const {config, fetchData, updateConfig} = useConfigurationStore();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;
        try {
            fetchData(signal);
        } catch {
            controller.abort()
        } finally {
            setIsLoading(false)
        }
        return () => {
            controller.abort()
        }

    }, [fetchData]);

    const onFileChange = async (key: keyof Configuration, file?: File) => {
        try {
            await updateConfig({...config, [key]: file })
        } catch (e) {
            console.error(e);
            toast(intl.formatMessage({id: 'errorMessage', defaultMessage: 'Une erreur est survenue'}))
        }
    }

    return <PageWrapper isLoading={isLoading}>
        <Container size={'lg'}>
            <ParamsCard
                title={intl.formatMessage({ id: 'ParamsPersonalizationPageTitle', defaultMessage: 'Personnalisation' })}
                subtitle={intl.formatMessage({ id: 'ParamsPersonalizationPageSubTitle', defaultMessage: 'Ajouter ou modifier vos photos de profil et de couverture pour personnaliser vos bons cadeaux.' })}>
                <Card style={{ marginTop: '28px' }}>
                    <Row style={{rowGap: '38px'}}>
                        <Col columns={[12, 12, 6]}>
                            <Label>
                                <FormattedMessage 
                                    id={'ParamsPersonalizationProfilLogoLabel'}
                                    defaultMessage={'Ajouter un logo'}
                                />
                            </Label>
                            <Uploader
                                small
                                buttonLabel={intl.formatMessage({
                                    id: 'buttonLabel',
                                    description: 'parcourir l\'ordinateur'
                                })}
                                middleLabel={intl.formatMessage({
                                    id: 'ParamsServiceInfosFormImageBlockupLoaderMessage',
                                    defaultMessage: 'Déposer{br} une image ici ou'
                                }, {br: <br/>}) as string}
                                onError={() => toast(intl.formatMessage({
                                    id: 'errorMessage',
                                    defaultMessage: 'Une erreur est survenue'
                                }))}
                                previewStyle={{borderRadius: '3px'}}
                                width={'200px'} height={'200px'}
                                fileType={'image/*'}
                                apiBaseUri={uploaderApi}
                                codeBouton={codeBouton}
                                token={token}
                                value={config.logoFile}
                                onChange={(file) => onFileChange('logoFile', file)}
                            />
                        </Col>
                        <Col columns={[12, 12, 6]}>
                            <Label>
                                <FormattedMessage 
                                    id={'ParamsPersonalizationCoverImageLabel'}
                                    defaultMessage={'Ajouter une image de couverture'}
                                />
                            </Label>
                            <Uploader
                                small
                                buttonLabel={intl.formatMessage({
                                    id: 'buttonLabel',
                                    description: 'parcourir l\'ordinateur'
                                })}
                                middleLabel={intl.formatMessage({
                                    id: 'ParamsServiceInfosFormImageBlockupLoaderMessage',
                                    defaultMessage: 'Déposer{br} une image ici ou'
                                }, {br: <br/>}) as string}
                                onError={() => toast(intl.formatMessage({
                                    id: 'errorMessage',
                                    defaultMessage: 'Une erreur est survenue'
                                }))}
                                previewStyle={{borderRadius: '3px'}}
                                width={'200px'} height={'200px'}
                                fileType={'image/*'}
                                apiBaseUri={uploaderApi}
                                codeBouton={codeBouton}
                                token={token}
                                value={config.coverImageFile}
                                onChange={(file) => onFileChange('coverImageFile', file)}
                            />
                        </Col>
                    </Row>
                </Card>
            </ParamsCard>
        </Container>
    </PageWrapper>
}