import {FC, useState} from "react";
import {Col, Radio, Row, Switch} from "@linkeo.com/ui-lib-react";
import {RadioInput} from "./radio-input";
import {useIntl} from "react-intl";

interface DownPaymentRadioGroupProps {
    initialDownPaymentValue?: number;
    downPayment?: number;
    onChange: (v: number | undefined) => void;
    disabled: boolean;
    includeZero?: boolean;
}

export const DownPaymentRadioGroup: FC<DownPaymentRadioGroupProps> = ({
                                                                          initialDownPaymentValue,
                                                                          downPayment,
                                                                          onChange,
                                                                          disabled,
                                                                          includeZero
                                                                      }) => {
    const [customDepositValue, setCustomDepositValue] = useState<number | null>(((typeof initialDownPaymentValue === 'number')
        && (includeZero ? initialDownPaymentValue >= 0 : initialDownPaymentValue > 0)
        && initialDownPaymentValue !== 0.25
        && initialDownPaymentValue !== 0.50
        && initialDownPaymentValue !== 0.75
        && initialDownPaymentValue !== 1.0)
        ? initialDownPaymentValue * 100
        : null)

    return <>
        <Col>
            <Radio name={'ParamsDepositForm'}
                   disabled={disabled}
                   onChange={() => onChange(0.25)}
                   value={downPayment === 0.25}>25%</Radio>
        </Col>
        <Col>
            <Radio name={'ParamsDepositForm'}
                   disabled={disabled}
                   onChange={() => onChange(0.50)}
                   value={downPayment === 0.50}>50%</Radio>
        </Col>
        <Col>
            <Radio name={'ParamsDepositForm'}
                   disabled={disabled}
                   onChange={() => onChange(0.75)}
                   value={downPayment === 0.75}>75%</Radio>
        </Col>
        <Col>
            <Radio name={'ParamsDepositForm'}
                   disabled={disabled}
                   onChange={() => onChange(1.0)}
                   value={downPayment === 1.0}>100%</Radio>
        </Col>
        <Col>
            <Radio name={'ParamsDepositForm'}
                   disabled={disabled}
                   onChange={() => {
                       if (customDepositValue) {
                           onChange(customDepositValue / 100)
                       } else if (typeof customDepositValue === 'number' && includeZero) {
                           onChange(customDepositValue / 100)
                       } else {
                           setCustomDepositValue(10);
                           onChange(0.10)
                       }
                   }}
                   value={(typeof downPayment === 'number') && (includeZero ? downPayment >= 0 : downPayment > 0) && downPayment !== 0.25 && downPayment !== 0.50 && downPayment !== 0.75 && downPayment !== 1.0}>
                <RadioInput inputValue={customDepositValue}
                            onInputChange={(v) => {
                                setCustomDepositValue(v);
                                onChange(v ? v / 100 : 0)
                            }}
                            minValue={includeZero ? 0 : 1}
                            disabled={disabled}
                            maxValue={99}
                            postText={'%'}/>
            </Radio>
        </Col>
    </>
}

interface DownPaymentFormProps {
    downPayment?: number;
    initialDownPaymentValue?: number;
    onChange: (v: number | undefined) => void;
    disabledSwitch?: boolean;
}

export const DownPaymentForm: FC<DownPaymentFormProps> = ({
                                                              downPayment,
                                                              initialDownPaymentValue,
                                                              onChange,
                                                              disabledSwitch
                                                          }) => {
    const intl = useIntl();

    return <Row>
        <Col>
            <Switch value={downPayment !== undefined}
                    disabled={disabledSwitch}
                    onChange={(v) => onChange(v ? 0 : undefined)}
                    onLabel={intl.formatMessage({
                        id: 'ParamsServiceEditFormDepositSwitchLabel',
                        defaultMessage: 'Activer l\'acompte personnalisé'
                    })}/>
        </Col>
        <Col>
            <Row>
                <Col>
                    <Radio name={'ParamsDepositForm'}
                           disabled={downPayment === undefined}
                           onChange={() => onChange(0)}
                           value={downPayment === 0}>0%</Radio>
                </Col>

                <DownPaymentRadioGroup downPayment={downPayment}
                                       onChange={onChange}
                                       initialDownPaymentValue={initialDownPaymentValue}
                                       disabled={downPayment === undefined}/>
            </Row>
        </Col>
    </Row>
}