import React, {FC, useEffect, useState} from "react";
import {PageWrapper} from "../../commons/components/page-wrapper";
import {Container, Dialog, InfiniteScroll, SpanBody1, useToaster} from "@linkeo.com/ui-lib-react";
import {ContainerHeader} from "../../commons/components/container-header";
import {useIntl} from "react-intl";
import {routeOptionsCreate, routeOptionsEdit} from "../../routes";
import {OptionCard} from "../../options/components/option-card";
import {EmptyList} from "../../commons/components/empty-list";
import {useOptionsStore} from "../../options/options-store";
import {AxiosError} from "axios";
import {generatePath} from "react-router-dom";
import {DialogFooter} from "../../commons/components/dialog-footer";

export const OptionsListPage: FC = () => {
    const intl = useIntl();
    const toast = useToaster();
    const {optionsList, fetchOptionsList, loadMoreOptions, deleteOption} = useOptionsStore();
    const [loading, setLoading] = useState<boolean>(true)
    const [openModal, setOpenModal] = useState<boolean>(false)
    const [selectedOptionId, setSelectedOptionId] = useState<string>('')
    const [loader, setLoader] = useState<boolean>(false)
    const [lock, setLock] = useState<boolean>(false)

    useEffect(() => {
        fetchOptionsList()
            .catch((err: AxiosError) => console.error(err))
            .finally(() => setLoading(false))
    }, [fetchOptionsList])

    const onRemoveClick = (id: string) => {
        setSelectedOptionId(id)
        setOpenModal(true)
    }

    const onDialogConfirm = async () => {
        setOpenModal(false)
        setLoading(true)
        try {
            await deleteOption(selectedOptionId)
            await fetchOptionsList()
        } catch (error) {
            console.error(error)
            toast(intl.formatMessage({id: 'errorMessage', defaultMessage: 'Une erreur est survenue'}))
        } finally {
            setLoading(false)
        }
    }

    const onListLoading = () => {
        setLoader(true);
        loadMoreOptions()
            .then((value) => setLock(value))
            .catch((err: AxiosError) => console.error(err))
            .finally(() => setLoader(false))
    }

    return <PageWrapper isLoading={loading}>
        <Container size={'lg'}>
            {optionsList.length > 0 ?
                <>
                    <ContainerHeader
                        title={intl.formatMessage({
                            id: 'OptionsListPageHeaderTitle',
                            defaultMessage: 'Liste des options'
                        })}
                        subtitle={intl.formatMessage({
                            id: 'OptionsListPageHeaderSubtitle',
                            defaultMessage: 'Ajouter des options que vous pourrez appliquer à vos prestations et réservations'
                        })}
                        buttonLabel={intl.formatMessage({
                            id: 'OptionsListPageHeaderButtonLabel',
                            defaultMessage: 'Ajouter une option'
                        })}
                        to={routeOptionsCreate}
                    />

                    <Dialog onClose={() => setOpenModal(false)}
                            show={openModal}
                            size={'md'}
                            title={intl.formatMessage({
                                id: 'optionsListPageRemoveOptionModalTitle',
                                defaultMessage: 'Supprimer cette option'
                            })}
                            footer={<DialogFooter onCancelClick={() => setOpenModal(false)}
                                                  onApproveClick={onDialogConfirm}/>}>
                        <SpanBody1>{intl.formatMessage({
                            id: 'optionsListPageRemoveOptionModalText',
                            defaultMessage: 'Etes-vous certain de vouloir supprimer cette option?'
                        })}</SpanBody1>
                    </Dialog>

                    <InfiniteScroll
                        isLoading={loader}
                        lock={lock}
                        moreResultLabel={intl.formatMessage({
                            id: 'optionListPageLoadMoreLabel',
                            defaultMessage: 'Charger plus d\'options'
                        })}
                        onNext={onListLoading}>
                        {optionsList.map(option => <OptionCard key={option.id}
                                                               name={option.name}
                                                               type={option.type}
                                                               choices={option.choices.map(e => e.label)}
                                                               onRemoveClick={() => onRemoveClick(option.id)}
                                                               link={generatePath(routeOptionsEdit, {optionId: option.id})}
                        />)}
                    </InfiniteScroll>
                </>
                : <EmptyList
                    buttonLabel={intl.formatMessage({
                        id: 'OptionsListPageHeaderButtonLabel',
                        defaultMessage: 'Ajouter une option'
                    })}
                    to={routeOptionsCreate}
                    icon={'gear'}
                    title={intl.formatMessage({id: 'OptionsListPageHeaderTitle', defaultMessage: 'Liste des options'})}
                    subtitle={intl.formatMessage({
                        id: 'OptionsListPageEmptyListSubtitle',
                        defaultMessage: 'Ajouter des options que vous pourrez appliquer à vos prestations{br} et réservations'
                    }, {br: <br/>})}
                />}
        </Container>
    </PageWrapper>
}