import {FC, useState} from "react";
import {Card, Col, Input, Radio, Row, SpanLink, SpanSubtitle2, ThemeType} from "@linkeo.com/ui-lib-react";
import {ReactComponent as StripeIcon} from "../../../icons/stripe.svg";
import {FormattedMessage, useIntl} from "react-intl";
import {useTheme} from "styled-components";
import {live, Mode, PaymentConfig, test} from "../../configuration-types";

interface PaymentStripeFormProps {
    mode: Mode;
    onPaymentModeChange: (mode: Mode) => void;
    pk_test?: string;
    sk_test?: string;
    pk_live?: string;
    sk_live?: string;
    onStripeKeyUpdate: (config: PaymentConfig) => void;
}

interface StripeKey extends Omit<PaymentConfig, 'mode'> {
}

export const PaymentStripeForm: FC<PaymentStripeFormProps> = ({
                                                                  mode,
                                                                  onPaymentModeChange,
                                                                  pk_test: pk_test_props,
                                                                  sk_test: sk_test_props,
                                                                  sk_live: sk_live_props,
                                                                  pk_live: pk_live_props,
                                                                  onStripeKeyUpdate
                                                              }) => {
    const theme = useTheme() as ThemeType;
    const intl = useIntl();
    const [value, setValue] = useState<StripeKey>({
        pk_test: pk_test_props,
        sk_test: sk_test_props,
        pk_live: pk_live_props,
        sk_live: sk_live_props
    })

    const onStripeKeyChange = (val: string, key: keyof PaymentConfig) => {
        setValue(prevState => {
            const updatedState: StripeKey = {...prevState};
            (Object.keys(updatedState) as (keyof StripeKey)[]).forEach(key => {
                if (updatedState[key] === '') {
                    updatedState[key] = undefined;
                }
                return updatedState;
            })
            return {...updatedState, [key]: val === '' ? undefined : val}
        })
    }

    return <Card style={{marginTop: '28px'}}>
        <form>
            <Row alignItems={'center'} justifyContent={'space-between'}>
                <Col>
                    <StripeIcon/>
                </Col>
                <Col>
                    <SpanSubtitle2>
                        <FormattedMessage
                            id={'ParamsPaymentStripeTitle'}
                            defaultMessage={'Le paiement en ligne est assuré par Stripe. {br}Stripe prélève 1.4% + 0,25cts par transaction effectuée.'}
                            values={{br: <br/>}}
                        />
                    </SpanSubtitle2>
                </Col>
                <Col>
                    <a href={'https://dashboard.stripe.com/dashboard'} target={'_blank'} rel="noreferrer">
                        <SpanLink>
                            <FormattedMessage
                                id={'ParamsPaymentStripeLinkLabel'}
                                defaultMessage={'Accéder à stripe.com'}
                            />
                        </SpanLink>
                    </a>
                </Col>
            </Row>
            <div style={{marginTop: '28px', paddingTop: '28px', borderTop: `1px solid ${theme.colors.grey90}`}}>
                <SpanSubtitle2>
                    <FormattedMessage
                        id={'ParamsPaymentStripeFormTitle'}
                        defaultMessage={'Pour activer défintivement le paiement sur votre site. {br}Merci de renseigner les informations relatives à votre compte Stripe ci-dessous.'}
                        values={{br: <br/>}}
                    />
                </SpanSubtitle2>
                <Row style={{marginLeft: '1px'}}>
                    <Col>
                        <Radio
                            name={'ParamsPaymentStripeRadio'}
                            onChange={() => onPaymentModeChange(live)}
                            value={mode === live}>
                            <FormattedMessage
                                id={'ParamsPaymentStripeRadioLabelLiveMode'}
                                defaultMessage={'Mode live'}
                            />
                        </Radio>
                    </Col>
                    <Col>
                        <Radio
                            name={'ParamsPaymentStripeRadio'}
                            onChange={() => onPaymentModeChange(test)}
                            value={mode === test}>
                            <FormattedMessage
                                id={'ParamsPaymentStripeRadioLabelTestMode'}
                                defaultMessage={'Mode test'}
                            />
                        </Radio>
                    </Col>
                </Row>
                <Row>
                    <Col columns={[12, 12, 6]}>
                        <Input
                            type={'text'}
                            placeholder={'pk_test_XXXXXXXXXXXXXXXXXXXXX'}
                            required
                            disabled={mode === live}
                            value={value.pk_test}
                            onChange={(pk_test) => onStripeKeyChange(pk_test, 'pk_test')}
                            onBlur={(val) => onStripeKeyUpdate({
                                ...value,
                                pk_test: val.target.value === '' ? undefined : val.target.value,
                                mode
                            })}
                            label={intl.formatMessage({id: 'PublishableKey', defaultMessage: 'Publishable key'})}
                        />
                    </Col>
                    <Col columns={[12, 12, 6]}>
                        <Input
                            type={'text'}
                            placeholder={'sk_test_XXXXXXXXXXXXXXXXXXXXX'}
                            required
                            disabled={mode === live}
                            value={value.sk_test}
                            onChange={(sk_test) => onStripeKeyChange(sk_test, 'sk_test')}
                            onBlur={(val) => onStripeKeyUpdate({
                                ...value,
                                sk_test: val.target.value === '' ? undefined : val.target.value,
                                mode
                            })}
                            label={intl.formatMessage({id: 'SecretKey', defaultMessage: 'Secret key'})}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col columns={[12, 12, 6]}>
                        <Input
                            type={'text'}
                            placeholder={'pk_live_XXXXXXXXXXXXXXXXXXXXX'}
                            required
                            disabled={mode === test}
                            value={value.pk_live}
                            onChange={(pk_live) => onStripeKeyChange(pk_live, 'pk_live')}
                            onBlur={(val) => onStripeKeyUpdate({
                                ...value,
                                pk_live: val.target.value === '' ? undefined : val.target.value,
                                mode
                            })}
                            label={intl.formatMessage({id: 'PublishableKey', defaultMessage: 'Publishable key'})}
                        />
                    </Col>
                    <Col columns={[12, 12, 6]}>
                        <Input
                            type={'text'}
                            placeholder={'sk_live_XXXXXXXXXXXXXXXXXXXXX'}
                            required
                            disabled={mode === test}
                            value={value.sk_live}
                            onChange={(sk_live) => onStripeKeyChange(sk_live, 'sk_live')}
                            onBlur={(val) => onStripeKeyUpdate({
                                ...value,
                                sk_live: val.target.value === '' ? undefined : val.target.value,
                                mode
                            })}
                            label={intl.formatMessage({id: 'SecretKey', defaultMessage: 'Secret key'})}
                        />
                    </Col>
                </Row>
            </div>
        </form>
    </Card>
}