import {FC, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {ParamsBookingShowCard} from "./params-booking-show-card";
import {ParamsBookingInputGroup} from "./params-booking-input-group";
import {Col, Radio} from "@linkeo.com/ui-lib-react";

interface ParamsBookingAuthentificationFormProps {

}

export const ParamsBookingAuthentificationForm: FC<ParamsBookingAuthentificationFormProps> = () => {
    const intl = useIntl();
    const [needLogIn, setNeedLogIn] = useState<boolean>(false);
    const [needMembership, setNeedMembership] = useState<boolean>(false)

    return <ParamsBookingShowCard
        title={intl.formatMessage({
            id: 'ParamsBookingAuthentificationTitle',
            defaultMessage: 'Rendez-vous des clients'
        })}
        subtitle={intl.formatMessage({
            id: 'ParamsBookingAuthentificationSubTitle',
            defaultMessage: 'Paramétrez les possibilités de réservation offertes à vos clients.'
        })}
        switched={needLogIn}
        setSwitched={setNeedLogIn}
        switchLabel={intl.formatMessage({
            id: 'ParamsBookingAuthentificationSwitchLabel',
            defaultMessage: 'Les clients doivent s\'authentifier avant de voir les prestations'
        })}
        caption={intl.formatMessage({
            id: 'ParamsBookingAuthentificationSwitchCaption',
            defaultMessage: 'Si vous activez cette option, les clients devront commencer par s\'authentifier pour avoir accès à vos prestations. Sinon l\'authentification sera demandée uniquement pour valider le rendez-vous.'
        })}>
        <ParamsBookingInputGroup type={'radio'}
                                 label={intl.formatMessage({
                                     id: 'ParamsBookingAuthentificationRadioLabel',
                                     defaultMessage: 'Quelles prestations vos clients peuvent-ils réserver ?'
                                 })}
                                 caption={intl.formatMessage({
                                     id: 'ParamsBookingAuthentificationRadioCaption',
                                     defaultMessage: 'En sélectionnant "Toutes les prestations", vos clients pourront prendre rendez-vous pour une prestaion à laquelle ils ne sont pas abonné en la payant au tarif normal.'
                                 })}>
            <Col>
                <Radio name={'ParamsBookingMembershipOption'} value={!needMembership}
                       onChange={() => setNeedMembership(!needMembership)}>
                    <FormattedMessage
                        id={'ParamsBookingAuthentificationNoMembershipNeededLabel'}
                        defaultMessage={'Toutes les prestations'}
                    />
                </Radio>
            </Col>
            <Col>
                <Radio name={'ParamsBookingMembershipOption'} value={needMembership}
                       onChange={() => setNeedMembership(!needMembership)}>
                    <FormattedMessage
                        id={'ParamsBookingAuthentificationMembershipNeededLabel'}
                        defaultMessage={'Uniquement les prestations auxquelles ils sont abonnées'}
                    />
                </Radio>
            </Col>
        </ParamsBookingInputGroup>
    </ParamsBookingShowCard>
}