import {FC} from "react";
import {Card, SpanBody2, SpanSubtitle1, SpanSubtitle2, Svg, ThemeType} from "@linkeo.com/ui-lib-react";
import {FormattedMessage, useIntl} from "react-intl";
import {useTheme} from "styled-components";

interface GiftcardPreviewProps {
    code: string;
    serviceName?: string;
    serviceValue?: number;
    currency: string;
    purchaseDate: string;
    expirationDate: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string
}

export const GiftcardPreview: FC<GiftcardPreviewProps> = ({
                                                              code,
                                                              serviceName,
                                                              serviceValue,
                                                              currency,
                                                              purchaseDate,
                                                              expirationDate,
                                                              firstName,
                                                              lastName,
                                                              email,
                                                              phone
                                                          }) => {
    const theme = useTheme() as ThemeType;
    const intl = useIntl();

    return <Card style={{marginTop: '42px', wordWrap: 'break-word'}}>
        <Svg icon={'gift'} width={24} height={24} style={{display: 'block', margin: 'auto'}}/>
        <div style={{padding: '16px', borderBottom: `1px solid ${theme.colors.grey90}`, textAlign: 'center'}}>
            <SpanBody2>
                <FormattedMessage
                    id={'GiftcardCreatePageRecapTitle'}
                    defaultMessage={'Bon cadeau n°{code}'}
                    values={{code: code}}
                />
            </SpanBody2>
        </div>
        <div style={{paddingTop: "16px"}}>
            <SpanSubtitle1>
                <FormattedMessage
                    id={'GiftcardPreviewServiceTitle'}
                    defaultMessage={'Prestation concernée: '}
                />
            </SpanSubtitle1>
            <SpanSubtitle2>{serviceName}</SpanSubtitle2>
        </div>
        <SpanSubtitle1>
            <FormattedMessage
                id={'GiftcardPreviewServicePriceTitle'}
                defaultMessage={'Valeur du bon: '}
            />
        </SpanSubtitle1>
        {serviceValue &&
            <SpanSubtitle2>{intl.formatNumber(serviceValue, {style: 'currency', currency})}</SpanSubtitle2>}
        <div>
            <SpanSubtitle1>
                <FormattedMessage
                    id={'GiftcardPreviewPurchaseDateTitle'}
                    defaultMessage={'Date d\'achat: '}
                />
            </SpanSubtitle1>
            <SpanSubtitle2>{intl.formatDate(purchaseDate, {dateStyle: 'short'})}</SpanSubtitle2>
        </div>
        <SpanSubtitle1>
            <FormattedMessage
                id={'GiftcardPreviewExpirationDateTitle'}
                defaultMessage={'Date d\'expiration: '}
            />
        </SpanSubtitle1>
        <SpanSubtitle2>{intl.formatDate(expirationDate, {dateStyle: 'short'})}</SpanSubtitle2>
        <div>
            <SpanSubtitle1>
                <FormattedMessage
                    id={'GiftcardPreviewGiftedByTitle'}
                    defaultMessage={'Offert par: '}
                />
            </SpanSubtitle1>
            <SpanSubtitle2>{firstName + ' ' + lastName}</SpanSubtitle2>
        </div>
        <SpanSubtitle1>
            <FormattedMessage
                id={'email'}
                defaultMessage={'Email'}
            />{': '}
        </SpanSubtitle1>
        <SpanSubtitle2>{email}</SpanSubtitle2>
        <div>
            <SpanSubtitle1>
                <FormattedMessage
                    id={'CustomerEditPagePhoneInputLabel'}
                    defaultMessage={'Téléphone'}
                />{': '}
            </SpanSubtitle1>
            <SpanSubtitle2>{phone}</SpanSubtitle2>
        </div>
    </Card>
}