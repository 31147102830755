import {FC, useState} from "react";
import {useIntl} from "react-intl";
import {EmptyListText} from "./empty-list-text";
import {InfiniteScroll, ThemeType} from "@linkeo.com/ui-lib-react";
import {ListCard} from "./list-card";
import {useTheme} from "styled-components";
import {CardLoading} from "./card-loading";

interface CustomersGiftcardsListProps {
    currency: string;
    length: number
}

export const CustomersGiftcardsList: FC<CustomersGiftcardsListProps> = ({currency, length}) => {
    const intl = useIntl();
    const theme = useTheme() as ThemeType;
    const [loading] = useState<boolean>(false);
    const [isLoading] = useState<boolean>(false);
    const [lock] = useState<boolean>(false)

    return loading ? <CardLoading/>
        : length > 0
            ? <InfiniteScroll isLoading={isLoading} lock={lock}
                              moreResultLabel={intl.formatMessage({
                                  id: 'DefaultMoreResultLabel',
                                  defaultMessage: 'Charger plus'
                              })}
                              onNext={() => {
                              }}>
                <ListCard
                    index={0}
                    giftcardCode={'6509C2FD788DD35'}
                    title={'AQUAMIX'}
                    details={intl.formatMessage({
                            id: 'GiftcardsValidityDate',
                            defaultMessage: 'jusqu\'au {date}'
                        },
                        {date: intl.formatDate(new Date().toString(), {dateStyle: 'short'})})}
                    icon={'check-circle'}
                    status={intl.formatMessage({id: 'GiftcardStatusOK', defaultMessage: "Bon cadeau valable"})}
                    value={26} currency={currency} color={theme.colors.success}/>
                <ListCard
                    index={1}
                    giftcardCode={'6509C2HHGHG55D35'}
                    title={'Cours d\'espagnol'}
                    details={intl.formatMessage({
                        id: 'GiftcardsExpirationDate',
                        defaultMessage: 'le {date}'
                    }, {date: intl.formatDate(new Date().toString(), {dateStyle: 'short'})})}
                    icon={'cross-circle'}
                    status={intl.formatMessage({
                        id: 'GiftcardStatusExpired',
                        defaultMessage: "Bon cadeau expiré"
                    })}
                    value={32} currency={currency} color={theme.colors.grey60}/>
                <ListCard
                    index={1}
                    giftcardCode={'6509TAFSQ32585D35'}
                    title={'AQUAMIX'}
                    details={intl.formatMessage({
                        id: 'GiftcardsExpirationDate',
                        defaultMessage: 'le {date}'
                    }, {date: intl.formatDate(new Date().toString(), {dateStyle: 'short'})})}
                    icon={'cross-circle'}
                    status={intl.formatMessage({id: 'GiftcardStatusUsed', defaultMessage: "Bon cadeau déjà utilisé"})}
                    value={32} currency={currency} color={theme.colors.danger}/>
            </InfiniteScroll>
            : <EmptyListText text={intl.formatMessage({
                id: 'CustomersGiftcardsListEmpty',
                defaultMessage: 'Aucun bon cadeau existant pour ce client.'
            })}/>
}