import {FC, useEffect, useState} from "react";
import {Container, InfiniteScroll} from "@linkeo.com/ui-lib-react";
import {useIntl} from "react-intl";
import {PageWrapper, PageWrapperSubHeader} from "../../commons/components/page-wrapper";
import {ContainerHeader} from "../../commons/components/container-header";
import {EmptyList} from "../../commons/components/empty-list";
import {CustomerCard} from "../../customers/components/customer-card";
import {CustomersNav} from "../../customers/components/customers-nav";
import {routeCustomerCreate, routeCustomersEdit} from "../../routes";
import {generatePath} from "react-router-dom";
import {BackToTop} from "../../commons/components/back-to-top";

interface CustomersListPageProps {

}

const customersList = [
    {
        id: 'aqwrfvuj',
        email: 'abc@email.com',
        firstName: 'Prenom',
        lastName: 'Nom',
        phone: '0600000000'
    },
    {
        id: 'zsxtgbik',
        email: 'hello@email.com',
        firstName: 'Margaux',
        lastName: 'Montet',
        phone: '0666666666'
    },
    {
        id: 'edcyhnol',
        email: 'test@email.com',
        firstName: 'Prenom',
        lastName: 'Test',
        phone: '0000000000'
    }
]

export const CustomersListPage: FC<CustomersListPageProps> = () => {
    const intl = useIntl();
    const [filteredList, setFilteredList] = useState(customersList)
    const [loading] = useState<boolean>(false);
    const [isLoading] = useState<boolean>(false);
    const [lock] = useState<boolean>(false);
    const [isSelected, setIsSelected] = useState<boolean>(false);
    const [searchValue, setSearchValue] = useState<string>('')

    useEffect(() => {
        const lowerSearchValue = searchValue.toLowerCase()
        setFilteredList(customersList.filter(el => Object.values(el).some(value => value.toLowerCase().includes(lowerSearchValue))))
    }, [searchValue])

    return <PageWrapper isLoading={loading}>
        <PageWrapperSubHeader>
            <CustomersNav
                onSelectClick={() => setIsSelected(!isSelected)}
                isSelected={isSelected}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
            />
        </PageWrapperSubHeader>
        <Container size={'lg'}>
            {customersList.length > 0 ? <>
                    <ContainerHeader
                        title={intl.formatMessage({
                            id: 'CustomerListPageContainerHeaderTitle',
                            defaultMessage: 'Fichier clients'
                        })}
                        subtitle={intl.formatMessage({
                            id: 'CustomerListPageContainerHeaderSubtitle',
                            defaultMessage: 'Retrouvez ici la liste des clients ayant créé un compte sur votre site.'
                        })}
                        buttonLabel={intl.formatMessage({
                            id: 'CustomerListPageContainerButtonLabel',
                            defaultMessage: '+ Ajouter un client'
                        })}
                        to={routeCustomerCreate}/>


                    <InfiniteScroll isLoading={isLoading} lock={lock} onNext={() => {
                    }}
                                    moreResultLabel={intl.formatMessage({
                                        id: 'CustomerListPageMoreResultLabel',
                                        defaultMessage: '+ Charger plus de contacts'
                                    })}>
                        {filteredList.map(customer => {
                            return <CustomerCard key={customer.id}
                                                 firstName={customer.firstName}
                                                 lastName={customer.lastName}
                                                 email={customer.email}
                                                 phone={customer.phone}
                                                 link={generatePath(routeCustomersEdit, {id: customer.id})}
                                                 isSelected={isSelected}
                            />
                        })}
                    </InfiniteScroll> </>
                : <EmptyList
                    buttonLabel={intl.formatMessage({
                        id: 'CustomerListPageContainerButtonLabel',
                        defaultMessage: '+ Ajouter un client'
                    })}
                    to={routeCustomerCreate}
                    title={intl.formatMessage({
                        id: 'CustomerListPageContainerHeaderTitle',
                        defaultMessage: 'Fichier clients'
                    })}
                    subtitle={intl.formatMessage({
                        id: 'CustomersListPageEmptyListSubtitle',
                        defaultMessage: 'Vous n\'avez pas encore de contacts dans votre fichier clients'
                    })}
                    icon={'person'}/>
            }
        <BackToTop/>
        </Container>
    </PageWrapper>
}