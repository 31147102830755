import React, {FC} from "react";
import {BaseButton, Col, IconName, Row, SpanBody2, SpanSubtitle1, Svg, ThemeType} from "@linkeo.com/ui-lib-react";
import {FormattedMessage} from "react-intl";
import {useTheme} from "styled-components";

interface GiftcardInfoCardProps {
    color: string;
    code: string;
    giftedBy: string;
    icon: IconName;
    statusText: string;
    dateSubtitle: string;
    onIconClick: () => void;
    showIcon: IconName
}

export const GiftcardInfoCard: FC<GiftcardInfoCardProps> = ({
                                                                color,
                                                                code,
                                                                giftedBy,
                                                                icon,
                                                                statusText,
                                                                dateSubtitle,
                                                                onIconClick,
                                                                showIcon
                                                            }) => {
    const theme = useTheme() as ThemeType;

    return <Row alignItems={'center'} style={{padding: '32px'}}>
        <Col columns={[0.75]}>
            <Svg icon={'gift'} width={32} height={32} fill={color}/>
        </Col>
        <Col columns={[12, 12, 2.75]}>
            <SpanBody2 style={{color, wordWrap: 'break-word'}}>{code}</SpanBody2>
        </Col>
        <Col columns={[12, 12, 2.5]}>
            <SpanSubtitle1>
                <FormattedMessage
                    id={'GiftcardListPageGiftedBySubtitle'}
                    defaultMessage={'Offert par: {br} {name}'}
                    values={{br: <br/>, name: giftedBy}}
                />
            </SpanSubtitle1>
        </Col>
        <Col columns={[12, 12, 5]}>
            <Row alignItems={'center'}>
                <Col style={{padding: 0}}>
                    <Svg icon={icon} width={26} height={26} fill={color}/>
                </Col>
                <Col>
                    <div>
                        <SpanSubtitle1 style={{color}}>{statusText}</SpanSubtitle1>
                    </div>
                    <SpanSubtitle1>
                        {dateSubtitle}
                    </SpanSubtitle1>
                </Col>
            </Row>
        </Col>
        <Col columns={[1]}>
            <BaseButton onClick={onIconClick}>
                <Svg icon={showIcon} width={20} height={20} fill={theme.colors.grey10}/>
            </BaseButton>
        </Col>
    </Row>
}