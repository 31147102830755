import React, {FC, ReactElement} from "react";
import {Button, Col, Row, SpanBody1, SpanButton} from "@linkeo.com/ui-lib-react";
import {StyledLink} from "./styled-link";
import styled from "styled-components";

const H2 = styled.h2`
    color: ${({theme}) => theme.colors.grey50};
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    line-height: 34px;
    margin: 0;
`
const Column = styled(Col)`
    @media screen and (max-width: 600px) {
        padding-top: 10px;
    }
`

interface ContainerHeaderProps {
    title: string;
    subtitle?: string;
    buttonLabel?: string;
    to?: string;
    onClick?: () => void;
    children?: ReactElement;
}

export const ContainerHeader: FC<ContainerHeaderProps> = ({
                                                              title,
                                                              subtitle,
                                                              buttonLabel,
                                                              to,
                                                              onClick,
                                                              children
                                                          }) => {

    return <div style={{marginBottom: '28px'}}>
        <Row justifyContent={['space-between']} alignItems={['center']}>
            <Col>
                <H2>{title}</H2>
            </Col>
            <Col>
                <Row alignItems={'center'}>
                    {children}
                    <Column>
                        {buttonLabel && to &&
                            <StyledLink
                                to={to}
                                buttonLabel={buttonLabel}
                            />}
                        {onClick && buttonLabel && !to &&
                            <Button onClick={onClick} colorType={'primary'}>
                                <SpanButton>
                                    {buttonLabel}
                                </SpanButton>
                            </Button>}
                    </Column>
                </Row>
            </Col>
        </Row>
        {subtitle && <div style={{paddingTop: '16px'}}>
            <SpanBody1>{subtitle}</SpanBody1>
        </div>}
    </div>
}