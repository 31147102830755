import {FC, useEffect, useState} from "react";
import {PageWrapper} from "../../commons/components/page-wrapper";
import {Card, Container, SpanSubtitle2, useToaster} from "@linkeo.com/ui-lib-react";
import {useIntl} from "react-intl";
import {OptionForm} from "../../options/components/option-form";
import {useOptionsStore} from "../../options/options-store";
import {OptionPayload} from "../../options/options-types";
import {useNavigate, useParams} from "react-router";
import {routeOptions} from "../../routes";
import styled from "styled-components";
import {Error404} from "../../commons/components/404-error";
import {useConfigurationStore} from "../../params/configuration-store";

const H2 = styled.h2`
    color: ${({theme}) => theme.colors.grey10};
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 34px;
    font-feature-settings: 'pnum' on, 'lnum' on;
`

export const OptionsEditPage: FC = () => {
    const intl = useIntl();
    const {option, editOption, resetOption, fetchOption, error} = useOptionsStore();
    const {optionId} = useParams();
    const [loading, setLoading] = useState<boolean>(false);
    const {config, fetchData} = useConfigurationStore();
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const toast = useToaster();
    const navigate = useNavigate();

    useEffect(() => {
        setIsLoading(true);
        Promise.allSettled([
            optionId ? fetchOption(optionId) : Promise.resolve(null),
            fetchData()
        ]).catch(e => console.error(e))
            .finally(() => setIsLoading(false))
        return () => resetOption()
    }, [optionId, fetchOption, resetOption, fetchData])

    const onSaveOptionClick = (option: OptionPayload) => {
        setLoading(true)
        editOption(option, optionId).then(() => {
            resetOption()
            navigate(routeOptions)
        }).catch(error => {
            console.error(error)
            toast(intl.formatMessage({id: 'errorMessage', defaultMessage: 'Une erreur est survenue'}))
        }).finally(() => setLoading(false))
    }

    return <PageWrapper isLoading={isLoading}>
        {!error ? <Container size={'lg'}>
            <Card>
                <H2>{intl.formatMessage({id: 'OptionsEditPageTitle', defaultMessage: 'Ajouter une option'})}</H2>
                <SpanSubtitle2>{intl.formatMessage({
                    id: 'OptionsEditPageSubTitle',
                    defaultMessage: 'Renseigner les données de la nouvelle option.'
                })}</SpanSubtitle2>
                <OptionForm
                    form={option}
                    onSubmit={onSaveOptionClick}
                    loading={loading}
                    paymentPercent={config.paymentPercent}
                />
            </Card>
        </Container> : <Error404/>}
    </PageWrapper>
}