import {FC, useRef, useState} from "react";
import {Col, Dropdown, SelectOption, SpanSubtitle1, Svg, ThemeType} from "@linkeo.com/ui-lib-react";
import styled, {useTheme} from "styled-components";

const FilterRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 7px 16px;
    cursor: pointer;
    border-radius: 3px;
    
    @media screen and (max-width: 611px) {
        padding: 0
    }
`
const List = styled.div<{ index: number }>`
    background-color: ${({theme}) => theme.colors.grey90};
    padding: 12px;
    border-top: ${({theme, index}) => index !== 0 && `1px solid ${theme.colors.grey80}`};

    &:hover {
        background-color: ${({theme}) => theme.colors.grey95};
    }
`

interface NavFilterProps {
    label: string
    options: SelectOption<string>[];
    onFilterClick: (select: string) => void;
    selected?: string
}

export const NavFilter: FC<NavFilterProps> = ({options, onFilterClick, selected, label}) => {
    const theme = useTheme() as ThemeType;
    const [show, setShow] = useState<boolean>(false);
    const wrapperRef = useRef(null);

    return <Col>
        <FilterRow ref={wrapperRef}
                   onMouseEnter={() => setShow(true)}
                   onMouseLeave={() => setShow(false)}>
            <SpanSubtitle1 style={{color: theme.colors.grey60, fontWeight: 700, marginRight: '16px'}}>
                {label}
            </SpanSubtitle1>
            <SpanSubtitle1 style={{fontWeight: 600}}>
                {selected}
            </SpanSubtitle1>
            <Svg icon={"down"}
                 width={20}
                 height={20}
                 style={{marginBottom: "4px"}}
                 fill={theme.colors.grey10}/>
            <Dropdown show={show} onClose={() => setShow(false)} wrapperRef={wrapperRef} fulfilled>
                {options.map((el, index) => <List key={index} index={index}
                                                  onClick={() => onFilterClick(el.value)}>
                    <SpanSubtitle1>{el.label}</SpanSubtitle1>
                </List>)}
            </Dropdown>
        </FilterRow>
    </Col>
}