import {FC, ReactNode, useEffect, useState} from "react";

const checkImageExists = (uri: string): Promise<boolean> => {
    return new Promise((resolve) => {
        const img = new Image();
        img.src = uri;
        if (img.complete) {
            resolve(true);
        } else {
            img.onload = () => resolve(true);
            img.onerror = () => resolve(false);
        }
    });
}

export const ImageLoader: FC<{ uri?: string }> = ({ uri }) => {
    const [imageExists, setImageExists] = useState<boolean>(false);
    useEffect(() => {
        if (uri) {
            checkImageExists(uri).then(exists => {
                setImageExists(exists)
            });
        }
    }, [uri]);
    let content: ReactNode;
    if (imageExists) {
        content = <div style={{
            backgroundImage: `url(${uri})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            height: '100%',
            width: '100%',
            borderRadius: '3px'
        }}></div>
    } else {
        content = null;
    }
    return <div role="img" style={{ height: '100%', width: '100%', borderRadius: '3px', overflow: 'hidden' }}>
        {content}
    </div>
}