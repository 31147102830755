import {FC, ReactNode} from "react";
import {Card, SpanBody2} from "@linkeo.com/ui-lib-react";

interface ParamsRadioCardProps {
    children: ReactNode;
    title: string
}

export const ParamsRadioCard: FC<ParamsRadioCardProps> = ({children, title}) => {

    return <Card>
        <SpanBody2 >
            {title}
        </SpanBody2>
        {children}
    </Card>
}