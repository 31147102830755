import {Dispatch, FC, ReactNode, SetStateAction} from "react";
import styled, {useTheme} from "styled-components";
import {ParamsCard} from "../params-card";
import {SpanCaption1, Switch, ThemeType} from "@linkeo.com/ui-lib-react";

const Container = styled.div<{ show: boolean }>`
  position: relative;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
  max-height: ${({show}) => show ? '350px' : 0};
  @media screen and (max-width: 600px) {
    max-height: ${({show}) => show ? '500px' : 0};
  }
`

interface ParamsBookingShowCardProps {
    title: string;
    subtitle: string;
    switched: boolean;
    setSwitched: Dispatch<SetStateAction<boolean>>;
    switchLabel: string;
    caption: string;
    children: ReactNode
}

export const ParamsBookingShowCard: FC<ParamsBookingShowCardProps> = ({
                                                                          title,
                                                                          subtitle,
                                                                          setSwitched,
                                                                          switched,
                                                                          switchLabel,
                                                                          caption,
                                                                          children
                                                                      }) => {
    const theme = useTheme() as ThemeType;

    return <ParamsCard title={title} subtitle={subtitle} inCard={true}>
        <div style={{marginLeft: '36px'}}>
            <Switch
                value={switched}
                onIcon={'check'}
                offIcon={'cross'}
                color={theme.colors.grey40}
                onChange={setSwitched}
                onLabel={switchLabel}
            />
        </div>
        <SpanCaption1>
            {caption}
        </SpanCaption1>
        <Container show={switched}>
            {children}
        </Container>
    </ParamsCard>
}