import {FC, useState} from "react";
import {
    BaseButton,
    Card,
    Col,
    Row,
    SpanLink,
    SpanSubtitle1,
    SquareButton,
    ThemeType
} from "@linkeo.com/ui-lib-react";
import {useIntl} from "react-intl";
import styled, {useTheme} from "styled-components";
import {Link} from "react-router-dom";

const service = 'service';

const H3 = styled.h3`
  color: ${({theme}) => theme.colors.dark};
  font-family: 'Raleway', sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 21px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  margin: 0 0 4px 0
`

interface OptionCardProps {
    name: string;
    type: string;
    choices: string[];
    onRemoveClick: () => void;
    link: string
}

export const OptionCard: FC<OptionCardProps> = ({
                                                    choices,
                                                    name,
                                                    type,
                                                    onRemoveClick,
                                                    link
                                                }) => {
    const intl = useIntl();
    const theme = useTheme() as ThemeType;
    const [choicesList, setChoicesList] = useState<string[]>(choices.slice(0, 3))

    const onButtonClick = () => {
        if (choicesList.length === choices.length) {
            setChoicesList(choices.slice(0, 3))
        } else {
            setChoicesList(choices)
        }
    }

    return <Card style={{marginBottom: '16px'}}>
        <Row>
            <Col columns={[3]}>
                <H3>{name}</H3>
                <SpanSubtitle1>{intl.formatMessage({
                    id: 'OptionCardSubtitle',
                    defaultMessage: 'Option de {type}'
                }, {
                    type: type === service ? intl.formatMessage({
                        id: 'OptionCardServiceTypeLabel',
                        defaultMessage: 'prestation'
                    }) : intl.formatMessage({
                        id: 'OptionCardBookingTypeLabel',
                        defaultMessage: 'réservation'
                    })
                })}</SpanSubtitle1>
            </Col>
            <Col columns={[7]}>
                <div style={{marginBottom: '10px'}}><SpanSubtitle1>{intl.formatMessage({
                    id: 'OptionsCardOptionsListTitle',
                    defaultMessage: 'Choix disponibles'
                })}</SpanSubtitle1></div>

                {choicesList.map((option, index) => <div
                    style={{
                        backgroundColor: index % 2 === 0 ? theme.colors.grey95 : theme.colors.light,
                        padding: '8px',
                        borderRadius: '3px'
                    }} key={index}>
                    <SpanSubtitle1>{option}</SpanSubtitle1></div>)}

                {choices.length > 3 && <BaseButton
                    onClick={onButtonClick}><SpanLink>{choicesList.length === choices.length ?
                    intl.formatMessage({
                        id: 'OptionsCardShortList',
                        defaultMessage: '- Rétrécir la liste'
                    }) : intl.formatMessage({
                        id: 'OptionsCardFullList',
                        defaultMessage: '+ {number, plural, =1 {1 autre choix} other {# autres choix}}'
                    }, {number: (choices.length - choicesList.length)})}</SpanLink></BaseButton>}

            </Col>
            <Col columns={[2]}>
                <Row justifyContent={["space-evenly"]}>
                    <Link to={link}><SquareButton backgroundColor={theme.colors.secondary}
                                  icon={'edit'}
                                  fontColor={theme.colors.light}>
                    </SquareButton></Link>
                    <SquareButton colorType={"primary"}
                                  icon={'delete'}
                                  onClick={onRemoveClick}>
                    </SquareButton>
                </Row>
            </Col>
        </Row>
    </Card>
}