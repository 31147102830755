import {FC} from "react";
import styled, {useTheme} from "styled-components";
import {Link} from "react-router-dom";
import {FormattedMessage} from "react-intl";
import {Col, getTypo, Row, Svg, ThemeType} from "@linkeo.com/ui-lib-react";
import {darken} from "polished";

const Wrapper = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: inherit;
  ${getTypo('subtitle2')};
  background: ${({theme}) => theme.colors.grey80};
  padding: 10px 15px 10px 5px;
  margin-bottom: 28px;
  border-radius: 20px;
  transition: background-color ease 0.4s;
  
  &:hover {
    background: ${({theme}) => darken(0.08, theme.colors.grey80)};
    
  }
`

interface PreviousPageLinkProps {
    link: string
}

export const PreviousPageLink: FC<PreviousPageLinkProps> = ({link}) => {
    const theme = useTheme() as ThemeType;

    return <Row>
        <Col>
            <Wrapper to={link}>
                <Svg icon={'left'} fill={theme.colors.grey40} height={22} width={22}/>
                <FormattedMessage
                    id={'previousPageLinkLabel'}
                    defaultMessage={'Retour'}
                />
            </Wrapper>
        </Col>
    </Row>
}