import {FC} from "react";
import {Col, IconName, Row, SpanBody2, SpanSubtitle2, Svg, ThemeType} from "@linkeo.com/ui-lib-react";
import {useIntl} from "react-intl";
import styled, {useTheme} from "styled-components";

export const Container = styled(Row)<{ index: number }>`
  padding: 26px;
  border-top: ${({index, theme}) => index !== 0 && `1px solid ${theme.colors.grey90}`};
`

interface ListCardProps {
    index: number;
    title: string;
    details: string;
    icon: IconName;
    status: string;
    value: number;
    giftcardCode?: string;
    currency: string;
    color: string
}

export const ListCard: FC<ListCardProps> = ({
                                                title,
                                                details,
                                                icon,
                                                status,
                                                giftcardCode,
                                                value,
                                                currency,
                                                color,
                                                index
                                            }) => {
    const intl = useIntl();
    const theme = useTheme() as ThemeType;

    return <Container justifyContent={'space-between'} alignItems={'center'} index={index}>
        <Col>
            {giftcardCode ? <>
                    <div>
                        <SpanBody2>{giftcardCode} - </SpanBody2>
                        <SpanBody2>{title}</SpanBody2>
                        <SpanSubtitle2> ({intl.formatNumber(value, {style: 'currency', currency})})</SpanSubtitle2>
                    </div>
                    <SpanSubtitle2>{details}</SpanSubtitle2></>

                : <>
                    <div>
                        <SpanBody2>{title} -</SpanBody2>
                        <SpanBody2> {intl.formatNumber(value, {style: 'currency', currency})}</SpanBody2>
                    </div>
                    <SpanSubtitle2>{details}</SpanSubtitle2>
                </>}
        </Col>
        <Col style={{display: 'flex', alignItems: 'center'}}>
            <Svg icon={icon} height={24} width={24} fill={color}/>
            <SpanBody2
                style={{color: giftcardCode ? color : theme.colors.grey20, marginLeft: '8px'}}>{status}</SpanBody2>
        </Col>
    </Container>
}