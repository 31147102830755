import React, {FC, ReactNode} from "react";
import {Col, Flex, IconName, Row, SpanBody1, Svg, ThemeType} from "@linkeo.com/ui-lib-react";
import styled, {useTheme} from "styled-components";
import {StyledLink} from "./styled-link";

const H2 = styled.h2`
  font-family: 'Raleway', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 34px;
  text-align: center;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: ${({theme}) => theme.colors.grey50};
`

interface EmptyListProps {
    buttonLabel: string;
    to: string;
    title: string;
    subtitle: string | ReactNode;
    icon?: IconName
}

export const EmptyList: FC<EmptyListProps> = ({buttonLabel, to, title, subtitle, icon}) => {
    const theme = useTheme() as ThemeType;

    return <Row justifyContent={['center']} alignItems={['center']}
                style={{textAlign: 'center', height: '100%', marginTop: '213px'}}>
        <Col columns={[12]}>
            {icon && <Svg icon={icon} fill={theme.colors.primary} height={50} width={50}/>}
            <H2>{title}</H2>
            <SpanBody1>{subtitle}</SpanBody1>
            <Flex justifyContent={"center"} style={{marginTop: '56px'}}><StyledLink to={to} buttonLabel={buttonLabel}/></Flex>
        </Col>
    </Row>
}