import React, {FC} from "react";
import {Col, Input, Row} from "@linkeo.com/ui-lib-react";
import {useIntl} from "react-intl";
import {DateField, FromToSelect, TimeField} from "./from-to-select";

interface UnavailableAppointmentFormProps {
    notes: string;
    onNotesChange: (notes: string) => void;
    fromDate: Date;
    toDate: Date;
    disabled: boolean;
    onTimeSelect: (dateField: DateField, timeField: TimeField, value: string) => void;
}

export const UnavailableAppointmentForm: FC<UnavailableAppointmentFormProps> = ({
                                                                                    notes,
                                                                                    onNotesChange,
                                                                                    fromDate,
                                                                                    toDate,
                                                                                    onTimeSelect,
                                                                                    disabled
                                                                                }) => {
    const intl = useIntl();

    return <Row alignItems={'center'} style={{flexWrap: 'wrap', maxWidth: '100%'}}>
        <Col columns={[12, 12, 12, 4.5]}>
            <Input type={'text'}
                   value={notes}
                   onChange={onNotesChange}
                   placeholder={intl.formatMessage({
                       id: 'AppointmentDialogNotAvailableInputPlaceholder',
                       defaultMessage: 'Motif de l\'indisponibilité'
                   })}/>
        </Col>
        <Col columns={[12, 12, 12, 7.5]}>
            <FromToSelect fromDate={fromDate} toDate={toDate}
                          onTimeSelect={onTimeSelect}
                          disabled={disabled}/>
        </Col>
    </Row>
}