import React, { useEffect, useState } from 'react';
import frFR from './lang/fr-FR.json';
import enAU from './lang/en-AU.json';
import enCA from './lang/en-CA.json';
import enUS from './lang/en-US.json';
import frCA from './lang/fr-CA.json';
import {IntlProvider, isLocale, Locale} from "./providers/intl-provider";
import {BrowserRouter} from "react-router-dom";
import {ThemeProvider, ToasterProvider} from "@linkeo.com/ui-lib-react";
import {Theme} from "./config/theme";
import {Router} from "./router";
import {AuthProvider} from "./providers/sso/auth-provider";
import {ROOT_PATH, ssoClientId, ssoClientSecret, ssoUri} from "./config/config";
import {OptionsStore} from "./options/options-store";
import {AgendasStore} from "./agendas/agendas-store";
import {ConfigurationStore} from './params/configuration-store';
import {getConfiguration} from "./providers/configuration/configuration-api";

const getMessage = (locale: Locale) => {
    switch (locale) {
        default:
        case 'fr-FR':
            return frFR;
        case 'en-AU':
            return enAU;
        case 'en-CA':
            return enCA;
        case 'en-US':
            return enUS;
        case 'fr-CA':
            return frCA;
    }
};

interface AppProps {
    lang: string | undefined
}

function App({lang}: AppProps) {
    const [token, setToken] = useState<string>();
    const [codeBouton, setCodeBouton] = useState<string>();
    const [locale, setLocale] = useState<Locale>();

    useEffect(() => {
        if (lang) {
            switch (lang) {
                default:
                case 'fr':
                    setLocale('fr-FR')
                    break;
                case 'en':
                    setLocale('en-US')
                    break;
            }
        } else if (codeBouton) {
            getConfiguration(codeBouton)
                .then(response => {
                    isLocale(response.locale)
                        ? setLocale(response.locale)
                        : setLocale('fr-FR')
                })
                .catch(err => {
                    console.error(err)
                    setLocale('fr-FR')
                })
        }
    }, [codeBouton, lang])

    return (
        <BrowserRouter basename={ROOT_PATH}>
            <ThemeProvider theme={Theme}>
                <ToasterProvider>
                    <AuthProvider onTokenChange={(token) => setToken(token)}
                                  onCodeBoutonChange={(codeBouton) => setCodeBouton(codeBouton)}
                                  clientId={ssoClientId}
                                  clientSecret={ssoClientSecret}
                                  ssoUri={ssoUri}>
                        {locale && <IntlProvider locale={locale} messages={getMessage(locale)}>
                            {token && codeBouton &&
                                <ConfigurationStore codeBouton={codeBouton} token={token}>
                                    <AgendasStore codeBouton={codeBouton} token={token}>
                                        <OptionsStore token={token} locale={locale} codeBouton={codeBouton}>
                                            <Router/>
                                        </OptionsStore>
                                    </AgendasStore>
                                </ConfigurationStore>
                            }
                        </IntlProvider>}
                    </AuthProvider>
                </ToasterProvider>
            </ThemeProvider>
        </BrowserRouter>
    );
}

export default App;
