export const service = 'service';
export const booking = 'booking';

interface Choice {
    id?: string;
    label: string;
    price: number
}

export interface OptionPayload {
    name: string;
    type: typeof service | typeof booking;
    min: number;
    max: number | null;
    countable: boolean;
    extraCharge : number;
    maxExtra: number;
    maxFree: number;
    choices: Choice[];
    downPayment?: number;
}

export interface Option extends OptionPayload {
    id: string;
}