import React, {FC, ReactNode} from "react";
import {Col, InputNumber, LabelWrapper, Row} from "@linkeo.com/ui-lib-react";

interface OptionInputProps {
    label: string | ReactNode;
    value: number;
    min: number;
    onChange: (n: number | null) => void
}

export const OptionInput: FC<OptionInputProps> = ({label, value, onChange, min}) => {

    return <Col columns={[12, 12, 6]}>
        <Row justifyContent={'space-between'}>
            <Col columns={[12, 12, 6]}>
                <LabelWrapper label={label} required/>
            </Col>
            <Col columns={[12, 12, 3]}>
                <InputNumber value={value} min={min} onChange={(n) => onChange(n)}/>
            </Col>
        </Row>
    </Col>
}