import {FC, useMemo, useState} from "react";
import {Tabs} from "@linkeo.com/ui-lib-react";
import {useIntl} from "react-intl";
import {CustomersGiftcardsList} from "./customers-giftcards-list";
import {CustomerAppointmentsTimelineList} from "./customer-appointments-timeline-list";
import {CustomerBookingList} from "./customer-booking-list";
import {CustomerInfoForm} from "./customer-info-form";
import styled from "styled-components";

const TabNav = styled(Tabs)`
    div {
        overflow: scroll;
    }
`;

interface CustomerTabContentProps {
    id?: string;
}

export const CustomerTabContent: FC<CustomerTabContentProps> = ({id}) => {
    const intl = useIntl();
    const [isLoading] = useState<boolean>(false);
    const [load] = useState<boolean>(false);
    const [timelineLock] = useState<boolean>(false);
    const [bookingLock] = useState<boolean>(false);

    const tabs = useMemo(() => {
        return [
            {
                title: intl.formatMessage({id: 'CustomerEditPageTabTitleInfo', defaultMessage: 'Informations'}),
                content: <CustomerInfoForm/>
            },
            {
                title: intl.formatMessage({id: 'CustomerEditPageTabTitleBooking', defaultMessage: 'Réservation(s)'}),
                content: <CustomerBookingList
                    isLoading={isLoading}
                    lock={bookingLock}
                    loading={load}
                    onAppointmentsLoading={() => {
                    }}
                    currency={'EUR'}
                    appointmentsLength={2}
                />
            },
            {
                title: intl.formatMessage({id: 'CustomerEditPageTabTitleTimeline', defaultMessage: 'Historique'}),
                content: <CustomerAppointmentsTimelineList
                    currency={'EUR'}
                    isLoading={isLoading}
                    lock={timelineLock}
                    loading={load}
                    onAppointmentsListLoading={() => {
                    }}
                    appointmentsLength={2}/>
            },
            {
                title: intl.formatMessage({id: 'CustomerEditPageTabTitleGiftcards', defaultMessage: 'Bons cadeaux'}),
                content: <CustomersGiftcardsList currency={'EUR'} length={3}/>
            }
        ]
    }, [bookingLock, intl, isLoading, load, timelineLock])

    return <TabNav tabs={tabs} disabled={!id} margin={'18px -30px 30px'} />
}