import {ThemeColors, ThemeType} from "@linkeo.com/ui-lib-react";

const colors: ThemeColors = {
    primary: '#086C6B',
    invertPrimary: '#f5f5f5',
    secondary: '#828282',
    invertSecondary: '#0D0D0D',
    success: '#5CC785',
    lightSuccess: '#35D759',
    warning: '#d78635',
    lightWarning: '#d78635',
    danger: '#F2423F',
    lightDanger: '#FF6666',
    light: '#ffffff',
    dark: '#000000',
    grey10: '#1a1a1a',
    grey20: '#333333',
    grey30: '#8F8F8F',
    grey40: '#666666',
    grey50: '#0D0D0D',
    grey60: '#999999',
    grey70: '#b3b3b3',
    grey80: '#cccccc',
    grey90: '#E5E5E5',
    grey95: '#EEEEEE',
};
export const Theme: ThemeType = {
    colors,
    icons: 'back',
    input: {
        borderRadius: '2px',
        backgroundColor: colors.grey95,
        color: colors.grey20,
        borderColor: colors.grey90,
        placeholderColor: colors.grey60,
    },
    box: {
        borderRadius: '2px',
    },
    card: {
        padding: '30px max(10px, min(3vw, 45px))',
    },
    button: {
        small: {
            borderRadius: '4px',
            padding: '4px 12px 3px',
        },
        default: {
            borderRadius: '4px',
            padding: '11px 16px 9px',
        },
    },
    fonts: {
        h1: {
            color: colors.grey50,
            family: '"Raleway", sans-serif',
            lineHeight: '70px',
            size: '60px',
            weight: '700',
            letterSpacing: 'initial',
            textTransform: 'initial',
            style: 'normal',
            textDecoration: 'initial',
            margin: '0 0 8px 0',
        },
        h2: {
            color: colors.grey50,
            family: '"Raleway", sans-serif',
            lineHeight: '34px',
            size: '30px',
            weight: '400',
            letterSpacing: 'initial',
            textTransform: 'initial',
            style: 'normal',
            textDecoration: 'initial',
            margin: '0 0 8px 0',
        },
        h3: {
            color: colors.dark,
            family: '"Raleway", sans-serif',
            lineHeight: '21px',
            size: '20px',
            weight: '900',
            letterSpacing: 'initial',
            textTransform: 'initial',
            style: 'normal',
            textDecoration: 'initial',
            margin: '0 0 8px 0',
        },
        h4: {
            color: colors.grey10,
            family: '"Raleway", sans-serif',
            lineHeight: '34px',
            size: '28px',
            weight: '600',
            letterSpacing: 'initial',
            textTransform: 'initial',
            style: 'normal',
            textDecoration: 'initial',
            margin: '0 0 8px 0',
        },
        h5: {
            color: colors.grey50,
            family: '"Raleway", sans-serif',
            lineHeight: '21px',
            size: '20px',
            weight: '500',
            letterSpacing: 'initial',
            textTransform: 'initial',
            style: 'normal',
            textDecoration: 'initial',
            margin: '0 0 8px 0',
        },
        h6: {
            color: colors.grey10,
            family: '"Raleway", sans-serif',
            lineHeight: '21px',
            size: '20px',
            weight: '600',
            letterSpacing: 'initial',
            textTransform: 'initial',
            style: 'normal',
            textDecoration: 'initial',
            margin: '0 0 8px 0',
        },
        body1: {
            color: colors.grey30,
            family: '"Raleway", sans-serif',
            lineHeight: '24px',
            size: '16px',
            weight: '400',
            letterSpacing: 'initial',
            textTransform: 'initial',
            style: 'normal',
            textDecoration: 'initial',
            margin: '0',
        },
        input: {
            color: colors.grey60,
            family: '"Raleway", sans-serif',
            lineHeight: '22px',
            size: '16px',
            weight: '400',
            letterSpacing: 'initial',
            textTransform: 'initial',
            style: 'normal',
            textDecoration: 'initial',
            margin: '0',
        },
        body2: {
            color: colors.grey20,
            family: '"Raleway", sans-serif',
            lineHeight: '26px',
            size: '18px',
            weight: '600',
            letterSpacing: 'initial',
            textTransform: 'initial',
            style: 'normal',
            textDecoration: 'initial',
            margin: '0',
        },
        fontButton: {
            color: colors.light,
            family: '"Raleway", sans-serif',
            lineHeight: '20px',
            size: '16px',
            weight: '400',
            letterSpacing: 'initial',
            textTransform: 'initial',
            style: 'normal',
            textDecoration: 'initial',
            margin: '0',
        },
        smallFontButton: {
            color: colors.light,
            family: '"Raleway", sans-serif',
            lineHeight: '21px',
            size: '13px',
            weight: '400',
            letterSpacing: 'initial',
            textTransform: 'initial',
            style: 'normal',
            textDecoration: 'initial',
            margin: '0',
        },
        subtitle1: {
            color: colors.grey10,
            family: '"Raleway", sans-serif',
            lineHeight: '21px',
            size: '15px',
            weight: '400',
            letterSpacing: 'initial',
            textTransform: 'initial',
            style: 'normal',
            textDecoration: 'initial',
            margin: '0',
        },
        subtitle2: {
            color: colors.grey40,
            family: '"Raleway", sans-serif',
            lineHeight: '21px',
            size: '17px',
            weight: '400',
            letterSpacing: 'initial',
            textTransform: 'initial',
            style: 'normal',
            textDecoration: 'initial',
            margin: '0',
        },
        caption1: {
            color: colors.grey30,
            family: '"Raleway", sans-serif',
            lineHeight: '1.125rem',
            size: '14px',
            weight: '600',
            letterSpacing: 'initial',
            textTransform: 'initial',
            style: 'italic',
            textDecoration: 'initial',
            margin: '0',
        },
        caption2: {
            color: colors.grey40,
            family: '"Raleway", sans-serif',
            lineHeight: '19px',
            size: '16px',
            weight: '500',
            letterSpacing: 'initial',
            textTransform: 'initial',
            style: 'italic',
            textDecoration: 'initial',
            margin: '0',
        },
        link: {
            color: colors.grey40,
            family: '"Raleway", sans-serif',
            lineHeight: '34px',
            size: '15px',
            weight: '400',
            letterSpacing: 'initial',
            textTransform: 'initial',
            style: 'normal',
            textDecoration: 'underline',
            margin: '0',
        },
    },
};
