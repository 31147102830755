import {FC} from "react";
import {BaseInputNumber, Col, Row, SelectOption, SimpleSelect} from "@linkeo.com/ui-lib-react";
import styled from "styled-components";

const SelectContainer = styled(Col)`
  padding: 0 4px;

  > div > div {
    margin: 0
  }
`

interface ParamsRadioSelectProps {
    preText?: string;
    postText: string;
    numberValue: number;
    minValue: number;
    hasError: boolean;
    onNumberChange: (v: number | null) => void;
    selectOptions: SelectOption<string>[];
    selectValue: SelectOption<string>;
    onSelect: (v: SelectOption<string>) => void;
}

export const ParamsRadioSelect: FC<ParamsRadioSelectProps> = ({
                                                                  postText,
                                                                  onSelect,
                                                                  selectOptions,
                                                                  selectValue,
                                                                  numberValue,
                                                                  onNumberChange,
                                                                  minValue,
                                                                  hasError,
                                                                  preText
                                                              }) => {

    return <Row style={{verticalAlign: 'center'}} wraps={"wrap"}>
        {preText && <Col columns={[12, 2.5]} style={{padding: '0 0 0 16px'}}>
            {preText}
        </Col>}
        <Col columns={[10, 2]} style={{padding: preText ? '0 4px' : '0 4px 0 16px'}}>
            <BaseInputNumber min={minValue} value={numberValue}
                             hasError={hasError}
                             onChange={onNumberChange}/>
        </Col>
        <SelectContainer columns={[10, 4]}>
            <SimpleSelect options={selectOptions}
                          selected={selectValue}
                          onSelect={onSelect}/>
        </SelectContainer>
        <Col columns={[12, 3.5]} style={{padding: '4px'}}>
            {postText}
        </Col>
    </Row>
}