import { FC, FormEvent, useState } from "react";
import {
    BaseButton,
    Card, Checkbox,
    Col,
    DatePicker, InputNumber, Radio,
    Row,
    Select,
    SelectOption,
    SpanBody2,
    SpanButton, SpanSubtitle1, SquareButton, Switch, ThemeType, useToaster
} from "@linkeo.com/ui-lib-react";
import { FormattedMessage, useIntl } from "react-intl";
import { getLocaleDaysNames, getPreviousDate } from "../../../utils/date";
import { hasDuplicates, updateItemInArray } from "../../../utils/misc";
import { FormFooter } from "../../../commons/components/form-footer";
import { TimeSelect } from "./time-select";
import styled, { useTheme } from "styled-components";

const AddButton = styled(SpanButton)`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 14px;
  font-weight: 600;
  border-bottom: 1px solid ${({ theme }) => theme.colors.primary};

  &:hover {
    border-bottom: none;
  }
`

const percent = 'percent';
const monetary = 'monetary';

type ValueType = typeof percent | typeof monetary

interface Opening {
    day: number;
    from: string;
    to: string
}

interface FormProps {
    active: boolean;
    endDate: string;
    startDate: string;
    openings?: Opening[];
    services: string[];
    value: number | null;
    type: ValueType;
}

interface ParamsDiscountFormProps {
    form: FormProps;
    services: string[];
    onCancelClick: () => void;
    servicesSectionTitle: string;
}

export const ParamsDiscountForm: FC<ParamsDiscountFormProps> = ({ form: formProps, services, onCancelClick, servicesSectionTitle }) => {
    const intl = useIntl();
    const toast = useToaster();
    const theme = useTheme() as ThemeType;
    const [form, setForm] = useState<FormProps>(formProps);
    const [selectAll, setSelectAll] = useState<boolean>(false);
    const dayOptions: SelectOption<number>[] = getLocaleDaysNames('fr-FR').map((el, index) => {
        return { label: el.replace(/^./, str => str.toUpperCase()), value: index }
    });
    const hoursOptions: SelectOption<string>[] = Array.from({ length: 24 }, (_, i) => {
        return { label: i.toString().padStart(2, '0') + 'h', value: i.toString().padStart(2, '0') }
    });
    const minOptions: SelectOption<string>[] = [
        { label: '00', value: '00' },
        { label: '30', value: '30' }
    ];

    const onCheckboxSelect = (service: string) => {
        const find = form.services.find(el => el === service);
        if (!find) {
            setForm({ ...form, services: [...form.services, service] })
        } else {
            setForm({ ...form, services: form.services.filter(el => el !== service) })
        }
    }

    const hasTimeError = (openings: { day: number, from: string, to: string }[]) => {
        for (const item of openings) {
            if (item.from >= item.to) {
                return true
            }
        }
        return false
    }

    const _onSubmit = (e: FormEvent) => {
        e.stopPropagation();
        e.preventDefault();
        if (!form.value || form.value < 1 || form.services.length < 1) {
            toast(intl.formatMessage({
                id: 'ParamsDiscountFormToastErrorMessage',
                defaultMessage: 'Le formulaire est invalide'
            }))
        }
    }

    return <Card style={{ marginTop: '28px' }}>
        <form onSubmit={_onSubmit}>
            <section>
                <SpanBody2>
                    <FormattedMessage
                        id={'ParamsDiscountEditPageIntervalTitle'}
                        defaultMessage={'Période promotionnelle*'}
                    />
                </SpanBody2>
                <Row style={{ marginBottom: '20px' }}>
                    <Col columns={[12, 12, 3]}>
                        <DatePicker
                            locale={'fr'}
                            label={intl.formatMessage({
                                id: 'ParamsDiscountsEditPageIntervalStartLabel',
                                defaultMessage: 'Début de la promo: '
                            })}
                            value={new Date(form.startDate)}
                            disabledDays={{ min: getPreviousDate() }}
                            onChange={(value) => setForm({
                                ...form,
                                startDate: value ? value.toString() : new Date().toString()
                            })}
                        />
                    </Col>
                    <Col columns={[12, 12, 3]}>
                        <DatePicker
                            locale={'fr'}
                            label={intl.formatMessage({
                                id: 'ParamsDiscountsEditPageIntervalEndLabel',
                                defaultMessage: 'Fin de la promo: '
                            })}
                            value={new Date(form.endDate)}
                            disabledDays={{ min: new Date(form.startDate) }}
                            onChange={(value) => setForm({
                                ...form,
                                endDate: value ? value.toString() : new Date().toString()
                            })}
                        />
                    </Col>
                </Row>
            </section>
            {form.openings && <section>
                <SpanBody2>
                    <FormattedMessage
                        id={'ParamsDiscountEditPageIntervalTimeTitle'}
                        defaultMessage={'Quel créneau horaire souhaitez vous mettre en promotion ?*'}
                    />
                </SpanBody2>
                {form.openings.map((el, index) => {
                    const hasError = el.to <= el.from || (!!form.openings && form.openings.filter(v => v.day === el.day && v.from === el.from && el.to === v.to).length > 1)
                    return <Row key={index} alignItems={'center'}>
                        <Col columns={[12, 12, 3]}>
                            <Select
                                hasError={hasError}
                                options={dayOptions}
                                selected={dayOptions[el.day]}
                                onSelect={(val) => form.openings && setForm({
                                    ...form, openings: updateItemInArray(form.openings, index, {
                                        ...form.openings[index], day: val.value
                                    })
                                })}
                            />
                        </Col>
                        <Col columns={[12, 12, 4, 3]}>
                            <TimeSelect
                                label={intl.formatMessage({
                                    id: 'ScheduleFormSelectStartHoursLabel',
                                    defaultMessage: 'De: '
                                })}
                                hasError={hasError}
                                hoursOptions={hoursOptions}
                                selectedHour={hoursOptions.find(val => val.value === el.from.slice(0, 2))}
                                onHourSelect={(val) => form.openings && setForm({
                                    ...form,
                                    openings: updateItemInArray(form.openings, index, {
                                        ...form.openings[index],
                                        from: val.value + form.openings[index].from.slice(2, 5)
                                    })
                                })}
                                minOptions={minOptions}
                                selectedMin={minOptions.find(val => val.value === el.from.slice(3, 5))}
                                onMinSelect={(val) => form.openings && setForm({
                                    ...form,
                                    openings: updateItemInArray(form.openings, index, {
                                        ...form.openings[index],
                                        from: form.openings[index].from.slice(0, 3) + val.value
                                    })
                                })}
                            />
                        </Col>
                        <Col columns={[12, 12, 4, 3]}>
                            <TimeSelect
                                label={intl.formatMessage({
                                    id: 'ScheduleFormSelectEndHoursLabel',
                                    defaultMessage: 'À: '
                                })}
                                hasError={hasError}
                                hoursOptions={hoursOptions}
                                selectedHour={hoursOptions.find(val => val.value === el.to.slice(0, 2))}
                                onHourSelect={(val) => form.openings && setForm({
                                    ...form,
                                    openings: updateItemInArray(form.openings, index, {
                                        ...form.openings[index],
                                        to: val.value + form.openings[index].to.slice(2, 5)
                                    })
                                })}
                                minOptions={minOptions}
                                selectedMin={minOptions.find(val => val.value === el.to.slice(3, 5))}
                                onMinSelect={(val) => form.openings &&  setForm({
                                    ...form,
                                    openings: updateItemInArray(form.openings, index, {
                                        ...form.openings[index],
                                        to: form.openings[index].to.slice(0, 3) + val.value
                                    })
                                })}
                            />
                        </Col>
                        {index !== 0 && <Col>
                            <SquareButton icon={'delete'} fontColor={theme.colors.grey40}
                                onClick={() => form.openings && setForm({
                                    ...form,
                                    openings: form.openings.filter((_, i) => i !== index)
                                })}
                            />
                        </Col>}
                    </Row>
                })}
                <BaseButton style={{ margin: '16px 0' }}
                    onClick={() => form.openings && setForm({
                        ...form, openings: [...form.openings, {
                            day: 0,
                            from: '00:00',
                            to: '00:00'
                        }]
                    })}>
                    <AddButton>
                        <FormattedMessage
                            id={'ParamsDiscountEditPageAddOpeningButtonLabel'}
                            defaultMessage={'+ Ajouter un créneau promo'}
                        />
                    </AddButton>
                </BaseButton>
                {hasDuplicates(form.openings)
                    ? <div>
                        <SpanSubtitle1 style={{ color: theme.colors.danger }}>
                            <FormattedMessage
                                id={'ParamsDiscountFormSameDateErrorMessage'}
                                defaultMessage={'Vous avez deux créneaux semblables sur cette promo.'}
                            />
                        </SpanSubtitle1>
                    </div>
                    : hasTimeError(form.openings) && <div>
                        <SpanSubtitle1 style={{ color: theme.colors.danger }}>
                            <FormattedMessage
                                id={'ParamsDiscountFormDateErrorMessage'}
                                defaultMessage={'Veuillez verifier la validité des créneaux (le début doit être antérieur à la fin).'}
                            />
                        </SpanSubtitle1>
                    </div>}
            </section>}
            <section style={{ marginTop: '38px' }}>
                <SpanBody2>
                    <FormattedMessage
                        id={'ParamsDiscountEditPageDiscountTypeTitle'}
                        defaultMessage={'Réduction à appliquer*'}
                    />
                </SpanBody2>
                <Row style={{ margin: '6px -16px -16px' }}>
                    <Col>
                        <Radio name={'ParamsDiscountType'}
                            onChange={() => setForm({ ...form, type: percent })}
                            value={form.type === percent}>
                            <FormattedMessage
                                id={'ParamsDiscountFormTypePercentLabel'}
                                defaultMessage={'Pourcentage (%)'}
                            />
                        </Radio>
                    </Col>
                    <Col>
                        <Radio name={'ParamsDiscountType'}
                            onChange={() => setForm({ ...form, type: monetary })}
                            value={form.type === monetary}>
                            <FormattedMessage
                                id={'ParamsDiscountFormTypeMonetaryLabel'}
                                defaultMessage={'Montant (€)'}
                            />
                        </Radio>
                    </Col>
                </Row>
                <Row>
                    <Col columns={[12, 6, 3]}>
                        <InputNumber onChange={(value) => setForm({ ...form, value })}
                            value={form.value}
                            min={1}
                            hasError={!form.value || form.value < 1}
                        />
                    </Col>
                </Row>
                {(!form.value || form.value < 1) && <SpanSubtitle1 style={{ color: theme.colors.danger }}>
                    <FormattedMessage
                        id={'ParamsDiscountFormTypeErrorMessage'}
                        defaultMessage={'Veuillez renseigner une valeur numerique entre 1 et 99.'}
                    />
                </SpanSubtitle1>}
            </section>
            <section style={{ marginTop: '28px' }}>
                <Row alignItems={'center'}>
                    <Col>
                        <SpanBody2>
                            {servicesSectionTitle}
                        </SpanBody2>
                    </Col>
                    <Col>
                        <Checkbox value={selectAll}
                            onChange={(v) => {
                                setSelectAll(v);
                                v ? setForm({ ...form, services }) : setForm({ ...form, services: [] })
                            }}>
                            <FormattedMessage
                                id={'ParamsDiscountFormSelectAllCheckboxLabel'}
                                defaultMessage={'Tout selectionner'}
                            />
                        </Checkbox>
                    </Col>
                </Row>
                <Row>
                    {services.map((service, index) => {
                        return <Col key={index}>
                            <Checkbox onChange={() => onCheckboxSelect(service)}
                                value={!!form.services.find(el => el === service)}>
                                {service}
                            </Checkbox>
                        </Col>
                    })}
                </Row>
                {form.services.length < 1 && <SpanSubtitle1 style={{ color: theme.colors.danger }}>
                    <FormattedMessage
                        id={'ParamsDiscountFormServicesErrorMessage'}
                        defaultMessage={'Veuillez sélectionner une prestation au moins.'}
                    />
                </SpanSubtitle1>}
            </section>
            <section style={{ marginTop: '36px' }}>
                <Switch
                    onLabel={intl.formatMessage({
                        id: 'ParamsDiscountFormActiveSwitchLabel',
                        defaultMessage: 'Activer la promotion'
                    })}
                    value={form.active}
                    onChange={(active) => setForm({ ...form, active })}
                    color={theme.colors.grey40}
                    onIcon={'check'}
                    offIcon={'cross'}
                />
            </section>
            <FormFooter
                onCancelClick={onCancelClick}
                submitButtonText={intl.formatMessage({
                    id: 'ParamsDiscountEditPageSubmitButtonLabel',
                    defaultMessage: 'Enregistrer la promotion'
                })}
            />
        </form>
    </Card>
}