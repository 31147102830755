import {FC, useState} from "react";
import {Dialog, SpanSubtitle1, SpanSubtitle2, ThemeType} from "@linkeo.com/ui-lib-react";
import {ReactComponent as WarningIcon} from "../../icons/warning.svg";
import {FormattedMessage, useIntl} from "react-intl";
import {useTheme} from "styled-components";
import {DeleteDialogFooter} from "../../commons/components/delete-dialog-footer";

interface AgendasDeleteModalProps {
    openModal: boolean;
    onCloseModal: () => void;
    onAgendaDelete: () => void;
}

export const AgendasDeleteModal: FC<AgendasDeleteModalProps> = ({
                                                                                openModal,
                                                                                onCloseModal,
                                                                                onAgendaDelete
                                                                            }) => {
    const intl = useIntl();
    const theme = useTheme() as ThemeType;
    const [confirmDeleteAgenda, setConfirmDeleteAgenda] = useState<boolean>(false);

    const onClose = () => {
        setConfirmDeleteAgenda(false);
        onCloseModal();
    }

    return <Dialog show={openModal}
                   backgroundColor={theme.colors.danger}
                   onClose={onClose}
                   title={intl.formatMessage({
                       id: 'ParamsAgendaDeleteModalTitle',
                       defaultMessage: 'Supprimer l\'agenda'
                   })}
                   size={'md'}
                   footer={<DeleteDialogFooter onCancelClick={onClose}
                                         onConfirmDeleteClick={confirmDeleteAgenda ? onAgendaDelete : () => setConfirmDeleteAgenda(true)}
                   />}>
        <div style={{textAlign: 'center'}}>
            <WarningIcon fill={theme.colors.danger} width={48} height={48} style={{marginLeft: '22px'}}/>
            <div>
                <SpanSubtitle2 style={{fontWeight: 600}}>
                    <FormattedMessage
                        id={'Careful'}
                        defaultMessage={'Attention'}
                    />
                </SpanSubtitle2>
            </div>
            <SpanSubtitle1 style={{color: theme.colors.grey40}}>
                {confirmDeleteAgenda
                    ? <FormattedMessage
                        id={'ParamsAgendaDeleteModalSecondWarning'}
                        defaultMessage={'Tous les rendez-vous associés à cet agenda seront annulés. {br}Confirmez-vous la suppression ?'}
                        values={{br: <br />}}
                    />
                    : <FormattedMessage
                        id={'ParamsAgendaDeleteModalFirstWarning'}
                        defaultMessage={'Cet agenda peut contenir des rendez-vous à venir. {br}Êtes vous certain de vouloir continuer ?'}
                        values={{br: <br/>}}
                    />}
            </SpanSubtitle1>
        </div>
    </Dialog>
}