import {FC} from "react";
import {Col, Row} from "@linkeo.com/ui-lib-react";
import {useIntl} from "react-intl";
import {GiftcardTotalCard} from "./giftcard-total-card";

interface GiftcardListDetailsProps {
    total: number;
    paid: number;
    used: number;
    expired: number
}

export const GiftcardListDetails: FC<GiftcardListDetailsProps> = ({total, used, paid, expired}) => {
    const intl = useIntl();

    return <Row style={{marginBottom: '32px'}}>
        <Col columns={[12, 12, 4]}>
            <GiftcardTotalCard
                icon={"gift"}
                text={intl.formatMessage({
                    id: 'GiftcardsListPageTotalCardTitle',
                    defaultMessage: '{number, plural, =0 {0 bon vendu} one {1 bon vendu} other {# bons vendus}}'
                }, {number: total})}
            />
        </Col>
        <Col columns={[12, 12, 8]}>
            <GiftcardTotalCard
                icon={"calendar"}
                text={intl.formatMessage({
                    id: 'GiftcardsListPageDetailsCardTitle',
                    defaultMessage: "{paid, plural, =0 {0 bon valable} one {1 bon valable} other {# bons valables}} , {used, plural, =0 {0 bon utilisé} one {1 bon utilisé} other {# bons utilisés}}, {expired, plural, =0 {0 bon expiré} one {1 bon expiré} other {# bons expirés}}"
                }, {
                    paid,
                    used,
                    expired
                })}
            />
        </Col>
    </Row>
}