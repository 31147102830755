import { FC } from "react";
import { BaseButton, Col, H2, Row, SpanBody1, Svg, ThemeType } from "@linkeo.com/ui-lib-react";
import { useTheme } from "styled-components";

interface ParamsFormHeaderProps {
    title: string;
    subtitle: string;
    onCloseClick: () => void;
}

export const ParamsFormHeader: FC<ParamsFormHeaderProps> = ({ title, subtitle, onCloseClick }) => {
    const theme = useTheme() as ThemeType;

    return <div>
        <Row justifyContent={'space-between'} style={{flexWrap: 'nowrap'}}>
            <Col>
                <H2>{title}</H2>
            </Col>
            <Col>
                <BaseButton onClick={onCloseClick}>
                    <Svg icon={'cross'} height={38} width={38} fill={theme.colors.grey60} />
                </BaseButton>
            </Col>
        </Row>
        <Row>
            <Col columns={[7.5]}>
                <SpanBody1>{subtitle}</SpanBody1>
            </Col>
        </Row>
    </div>
}