import {FC, useState} from "react";
import {
    Button,
    Col,
    Dialog,
    Input,
    Row,
    SpanBody1,
    SpanButton,
    SpanSubtitle1,
    ThemeType
} from "@linkeo.com/ui-lib-react";
import {FormattedMessage, useIntl} from "react-intl";
import {useTheme} from "styled-components";

interface SubscriptionModalProps {
    openModal: boolean;
    onCloseModal: () => void;
    link: string;
}

export const SubscriptionModal: FC<SubscriptionModalProps> = ({openModal, onCloseModal, link}) => {
    const intl = useIntl();
    const theme = useTheme() as ThemeType;
    const [agendaLink, setAgendaLink] = useState<string>(link)

    const onCopyClick = () => {
        navigator.clipboard.writeText(agendaLink);
        onCloseModal();
    }

    return <Dialog show={openModal}
                   onClose={onCloseModal}
                   title={intl.formatMessage({id: 'SuscriptionModalTitle', defaultMessage: 'S\'abonner à un planning'})}
                   size={'sm'}>
        <SpanSubtitle1>
            <FormattedMessage
                id={'SubscriptionModalSubtitle'}
                defaultMessage={'Vous pouvez vous abonner à un planning pour consultation en suivant les instructions ci-dessous. ' +
                    'Les plannings auxquels vous vous abonnez se mettent à jour automatiquement. Ils sont affichés directement dans votre calendrier iPhone/iPad ou Google Agenda.'}
            />
        </SpanSubtitle1>
        <div style={{margin: '10px 0'}}>
            <SpanBody1 style={{color: theme.colors.dark, fontWeight: 600}}>
                <FormattedMessage
                    id={'SubscriptionModalIphoneTitle'}
                    defaultMessage={'Avec un iPhone ou un iPad'}
                />
            </SpanBody1>
        </div>
        <SpanSubtitle1>
            <FormattedMessage
                id={'SubscriptionModalIphoneSubtitle'}
                defaultMessage={'Accédez à Réglages > Calendrier > Comptes > Ajouter un compte > Autre. Touchez «Ajouter un cal. avec abonnement». Copiez l\'adresse de l\'agenda ci-dessous.'}
            />
        </SpanSubtitle1>
        <div style={{margin: '10px 0'}}>
            <SpanBody1 style={{color: theme.colors.dark, fontWeight: 600}}>
                <FormattedMessage
                    id={'SubscriptionModalGoogleTitle'}
                    defaultMessage={'Avec Google Agenda'}
                />
            </SpanBody1>
        </div>
        <SpanSubtitle1>
            <FormattedMessage
                id={'SubscriptionModalGoogleSubTitle'}
                defaultMessage={'Sur votre ordinateur, ouvrez {link}. À gauche, à côté de «Autres agendas» cliquez sur Ajouter > À partir de l\'URL. ' +
                    'Copiez l\'adresse de l\'agenda ci-dessous. Un délai minimum de 12 heures peut être nécessaire avant que les modifications s\'affichent dans Google Agenda.'}
                values={{
                    link: <a href={'https://calendar.google.com'} target={'_blank'}
                             rel="noreferrer">{intl.formatMessage({
                        id: 'GoogleAgenda',
                        defaultMessage: 'Google Agenda'
                    })}</a>
                }}
            />
        </SpanSubtitle1>
        <Row alignItems={'center'} style={{marginTop: '18px'}}>
            <Col columns={[12, 9, 9]}>
                <Input value={agendaLink} onChange={(v) => setAgendaLink(v)}/>
            </Col>
            <Col columns={[12, 2, 2]}>
                <Button colorType={'primary'} onClick={onCopyClick}>
                    <SpanButton>
                        <FormattedMessage
                            id={'Copy'}
                            defaultMessage={'Copier'}
                        />
                    </SpanButton>
                </Button>
            </Col>
        </Row>
    </Dialog>
}