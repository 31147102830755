import {FC, useState} from "react";
import {Col, Radio, Switch, ThemeType} from "@linkeo.com/ui-lib-react";
import {FormattedMessage, useIntl} from "react-intl";
import {useTheme} from "styled-components";
import {ParamsBookingInputGroup} from "./params-booking-input-group";
import {ParamsBookingShowCard} from "./params-booking-show-card";

export const ParamsBookingAgendaForm: FC = () => {
    const intl = useIntl();
    const theme = useTheme() as ThemeType;
    const [allowAgenda, setAllowAgenda] = useState<boolean>(false);
    const [agendaButton, setAgendaButton] = useState<string>('WHO');
    const [isMandatory, setIsMandatory] = useState<boolean>(false);

    return <ParamsBookingShowCard
        title={intl.formatMessage({id: 'ParamsBookingPageAgendaTitle', defaultMessage: 'Choix de l\'agenda'})}
        subtitle={intl.formatMessage({
            id: 'ParamsBookingPageAgendaSubTitle',
            defaultMessage: 'Donnez à vos clients la possibilité de choisir un agenda.'
        })}
        switched={allowAgenda}
        setSwitched={setAllowAgenda}
        switchLabel={intl.formatMessage({
            id: 'ParamsBookingAgendaSwitchLabel',
            defaultMessage: 'Donner la possibilité à vos clients de choisir un agenda à la réservation'
        })}
        caption={intl.formatMessage({
            id: 'ParamsBookingAgendaFormSwitchCaption',
            defaultMessage: 'Si vous activez cette option, vos clients pourront choisir sur quel agenda ils veulent effectuer leur réservation. Selon votre organisation, cela peut leur permettre de choisir avec quelle personne ou dans quelle endroit ils veulent réserver.'
        })}>
        <ParamsBookingInputGroup
            type={'radio'}
            label={intl.formatMessage({
                id: 'ParamsBookingAgendaButtonLabel',
                defaultMessage: 'Quel texte souhaitez-vous afficher sur le bouton permettant de choisir un agenda ?'
            })}
            caption={intl.formatMessage({
                id: 'ParamsBookingAgendaButtonCaption',
                defaultMessage: 'L\'intitulé "Avec qui ?" est adapté si vos agendas correspondent à des personnes. L\'intitulé "Où ?" est adapté si vos agendas correspondent à des lieux'
            })}>
            <Col>
                <Radio name={'ParamsBookingAgendaButtonOption'}
                       onChange={() => setAgendaButton('WHO')}
                       value={agendaButton === 'WHO'}>
                    <FormattedMessage
                        id={'ParamsBookingAgendaButtonWhoLabel'}
                        defaultMessage={'Avec qui ?'}
                    />
                </Radio>
            </Col>
            <Col>
                <Radio name={'ParamsBookingAgendaButtonOption'}
                       onChange={() => setAgendaButton('WHERE')}
                       value={agendaButton === 'WHERE'}>
                    <FormattedMessage
                        id={'ParamsBookingAgendaButtonWhereLabel'}
                        defaultMessage={'Où ?'}
                    />
                </Radio>
            </Col>
        </ParamsBookingInputGroup>
        <ParamsBookingInputGroup
            type={'switch'}
            label={intl.formatMessage({
                id: 'ParamsBookingAgendaMandatoryLabel',
                defaultMessage: 'Le choix de l\'agenda est-il obligatoire ?'
            })}
            caption={intl.formatMessage({
                id: 'ParamsBookingAgendaMandatoryCaption',
                defaultMessage: 'Vos client devront obligatoirement choisir un agenda si ils veulent effectuer une réservation'
            })}>
            <Switch
                value={isMandatory}
                onIcon={'check'}
                offIcon={'cross'}
                color={theme.colors.grey40}
                onChange={setIsMandatory}
                onLabel={intl.formatMessage({
                    id: 'IsMandatory',
                    defaultMessage: 'Obligatoire'
                })}
            />
        </ParamsBookingInputGroup>
    </ParamsBookingShowCard>
}