import axios from "axios";

interface SSOResource {
    access_token: string;
    client_id: string;
    expires: number;
    user_id: string;
}

interface SSORefresh {
    access_token: string;
    expires_in: number;
    refresh_token: string;
    token_type: 'Bearer';
}

export class SsoApi {
    constructor(private ssoUri: string) {
    }
    async getResource(token: string): Promise<SSOResource> {
        const params = new URLSearchParams();
        params.append('access_token', token);
        const response = await axios.post(`${this.ssoUri}/resource`, params,{
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        })
        return response.data
    }
    async refresh(clientId: string, clientSecret: string, refreshToken: string): Promise<SSORefresh> {
        const params = new URLSearchParams();
        params.append('grant_type', 'refresh_token');
        params.append('client_id', clientId);
        params.append('client_secret', clientSecret);
        params.append('refresh_token', refreshToken);
        const response = await axios.post(`${this.ssoUri}/token`, params, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        })
        return response.data
    }
}