import { FC, FormEvent, useState } from "react";
import { Card, Checkbox, Col, Input, Row, SpanBody1, SpanBody2, Textarea } from "@linkeo.com/ui-lib-react";
import { FormattedMessage, useIntl } from "react-intl";
import { FormFooter } from "../../commons/components/form-footer";

interface ServiceCategoryFormProps {
    form: { title: string, description: string, services: string[] };
    onCancelClick: () => void;
    services: string[]
}

export const ServiceCategoryForm: FC<ServiceCategoryFormProps> = ({ form: formProps, onCancelClick, services }) => {
    const intl = useIntl();
    const [form, setForm] = useState(formProps)

    const onCheckboxSelect = (value: string) => {
        const find = form.services.find(el => el === value)
        if (!find) {
            setForm({...form, services: [...form.services, value]})
        } else {
            setForm({...form, services: form.services.filter(el => el !== value)})
        }
    }

    const _onSubmit = (e: FormEvent) => {
        e.preventDefault();
        e.stopPropagation();
    }

    return <Card style={{ marginBottom: "28px" }}>
        <form onSubmit={_onSubmit}>
            <SpanBody2>
                <FormattedMessage
                    id={'ParamsServiceCategoryEditFormTitle'}
                    defaultMessage={'Ajouter une catégorie'}
                />
            </SpanBody2>
            <Input type={'text'} required
                placeholder={intl.formatMessage({ id: 'ParamsCategoryCardTitle', defaultMessage: 'Titre de la catégorie' }) + ' *'}
                value={form.title}
                onChange={(title) => setForm({ ...form, title })}
            />
            <Textarea placeholder={intl.formatMessage({ id: 'descriptionPlaceholder', defaultMessage: 'Description' })}
                value={form.description}
                style={{ minHeight: '110px' }}
                onChange={(description) => setForm({ ...form, description })}
            />
            <SpanBody2>
                <FormattedMessage
                    id={'ParamsServiceCategoryFormServicesTitle'}
                    defaultMessage={'Prestations associées'}
                />
            </SpanBody2>
            <p style={{ margin: 0 }}>
                <SpanBody1>
                    <FormattedMessage
                        id={'ParamsServiceCategoryFormServicesSubTitle'}
                        defaultMessage={'Cochez dans la liste ci-dessous les prestations associées à cette catégorie.'}
                    />
                </SpanBody1>
            </p>
            <Row style={{marginBottom: '28px'}}>
                {services.map((service, index) => {
                    return <Col key={index}>
                        <Checkbox value={!!form.services.find(el => el === service)}
                            onChange={() => onCheckboxSelect(service)}>
                            {service}
                        </Checkbox>
                    </Col>
                })}
            </Row>
            <FormFooter
                onCancelClick={onCancelClick}
                saveIcon={"check"}
                submitButtonText={intl.formatMessage({ id: 'SaveButtonLabel', defaultMessage: 'Enregistrer' })}
            />
        </form>
    </Card>
}