import React, {FC} from "react";
import {Col, Row, SpanSubtitle1, SquareButton, ThemeType} from "@linkeo.com/ui-lib-react";
import {useIntl} from "react-intl";
import {useTheme} from "styled-components";
import {ReactComponent as SubscriptionIcon} from "../../icons/subscription.svg";
import {DragButton} from "../../commons/components/drag-button";
import {CardWrapper} from "../../params/components/card-wrapper";

interface ColAgendaProps {
    subtitle: string;
    title: string;
    columns?: number | number[]
}

const ColAgenda: FC<ColAgendaProps> = ({subtitle, title, columns}) => {
    const theme = useTheme() as ThemeType;

    return <Col columns={columns}>
        <div>
            <SpanSubtitle1 style={{color: theme.colors.grey60}}>
                {title}
            </SpanSubtitle1>
        </div>
        <SpanSubtitle1 style={{fontWeight: 600}}>
            {subtitle}
        </SpanSubtitle1>
    </Col>
}

interface AgendasCardProps {
    name: string;
    description: string;
    onSubscriptionClick: () => void;
    onAgendaDeleteClick: () => void;
    onEditClick: () => void;
    disabled: boolean;
    onDragOver: (e: React.DragEvent) => void;
    onDrop: () => void;
    onDragStart: () => void;
    onDragEnter: () => void;
}

export const AgendasCard: FC<AgendasCardProps> = ({
                                                                  name,
                                                                  description,
                                                                  onSubscriptionClick,
                                                                  onAgendaDeleteClick,
                                                                  onEditClick,
                                                                  disabled,
                                                                  onDragOver,
                                                                  onDrop,
                                                                  onDragStart,
                                                                  onDragEnter
                                                              }) => {
    const intl = useIntl();
    const theme = useTheme() as ThemeType;


    return <CardWrapper disabled={disabled} draggable
                        onDragEnter={onDragEnter}
                        onDragStart={onDragStart}
                        onDragOver={(e) => onDragOver(e)}
                        onDrop={onDrop}>
        <Row alignItems={'center'}>
            <Col columns={[12, 1]}>
                <DragButton/>
            </Col>
            <ColAgenda columns={[6, 3]} subtitle={name} title={intl.formatMessage({
                id: 'ParamsAgendasCardAgendaTitle',
                defaultMessage: 'Nom du planning :'
            })}/>
            <ColAgenda columns={[12, 6]} subtitle={description} title={intl.formatMessage({
                id: 'ParamsAgendasCardAgendaDescriptionTitle',
                defaultMessage: 'Description :'
            })}/>
            <Col columns={[12, 2]}>
                <Row justifyContent={'center'}>
                    <SquareButton small style={{margin: 0, padding: '5px 0 0 5px'}} onClick={onSubscriptionClick}>
                        <SubscriptionIcon height={18} width={18} fill={theme.colors.grey60}/>
                    </SquareButton>
                    <SquareButton icon={'edit'} fontColor={theme.colors.grey60} small style={{margin: '0 10px'}}
                                  onClick={onEditClick}/>
                    <SquareButton icon={'delete'} fontColor={theme.colors.grey60} small onClick={onAgendaDeleteClick}/>
                </Row>
            </Col>
        </Row>
    </CardWrapper>
}