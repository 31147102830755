import {FC, ReactNode} from "react";
import styled from "styled-components";
import {SpanSubtitle1} from "@linkeo.com/ui-lib-react";

const RadioWrapper = styled.div<{inCard?: boolean}>`
  margin-top: 20px;
  padding-top: ${({inCard}) => inCard ? '10px' : '20px'};
  border-top: ${({inCard, theme}) => inCard ? 'none' : `1px solid ${theme.colors.grey90}`};
`

interface ParamsRadioItemProps {
    children: ReactNode;
    label?: string;
    inCard?: boolean;
}

export const ParamsRadioItem: FC<ParamsRadioItemProps> = ({label, children, inCard}) => {

    return <RadioWrapper inCard={inCard}>
        <label>
            {label && <SpanSubtitle1>
                {label}
            </SpanSubtitle1>}
            {children}
        </label>
    </RadioWrapper>
}